import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  rates: any;
  notificationAlert: boolean;
  transactionAlert: boolean;
  nbtRates: any;
  countries: any;
  selectRates: any;
  countriesAlpha3: any;
  loadingRates: boolean;
  statementOrderModalOpen: boolean;
}
const initialState: UserState = {
  rates: [],
  notificationAlert: false,
  transactionAlert: false,
  nbtRates: [],
  countries: [],
  selectRates: [],
  countriesAlpha3: [],
  loadingRates: false,
  statementOrderModalOpen: false,
};

export const global = createSlice({
  name: 'global',
  initialState,
  reducers: {
    statementOrderModalOpenAC(state, action: PayloadAction<any>) {
      state.statementOrderModalOpen = action.payload;
    },
    loadingRatesAC(state, action: PayloadAction<any>) {
      state.loadingRates = action.payload;
    },
    countriesAC(state, action: PayloadAction<any>) {
      state.countries = [];
      action.payload.forEach((item: any) => {
        state.countries.push({
          label: item.name_en + ' (' + item.alpha3 + ')',
          value: item.alpha3,
          id: item.id,
        });
      });
      action.payload.forEach((item: any) => {
        state.countriesAlpha3.push({
          label: item.name_en + ' (' + item.alpha3 + ')',
          value: item.alpha3,
          id: item.id,
        });
      });
    },
    nbtRatesAC(state, action: PayloadAction<any>) {
      state.nbtRates = action.payload;
    },
    ratesAC(state, action: PayloadAction<any>) {
      state.rates = action.payload;
      state.selectRates = [];
      action.payload.forEach((item: any) => {
        state.selectRates.push({
          label: item.ccy,
          value: item.ccy,
          rate: item.sale_rate,
        });
      });
      state.selectRates.push({
        label: 'TJS',
        value: 'TJS',
      });
    },
    notificationAlertAC(state, action: PayloadAction<boolean>) {
      state.notificationAlert = action.payload;
    },
    transactionAlertAC(state, action: PayloadAction<boolean>) {
      state.transactionAlert = action.payload;
    },
  },
});

export const {
  ratesAC,
  notificationAlertAC,
  transactionAlertAC,
  nbtRatesAC,
  countriesAC,
  loadingRatesAC,
  statementOrderModalOpenAC,
} = global.actions;
export default global.reducer;
