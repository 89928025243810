import React, { useEffect, useState } from 'react';

import Auth from '@pages/Auth/Index';
import Main from '@pages/Main/Index';

export const useAppRoute = (isAuth: boolean) => {
  const [isAt, setIsAt] = useState<boolean>(true);
  const at = localStorage.getItem('at');

  useEffect(() => {
    setIsAt(!!at);
  }, [at]);

  return isAuth && isAt ? <Main /> : <Auth />;
};
