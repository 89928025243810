import { Route, Routes } from 'react-router-dom';
import Statement from './Statement';
import Orders from './Orders';

const Index = () => {
  return (
    <div className='flex justify-center'>
      <div className='lg:w-[1130px] w-full'>
        <div className='flex'>
          <div className='p-4 lg:p-8 w-full h-full bg-white mb-12'>
            <Routes>
              <Route path='/' element={<Statement />} key='list' />
              <Route path='/orders' element={<Orders />} key='orders' />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
