import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { Button, Popover } from 'antd';
import { useNavigate } from 'react-router-dom';

import accountsActiveIcon from '@assets/images/icons/active/accountsIcon-active.svg';
import accountIcon from '@assets/images/icons/accountIcon.svg';
import transactionsIconActive from '@assets/images/icons/active/transactionsIcon-active.svg';
import updateIcon from '@assets/images/icons/updateIcon.svg';
import calendarIcon from '@assets/images/icons/calendarIcon.svg';

import Currencies from '@components/Currency/OrienbankRates';

import { getTransactionListApi } from '@http/transactions';
import { getAccountsApi } from '@http/accounts';

import getStatus from '@utils/getStatus';
import digitNumber from '@utils/digitNumber';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

function Dashboard() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const transactionsHistory = useAppSelector(state => state.transactionsReducer.transactionsHistory);
  const bankAccounts = useAppSelector(state => state.accountsReducer.filteredAccounts);
  const userInfo = useAppSelector(state => state.appReducer.user);

  const [showAccountBalanceIndex, setShowAccountBalanceIndex] = useState('');

  const showAccounBalance = (item: any) => {
    if (showAccountBalanceIndex === item.cust_ac_no) {
      setShowAccountBalanceIndex('');
    } else {
      setShowAccountBalanceIndex(item.cust_ac_no);
    }
  };

  useEffect(() => {
    dispatch(getTransactionListApi(1, {}));
    dispatch(getAccountsApi());
  }, []);

  return (
    <div>
      <main className='pb-14 w-full bg-stone-100'>
        <div className='flex justify-center'>
          <div className='lg:w-[1130px] w-full' style={{ color: '#011957' }}>
            <div className='py-5 lg:py-8 flex items-center max-lg:mx-3'>
              <img className='w-8 h-8' src={accountIcon} alt='' />
              <div className='ml-3'>
                <p className='font-semibold text-base'>{userInfo?.cust_info?.full_name.replace(/['"""]/g, '')}</p>
                <span className='text-base'>{userInfo?.cust_info?.address}</span>
              </div>
            </div>
            <div className='max-lg:mx-3 md:grid lg:grid-cols-3 grid-cols-2 gap-6'>
              <div className='lg:col-span-2 col-span-1 bg-white rounded-xl p-5 w-full'>
                <div className='flex justify-between'>
                  <div className='flex items-center'>
                    <div className=''>
                      <img className='w-4 h-4' src={accountsActiveIcon} alt='' />
                    </div>
                    <div className='ml-3 text-sm'>
                      <p className='font-semibold text-base'>{t('MyAccounts')}</p>
                    </div>
                  </div>
                </div>
                <div className='mt-5'>
                  <div>
                    {bankAccounts.length > 0 ? (
                      <div>
                        {bankAccounts.slice(0, 3).map((item: any) => (
                          <div key={item.cust_ac_no}>
                            <div
                              style={{ borderBottom: '1px solid #eee' }}
                              className='flex justify-between max-lg:h-[60px] max-lg:mt-5 lg:p-5 border-b'
                            >
                              <div className='flex'>
                                <div className='ml-3'>
                                  <p className='text-base font-medium'>{item.cust_ac_no}</p>
                                  <div>
                                    {item.record_stat === 'O' ? (
                                      <div className='flex items-center'>
                                        <div className='bg-green-500 h-3 w-3 mr-3 rounded-full' />
                                        <p className='max-md:text-sm text-base'>{t('Active')}</p>
                                      </div>
                                    ) : (
                                      <div className='flex items-center'>
                                        <div className='bg-red-400 h-3 w-3 mr-3 rounded-full' />
                                        <p className='max-md:text-sm text-base'>{t('NoActive')}</p>
                                      </div>
                                    )}
                                  </div>
                                  <div className='lg:hidden mt-0.5'>
                                    {showAccountBalanceIndex === item.cust_ac_no ? (
                                      <span className='max-md:text-sm text-base'>
                                        {digitNumber(item.acy_avl_bal)} <span className='font-bold'>{item.ccy}</span>
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <p className='text-base font-medium max-lg:hidden'>
                                {showAccountBalanceIndex === item.cust_ac_no ? (
                                  <div>
                                    <span className='max-md:text-sm text-base'>
                                      {digitNumber(item.acy_avl_bal)} <span className='font-bold'>{item.ccy}</span>
                                    </span>
                                    <Popover title={t('Hide balance')} overlayStyle={{ width: '175px' }}>
                                      <EyeInvisibleOutlined
                                        style={{ fontSize: 20 }}
                                        className='mx-3 cursor-pointer'
                                        onClick={() => showAccounBalance(item)}
                                      />
                                    </Popover>
                                  </div>
                                ) : (
                                  <Popover title={t('Show balance')} overlayStyle={{ width: '170px' }}>
                                    <EyeOutlined
                                      style={{ fontSize: 20 }}
                                      className='mx-3 cursor-pointer'
                                      onClick={() => showAccounBalance(item)}
                                    />
                                  </Popover>
                                )}
                              </p>

                              <p className='text-base font-medium lg:hidden'>
                                {showAccountBalanceIndex === item.cust_ac_no ? (
                                  <EyeInvisibleOutlined
                                    style={{ fontSize: 20 }}
                                    className='mx-3 cursor-pointer'
                                    onClick={() => showAccounBalance(item)}
                                  />
                                ) : (
                                  <EyeOutlined
                                    style={{ fontSize: 20 }}
                                    className='mx-3 cursor-pointer'
                                    onClick={() => showAccounBalance(item)}
                                  />
                                )}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className='h-24 flex items-center justify-center'>
                        <p>{t('NoAccounts')}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex justify-end mt-6'>
                  <Button type='primary' htmlType='submit' onClick={() => navigate('/accounts/bankAccounts')}>
                    {t('All accounts')}
                  </Button>
                </div>
              </div>
              <div className='col-span-1 max-md:mt-6 h-full'>
                <div className='bg-white rounded-xl p-5 h-full'>
                  <div>
                    <Currencies size='text-sm' />
                    <div className='flex justify-end lg:mt-12'>
                      <Button type='primary' htmlType='submit' onClick={() => navigate('/rates')}>
                        {t('All currencies')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='max-lg:mx-3 mt-7'>
              <div className='bg-white rounded-xl p-5'>
                <div className='flex justify-between'>
                  <div className='flex items-center'>
                    <div className=''>
                      <img className='w-4 h-4' src={transactionsIconActive} alt='' />
                    </div>
                    <div className='ml-3 text-sm'>
                      <p className='font-semibold text-base'>{t('Last operations')}</p>
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <Popover title={t('Update')} overlayStyle={{ width: '90px' }}>
                      <div className='cursor-pointer' onClick={() => dispatch(getTransactionListApi(1, {}))}>
                        <img className='w-4 h-4' src={updateIcon} alt='' />
                      </div>
                    </Popover>
                    <Popover title={t('View all')} overlayStyle={{ width: '140px' }}>
                      <div className='ml-7 cursor-pointer' onClick={() => navigate('/transactions/allTransactions')}>
                        <img className='w-4 h-4' src={calendarIcon} alt='' />
                      </div>
                    </Popover>
                  </div>
                </div>
                <div className='mt-5'>
                  <div
                    className='grid max-md:grid-cols-10 grid-cols-10 max-md:p-3 max-md:text-sm text-base p-5 gap-3 font-semibold'
                    style={{ borderBottom: '1px solid #eee' }}
                  >
                    <p className='max-md:col-span- col-span-2 max-md:hidden'>{t('Date')}</p>
                    <p className='max-md:col-span-6 col-span-4'>{t('Operation')}</p>
                    <p className='col-span-2 text-right max-md:hidden'>{t('Status')}</p>
                    <p className='max-md:col-span-4 col-span-2 text-right'>{t('Amount')}</p>
                  </div>
                  {transactionsHistory?.results?.length > 0 ? (
                    <div>
                      {transactionsHistory?.results?.slice(0, 5).map((item: any) => (
                        <div
                          key={item.trans_id}
                          style={{ borderBottom: '1px solid #eee' }}
                          className='max-md:p-3 p-5 gap-5 grid max-md:grid-cols-10 grid-cols-10 listItem md:text-base max-md:text-sm'
                        >
                          <p className='max-md:col-span-3 col-span-2 max-md:hidden font-medium max-md:text-sm'>
                            {item.created_at.slice(0, 10)}
                          </p>
                          <div className='max-md:col-span-6 col-span-4'>
                            <p className='font-medium'>{item.type_name}</p>
                            <p className='text-slate-600 text-sm'>{item.narrative}</p>
                            <p className='md:hidden text-slate-600 max-md:text-sm mt-2'>
                              {item.created_at.slice(0, 10)}
                            </p>
                          </div>
                          <p className='max-md:hidden col-span-2 text-right'>{getStatus(item.status, 12, t)}</p>
                          <p className='max-md:col-span-4 col-span-2 text-right text-base font-medium max-md:text-sm'>
                            <span>{digitNumber(item.receiving_amount)} </span>
                            <span>{item.receiving_ccy}</span>
                            <p className='md:hidden mt-2'>{getStatus(item.status, 12, t)}</p>
                          </p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className='h-24 flex items-center justify-center'>
                      <p>{t('No Transactions')}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Dashboard;
