import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import digitNumber from '@utils/digitNumber';
import { Button } from 'antd';
import { downloadLoanPaymentScheduleApi } from '@http/loans';
import { formatDate } from '@utils/formatDate';
import { useState } from 'react';

function LoanInfo() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loanInfo = useAppSelector(state => state.loansReducer.loanInfo);

  return (
    <div className='bg-orange-50 p-5 rounded text-sm'>
      <h1 className='text-lg font-semibold'>{t('Loan Information')}</h1>
      <div className='mt-5'>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Account')}:</p>
          <p className='font-medium'>{loanInfo?.cr_prod_ac}</p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Loan amount')}:</p>
          <p className='font-medium'>
            {digitNumber(loanInfo?.amount_financed)} {loanInfo?.currency}
          </p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Loan start date')}:</p>
          <p className='font-medium'>{formatDate(loanInfo?.book_date)}</p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Loan end date')}:</p>
          <p className='font-medium'>{formatDate(loanInfo?.maturity_date)}</p>
        </div>
        {/* <div className='mt-3'>
          <p className='text-slate-500'>{t('Loan type')}:</p>
          <p className='font-medium'>{getLoanTypeName(loanInfo?.product_code)}</p>
        </div> */}
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Loan balance')}:</p>
          <p className='font-medium'>
            {digitNumber(loanInfo?.loan_balance)} {loanInfo?.currency}
          </p>
        </div>

        {loanInfo?.next_payment_date ? (
          <div className='mt-3'>
            <p className='text-slate-500'>{t('Next payment date')}:</p>
            <p className='font-medium'>{formatDate(loanInfo?.next_payment_date)}</p>
          </div>
        ) : null}

        {loanInfo?.amount_due ? (
          <div className='mt-3'>
            <p className='text-slate-500'>{t('Amount due')}:</p>
            <p className='font-medium'>
              {digitNumber(loanInfo?.amount_due)} {loanInfo?.currency}
            </p>
          </div>
        ) : null}

        <div className='mt-3'>
          {loanInfo?.account_status === 'A' ? (
            <div>
              <div className='flex items-center'>
                <div className='bg-green-500 h-3 w-3 mr-2 rounded-full' />
                <p>{t('Active')}</p>
              </div>
              <Button
                type='primary'
                size='small'
                className='mt-5'
                onClick={() => dispatch(downloadLoanPaymentScheduleApi({ contract_no: loanInfo.account_number }))}
              >
                {t('Download payment schedule')}
              </Button>
            </div>
          ) : (
            <div className='flex items-center'>
              <div className='bg-red-400 h-3 w-3 mr-2 rounded-full' />
              <p>{t('Closed')}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoanInfo;
