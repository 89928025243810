import { Button, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetPasswordApi } from '@http/auth';
import { useTranslation } from 'react-i18next';
import Header from './components/header';
import Footer from '@components/Container/Footer';
import FooterNew from '@components/Container/Footer-new';

function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async (values: any) => {
    const query = {
      new_password: values.newPassword,
      new_password2: values.newPassword2,
    };

    dispatch(resetPasswordApi(query, navigate));
  };

  return (
    <div
      className='bg-white'
      style={{ height: '100vh', display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: 'auto 1fr auto' }}
    >
      <Header />
      <main className='flex items-center justify-center py-12'>
        <div className='w-[340px] lg:w-[480px]'>
          <Form name='optCode' onFinish={onFinish} autoComplete='off'>
            <div className='text-center mb-7 text-2xl'>
              <p>{t('Change your password')}</p>
            </div>

            <Form.Item
              name='newPassword'
              rules={[
                { required: true, message: `${t('Required')}` },
                { min: 8, message: `${t('Field must contain at least 8 digits')}` },
              ]}
              hasFeedback
            >
              <Input.Password
                className='auth__input-primary text-lg'
                style={{ width: '100%' }}
                placeholder={`${t('Password')}`}
              />
            </Form.Item>

            <Form.Item
              name='newPassword2'
              dependencies={['newPassword']}
              hasFeedback
              rules={[
                { min: 8, message: `${t('Field must contain at least 8 digits')}` },
                {
                  required: true,
                  message: `${t('Required')}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(`${t('Password does not match')}`));
                  },
                }),
              ]}
            >
              <Input.Password
                className='auth__input-primary text-lg'
                style={{ width: '100%' }}
                placeholder={`${t('Confirm password')}`}
              />
            </Form.Item>

            <Form.Item>
              <div className='flex justify-end mt-5'>
                <Button className='w-full h-[57px] text-lg' type='primary' htmlType='submit'>
                  {t('Save')}
                </Button>
              </div>
            </Form.Item>
            <p className='text-center'>{t('Change and remind password')}</p>
          </Form>
        </div>
      </main>
      <div className='max-lg:hidden mt-10' style={{ borderTop: '1px solid #eee' }}>
        <FooterNew />
      </div>
    </div>
  );
}

export default ResetPassword;
