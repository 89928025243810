import iconG from '@assets/images/deposits/iconG.svg';
import { Button } from 'antd';

function DepositsBanner(props: any) {
  const { deposit } = props;

  return (
    <div className='bg-white overflow-hidden rounded-[30px] items-center grid grid-cols-[auto_2fr] gap-x-5 mb-14'>
      <img src={deposit?.imgPath} alt='' />
      <div className='flex items-center p-[30px]'>
        <div>
          <h1 className='text-2xl color-active'>{deposit?.title}</h1>
          <p className='text-base text-stone-500 mt-2'>{deposit?.subTitle}</p>
          <div className='mt-7 grid grid-cols-1 gap-y-5'>
            {deposit?.conditions?.map((condition: any) => (
              <div className='flex items-start space-x-3 text-base'>
                <img src={iconG} alt='' className='w-8 h-8' />
                <div>
                  <p className='mb-2 font-semibold'>{condition?.title}</p>
                  <p>{condition?.text}</p>
                </div>
              </div>
            ))}
          </div>
          <Button type='primary' className='mt-10'>
            Подробнее
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DepositsBanner;
