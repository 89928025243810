import { useState } from 'react';
import { Button, Form, Select, Input, Card } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useAppDispatch } from '@hooks/redux';
import { getSalaryInfoApi } from '@http/salary';
import { useLocation } from 'react-router-dom';
import { salaryInfoSearchQueryAC } from '@store/reducers/salary';
import { useTranslation } from 'react-i18next';

const SalaryInfoSearchForm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const pickedSalaryFileId = location.pathname.slice(13);
  const [searchQuery, setSearchQuery] = useState({
    file_id: pickedSalaryFileId,
    status: '',
    from_amount: '',
    to_amount: '',
    fio: '',
  });

  const handleFinish = () => {
    dispatch(getSalaryInfoApi(searchQuery, 1));
    dispatch(salaryInfoSearchQueryAC(searchQuery));
  };

  return (
    <Card type='inner' title='Фильтры'>
      <Form onFinish={handleFinish} form={form}>
        <div className='flex items-start gap-x-4'>
          <Form.Item name='fio' className='w-[22%] mb-0'>
            <Input
              size='large'
              placeholder={`${t('FIO')}`}
              onChange={(e: any) => setSearchQuery({ ...searchQuery, fio: e.target.value })}
            />
          </Form.Item>

          <Form.Item name='from_amount' className='w-[160px] mb-0'>
            <Input
              type='number'
              max={9999999}
              min={1}
              maxLength={12}
              size='large'
              placeholder={`${t('From amount')}`}
              onChange={(e: any) => setSearchQuery({ ...searchQuery, from_amount: e.target.value })}
            />
          </Form.Item>
          <Form.Item name='to_amount' className='w-[160px] mb-0'>
            <Input
              type='number'
              max={9999999}
              min={1}
              maxLength={12}
              size='large'
              placeholder={`${t('To amount')}`}
              onChange={(e: any) => setSearchQuery({ ...searchQuery, to_amount: e.target.value })}
            />
          </Form.Item>
          <Form.Item name='status' className='w-[200px] mb-0'>
            <Select
              allowClear
              size='large'
              placeholder={`${t('Status')}`}
              onChange={value => setSearchQuery({ ...searchQuery, status: value })}
            >
              <Select.Option value='SUCCESS'>{t('Successful')}</Select.Option>
              <Select.Option value='IN_PROGRESS'>{t('In progress')}</Select.Option>
              <Select.Option value='IN_CONFIRMATION'>{t('In confirmation')}</Select.Option>
              <Select.Option value='IN_VERIFICATION'>{t('In verification')}</Select.Option>
              <Select.Option value='FAILED'>{t('Failed')}</Select.Option>
              <Select.Option value='PARTIAL_SUCCESS'>{t('Partial success')}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item className='mb-0'>
            <Button icon={<SearchOutlined />} size='large' type='primary' htmlType='submit'>
              {t('Search')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Card>
  );
};

export default SalaryInfoSearchForm;
