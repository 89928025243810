import { useState } from 'react';
import { useAppSelector } from '@hooks/redux';
import currencyActiveIcon from '@assets/images/icons/active/currencyIcon-active.svg';
import usaIcon from '@assets/images/icons/usaIcon.png';
import europeIcon from '@assets/images/icons/europeIcon.png';
import russiaIcon from '@assets/images/icons/russiaIcon.png';
import { AllRates } from './allRates';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

function OrienbankRates(props: any) {
  const { t } = useTranslation();
  const { showAll, size } = props;

  const [showAllRates, setShowAllRates] = useState(false);
  const currentDate = new Date().toLocaleString();
  const rates = useAppSelector(state => state.globalReducer.rates);
  const nbtRates = useAppSelector(state => state.globalReducer.nbtRates);

  const loadingRates = useAppSelector(state => state.globalReducer.loadingRates);

  return (
    <div>
      <div className='flex items-center'>
        <div className=''>
          <img className='w-4 h-4' src={currencyActiveIcon} alt='' />
        </div>
        <div className='ml-3'>
          <p className='font-semibold text-base'>{t('Exchange Rates')}</p>
        </div>
      </div>
      <p className='text-xs mt-1'>
        {t('Updated')} {currentDate}
      </p>
      <div className={`${size} grid grid-cols-12 border-b pb-2 gap-5 mt-5`}>
        <p className='col-span-4 font-semibold'>{t('Currency')}</p>
        <p className='col-span-2 font-semibold'>{t('NBT')}</p>
        <p className='col-span-3 font-semibold text-right'>{t('Purchase')}</p>
        <p className='col-span-3 font-semibold text-right'>{t('Sale')}</p>
      </div>
      {loadingRates ? (
        <div>
          <div className='flex justify-between mt-5'>
            <Skeleton.Input size='small' />
            <Skeleton.Input style={{ width: '180px' }} size='small' />
          </div>
          <div className='flex justify-between mt-5'>
            <Skeleton.Input size='small' />
            <Skeleton.Input style={{ width: '180px' }} size='small' />
          </div>
          <div className='flex justify-between mt-5'>
            <Skeleton.Input size='small' />
            <Skeleton.Input style={{ width: '180px' }} size='small' />
          </div>
        </div>
      ) : (
        <div className={`${size}`}>
          <div className='grid grid-cols-12 mt-3 pb-2 gap-5'>
            <div className='col-span-4'>
              <div className='flex'>
                <img className='w-5 h-5 mr-3' src={usaIcon} alt='' />
                <span>{nbtRates[0]?.ccy}</span>
              </div>
              <span>{t('One Dollar')}</span>
            </div>
            <span className='col-span-2'>{nbtRates[0]?.rate}</span>
            <span className='col-span-3 text-right'>{rates[1]?.buy_rate}</span>
            <span className='col-span-3 text-right'>{rates[1]?.sale_rate}</span>
          </div>
          <div className='grid grid-cols-12 mt-3 pb-2 gap-5'>
            <div className='col-span-4'>
              <div className='flex'>
                <img className='w-5 h-5 mr-3' src={europeIcon} alt='' />
                <span>{nbtRates[1]?.ccy}</span>
              </div>
              <span>{t('One Euro')}</span>
            </div>
            <span className='col-span-2'>{nbtRates[1]?.rate}</span>
            <span className='col-span-3 text-right'>{rates[2]?.buy_rate}</span>
            <span className='col-span-3 text-right'>{rates[2]?.sale_rate}</span>
          </div>
          <div className='grid grid-cols-12 mt-3 pb-2 gap-5'>
            <div className='col-span-4'>
              <div className='flex'>
                <img className='w-5 h-5 mr-3' src={russiaIcon} alt='' />
                <span>{nbtRates[2]?.ccy}</span>
              </div>
              <span>{t('One Ruble')}</span>
            </div>
            <span className='col-span-2'>{nbtRates[2]?.rate}</span>
            <span className='col-span-3 text-right'>{rates[0]?.buy_rate}</span>
            <span className='col-span-3 text-right'>{rates[0]?.sale_rate}</span>
          </div>
        </div>
      )}
      <div className='mt-5 mb-7'>
        <p
          className='color-active text-end font-semibold cursor-pointer'
          onClick={() => setShowAllRates(!showAllRates)}
        >
          {showAll ? <div>{!showAllRates ? `${t('Show all currencies')}` : `${t('Hide all currencies')}`}</div> : null}
        </p>
      </div>
      {showAll ? <div>{showAllRates ? <AllRates /> : null}</div> : null}
    </div>
  );
}

export default OrienbankRates;
