import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@hooks/redux';

import { useTranslation } from 'react-i18next';
import DepositInfo from '@components/Deposits/DepositInfo';
import { Button } from 'antd';
import DepositList from '@components/Deposits/DepositList';

function Deposits() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const depositList = useAppSelector(state => state.depositsReducer.depositList);

  return (
    <div className='flex justify-center'>
      <div className='lg:w-[1130px] w-full'>
        <div className='flex'>
          <div className='p-4 lg:p-8 w-full h-full bg-white mb-12'>
            <div className='flex justify-between'>
              <h1 className='text-xl lg:text-2xl font-semibold color-active mb-5'>{t('Deposits')}</h1>
              {/* <Button type='primary' onClick={() => navigate('/deposits/info')}>
                {t('Open new deposit')}
              </Button> */}
            </div>
            <div className='sm:grid lg:grid-cols-6 sm:grid-cols-8 gap-10'>
              <div className='lg:col-span-4 sm:col-span-5'>
                <DepositList itemsCount='20' defaultInfo={true} />
              </div>
              <div className='lg:col-span-2 sm:col-span-3'>
                {depositList?.results?.length > 0 ? (
                  <div className='max-sm:mt-5'>
                    <DepositInfo />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deposits;
