import { useEffect } from 'react';
import { useAppRoute } from '@hooks/useAppRoute';
import { isAuthAC, isSwitchRouteLoadingAC } from '@store/reducers/app';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import SpinApp from '@components/Global/SpinApp';
import LoaderApp from '@components/Global/LoaderApp';
import { getUserInfoApi, getUserPermissionsApi } from '@http/user';
import { getAlertsApi, getNbtRatesApi, getRatesApi } from '@http/global';
import NotificationModal from '@components/Global/NotificationModal';
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const isSpin = useAppSelector(state => state.appReducer.isSpin);
  const isAuth = useAppSelector(state => state.appReducer.isAuth);
  const isSwitchRouteLoading = useAppSelector(state => state.appReducer.isSwitchRouteLoading);
  const token = localStorage.getItem('at');
  const lang: any = localStorage.getItem('lang');

  const appRoute = useAppRoute(isAuth);

  useEffect(() => {
    i18n.changeLanguage(lang);
    if (token) {
      dispatch(isAuthAC(true));
      dispatch(getUserPermissionsApi());
      dispatch(getUserInfoApi());
      dispatch(getAlertsApi());
      dispatch(getRatesApi());
      dispatch(getNbtRatesApi());
    } else {
      localStorage.removeItem('at');
      dispatch(isSwitchRouteLoadingAC(false));
    }
  }, [token]);

  if (isSwitchRouteLoading) return <LoaderApp />;

  return (
    <SpinApp spinning={isSpin > 0}>
      {appRoute}
      <NotificationModal />
    </SpinApp>
  );
}

export default App;
