import { Routes, Route, Navigate } from 'react-router-dom';
import Header from '@components/Container/Header';
import Payments from './Content/Payments/Index';
import Salary from './Content/Salary/Index';
import Accounts from './Content/Accounts/Index';
import Statement from './Content/Statement/Index';
import Transactions from './Content/Transactions/Index';
import Commissions from './Content/Commissions/Commissions';
import Visa from './Content/Visa/Visa';
import Rates from './Content/Rates/Rates';
import AllAccounts from './Content/Accounts/AllAccounts';
import Profile from './Content/Profile/Profile';
import OwnAccountsPaymentStatus from '@components/Payments/status/OwnAccountsPaymentStatus';
import InternalPaymentStatus from '@components/Payments/status/InternalPaymentStatus';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { paymentsPermissionsAC } from '@store/reducers/user';
import FooterNew from '@components/Container/Footer-new';

import InterbankPaymentStatus from '@components/Payments/status/InterbankPaymentStatus';
import SwiftPaymentStatus from '@components/Payments/status/SwiftPaymentStatus';
import Templates from './Content/Templates/Templates';
import ExchangePaymentStatus from '@components/Payments/status/ExchangePaymentStatus';
import SwiftRubPaymentStatus from '@components/Payments/status/SwiftRubPaymentStatus';
import Dashboard from '@pages/Dashboard';
import Deposits from './Content/Deposits/Deposits';
import DepositsInfo from './Content/Deposits/DepositsInfo';
import Loans from './Content/Loans/Index';
import Counterparties from './Content/Counterparties/Counterparties';
import CounterpartiesCreate from './Content/Counterparties/CounterpartiesCreate';

function Main() {
  const dispatch = useAppDispatch();
  const permissions = useAppSelector(state => state.userReducer.permissions);

  let routes: any = [];
  const permissionsRoutes: any = [
    {
      key: 'statement',
      element: <Statement />,
      path: '/statement/*',
      isMobile: true,
    },
    {
      key: 'payments',
      element: <Payments />,
      path: '/payments/*',
      isMobile: true,
    },
    {
      key: 'salary',
      element: <Salary />,
      path: '/salary/*',
      isMobile: true,
    },
    {
      key: 'deposits',
      element: <Deposits />,
      path: '/deposits',
      isMobile: true,
    },
    {
      key: 'credits',
      element: <Loans />,
      path: '/loans/*',
      isMobile: true,
    },
  ];

  const staticRoutes = [
    {
      key: '/',
      element: <Dashboard />,
      path: '/',
      isMobile: true,
    },
    {
      key: '/profile',
      element: <Profile />,
      path: '/profile',
      isMobile: true,
    },
    {
      key: '/counterparties',
      element: <Counterparties />,
      path: '/counterparties',
      isMobile: true,
    },
    {
      key: '/templates',
      element: <Templates />,
      path: '/templates',
      isMobile: true,
    },
    {
      key: '/transactions/*',
      element: <Transactions />,
      path: '/transactions/*',
      isMobile: true,
    },
    {
      key: '/accounts/*',
      element: <Accounts />,
      path: '/accounts/*',
      isMobile: true,
    },
    {
      key: '/commissions',
      element: <Commissions />,
      path: '/commissions',
      isMobile: true,
    },
    {
      key: '/visa',
      element: <Visa />,
      path: '/visa',
      isMobile: true,
    },
    {
      key: '/rates',
      element: <Rates />,
      path: '/rates',
      isMobile: true,
    },
    {
      key: '/allAccounts',
      element: <AllAccounts />,
      path: '/allAccounts',
      isMobile: true,
    },
    {
      key: 'ownAccountsPaymentStatus',
      element: <OwnAccountsPaymentStatus />,
      path: '/ownAccountsPaymentStatus/*',
      isMobile: true,
    },
    {
      key: 'internalPaymentStatus',
      element: <InternalPaymentStatus />,
      path: '/internalPaymentStatus/*',
      isMobile: true,
    },
    {
      key: 'interbankPaymentStatus',
      element: <InterbankPaymentStatus />,
      path: '/interbankPaymentStatus/*',
      isMobile: true,
    },
    {
      key: 'swiftPaymentStatus',
      element: <SwiftPaymentStatus />,
      path: '/swiftPaymentStatus/*',
      isMobile: true,
    },
    {
      key: 'swiftRubPaymentStatus',
      element: <SwiftRubPaymentStatus />,
      path: '/swiftRubPaymentStatus/*',
      isMobile: true,
    },
    {
      key: 'exchangePaymentStatus',
      element: <ExchangePaymentStatus />,
      path: '/exchangePaymentStatus/*',
      isMobile: true,
    },
    {
      key: 'deposits/info',
      element: <DepositsInfo />,
      path: '/deposits/info',
      isMobile: true,
    },
    {
      key: 'counterparties/create',
      element: <CounterpartiesCreate />,
      path: '/counterparties/create',
      isMobile: true,
    },
  ];

  const checkRoutes = () => {
    routes = [...staticRoutes];
    for (let i = 0; i < permissionsRoutes.length; i++) {
      for (const key in permissions) {
        if (window.innerWidth < 768) {
          if (permissionsRoutes[i].key === key && permissionsRoutes[i].isMobile) {
            routes.push(permissionsRoutes[i]);
          }
        } else {
          if (permissionsRoutes[i].key === key) {
            routes.push(permissionsRoutes[i]);
          }
        }
        if (permissions?.payments?.length > 0) {
          dispatch(paymentsPermissionsAC(permissions.payments));
        }
      }
    }
  };

  checkRoutes();

  return (
    <div style={{ height: '100vh', display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: 'auto 1fr auto' }}>
      <div className='flex justify-center' style={{ borderBottom: '1px solid #eee' }}>
        <Header />
      </div>

      <Routes>
        {routes.map((route: any) => (
          <Route key={route.key} path={route.path} element={route.element} />
        ))}
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>

      <div className='bg-white' style={{ borderTop: '1px solid #eee' }}>
        <FooterNew />
      </div>
    </div>
  );
}

export default Main;
