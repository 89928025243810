import { useEffect, useState } from 'react';
import { Card, Pagination, Button, Tag, Divider, Modal, Form, Input, Statistic, Select, Popover } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  confirmSalaryFileApi,
  dowloadOriginalSalaryFileApi,
  downloadSalaryExtractFileApi,
  getSalaryInfoApi,
  refreshSalaryInfoApi,
} from '@http/salary';
import { useLocation, useNavigate } from 'react-router-dom';
import SalaryInfoSearchForm from './SalaryInfoSearchForm';
import getStatus from '@utils/getStatus';
import { ArrowLeftOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import digitNumber from '@utils/digitNumber';
import { useTranslation } from 'react-i18next';
import SalaryActionModal from './SalaryActionModal';
import { actionModalTypeAC, salaryActionModalOpenAC } from '@store/reducers/salary';
import { otpConfirmAC } from '@store/reducers/transactions';
import { transactionVerifyApi, transactionVerifyResendCodeApi } from '@http/transactions';
import { fixDate, formatDate } from '@utils/formatDate';
import { getSenderAccountsApi } from '@http/accounts';
import openNotification from '@utils/openNotification';

const { Countdown } = Statistic;

export const SalaryInfo = () => {
  const [drawAccountForm] = Form.useForm();
  const [confirmForm] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const pickedSalaryInfo = useAppSelector(state => state.salaryReducer.pickedSalaryInfo);
  const salaryInfoSearchQuery = useAppSelector(state => state.salaryReducer.salaryInfoSearchQuery);
  const verifyFileId = useAppSelector(state => state.salaryReducer.verifyFileId);
  const otpLifeTime = useAppSelector(state => state.appReducer.otpLifeTime);
  const pickedSalaryFileId = location.pathname.slice(13);
  const senderAccounts = useAppSelector(state => state.accountsReducer.senderAccounts);

  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [time, setTime] = useState(false);
  const [drawAccount, setDrawAccount] = useState<any>({});

  const changePage = (page: any) => {
    dispatch(getSalaryInfoApi({ ...salaryInfoSearchQuery, file_id: pickedSalaryFileId }, page));
  };

  const approveFile = () => {
    if (+drawAccount.balance > +pickedSalaryInfo?.file_info?.total_amount) {
      dispatch(
        confirmSalaryFileApi(
          {
            action: 'PROCESS',
            file_id: pickedSalaryInfo?.file_info?.file_id,
            draw_account: drawAccount.value,
          },
          () => {
            setIsOtpModalOpen(true);
          },
          true
        )
      );
    } else {
      openNotification('error', {
        code: '',
        message: 'Ошибка',
        detail: t('Insufficient funds in the account'),
      });
    }
  };

  const verifySalaryFile = (values: any) => {
    dispatch(
      transactionVerifyApi({ code: values.code, trans_id: verifyFileId, trn_type: 'SALARY' }, () => {
        dispatch(getSalaryInfoApi({ ...salaryInfoSearchQuery, file_id: pickedSalaryFileId }, 1));
        confirmForm.resetFields();
        setIsOtpModalOpen(false);
      })
    );
  };

  const refreshData = () => {
    if (
      pickedSalaryInfo?.file_info?.status === 'IN_PROGRESS' ||
      pickedSalaryInfo?.file_info?.status === 'IN_VALIDATION'
    ) {
      dispatch(refreshSalaryInfoApi({ ...salaryInfoSearchQuery, file_id: pickedSalaryFileId }, 1));
    }
  };

  useEffect(() => {
    dispatch(getSenderAccountsApi({ transaction_type: 'SALARY' }));
    dispatch(getSalaryInfoApi({ ...salaryInfoSearchQuery, file_id: pickedSalaryFileId }, 1));
  }, []);

  useEffect(() => {
    const interval = setInterval(refreshData, 5000);

    dispatch(otpConfirmAC(false));
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Card
        title={
          <div className='flex items-center'>
            <ArrowLeftOutlined className='cursor-pointer' onClick={() => navigate('/salary/list')} />
            <p className='ml-5 color-active'>{t('File information')}</p>
          </div>
        }
      >
        <div className='leading-8'>
          <p>
            <span className='font-semibold'>{t('Status')}:</span>{' '}
            {getStatus(pickedSalaryInfo?.file_info?.status, 12, t)}
          </p>
          <p>
            <span className='font-semibold'>{t('File name')}:</span> {pickedSalaryInfo?.file_info?.title}
          </p>
          <p>
            <span className='font-semibold'>{t('Company name')}:</span> {pickedSalaryInfo?.file_info?.customer}
          </p>
          <p>
            <span className='font-semibold'>{t('Narrative')}:</span> {pickedSalaryInfo?.file_info?.narrative}
          </p>
          <p>
            <span className='font-semibold'>{t('File date')}:</span> {pickedSalaryInfo?.file_info?.file_date}
          </p>
          <p>
            <span className='font-semibold'>{t('Date of creation')}:</span> {pickedSalaryInfo?.file_info?.created_at}
          </p>
          <p>
            <span className='font-semibold'>{t('Total amount')}: </span>
            {digitNumber(pickedSalaryInfo?.file_info?.total_amount)} {pickedSalaryInfo?.file_info?.trn_ccy}
          </p>
          <p>
            <span className='font-semibold'>{t('Commission')}: </span>
            {digitNumber(pickedSalaryInfo?.file_info?.fee_amount)} {pickedSalaryInfo?.file_info?.trn_ccy}
          </p>
          <p>
            <span className='font-semibold'>{t('Number of transactions')}:</span>{' '}
            {pickedSalaryInfo?.file_info?.trn_count}
          </p>
          <p>
            <span className='font-semibold'>{t('Successful count')}:</span> {pickedSalaryInfo?.file_info?.success_count}
          </p>
          <p>
            <span className='font-semibold'>{t('Not successful count')}:</span>{' '}
            {pickedSalaryInfo?.file_info?.fail_count}
          </p>
          {pickedSalaryInfo?.file_info?.error_message ? (
            <p>
              <span className='font-semibold'>{t('Error')}:</span> {pickedSalaryInfo?.file_info?.error_message}
            </p>
          ) : null}
          {pickedSalaryInfo?.file_info?.authenticator.length > 0 ? (
            <div className=''>
              <p className='mr-3 font-semibold'>{t('Authorizers')}: </p>
              {pickedSalaryInfo?.file_info.authenticator.map((item: any) => (
                <div className='flex items-center '>
                  <p className='mr-3 font-semibold'>{item.authenticator_name}</p>
                  {item.authed === true ? <Tag color='success'>{t('Approved')}</Tag> : null}
                  {item.authed === false ? <Tag color='error'>{t('Rejected')}</Tag> : null}
                  {item.authed === null ? <Tag color='processing'>{t('Signing in')}</Tag> : null}
                </div>
              ))}
            </div>
          ) : null}

          <Form form={drawAccountForm} name='drawAccountForm' className='mt-5' onFinish={approveFile}>
            {pickedSalaryInfo?.file_info?.status === 'READY_TO_PROCEED' ? (
              <div className='flex'>
                <Form.Item
                  name='draw_account'
                  className='sm:w-[350px] max-sm:w-[215px] mb-0'
                  rules={[{ required: true, message: `${t('Required')}` }]}
                >
                  <Select
                    allowClear
                    size='middle'
                    placeholder={`${t('Select account')}`}
                    options={senderAccounts.map(item => ({
                      label: `${item.label} - ${item.acy_avl_bal}`,
                      value: item.value,
                      balance: item.acy_avl_bal,
                    }))}
                    onChange={(value, item) => setDrawAccount(item)}
                  />
                </Form.Item>
                <Button
                  disabled={!pickedSalaryInfo?.file_info?.valid}
                  type='primary'
                  size='middle'
                  className='ml-3'
                  htmlType='submit'
                >
                  {t('Confirm')}
                </Button>
              </div>
            ) : null}
          </Form>

          <div className='flex mt-5'>
            {pickedSalaryInfo?.file_info?.status === 'SUCCESS' ? (
              <>
                <Button
                  type='primary'
                  className='mr-3'
                  size='middle'
                  onClick={() => dispatch(dowloadOriginalSalaryFileApi({ file_id: pickedSalaryFileId }))}
                >
                  {t('Download file')}
                </Button>
                <Button
                  type='primary'
                  size='middle'
                  onClick={() => dispatch(downloadSalaryExtractFileApi({ file_id: pickedSalaryFileId }))}
                >
                  {t('Download extract')}
                </Button>
              </>
            ) : null}
            {pickedSalaryInfo?.file_info?.is_authenticator && pickedSalaryInfo?.file_info?.status === 'TO_APPROVE' ? (
              <div>
                <Button
                  danger
                  className='ml-3'
                  size='middle'
                  onClick={() => {
                    dispatch(salaryActionModalOpenAC(true));
                    dispatch(actionModalTypeAC('DECLINE'));
                  }}
                >
                  {t('Reject')}
                </Button>
                <Button
                  className='ml-3'
                  type='primary'
                  size='middle'
                  onClick={() => {
                    dispatch(salaryActionModalOpenAC(true));
                    dispatch(actionModalTypeAC('APPROVE'));
                  }}
                >
                  {t('Confirm')}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </Card>
      <p className='my-5 text-lg font-semibold color-active'>{t('Transactions')}</p>
      <div className='mb-5 mt-7 max-sm:hidden'>
        <SalaryInfoSearchForm />
      </div>

      <div
        className='grid grid-cols-12 text-sm pb-5 p-5 gap-5 font-semibold color-active'
        style={{ borderBottom: '1px solid #eee' }}
      >
        <p className='col-span-2 max-sm:hidden'>{t('Date')}</p>
        <p className='col-span-2 max-sm:hidden'>{t('FIO')}</p>
        <p className='col-span-2 max-lg:col-span-3 max-sm:col-span-7'>{t('Account')}</p>
        <p className='col-span-2 max-sm:hidden'>{t('TxID')}</p>
        <p className='col-span-2 max-lg:col-span-3 text-right max-sm:col-span-5'>{t('Amount')}</p>
        <p className='col-span-2  text-right max-lg:hidden'>{t('Status')}</p>
      </div>
      {pickedSalaryInfo?.results?.length > 0 ? (
        <div>
          {pickedSalaryInfo?.results?.map((item: any) => (
            <div
              style={{ borderBottom: '1px solid #eee' }}
              className='grid grid-cols-12 items-center sm:p-5 max-sm:p-3 border-b cursor-pointer gap-5 listItem text-sm'
              key={item.id}
            >
              <p className='col-span-2 max-sm:hidden'>
                <div className='flex items-center gap-5'>
                  <div className='max-lg:hidden'>
                    {item.valid ? (
                      <CheckCircleOutlined style={{ color: 'green', fontSize: '20px' }} />
                    ) : (
                      <Popover title={item.error_message}>
                        <CloseCircleOutlined className='cursor-pointer' style={{ color: 'red', fontSize: '20px' }} />
                      </Popover>
                    )}
                  </div>
                  <span>{item.created_at.slice(0, 10)}</span>
                </div>
              </p>
              <p className='col-span-2 max-sm:hidden'>
                {item.last_name} {item.first_name}
              </p>
              <p className='col-span-2 max-lg:col-span-3 max-sm:hidden'>{item.account_no}</p>
              <div className='sm:hidden max-sm:col-span-7'>
                <p className='font-semibold'>
                  {item.last_name} {item.first_name}
                </p>
                <p className='col-span-2 max-lg:col-span-3 max-sm:col-span-7'>{item.account_no}</p>
              </div>
              <p className='col-span-2 max-sm:hidden'>{item.trans_id}</p>
              <p className='col-span-2 max-lg:col-span-3 max-sm:col-span-5 text-right'>
                {digitNumber(item.amount)} {item.ccy}
                <p className='col-span-2 mt-2 text-right lg:hidden'>{getStatus(item.status, 12, t)}</p>
              </p>
              <p className='col-span-2  text-right max-lg:hidden'>{getStatus(item.status, 12, t)}</p>
            </div>
          ))}
          <div className='mt-7 flex justify-end'>
            <Pagination
              onChange={changePage}
              current={pickedSalaryInfo.current_page}
              defaultPageSize={10}
              total={pickedSalaryInfo.total_pages * 10}
              showSizeChanger={false}
            />
          </div>
        </div>
      ) : (
        <div className='h-24 lg:h-72 flex items-center justify-center'>
          <p>{t('No Transactions')}</p>
        </div>
      )}
      <SalaryActionModal fileId={pickedSalaryFileId} />
      <Modal
        open={isOtpModalOpen}
        onOk={() => setIsOtpModalOpen(false)}
        onCancel={() => setIsOtpModalOpen(false)}
        footer={null}
      >
        <div>
          <p className='text-base'>{t('Confirm payment')}</p>
          <span className='text-xs text-slate-400'>{t('ConfirmSmsFromPhone')}</span>

          <Form form={confirmForm} name='internalOperations' onFinish={verifySalaryFile}>
            <Form.Item className='m-0' name='code' rules={[{ required: true, message: `${t('Required')}` }]}>
              <Input
                placeholder={`${t('Enter SMS code')}`}
                size='large'
                style={{ width: '100%' }}
                type='text'
                maxLength={6}
              />
            </Form.Item>
            {!time ? (
              <div className='mt-3 flex items-center text-sm text-slate-400'>
                <span className='mr-2'>{t('ResendTime')}</span>
                <Countdown
                  className='!text-xs'
                  style={{ fontSize: 12 }}
                  value={otpLifeTime}
                  onFinish={() => setTime(true)}
                  format='mm:ss'
                />
                <span className='ml-2'> {t('Second')}!</span>
              </div>
            ) : null}
            <div className='flex justify-end mt-5'>
              {time ? (
                <Button
                  className='mr-3'
                  onClick={() => {
                    dispatch(otpConfirmAC(Date.now() + 1000 * 30));
                    setTime(false);
                    dispatch(transactionVerifyResendCodeApi({ trans_id: verifyFileId, trn_type: 'SALARY' }));
                  }}
                >
                  {t('ResendCode')}
                </Button>
              ) : null}
              <Button type='primary' htmlType='submit'>
                {t('Confirm')}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default SalaryInfo;
