import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface INotificationModalAttrs {
  isOpen: boolean;
  type?: 'success' | 'error' | undefined;
  message?: string | undefined;
  width?: string;
}

interface AppState {
  user: any;
  isOtp: boolean;
  isSpin: number;
  isAuth: boolean;
  isSwitchRouteLoading: boolean;
  checkResetPassword: boolean;
  changePasswordOtpState: boolean;
  otpLifeTime: number;
  otpType: string;
  notificationModal: INotificationModalAttrs;
  requestInfoModal: boolean;
}

const initialState: AppState = {
  user: {},
  isOtp: false,
  isSpin: 0,
  isAuth: false,
  isSwitchRouteLoading: true,
  checkResetPassword: false,
  changePasswordOtpState: false,
  otpLifeTime: 0,
  otpType: 'sms',
  requestInfoModal: false,
  notificationModal: {
    isOpen: false,
    type: 'success',
    message: '',
    width: '400px',
  },
};

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    requestInfoModalAC(state, action: PayloadAction<boolean>) {
      state.requestInfoModal = action.payload;
    },
    otpTypeAC(state, action: PayloadAction<string>) {
      state.otpType = action.payload;
    },
    otpLifeTimeAC(state, action: PayloadAction<number>) {
      state.otpLifeTime = action.payload;
    },
    notificationModalAC(state, action: PayloadAction<INotificationModalAttrs>) {
      state.notificationModal = action.payload;
    },
    isSwitchRouteLoadingAC(state, action: PayloadAction<boolean>) {
      state.isSwitchRouteLoading = action.payload;
    },
    isAuthAC(state, action: PayloadAction<boolean>) {
      state.isAuth = action.payload;
    },
    isSpinAC(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.isSpin += 1;
      } else if (state.isSpin > 0) {
        state.isSpin -= 1;
      } else {
        state.isSpin = 0;
      }
    },
    isOtpAC(state, action: PayloadAction<boolean>) {
      state.isOtp = action.payload;
    },
    userAC(state, action: PayloadAction<any>) {
      state.user = action.payload;
    },
    checkResetPasswordAC(state, action: PayloadAction<any>) {
      state.checkResetPassword = action.payload;
    },
    changePasswordOtpStateAC(state, action: PayloadAction<any>) {
      state.changePasswordOtpState = action.payload;
    },
  },
});

export const {
  requestInfoModalAC,
  otpTypeAC,
  isAuthAC,
  isSpinAC,
  isOtpAC,
  userAC,
  isSwitchRouteLoadingAC,
  checkResetPasswordAC,
  changePasswordOtpStateAC,
  notificationModalAC,
  otpLifeTimeAC,
} = app.actions;
export default app.reducer;
