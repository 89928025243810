import { useEffect } from 'react';
import { Pagination, Button } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useNavigate } from 'react-router-dom';
import { FileExcelOutlined } from '@ant-design/icons';

import getStatus from '@utils/getStatus';

import BulkTransactionSearchForm from './BulkTransactionSearchForm';

import { getBulkFilesApi } from '@http/bulkTransaction';
import { useTranslation } from 'react-i18next';
import { otpConfirmAC } from '@store/reducers/transactions';
import BulkTransactionPreviewModal from './BulkTransactionPreviewModal';
import ImportFileModal from './ImportFileModal';
import { isImportFileModalOpenAC } from '@store/reducers/bulkTransaction';

export const BulkTransactionList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const bulkTransactionList = useAppSelector(state => state.bulkTransactionReducer.bulkTransactionList);

  const changePage = (page: number) => {
    dispatch(getBulkFilesApi({}, page));
  };

  useEffect(() => {
    dispatch(getBulkFilesApi({}, 1));
    dispatch(otpConfirmAC(false));
  }, []);

  return (
    <div>
      <div className='flex items-center gap-x-3 mb-2 justify-between'>
        <h1 className='text-2xl font-semibold color-active'>{t('Upload file')}</h1>
        <div className='flex items-center gap-x-4'>
          <Button
            size='large'
            type='primary'
            icon={<FileExcelOutlined />}
            onClick={() => dispatch(isImportFileModalOpenAC(true))}
          >
            {t('Import')}
          </Button>
        </div>
      </div>
      <div className='mb-5 mt-7'>
        <BulkTransactionSearchForm />
      </div>
      <div
        className='grid grid-cols-9 text-sm pb-5 p-5 gap-3 font-semibold color-active'
        style={{ borderBottom: '1px solid #eee' }}
      >
        <p className='col-span-2'>{t('Date')}</p>
        <p className='col-span-1'>{t('Initiator')}</p>
        <p className='col-span-2 text-right'>{t('Number of transactions')}</p>
        <p className='col-span-2 text-right'>{t('Amount')}</p>
        <p className='col-span-2 text-right'>{t('Status')}</p>
      </div>
      {bulkTransactionList?.results?.length > 0 ? (
        <div>
          {bulkTransactionList?.results?.map((item: any) => (
            <div
              style={{ borderBottom: '1px solid #eee' }}
              className='grid grid-cols-9 items-center p-5 border-b cursor-pointer gap-5 listItem text-sm'
              key={item.id}
              onDoubleClick={() => {
                navigate(`/payments/bulk/info/${item.file_id}`);
              }}
            >
              <p className='col-span-2 font-medium'>{item?.created_at}</p>
              <p className='col-span-1'>{item.initiator}</p>
              <p className='col-span-2 text-right'>{item.trn_count}</p>
              <p className='col-span-2 text-right'>
                {item.total_amount} {item.trn_ccy}
              </p>
              <p className='col-span-2 text-right'>{getStatus(item.status, 12, t)}</p>
            </div>
          ))}

          <div className='flex justify-end mt-5'>
            <Pagination
              onChange={changePage}
              current={bulkTransactionList.current_page}
              defaultPageSize={10}
              total={bulkTransactionList.total_pages * 10}
              showSizeChanger={false}
            />
          </div>
        </div>
      ) : (
        <div className='h-24 lg:h-72 flex items-center justify-center'>
          <p>{t('No Transactions')}</p>
        </div>
      )}

      <BulkTransactionPreviewModal />
      <ImportFileModal />
    </div>
  );
};

export default BulkTransactionList;
