import { Form, Select, Input, Button, Space, DatePicker, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import digitNumber from '@utils/digitNumber';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';

import {
  interbankOperationsQueryAC,
  isConfirmPaymentModalOpenAC,
  isDocumentTypesModalOpenAC,
  isSaveTemplateModalOpenAC,
  newInterbankTemplateAC,
} from '@store/reducers/payments';
import { deadlineAC } from '@store/reducers/transactions';
import { senderAccountsModalOpenAC } from '@store/reducers/accounts';

const { TextArea } = Input;

const InterbankOperationsForm = ({ form, senderAccountCurrency, selectedDocTypeName }: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const senderAccountBalance = useAppSelector(state => state.accountsReducer.senderAccountBalance);
  const bankBics = useAppSelector(state => state.paymentsReducer.bankBics);
  const interbankOperationsQuery = useAppSelector(state => state.paymentsReducer.interbankOperationsQuery);
  const newInterbankTemplate = useAppSelector(state => state.paymentsReducer.newInterbankTemplate);

  const submit = () => {
    if (+interbankOperationsQuery.sending_amount > +senderAccountBalance) {
      form.setFields([
        {
          name: 'sending_ac',
          errors: [t('Insufficient funds in the account')],
        },
      ]);
    } else {
      dispatch(isConfirmPaymentModalOpenAC(true));
      dispatch(deadlineAC(Date.now() + 1000 * 30));
    }
  };

  const openSaveTemplateModal = () => {
    dispatch(
      newInterbankTemplateAC({
        ...newInterbankTemplate,
        sending_amount: form.getFieldValue('sending_amount'),
        sending_ac: form.getFieldValue('sending_ac'),
        receiving_ac: form.getFieldValue('receiving_ac'),
        receiver_name: form.getFieldValue('receiver_name'),
        sending_ccy: senderAccountCurrency,
        bank_code: form.getFieldValue('bank_code'),
        tax_id: form.getFieldValue('tax_id'),
        narrative: form.getFieldValue('narrative'),
        doc_no: form.getFieldValue('doc_no'),
        doc_type: form.getFieldValue('doc_type'),
        doc_date: form.getFieldValue('doc_date')?.format('DD.MM.YYYY'),
      })
    );

    dispatch(isSaveTemplateModalOpenAC(true));
  };

  return (
    <Form form={form} name='InterbankOperations' onFinish={submit}>
      <p className='text-base color-active font-semibold mb-4'>{t('Sender information')}</p>
      <p className='text-bs color-title md:hidden'>{t('From account')}:</p>
      <div className='md:grid grid-cols-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('From account')}:</p>
        <div className='col-span-5'>
          <Form.Item className='m-0' name='sending_ac' rules={[{ required: true, message: `${t('Required')}` }]}>
            <Space.Compact size='large' className='w-full'>
              <Input disabled style={{ width: 60 }} size='large' value={senderAccountCurrency} />
              <Input
                value={interbankOperationsQuery.sending_ac}
                placeholder={`${t('Select account')}`}
                onClick={() => dispatch(senderAccountsModalOpenAC(true))}
                size='large'
                style={{ width: '100%' }}
                type='text'
                maxLength={20}
              />
            </Space.Compact>
          </Form.Item>
          {senderAccountBalance ? (
            <p className='text-bs color-title font-medium text-end mt-2'>
              {t('Balance')}: {digitNumber(senderAccountBalance)} {senderAccountCurrency}
            </p>
          ) : null}
        </div>
      </div>
      <Divider className='my-6' />
      <p className='text-base color-active font-semibold mb-4'>{t('Recipient information')}</p>
      <p className='text-bs color-title md:hidden'>{t('Account number')}:</p>
      <div className='md:grid grid-cols-7 md:mt-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Account number')}:</p>
        <div className='col-span-5'>
          <Form.Item
            className='m-0'
            name='receiving_ac'
            rules={[
              { required: true, message: `${t('Required')}` },
              { min: 20, message: `${t('Account must contain at least 20 digits')}` },
              { max: 21, message: `${t('Account must contain no more than 21 digits')}` },
              {
                pattern: /^[0-9]+$/,
                message: `${t('Enter number only')}`,
              },
            ]}
            hasFeedback
          >
            <Input
              value={interbankOperationsQuery.receiving_ac}
              onChange={e =>
                dispatch(interbankOperationsQueryAC({ ...interbankOperationsQuery, receiving_ac: e.target.value }))
              }
              maxLength={21}
              placeholder={`${t('Enter Recipient Account')}`}
              size='large'
              style={{ width: '100%' }}
            />
          </Form.Item>
        </div>
      </div>
      <p className='text-bs color-title mt-5 md:hidden'>{t('Confirm account number')}:</p>
      <div className='md:grid grid-cols-7 md:mt-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Confirm account number')}:</p>
        <div className='col-span-5'>
          <Form.Item
            name='receiving_ac2'
            dependencies={['receiving_ac']}
            hasFeedback
            rules={[
              { required: true, message: `${t('Required')}` },
              { min: 20, message: `${t('Account must contain at least 20 digits')}` },
              { max: 21, message: `${t('Account must contain no more than 21 digits')}` },
              {
                pattern: /^[0-9]+$/,
                message: `${t('Enter number only')}`,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('receiving_ac') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(`${t('Account does not match')}`));
                },
              }),
            ]}
          >
            <Input
              onChange={e =>
                dispatch(interbankOperationsQueryAC({ ...interbankOperationsQuery, receiving_ac2: e.target.value }))
              }
              maxLength={21}
              placeholder={`${t('Enter Recipient Account')}`}
              size='large'
              style={{ width: '100%' }}
            />
          </Form.Item>
        </div>
      </div>
      <p className='text-bs color-title mt-5 md:hidden'>{t('Recipient')}:</p>
      <div className='md:grid grid-cols-7 md:mt-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Recipient')}:</p>
        <div className='col-span-5'>
          <Form.Item className='m-0' name='receiver_name' rules={[{ required: true, message: `${t('Required')}` }]}>
            <Input
              onChange={e =>
                dispatch(interbankOperationsQueryAC({ ...interbankOperationsQuery, receiver_name: e.target.value }))
              }
              type='text'
              placeholder={`${t('Enter Recipient')}`}
              size='large'
              style={{ width: '100%' }}
              maxLength={70}
            />
          </Form.Item>
        </div>
      </div>
      <p className='text-bs color-title mt-5 md:hidden'>{t('TIN')}:</p>
      <div className='md:grid grid-cols-7 md:mt-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('TIN')}:</p>
        <div className='col-span-5'>
          <Form.Item
            className='m-0'
            name='tax_id'
            rules={[
              {
                required: true,
                message: `${t('Required')}`,
              },
              {
                pattern: /^[0-9]+$/,
                message: `${t('Enter number only')}`,
              },
              {
                min: 9,
                message: `${t('Must be 9 numbers')}`,
              },
            ]}
          >
            <Input
              maxLength={9}
              minLength={9}
              size='large'
              placeholder={`${t('Enter TIN')}`}
              type='text'
              style={{ width: '100%' }}
              onChange={e =>
                dispatch(interbankOperationsQueryAC({ ...interbankOperationsQuery, tax_id: e.target.value }))
              }
            />
          </Form.Item>
        </div>
      </div>

      <Divider className='my-6' />
      <p className='text-base color-active font-semibold mb-4'>{t('Payment details')}</p>
      <p className='text-bs color-title mt-5 md:hidden'>{t('Transfer amount')}:</p>
      <div className='md:grid grid-cols-7 md:mt-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Transfer amount')}:</p>
        <div className='col-span-5'>
          <Form.Item
            className='m-0'
            name='sending_amount'
            rules={[
              { required: true, message: `${t('Required')}` },
              {
                pattern: /^[0-9]\d*(?:[.]\d{1,2})?$/,
                message: `${t('Wrong amount')}`,
              },
              {
                pattern: /^[1-9]\d*/,
                message: `${t('Amount must be more 1')}`,
              },
            ]}
          >
            <Space.Compact size='large' className='w-full'>
              <Input disabled style={{ width: 60 }} size='large' value={senderAccountCurrency} />
              <Input
                value={interbankOperationsQuery.sending_amount}
                maxLength={12}
                placeholder={`${t('Enter amount')}`}
                size='large'
                style={{ width: '100%' }}
                type='text'
                onChange={e => {
                  dispatch(interbankOperationsQueryAC({ ...interbankOperationsQuery, sending_amount: e.target.value }));
                }}
              />
            </Space.Compact>
          </Form.Item>
        </div>
      </div>
      <Divider className='my-6' />
      <p className='text-bs color-title mt-5 md:hidden'>{t('BIC')}:</p>
      <div className='md:grid grid-cols-7 md:mt-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('BIC')}:</p>
        <div className='col-span-5'>
          <Form.Item className='m-0' name='bank_code' rules={[{ required: true, message: `${t('Required')}` }]}>
            <Select
              size='large'
              style={{ width: '100%' }}
              allowClear
              showSearch
              optionFilterProp='children'
              onChange={value =>
                dispatch(interbankOperationsQueryAC({ ...interbankOperationsQuery, bank_code: value }))
              }
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={bankBics}
            />
          </Form.Item>
        </div>
      </div>
      <p className='text-bs color-title mt-5 md:hidden'>{t('Document type')}:</p>
      <div className='md:grid grid-cols-7 md:mt-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Document type')}:</p>
        <div className='col-span-5'>
          <div className='flex'>
            <Form.Item
              className='m-0 w-full'
              name='doc_type'
              rules={[
                { required: true, message: `${t('Required')}` },
                { pattern: /^\d+$/, message: `${t('Enter number only')}` },
              ]}
            >
              <Input
                maxLength={2}
                placeholder={`${t('Enter document type')}`}
                size='large'
                style={{ width: '100%', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                onChange={e =>
                  dispatch(interbankOperationsQueryAC({ ...interbankOperationsQuery, doc_type: e.target.value }))
                }
              />
            </Form.Item>
            <Button
              onClick={() => dispatch(isDocumentTypesModalOpenAC(true))}
              icon={<SearchOutlined />}
              size='large'
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftWidth: 0 }}
            ></Button>
          </div>
          <p className='text-sm text-end mt-2'>{selectedDocTypeName}</p>
        </div>
      </div>
      <p className='text-bs color-title mt-5 md:hidden'>{t('Document number')}:</p>
      <div className='md:grid grid-cols-7 md:mt-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Document number')}:</p>
        <div className='col-span-5'>
          <Form.Item
            className='m-0'
            name='doc_no'
            rules={[
              {
                required: true,
                message: `${t('Required')}`,
              },
              { pattern: /^\d+$/, message: `${t('Enter number only')}` },
            ]}
          >
            <Input
              maxLength={10}
              onChange={e =>
                dispatch(interbankOperationsQueryAC({ ...interbankOperationsQuery, doc_no: e.target.value }))
              }
              size='large'
              placeholder={`${t('Enter document number')}`}
              type='text'
              style={{ width: '100%' }}
            />
          </Form.Item>
        </div>
      </div>
      <p className='text-bs color-title mt-5 md:hidden'>{t('Document date')}:</p>
      <div className='md:grid grid-cols-7 md:mt-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Document date')}:</p>
        <div className='col-span-5'>
          <Form.Item className='m-0' name='doc_date' rules={[{ required: true, message: `${t('Required')}` }]}>
            <DatePicker
              onChange={value =>
                dispatch(
                  interbankOperationsQueryAC({ ...interbankOperationsQuery, doc_date: value?.format('DD.MM.YYYY') })
                )
              }
              format='DD.MM.YYYY'
              size='large'
              placeholder={`${t('Select date')}`}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </div>
      </div>
      <div className='mt-5'>
        <p>{t('Narrative')}:</p>
        <Form.Item
          className='m-0'
          name='narrative'
          rules={[
            { required: true, message: `${t('Required')}` },
            {
              pattern: /^(?=.*\S).*/,
              message: `${t('Enter symbol only')}`,
            },
          ]}
        >
          <TextArea
            value={interbankOperationsQuery.narrative}
            maxLength={140}
            onChange={e => {
              dispatch(interbankOperationsQueryAC({ ...interbankOperationsQuery, narrative: e.target.value }));
            }}
            rows={4}
          />
        </Form.Item>
      </div>
      <div className='flex justify-end mt-5'>
        <Button type='default' onClick={openSaveTemplateModal}>
          {t('Save template')}
        </Button>
        <Button type='primary' className='ml-3' htmlType='submit'>
          {t('Confirm')}
        </Button>
      </div>
    </Form>
  );
};

export default InterbankOperationsForm;
