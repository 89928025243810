import { Button, Tabs } from 'antd';

import { useAppDispatch, useAppSelector } from '@hooks/redux';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import type { TabsProps } from 'antd';
import ResidentsForm from '@components/Counterparties/forms/Residents';
import ForeigsForm from '@components/Counterparties/forms/Foreigs';

function CounterpartiesCreate() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('Residents'),
      children: <ResidentsForm />,
    },
    {
      key: '2',
      label: t('Foreigs'),
      children: <ForeigsForm />,
    },
  ];

  return (
    <div className='flex justify-center'>
      <div className='lg:w-[1130px] w-full'>
        <div className='flex'>
          <div className='p-4 lg:p-8 w-full h-full bg-white mb-12'>
            <div>
              <div className='flex justify-between items-center'>
                <h1 className='max-md:hidden text-2xl mb-2 font-semibold color-active'>{t('New counterparty')}</h1>
                <Button
                  type='primary'
                  onClick={() => {
                    navigate('/counterparties');
                  }}
                >
                  {t('Back')}
                </Button>
              </div>
              <div className='mt-1'>
                <Tabs defaultActiveKey='1' items={items} onChange={onChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CounterpartiesCreate;
