import { useEffect, useRef, useState } from 'react';
import { Select, Input, Button, DatePicker, Drawer } from 'antd';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getAccountsApi } from '@http/accounts';
import { getTransactionListApi } from '@http/transactions';
import { searchQueryAC } from '@store/reducers/transactions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { debounce } from '@utils/debounce';

const { RangePicker } = DatePicker;

export const TransactionFilters = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectAccounts = useAppSelector(state => state.accountsReducer.selectAccounts);
  const transactionStatus = useAppSelector(state => state.transactionsReducer.transactionStatus);

  const searchQuery = useAppSelector(state => state.transactionsReducer.searchQuery);

  const [open, setOpen] = useState(false);
  const debounceRef = useRef<any>(null);
  const statusRef = useRef(transactionStatus);

  const [showFilters, setShowFilters] = useState(false);

  const handleSearchMobile = () => {
    dispatch(getTransactionListApi(1, { ...searchQuery, status: transactionStatus }));
    dispatch(searchQueryAC({ ...searchQuery, status: transactionStatus }));
    setOpen(false);
    setShowFilters(false);
  };

  const handleSearch = () => {
    dispatch(getTransactionListApi(1, { ...searchQuery, status: transactionStatus }));
    dispatch(searchQueryAC({ ...searchQuery, status: transactionStatus }));
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setShowFilters(false);
  };

  useEffect(() => {
    dispatch(getAccountsApi());

    return () => {
      dispatch(searchQueryAC({}));
    };
  }, []);

  useEffect(() => {
    statusRef.current = transactionStatus;
  }, [transactionStatus]);

  useEffect(() => {
    if (!debounceRef.current) {
      debounceRef.current = debounce((query: any) => {
        dispatch(getTransactionListApi(1, { ...query, status: statusRef.current }));
      }, 700);
    }

    debounceRef.current(searchQuery);

    return () => {
      clearTimeout(debounceRef.current);
    };
  }, [searchQuery]);

  return (
    <div className='my-5'>
      <Button
        icon={<FilterOutlined />}
        type='primary'
        onClick={() => {
          setShowFilters(!showFilters);
          showDrawer();
        }}
      >
        {!showFilters ? t('Show filters') : t('Hide filters')}
      </Button>
      {showFilters ? (
        <div>
          {window.innerWidth < 768 ? (
            <Drawer title={false} placement='bottom' closable={false} onClose={onClose} open={open} key='bottom'>
              <div className=''>
                <Select
                  style={{ width: '100%' }}
                  size='large'
                  placeholder={`${t('Select account')}`}
                  options={selectAccounts}
                  onChange={(value: any) => dispatch(searchQueryAC({ ...searchQuery, sending_ac: value }))}
                  allowClear
                />
                <Input
                  className='mt-5'
                  style={{ width: '100%' }}
                  type='number'
                  max={9999999}
                  min={1}
                  maxLength={12}
                  size='large'
                  placeholder={`${t('From amount')}`}
                  onChange={(e: any) => dispatch(searchQueryAC({ ...searchQuery, from_amount: e.target.value }))}
                />
                <Input
                  className='mt-5'
                  style={{ width: '100%' }}
                  type='number'
                  max={9999999}
                  min={1}
                  maxLength={12}
                  size='large'
                  placeholder={`${t('To amount')}`}
                  onChange={(e: any) => dispatch(searchQueryAC({ ...searchQuery, to_amount: e.target.value }))}
                />
                <Select
                  className='mt-5'
                  style={{ width: '100%' }}
                  allowClear
                  size='large'
                  placeholder={`${t('Payment type')}`}
                  onChange={(value: any) => dispatch(searchQueryAC({ ...searchQuery, trn_type: value }))}
                >
                  <Select.Option value='OWN'>{t('Between own accounts')}</Select.Option>
                  <Select.Option value='INTERNAL'>{t('Intrabank')}</Select.Option>
                  <Select.Option value='INTERBANK'>{t('Interbank')}</Select.Option>
                  <Select.Option value='SWIFT'>{t('International')}</Select.Option>
                  <Select.Option value='SWIFT_RUB'>{t('Ruble transfer')}</Select.Option>
                </Select>
                <RangePicker
                  className='mt-5'
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={[`${t('Start date')}`, `${t('End date')}`]}
                  format='DD.MM.YYYY'
                  size='large'
                  onChange={(date: any) => {
                    if (date) {
                      dispatch(
                        searchQueryAC({
                          ...searchQuery,
                          from_date: date[0]?.format('YYYY-MM-DD'),
                          to_date: date[1]?.format('YYYY-MM-DD'),
                        })
                      );
                    } else {
                      dispatch(
                        searchQueryAC({
                          ...searchQuery,
                          from_date: '',
                          to_date: '',
                        })
                      );
                    }
                  }}
                  disabledDate={date => date && date > moment().add('day')}
                />
                <div className='flex justify-end mt-5'>
                  <Button size='large' type='primary' onClick={handleSearchMobile}>
                    {t('Confirm')}
                  </Button>
                </div>
              </div>
            </Drawer>
          ) : (
            <div className='mt-7'>
              <div className='flex gap-5 mt-5'>
                <Select
                  style={{ width: '30%' }}
                  size='large'
                  placeholder={`${t('Select account')}`}
                  options={selectAccounts}
                  onChange={(value: any) => {
                    dispatch(searchQueryAC({ ...searchQuery, status: transactionStatus, sending_ac: value }));
                  }}
                  allowClear
                />
                <Select
                  style={{ width: '30%' }}
                  allowClear
                  size='large'
                  placeholder={`${t('Payment type')}`}
                  onChange={(value: any) => {
                    dispatch(searchQueryAC({ ...searchQuery, status: transactionStatus, trn_type: value }));
                  }}
                >
                  <Select.Option value='OWN'>{t('Between own accounts')}</Select.Option>
                  <Select.Option value='INTERNAL'>{t('Intrabank')}</Select.Option>
                  <Select.Option value='INTERBANK'>{t('Interbank')}</Select.Option>
                  <Select.Option value='SWIFT'>{t('International')}</Select.Option>
                  <Select.Option value='SWIFT_RUB'>{t('Ruble transfer')}</Select.Option>
                  <Select.Option value='EXCHANGE'>{t('Exchange')}</Select.Option>
                </Select>
                <RangePicker
                  allowClear
                  style={{ width: '30%' }}
                  placeholder={[`${t('Start date')}`, `${t('End date')}`]}
                  format='DD.MM.YYYY'
                  size='large'
                  onChange={(date: any) => {
                    if (date) {
                      dispatch(
                        searchQueryAC({
                          ...searchQuery,
                          status: transactionStatus,
                          from_date: date[0]?.format('YYYY-MM-DD'),
                          to_date: date[1]?.format('YYYY-MM-DD'),
                        })
                      );
                    } else {
                      dispatch(
                        searchQueryAC({
                          ...searchQuery,
                          status: transactionStatus,
                          from_date: '',
                          to_date: '',
                        })
                      );
                    }
                  }}
                  disabledDate={date => date && date > moment().add('day')}
                />
              </div>

              <div className='flex gap-5 mt-5'>
                <Input
                  style={{ width: '20%' }}
                  type='number'
                  max={9999999}
                  min={1}
                  maxLength={12}
                  size='large'
                  placeholder={`${t('From amount')}`}
                  onChange={(e: any) => {
                    dispatch(searchQueryAC({ ...searchQuery, status: transactionStatus, from_amount: e.target.value }));
                  }}
                />
                <Input
                  style={{ width: '20%' }}
                  type='number'
                  max={9999999}
                  min={1}
                  maxLength={12}
                  size='large'
                  placeholder={`${t('To amount')}`}
                  onChange={(e: any) => {
                    dispatch(searchQueryAC({ ...searchQuery, status: transactionStatus, to_amount: e.target.value }));
                  }}
                />
                <Input
                  style={{ width: '20%' }}
                  type='text'
                  size='large'
                  placeholder={`${t('Recipient account')}`}
                  onChange={(e: any) => {
                    dispatch(
                      searchQueryAC({ ...searchQuery, status: transactionStatus, receiver_acc: e.target.value })
                    );
                  }}
                />
                <Input
                  style={{ width: '20%' }}
                  type='text'
                  size='large'
                  placeholder={`${t('Recipient')}`}
                  onChange={(e: any) => {
                    dispatch(
                      searchQueryAC({ ...searchQuery, status: transactionStatus, receiver_name: e.target.value })
                    );
                  }}
                />
                <Button icon={<SearchOutlined />} size='large' type='primary' onClick={handleSearch}>
                  {t('Search')}
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default TransactionFilters;
