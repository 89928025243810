import { useState } from 'react';
import { Button, Form, Select, Input, Card } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useAppDispatch } from '@hooks/redux';
import { useLocation } from 'react-router-dom';
import { getBulkTransactionInfoApi } from '@http/bulkTransaction';
import { useTranslation } from 'react-i18next';

const BulkTransactionInfoSearchForm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const pickedBulkTransactionFileId = location.pathname.slice(20);
  const [searchQuery, setSearchQuery] = useState({
    file_id: pickedBulkTransactionFileId,
    status: '',
    from_amount: '',
    to_amount: '',
    rec_name: '',
  });

  const handleFinish = () => {
    dispatch(getBulkTransactionInfoApi(searchQuery, 1));
  };

  return (
    <div>
      <Card type='inner' title={`${t('Filter')}`}>
        <Form onFinish={handleFinish} form={form}>
          <div className='flex items-start gap-x-4'>
            <Form.Item name='rec_name' className='w-[22%] mb-0'>
              <Input
                size='large'
                placeholder={`${t('FIO')}`}
                onChange={(e: any) => setSearchQuery({ ...searchQuery, rec_name: e.target.value })}
              />
            </Form.Item>

            <Form.Item name='from_amount' className='w-[16%] mb-0'>
              <Input
                type='number'
                max={9999999}
                min={1}
                maxLength={12}
                size='large'
                placeholder={`${t('From amount')}`}
                onChange={(e: any) => setSearchQuery({ ...searchQuery, from_amount: e.target.value })}
              />
            </Form.Item>
            <Form.Item name='to_amount' className='w-[16%] mb-0'>
              <Input
                type='number'
                max={9999999}
                min={1}
                maxLength={12}
                size='large'
                placeholder={`${t('To amount')}`}
                onChange={(e: any) => setSearchQuery({ ...searchQuery, to_amount: e.target.value })}
              />
            </Form.Item>
            <Form.Item name='status' className='w-[19%] mb-0'>
              <Select
                allowClear
                size='large'
                placeholder='Статус'
                onChange={value => setSearchQuery({ ...searchQuery, status: value })}
              >
                <Select.Option value='SUCCESS'>{t('Successful')}</Select.Option>
                <Select.Option value='IN_PROGRESS'>{t('In progress')}</Select.Option>
                <Select.Option value='IN_CONFIRMATION'>{t('In confirmation')}</Select.Option>
                <Select.Option value='IN_VERIFICATION'>{t('In verification')}</Select.Option>
                <Select.Option value='FAILED'>{t('Failed')}</Select.Option>
                <Select.Option value='PARTIAL_SUCCESS'>{t('Partial success')}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item className='mb-0'>
              <Button icon={<SearchOutlined />} size='large' type='primary' htmlType='submit'>
                {t('Search')}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default BulkTransactionInfoSearchForm;
