import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { checkIsAccountOwnApi } from '@http/accounts';
import { isAccountValidAC, searchedAccountAC, senderAccountsModalOpenAC } from '@store/reducers/accounts';
import {
  internalOperationsQueryAC,
  isConfirmPaymentModalOpenAC,
  isSaveTemplateModalOpenAC,
  newInternalTemplateAC,
} from '@store/reducers/payments';
import { deadlineAC } from '@store/reducers/transactions';
import digitNumber from '@utils/digitNumber';
import { Button, Form, Input, Space } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

const InternalOperationsForm = ({ form, senderAccountCurrency }: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const senderAccountBalance = useAppSelector(state => state.accountsReducer.senderAccountBalance);
  const searchedAccount = useAppSelector(state => state.accountsReducer.searchedAccount);
  const isAccountValid = useAppSelector(state => state.accountsReducer.isAccountValid);
  const internalOperationsQuery = useAppSelector(state => state.paymentsReducer.internalOperationsQuery);
  const newInternalTemplate = useAppSelector(state => state.paymentsReducer.newInternalTemplate);

  const openSaveTemplateModal = () => {
    dispatch(
      newInternalTemplateAC({
        ...newInternalTemplate,
        sending_amount: form.getFieldValue('sending_amount'),
        sending_ac: form.getFieldValue('sending_ac'),
        receiving_ac: form.getFieldValue('receiving_ac'),
        narrative: form.getFieldValue('narrative'),
        sending_ccy: senderAccountCurrency,
      })
    );

    dispatch(isSaveTemplateModalOpenAC(true));
  };

  const submit = () => {
    if (+internalOperationsQuery.sending_amount > +senderAccountBalance) {
      form.setFields([
        {
          name: 'sending_ac',
          errors: [t('Insufficient funds in the account')],
        },
      ]);
    } else if (senderAccountCurrency !== searchedAccount.ccy) {
      form.setFields([
        {
          name: 'receiving_ac',
          errors: [t('Sender and Recipient accounts must be same')],
        },
      ]);
    } else {
      dispatch(isConfirmPaymentModalOpenAC(true));
      dispatch(deadlineAC(Date.now() + 1000 * 30));
    }
  };

  const checkIsAccountOwn = (account: any) => {
    if (account.length === 20) {
      dispatch(isAccountValidAC(false));
      dispatch(searchedAccountAC({}));
      dispatch(checkIsAccountOwnApi({ cust_ac_no: account, transaction_type: 'INTERNAL' }));
    }
    if (account.length < 20) {
      dispatch(isAccountValidAC(false));
      dispatch(searchedAccountAC({}));
    }
  };
  return (
    <Form form={form} name='internalOperations' onFinish={submit}>
      <p className='text-base color-active font-semibold mb-4'>{t('Payment information')}</p>
      <p className='text-bs color-title md:hidden'>{t('From account')}:</p>
      <div className='md:grid grid-cols-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('From account')}:</p>
        <div className='col-span-5'>
          <Form.Item className='m-0' name='sending_ac' rules={[{ required: true, message: `${t('Required')}` }]}>
            <Space.Compact size='large' className='w-full'>
              <Input disabled style={{ width: 60 }} size='large' value={senderAccountCurrency} />
              <Input
                value={internalOperationsQuery.sending_ac}
                placeholder={`${t('Select account')}`}
                onClick={() => dispatch(senderAccountsModalOpenAC(true))}
                size='large'
                style={{ width: '100%' }}
                type='text'
                maxLength={20}
              />
            </Space.Compact>
          </Form.Item>
          {senderAccountBalance ? (
            <p className='text-bs color-title font-medium text-end mt-2'>
              {t('Balance')}: {digitNumber(senderAccountBalance)} {senderAccountCurrency}
            </p>
          ) : null}
        </div>
      </div>
      <p className='text-bs color-title mt-5 md:hidden'>{t('To account')}:</p>
      <div className='md:grid grid-cols-7 md:mt-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('To account')}:</p>
        <div className='col-span-5'>
          <Form.Item
            validateStatus={isAccountValid ? 'success' : 'error'}
            className='m-0'
            name='receiving_ac'
            rules={[
              { required: true, message: `${t('Required')}` },
              { min: 20, message: `${t('Account must contain at least 20 digits')}` },
              { max: 20, message: `${t('Account must contain no more than 20 digits')}` },
              {
                pattern: /^[0-9]+$/,
                message: `${t('Enter number only')}`,
              },
            ]}
          >
            <Space.Compact size='large' className='w-full'>
              <Input disabled style={{ width: 60 }} size='large' value={searchedAccount.ccy} />
              <Input
                value={internalOperationsQuery.receiving_ac}
                placeholder={`${t('Enter Recipient Account')}`}
                size='large'
                style={{ width: '100%' }}
                type='text'
                onChange={e => {
                  checkIsAccountOwn(e.target.value);
                  dispatch(internalOperationsQueryAC({ ...internalOperationsQuery, receiving_ac: e.target.value }));
                }}
              />
            </Space.Compact>
          </Form.Item>
          {searchedAccount.customer_name ? (
            <p className='text-sm font-medium text-end mt-2'>{searchedAccount.customer_name}</p>
          ) : null}
          {!isAccountValid ? (
            <p className='text-sm text-red-500 font-medium text-end mt-2'>{t('Account does not found')}</p>
          ) : null}
        </div>
      </div>
      <p className='text-bs color-title mt-5 md:hidden'>{t('Transfer amount')}:</p>
      <div className='md:grid grid-cols-7 md:mt-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Transfer amount')}:</p>
        <div className='col-span-5'>
          <Form.Item
            className='m-0'
            name='sending_amount'
            rules={[
              { required: true, message: `${t('Required')}` },
              {
                pattern: /^[0-9]\d*(?:[.]\d{1,2})?$/,
                message: `${t('Wrong amount')}`,
              },
              {
                pattern: /^[1-9]\d*/,
                message: `${t('Amount must be more 1')}`,
              },
            ]}
          >
            <Space.Compact size='large' className='w-full'>
              <Input disabled style={{ width: 60 }} size='large' value={senderAccountCurrency} />
              <Input
                value={internalOperationsQuery.sending_amount}
                maxLength={12}
                placeholder={`${t('Enter amount')}`}
                size='large'
                style={{ width: '100%' }}
                type='text'
                onChange={e => {
                  dispatch(internalOperationsQueryAC({ ...internalOperationsQuery, sending_amount: e.target.value }));
                }}
              />
            </Space.Compact>
          </Form.Item>
        </div>
      </div>
      <div className='mt-7'>
        <p className='text-base color-title'>{t('Narrative')}:</p>
        <Form.Item
          className='m-0'
          name='narrative'
          rules={[
            { required: true, message: `${t('Required')}` },
            {
              pattern: /^(?=.*\S).*/,
              message: `${t('Enter symbol only')}`,
            },
          ]}
        >
          <TextArea
            maxLength={250}
            onChange={e => {
              dispatch(internalOperationsQueryAC({ ...internalOperationsQuery, narrative: e.target.value }));
            }}
            rows={4}
          />
        </Form.Item>
      </div>
      <div className='flex justify-end mt-5'>
        <Button type='default' onClick={openSaveTemplateModal}>
          {t('Save template')}
        </Button>
        <Button type='primary' className='ml-3' htmlType='submit' disabled={!isAccountValid}>
          {t('Confirm')}
        </Button>
      </div>
    </Form>
  );
};

export default InternalOperationsForm;
