import { Link, Routes, useLocation, Route } from 'react-router-dom';

import AccountInfo from '@components/Accounts/AccountInfo';
import BankAccounts from '@components/Accounts/BankAccounts';
import VisaBusiness from '@components/Accounts/VisaBusiness';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getTransactionListApi } from '@http/transactions';
import getStatus from '@utils/getStatus';
import digitNumber from '@utils/digitNumber';
import { useTranslation } from 'react-i18next';

function AccountsIndex() {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const transactionsHistory = useAppSelector(state => state.transactionsReducer.transactionsHistory);
  const bankAccounts = useAppSelector(state => state.accountsReducer.filteredAccounts);
  const [showBalanceBlock, setShowBalanceBlock] = useState(false);
  const [balances, setbalances] = useState({
    TJS: 0,
    USD: 0,
    EUR: 0,
    RUB: 0,
    SAR: 0,
    CNY: 0,
  });

  const checkActiveRoute = (route: any) => {
    if (location.pathname.includes(route)) {
      return 'header__items-active';
    }
    return null;
  };

  const calculateAccountBalances = () => {
    const balances: any = {
      TJS: 0,
      USD: 0,
      EUR: 0,
      RUB: 0,
      SAR: 0,
      CNY: 0,
    };

    for (let i = 0; i < bankAccounts.length; i++) {
      if (balances.hasOwnProperty(bankAccounts[i].ccy)) {
        balances[bankAccounts[i].ccy] += bankAccounts[i].acy_avl_bal;
      }
    }

    setbalances(balances);
    setShowBalanceBlock(Object.values(balances).some((balance: any) => balance > 0));
  };

  useEffect(() => {
    dispatch(getTransactionListApi(1, {}));
  }, []);

  useEffect(() => {
    if (bankAccounts.length > 0) {
      calculateAccountBalances();
    }
  }, [bankAccounts]);

  const items = [
    {
      key: 'bankAccounts',
      label: t('Bank accounts'),
      element: <BankAccounts itemsCount='6' defaultInfo={true} />,
    },

    {
      key: 'visaBusiness',
      label: t('Visa Business'),
      element: <VisaBusiness />,
    },
  ];

  return (
    <div className='flex justify-center'>
      <div className='lg:w-[1130px] w-full'>
        <div className='flex'>
          <div className='p-4 lg:p-8 w-full h-full bg-white mb-12'>
            <div className='sm:grid lg:grid-cols-6 sm:grid-cols-8 gap-10'>
              <div className='lg:col-span-4 sm:col-span-5'>
                <h1 className='text-xl lg:text-2xl font-semibold color-active'>{t('MyAccounts')}</h1>
                <div className='flex' style={{ borderBottom: '1px solid #eee' }}>
                  {items.map((item: any) => (
                    <Link
                      key={item.key}
                      to={item.key}
                      className={`header__items py-5 ${checkActiveRoute(item.key)} mr-5 text-base`}
                    >
                      {item.label}
                    </Link>
                  ))}
                </div>
                <Routes>
                  {items.map((item: any) => (
                    <Route path={`${item.key}/*`} element={item.element} key={item.key} />
                  ))}
                </Routes>
                {location.pathname.slice(10) === 'bankAccounts' ? (
                  <div>
                    {bankAccounts.length > 0 ? (
                      <div className='flex justify-end mt-7'>
                        <Link to='/allAccounts'>
                          <p className='text-base cursor-pointer underline mb-5 color-active'>{t('All accounts')}</p>
                        </Link>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {location.pathname.slice(10) === 'bankAccounts' ? (
                  <div>
                    {bankAccounts.length > 0 ? (
                      <div className='grid md:grid-cols-4 mt-20'>
                        <div className='col-span-4'>
                          <h1 className='max-sm:text-lg text-2xl mb-5 font-semibold color-active'>
                            {t('Last operations')}
                          </h1>
                          <div
                            className='gap-5 grid grid-cols-12 py-5 px-5 max-md:text-sm font-semibold color-active text-base'
                            style={{ borderBottom: '1px solid #eee' }}
                          >
                            <p className='col-span-2 max-sm:col-span-3 max-md:hidden'>{t('Date')}</p>
                            <p className='col-span-4 max-md:col-span-7'>{t('Operation')}</p>
                            <p className='col-span-3 max-md:hidden text-right'>{t('Status')}</p>
                            <p className='col-span-3 max-md:col-span-5 text-right'>{t('Amount')}</p>
                          </div>
                          <div>
                            {transactionsHistory?.results?.length > 0 ? (
                              <div>
                                <div>
                                  {transactionsHistory?.results?.slice(0, 5).map((item: any) => (
                                    <div
                                      key={item.trans_id}
                                      style={{ borderBottom: '1px solid #eee' }}
                                      className='p-5 gap-5 grid grid-cols-12 listItem md:text-base max-md:text-sm'
                                    >
                                      <p className='col-span-2 max-sm:col-span-3 max-md:hidden font-medium'>
                                        {item.created_at.slice(0, 10)}
                                      </p>
                                      <div className='col-span-4 max-md:col-span-7'>
                                        <p className='font-medium'>{item.type_name}</p>
                                        <p className='text-slate-500 text-sm '>{item.narrative}</p>
                                        <p className='md:hidden max-md:text-sm mt-2'>{item.created_at.slice(0, 10)}</p>
                                      </div>
                                      <p className='col-span-3 max-md:hidden text-right'>
                                        {getStatus(item.status, 12, t)}
                                      </p>
                                      <p className='col-span-3 max-md:col-span-5 text-right font-medium'>
                                        <span>{digitNumber(item?.receiving_amount)} </span>
                                        <span className='font-semibold'>{item.receiving_ccy}</span>
                                        <p className='md:hidden mt-2'>{getStatus(item.status, 10, t)}</p>
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              <div className='h-24 flex items-center justify-center'>
                                <p>{t('No transaction')}</p>
                              </div>
                            )}
                          </div>
                          <div className='mt-7 flex justify-end'>
                            <Link to='/transactions/allTransactions'>
                              <p className='text-base cursor-pointer underline color-active'>{t('All operations')}</p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
              {location.pathname.slice(10) === 'bankAccounts' ? (
                <div className='lg:col-span-2 sm:col-span-3'>
                  {bankAccounts.length > 0 ? (
                    <div className='max-sm:mt-5'>
                      <AccountInfo />
                      <div className='mt-5'>
                        <div className='bg-orange-50 p-5 rounded text-sm'>
                          <h1 className='text-lg font-semibold'>{t('Account Balances')}</h1>
                          <div className='mt-5'>
                            {balances.TJS >= 0 ? (
                              <div className='mt-3'>
                                <p className='text-slate-500'>{t('Account Balances in somoni')}:</p>
                                <p className='font-medium'>{digitNumber(balances?.TJS)} TJS</p>
                              </div>
                            ) : null}
                            {balances.USD > 0 ? (
                              <div className='mt-3'>
                                <p className='text-slate-500'>{t('Account Balances in dollars')}:</p>
                                <p className='font-medium'>{digitNumber(balances?.USD)} USD</p>
                              </div>
                            ) : null}
                            {balances.EUR > 0 ? (
                              <div className='mt-3'>
                                <p className='text-slate-500'>{t('Account Balances in euro')}:</p>
                                <p className='font-medium'>{digitNumber(balances?.EUR)} EUR</p>
                              </div>
                            ) : null}
                            {balances.RUB > 0 ? (
                              <div className='mt-3'>
                                <p className='text-slate-500'>{t('Account Balances in ruble')}:</p>
                                <p className='font-medium'>{digitNumber(balances?.RUB)} RUB</p>
                              </div>
                            ) : null}
                            {balances?.SAR > 0 ? (
                              <div className='mt-3'>
                                <p className='text-slate-500'>{t('Account Balances in riyal')}:</p>
                                <p className='font-medium'>{digitNumber(balances.SAR)} SAR</p>
                              </div>
                            ) : null}
                            {balances?.CNY > 0 ? (
                              <div className='mt-3'>
                                <p className='text-slate-500'>{t('Account Balances in yuan')}:</p>
                                <p className='font-medium'>{digitNumber(balances.CNY)} CNY</p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountsIndex;
