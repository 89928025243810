import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from './assets/languages/ru';
import tg from './assets/languages/tj';
import en from './assets/languages/en';
import zh from './assets/languages/zh';

const resources = {
  ru: {
    translation: ru,
  },
  tg: {
    translation: tg,
  },
  en: {
    translation: en,
  },
  zh: {
    translation: zh,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'ru',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
