import { useEffect } from 'react';

import { Collapse, Pagination } from 'antd';
import { useAppSelector, useAppDispatch } from '@hooks/redux';
import TransactionInfo from './TransactionInfo';
import { getTransactionListApi } from '@http/transactions';

import getStatus from '@utils/getStatus';
import { activePanelAC, transactionStatusAC } from '@store/reducers/transactions';
import digitNumber from '@utils/digitNumber';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

function TransactionList() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const transactionList = useAppSelector(state => state.transactionsReducer.transactionsHistory);
  const transactionStatus = useAppSelector(state => state.transactionsReducer.transactionStatus);
  const searchQuery = useAppSelector(state => state.transactionsReducer.searchQuery);
  const activePanel = useAppSelector(state => state.transactionsReducer.activePanel);

  const getTransactionList = (page: number, status: string) => {
    dispatch(
      getTransactionListApi(page, {
        ...searchQuery,
        status: status,
      })
    );
  };

  const changePage = (page: number) => {
    getTransactionList(page, transactionStatus);
  };

  const openPanel = (key: any) => {
    dispatch(activePanelAC(key));
  };

  useEffect(() => {
    dispatch(activePanelAC(''));

    return () => {
      dispatch(transactionStatusAC(''));
    };
  }, []);

  useEffect(() => {
    dispatch(
      getTransactionListApi(1, {
        ...searchQuery,
        status: transactionStatus,
      })
    );
  }, [transactionStatus]);

  return (
    <div>
      <div className='grid grid-cols-12 font-semibold border-b py-5 sm:pl-12 sm:pr-5 max-sm:pl-8 max-sm:pr-3 max-sm:text-sm gap-5 color-active'>
        <p className='lg:col-span-1 md:col-span-2 col-span-2 max-sm:hidden'>{t('Date')}</p>
        <p className='lg:col-span-2 md:col-span-3 col-span-3 max-sm:hidden'>{t('Sender')}</p>
        <p className='lg:col-span-3 md:col-span-3 col-span-3 max-sm:col-span-4 max-sm:hidden'>{t('Recipient')}</p>
        <p className='lg:col-span-2 md:col-span-2 col-span-2 max-sm:col-span-7'>{t('Transaction type')}</p>
        <p className='col-span-2  text-right max-lg:hidden'>{t('Status')}</p>
        <p className='col-span-2 text-right col-span-2 max-sm:col-span-5'>{t('Amount')}</p>
      </div>
      {transactionList?.results?.length > 0 ? (
        <div>
          <Collapse activeKey={activePanel} onChange={openPanel} ghost className='p-0'>
            {transactionList?.results.map((item: any) => (
              <Panel
                header={
                  <div>
                    <div className='grid grid-cols-12 sm:items-center py-5 cursor-pointer gap-5'>
                      <p className='lg:col-span-1 md:col-span-2 max-sm:hidden col-span-2 font-medium'>
                        {item.created_at.slice(0, 10)}
                      </p>
                      <p className='lg:col-span-2 md:col-span-3 col-span-3 max-sm:hidden'>{item.sending_ac}</p>
                      <p className='lg:col-span-3 md:col-span-3 col-span-3 max-sm:hidden'>{item.receiving_ac}</p>
                      <div className='lg:hidden col-span-2 col-span-2 max-sm:col-span-7'>
                        <p className='max-sm:font-semibold'>{item.type_name}</p>
                        <p className='sm:hidden text-slate-600'>{item.sending_ac}</p>
                        <p className='md:hidden text-slate-600'>{item.created_at.slice(0, 10)}</p>
                      </div>
                      <p className='lg:col-span-2 md:col-span-1 max-lg:hidden'>{item.type_name}</p>
                      <p className='col-span-2 text-right max-lg:hidden'>{getStatus(item.status, 12, t)}</p>
                      <p className='col-span-2 text-right max-sm:col-span-5 max-lg:hidden'>
                        {digitNumber(item.sending_amount)} {item.sending_ccy}
                      </p>
                      <div className='col-span-2 text-right max-sm:col-span-5 lg:hidden'>
                        <p>
                          {digitNumber(item.sending_amount)} {item.sending_ccy}
                        </p>
                        <p>{getStatus(item.status, 12, t)}</p>
                      </div>
                    </div>
                  </div>
                }
                key={item.trans_id}
              >
                <TransactionInfo item={item} />
              </Panel>
            ))}
          </Collapse>
          <div className='mt-7 flex justify-end'>
            <Pagination
              onChange={changePage}
              current={transactionList.current_page}
              defaultPageSize={10}
              total={transactionList.total_pages * 10}
              showSizeChanger={false}
            />
          </div>
        </div>
      ) : (
        <div className='h-24 lg:h-72 flex items-center justify-center'>
          <p>{t('No Transactions')}</p>
        </div>
      )}
    </div>
  );
}

export default TransactionList;
