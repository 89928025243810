import { Button, Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { isSaveTemplateModalOpenAC } from '@store/reducers/payments';
import { saveTemplateApi } from '@http/user';
import { useState } from 'react';

const SaveTemplateModal = ({ template }: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [templateForm] = Form.useForm();
  const isSaveTemplateModalOpen = useAppSelector(state => state.paymentsReducer.isSaveTemplateModalOpen);
  const [title, setTitle] = useState('');

  const saveTemplate = () => {
    dispatch(saveTemplateApi({ ...template, title: title }));
    dispatch(isSaveTemplateModalOpenAC(false));
    templateForm.resetFields();
  };

  return (
    <div>
      <Modal
        footer={null}
        title={t('Save template')}
        open={isSaveTemplateModalOpen}
        onOk={() => dispatch(isSaveTemplateModalOpenAC(false))}
        onCancel={() => dispatch(isSaveTemplateModalOpenAC(false))}
      >
        <Form form={templateForm} name='template' onFinish={saveTemplate}>
          <Form.Item className='m-0' name='title' rules={[{ required: true, message: `${t('Required')}` }]}>
            <Input placeholder={`${t('Enter template name')}`} size='large' onChange={e => setTitle(e.target.value)} />
          </Form.Item>
          <div className='flex justify-end mt-5'>
            <Button type='primary' htmlType='submit'>
              {t('Save')}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default SaveTemplateModal;
