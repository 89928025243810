/* eslint import/no-anonymous-default-export: [2, {'allowObject': true}] */
export default {
  'header': {
    'main': 'Асоси',
    'vozm': 'Имкониятҳо',
    'products': 'Продуктхо',
    'tarifs': 'Тарифхо',
    'docs': 'Ҳуҷҷатгузорӣ',
    'request': 'Дархостро ирсол намоед',
    'logIn': 'Ворид шудан',
  },
  'mainPage': {
    'title': 'Бонк дар як телефон!',
    'desc': 'Молияи худро ба осонӣ ва қулай назорат кунед!',
    'request': 'Дархостро ирсол намоед',
  },
  'vozm': {
    'mainTitle': 'Имкониятҳои Ориён Бизнес',
    'title1': 'Тасдиқи осони пардохтҳо бо СМС-паёмак',
    'title2': 'Дастрасӣ ба барнома дар реҷаи 24/7',
    'title3': 'Дар ҳама гуна дастгоҳҳо фаъолият менамояд',
  },
  'products': {
    'mainTitle': 'Маҳсулот барои тиҷорати шумо',
    'title1': 'Visa business',
    'title2': 'Эквайринги тиҷоратӣ',
    'title3': 'Лоиҳаи музди меҳнат',
    'title4': 'Хизматрасониҳои ҳисоббаробаркунӣ-хазинавӣ',
    'title5': 'Қуттиҳои сейфӣ',
  },
  'tarifs': {
    'mainTitle': 'Нархҳо',
    'title1': 'Стандарт',
    'title1Description': '',
    'title2': 'Пардохтҳо',
    'title2Description': '',
    'title3': 'Амалиётҳои пардохт',
    'title3Description': '',
  },
  'documents': {
    'mainTitle': 'Ҳуҷҷатҳо',
    'title1': 'Нархҳо',
    'downloadLink': 'Тарофаҳо_барои_хизматрасонӣ_ба_шахсони_ҳуқуқӣ_ва_соҳибкорони_инфиродӣ',
    'title2': 'Шартномаи лоиҳаи музди меҳнат',
    'title2Description': '',
    'title3': 'Қоидаҳои амнияти иттилоотӣ',
    'title3Description': '',
    'title4': 'Назорати асъор',
    'title4Description': '',
  },
  'footer': {
    'forClients': {
      'title': 'Барои муштариён',
      'desc1': 'Пардохтҳо ва интиқолҳо',
      'desc2': 'Қарзҳо',
      'desc3': 'Амонатҳо',
      'desc4': 'Кортҳои бонкӣ',
    },
    'aboutBank': {
      'title': 'Дар бораи бонк',
      'desc1': 'Саҳифаи асосӣ',
      'desc2': 'Кор дар бонк',
      'desc3': 'Кӯмак',
      'desc4': 'Харитаи сайт',
    },
    'products': {
      'title': 'Маҳсулот',
      'desc1': 'OrienPay',
      'desc2': 'Банкинги мобилӣ',
      'desc3': 'Интернет-банкинг',
    },
    'contacts': {
      'title': 'Робита',
      'address': 'ш.Душанбе, Рӯдакӣ, 95/1',
      'callCenter': 'Маркази тамос 2222',
    },
  },
  'RECEIVED': 'Қабул шуд',
  'IN_PROGRESS': ' Коркард мешавад',
  'IN_VERIFICATION': 'Тасдиқ тариқи СМС',
  'IN_CONFIRMATION': 'Дар интизори тасдиқ',
  'READY': 'Омода шуд',
  'SUCCESS': 'Муваффақ',
  'FAILED': 'Номуваффақ',
  'DECLINED': 'Рад карда шуд',
  'INVALID_FILE': 'Ҳуҷҷати нодуруст',
  'INVALID': 'Нодуруст',
  'IN_APPROVAL': 'Дар ҳоли тасдиқшавӣ',
  'TO_APPROVE': 'Барои тасдиқ',
  'APPROVED': 'Тасдиқ шуд',
  'PARTIAL_SUCCESS': 'Қисман иҷро шуд',
  'POSTPONED': 'Ба таъхир гузошта шуд',
  'IN_BANK_REVIEW': 'Дар баррасии бонк',
  'IN_REVIEW': 'Дар баррасӣ',
  'BANK_APPROVED': 'Аз тарафи бонк тасдиқ шуд',
  'CANCELED': 'Рад шуд',
  'BANK_DECLINED': 'Бонк рад кард',
  'REVERSED': 'Баргардонида шуд',
  'IN_VALIDATION': 'Дар ҳоли тафтиш',
  'READY_TO_PROCEED': 'Омода барои гузаронидан',
  'Language': 'Тоҷикӣ',
  'Main page': 'Саҳифаи асосӣ',
  'Clients': 'Муштариён',
  'Legal entity': 'Шахси ҳуқуқӣ',
  'Transactions': 'Амалиёт',
  'Registration': 'Бақайдгирӣ',
  'List': 'Рӯйхат',
  'Restrictions': 'Маҳдудиятҳо',
  'Creating a client': 'Эҷоди муштарӣ',
  'Accounts': 'Суратҳисобҳо',
  'Accesses': 'Дастрасиҳо',
  'Filter': 'Филтр',
  'By client ID': 'Бо ID муштарӣ',
  'By username': 'Бо номи истифодабаранда',
  'By phone number': 'бо рақами телефони мобилӣ',
  'Status': 'Ҳолат',
  'Find': 'Ҷустуҷӯ',
  'ID': 'ID',
  'Name': 'Ном',
  'Surname': 'Насаб',
  'Patronymic': 'Номи падар',
  'Date of Birth': 'Рӯзи таваллуд',
  'Phone number': 'Рақами телефони мобилӣ',
  'Intrabank': 'Дохилибонкӣ',
  'Own accounts': 'Суратҳисобҳои шахсӣ',
  'Salaries': 'Маошҳо',
  'Interbank': 'Байнибонкӣ',
  'Interbank payment': 'Амалиёти Байнибонкӣ',
  'SWIFT': 'СВИФТ',
  'Ruble': 'Рубли русӣ',
  'view': 'view',
  'init': 'init',
  'outh': 'outh',
  'Account': 'Суратҳисоб',
  'Legal entities': 'Шахсони ҳуқуқӣ',
  'Customer _ no': 'Рақами муштарӣ',
  'Company': 'Ширкат',
  'Number of employees': 'Шумораи кормандон',
  'Transactions List of legal entity transactions': 'Амалиёт Рӯйхати амалиётҳои шахсони ҳуқуқӣ',
  'Search': 'Ҷустуҷӯ',
  'All': 'Ҳама',
  'Sign in': 'Воридшавӣ ба система',
  'Signing in': 'Дар ҳоли тасдиқшавӣ',
  'Processing': 'Коркард мешавад',
  'Not successful': 'Номуваффақ',
  'Successful': 'Муваффақ',
  'Date': 'Сана',
  'Sender': 'Ирсолкунанда',
  'Recipient': 'Қабулкунанда',
  'Sender account': 'Суратҳисоби ирсолкунанда',
  'Recipient account': 'Суратҳисоби қабулкунанда',
  'Transaction type': 'Навъи амалиёт',
  'Amount': 'Маблағ',
  'Hello': 'Рӯз ба хайр',
  'WannaEnter': 'Ба утоқи шахсии худ ворид шудан мехоҳед?',
  'Login': 'Логин',
  'Password': 'Рамз',
  'Sign up': 'Бақайдгирӣ',
  'Exchange Rates': 'Қурби асъор',
  'Orienbank': 'Ориёнбонк',
  'NBT': 'БМТ',
  'Currency': 'Асъор',
  'Purchase': 'Харид',
  'Sale': 'Фурӯш',
  'USD': 'USD',
  'EUR': 'EUR',
  'RUB': 'RUB',
  'US dollars': 'Доллар',
  'Euro': 'Евро',
  'Russian ruble': 'Рубли русӣ',
  'Successfully': 'Бомуваффақият',
  'All accounts': 'Ҳамаи суратҳисобҳо',
  'TJS': 'TJS',
  'Somoni': 'Сомонӣ',
  'Waiting for authorization': 'Интизори иҷозатдиҳӣ',
  'Last operations': 'Амалиётҳои охирин',
  'Between own accounts': 'Байни суратҳисобҳои худ',
  'Intrabank payment': 'Пардохти дохилибонкӣ',
  'MyAccounts': 'Суратҳисобҳои ман',
  'Active': 'Фаъол',
  'Not active': 'Ғайрифаъол',
  'Update': 'Навсозӣ',
  'Updated': 'Навсозишуда',
  'Hidden': 'Пинҳоншуда',
  'Russian': 'Русӣ',
  'English': 'Англисӣ',
  'Tajik': 'Тоҷикӣ',
  'Bank accounts': 'Суратҳисобҳои бонкӣ',
  'Visa Business': 'Visa Business',
  'Payment Information': 'Маълумоти пардохт',
  'Information': 'Маълумот',
  'Account Information': 'Маълумот оиди суратҳисоб',
  'File information': 'Маълумот оиди файл',
  'Account name': 'Номи суратҳисоб',
  'Balance': 'Тавозун',
  'Account type': 'Навъи суратҳисоб',
  'Term': 'Муддатнок',
  'Address': 'Нишонӣ',
  'TIN': 'РМА',
  'Date of opening': 'Санаи кушодашавӣ',
  'Requisites': 'Маълумоти муфассал',
  'Download': 'Зеркашӣ',
  'Upload': 'Боркуни',
  'Upload file': 'Боркуни файлҳо',
  'Description': 'Тавсиф',
  'Show filters': 'Намоишдиҳӣ филтрҳо',
  'Next': 'Идома',
  'No transaction': 'Амалиёт нест',
  'TxID': 'ID амалиёт',
  'Commission': 'Ҳаққи хизматрасонӣ',
  'Create': 'Эҷод кардан',
  'Date of creation': 'Санаи ташкилебӣ',
  'Retry payment': 'Такрори пардохт',
  'Download PDF': 'Зеркашии PDF',
  'Authorizers': 'Ваколатдорон',
  'Reject': 'Рад кардан',
  'Confirm': 'Тасдиқ кардан',
  'Approve': 'Тасвиб кардан',
  'Payment information': 'Маълумот оиди пардохт',
  'Bank information': 'Маълумот оиди бонк',
  'From account': 'Аз суратҳисоб',
  'To account': 'Ба суратҳисоб',
  'Transfer amount': 'Маблағи интиқол',
  'Narrative': 'Таъинот',
  'Transfer': 'Интиқол',
  'Multiple transfers': 'Интиқолҳои сершумор',
  'Template': 'Намуна',
  'Existing template': 'Намунаи мавҷудбуда',
  'Sender information': 'Маълумот оиди ирсолкунанда',
  'Recipient information': 'Маълумот оиди қабулкунанда',
  'Number': 'Рақам',
  'Account number': 'Рақами суратҳисоб',
  'Confirm account number': 'Тасдиқи рақами суратҳисоб',
  'Payment details': 'Тафсилоти пардохт',
  'Other details': 'Тафсилоти дигар',
  'Document type': 'Навъи ҳуҷҷат',
  'Document number': 'Рақами ҳуҷҷат',
  'Document date': 'Санаи ҳуҷҷат',
  'Save template': 'Сабти намуна',
  'Country': 'Мамлакат',
  'City': 'Шаҳр',
  'About Bank': 'Дар бораи бонк',
  'Purpose of the bank': 'Таъиноти бонк',
  'Enter amount': 'Маблағро ворид намоед',
  'Document': 'Ҳуҷҷат',
  'Enter document number': 'Рақами ҳуҷҷатро ворид намоед',
  'Initiator': 'Қабулкунанда',
  'Number of transactions': 'Миқдори амалиёт',
  'Not valid': 'Беэътибор',
  'Import': 'Воридот',
  'From the beginning': 'Аз аввал',
  'To the end': 'То охир',
  'Rejected': 'Рад карда шуд',
  'Importing payments from a file': 'Воридсозии пардохтҳо аз файл',
  'Download template for multiple transactions': 'Зеркашии намунаҳо барои амалиётҳои гуногун',
  'Download document': 'Зеркашии ҳуҷҷат',
  'Salary project': 'Лоиҳаи музди меҳнат',
  'Not authorized': 'Иҷозат дода нашудааст',
  'Select account': 'Интихоби суратҳисоб',
  'Select date': 'Интихоби сана',
  'View': 'Намоишдиҳӣ',
  'Order an statement': 'Фармоишдиҳии иқтибос',
  'View reports': 'Намоишдиҳӣ ҳисоботҳо',
  'View orders': 'Намоишдиҳӣ фармоишҳо',
  'Ordering a statement': 'Фармоиши иқтибос',
  'Enter the title': 'Сарлавҳаро ворид кунед',
  'Order': 'Фармоиш додан',
  'Orders': 'Фармоишҳо',
  'Ready': 'Омода',
  'In progress': 'Коркард',
  'Failed': 'Но муваффақ',
  'Period': 'Давра',
  'Order date': 'Санаи фармоиш',
  'Tariffs': 'Тарофаҳо',
  'Bank account': 'Суратҳисоби бонкӣ',
  'free': 'Ройгон',
  'Opening / Maintaining and closing a current account': 'Кушодан / нигоҳдорӣ ва пӯшонидани суратҳисоби ҷорӣ',
  'Receiving account statements': 'Гирифтани иқтибоси суратҳисоб',
  'Transfer between own accounts': 'Интиқоли маблағ байни суратҳисобҳои шахсӣ',
  'Transfer to accounts at other banks': 'Интиқол ба суратҳисобҳо дар бонкҳои дигар',
  'Show all currencies': 'Намоишдиҳӣ ҳамаи асъор',
  'Rate': 'Қурб',
  'Currency сode': 'Рамзи асъор',
  'Unit': 'Воҳиди андозагирӣ',
  'Currency name': 'Номгуӣ асъор',
  'No.': '№',
  'Change Password': 'Иваз кардани рамз',
  'Log out': 'Баромадан',
  'Profile': 'Профил',
  'Notification': 'Огоҳӣ',
  'Enter a new password': 'Рамзи навро ворид намоед',
  'Enter old password': 'Рамзи куҳнаро ворид намоед',
  'Сonfirm new password': 'Рамзи навро тасдиқ намоед',
  'Change': 'Иваз кардан',
  'Products': 'Маҳсулот',
  'Contacts': 'Тамос',
  'Home page': 'Саҳифаи асосӣ',
  'Payments and transfers': 'Пардохтҳо ва интиқолҳо',
  'OrienPay': 'OrienPay',
  'oriyon-business@oriyonbonk.tj': 'oriyon-business@oriyonbonk.tj',
  'Call center 2222': 'Маркази тамос 2222',
  'Bank jobs': 'Ҷойҳои корӣ дар бонк',
  'Help': 'Кӯмак',
  'Site map': 'Харитаи сайт',
  'Loans': 'Қарзҳо',
  'Deposits': 'Пасандозҳо',
  'Bank cards': 'Кортҳои бонкӣ',
  'Mobile banking': 'Бонки мобилӣ',
  'Internet banking': 'Интернет бонкинг',
  'OtpCode': 'Рамзи тасдиқ',
  'ResendTime': 'Интиқоли дубора тавассути',
  'Second': 'сония',
  'ResendCode': 'Рамзро бори дигар фиристодан',
  'Save': 'Сабт',
  'All operations': 'Ҳамаи амалиётҳо',
  'Update operations': 'Навсозӣ амалиёт',
  'Operation': 'Амалиёт',
  'No Transactions': 'Амалиёт нест',
  'Payments': 'Пардохтҳо',
  'More': 'Бештар',
  'CurrienciesToSomoni': 'Қурби расмии асъор нисбат ба сомонӣ',
  'Hide all currencies': 'Пинҳони асъор',
  'ToSign': 'Тасдиқ кунед',
  'Rejecteds': 'Рад кардашудаҳо',
  'Enter Recipient Account': 'Суратҳисоби қабулкунандаро ворид намоед',
  'Confirm payment': 'Тасдиқи пардохт',
  'Payment type': 'Намуди амалиёт',
  'Enter Recipient': 'Қабулкунандаро ворид намоед',
  'Enter TIN': 'РМА-ро ворид намоед',
  'Select document type': 'Намуди документро интихоб кунед',
  'Enter template name': 'Номи намуна-ро ворид намоед',
  'Recipient TIN': 'РМА-и қабулкунанда',
  'Show balance': 'Намоишдиҳӣ баланс',
  'Hide balance': 'Пинҳони баланс',
  'Hide filters': 'Пинҳони филтрҳо',
  'Swift': 'СВИФТ',
  'Bank name': 'Номи бонк',
  'Filters': 'Филтрҳо',
  'Show': 'Намоишдиҳӣ',
  'Select template': 'Шаблонро интихоб кунед',
  'One Dollar': '1 Доллар',
  'One Ruble': '1 Рубл',
  'One Euro': '1 Евро',
  'Orienbank Address': 'ш.Душанбе, Рудаки, 95/1',
  'Call Center': 'Маркази тамос',
  'Oriyonbank': 'Ориёнбонк',
  'Enter City': 'Шаҳро ворид намоед',
  'Enter Country': 'Мамлакатро ворид намоед',
  'Enter Address': 'Суроғаро ворид намоед',
  'Enter Account': 'Суратҳисобро ворид намоед',
  'Enter swift code': 'Свифт кодро ворид намоед',
  'Enter bank name': 'Номи бонкро ворид намоед',
  'Enter doc number': 'Рақами документро ворид намоед',
  'Enter SMS code': 'СМС-ро ворид намоед',
  'Find swift code': 'Ҷустучӯи свифт код',
  'Find bic code': 'Ҷустучӯи БИК код',
  'Required': 'Ҳатми',
  'Enter symbol only': 'Танҳо аломатҳоро ворид кунед',
  'Amount must be more 1': 'Маблағ бояд аз 1 зиёд бошад',
  'Wrong amount': 'Маблағи нодуруст',
  'Latin Only': 'Танҳо ҳарфҳои лотиниро ворид кунед',
  'Account does not match': 'Суратҳисоби воридкардаи шумо мувофиқат намекунад!',
  'Account must contain no more than 20 digits': 'Суратҳисоб набояд аз 20 рақам зиёд бошад',
  'Account must contain no more than 21 digits': 'Суратҳисоб набояд аз 21 рақам зиёд бошад',
  'Account must contain no more than 35 digits': 'Суратҳисоб набояд аз 35 рақам зиёд бошад',
  'Account must contain at least 8 digits': 'Суратҳисоб бояд ҳадди аққал 8 рақам дошта бошад',
  'Account must contain at least 10 digits': 'Суратҳисоб бояд ҳадди аққал 10 рақам дошта бошад',
  'Account must contain at least 20 digits': 'Суратҳисоб бояд ҳадди аққал 20 рақам дошта бошад',
  'Enter number only': 'Танҳо рақамҳоро ворид кунед',
  'Enter letters only': 'Танҳо ҳарфҳоро ворид кунед',
  'ConfirmSmsFromPhone': 'Барои тасдиқи транзаксия ба рақами телефони шумо коди SMS фиристода шуд',
  'No accounts': 'Суратҳисоб нест',
  'Account does not found': 'Суратҳисоб пайдо нашуд',
  'Must be 6 numbers': 'Бояд 6 рақам бошад',
  'Must be 9 numbers': 'Бояд 9 рақам бошад',
  'Login to account': 'Воридшавӣ ба утоқ',
  'Field must contain at least 8 digits': 'Майдон бояд на камтар аз 8 рақам дошта бошад!',
  'For registration':
    'Барои сабти ном дар системаи Интернет-банкинг ба идораи марказии Ориёнбонк, суроғаи рақамӣ муроҷиат намоед',
  'For any questions':
    'Барои ҳама гуна саволҳо лутфан ба маркази тамоси мо муроҷиат кунед, мо бо омодагӣ ба шумо кӯмак хоҳем кард',
  'Code': 'Код',
  'Sent code to phone for sequirity':
    'Барои бехатарӣ профили шумо, мо ба рақами телефони шумо рамзи тасдиқро мефиристем',
  'Sent code to email for sequirity': 'Барои бехатарӣ профили шумо, мо ба почтаи шумо рамзи тасдиқро мефиристем',
  'Change your password': 'Рамзи худро иваз кунед',
  'Password does not match': 'Пароли воридкардаи шумо мувофиқат намекунад!',
  'Confirm password': 'Паролро тасдиқ кунед',
  'Change and remind password': 'Рамзро ивазкунед ва ёдоварӣ намоед.',
  'International': 'Байналмилалӣ',
  'File date': 'Санаи файл',
  'Total amount': 'Маблағи умумй',
  'Debit': 'Дебит',
  'Credit': 'Кредит',
  'Own accounts payments': 'Амалиётиҳои байни суратҳисобҳо',
  'Intrabank payments': 'Амалиётиҳои дохилибонкӣ',
  'Interbank payments': 'Амалиётиҳои байнибонкӣ',
  'File name': 'Номи файл',
  'Successful count': 'Шумораи муваффақ',
  'Not successful count': 'Шумораи номуваффақ',
  'Download file': 'Зеркашии файл',
  'Error': 'Хатогиҳо',
  'Transaction list': 'Рӯйхат амалиётиҳо',
  'Branch': 'Филиал',
  'Card accounts': 'Суратҳисобҳои корти',
  'Approved': 'Тасдиқ кард',
  'From amount': 'Маблағ аз',
  'To amount': 'Маблағ то',
  'Start date': 'Саршавии сана',
  'End date': 'Тамомшавии сана',
  'Start period': 'Саршавии давра',
  'End period': 'Тамомшавии давра',
  'No data': 'Маълумот нест',
  'Enter document type': 'Намуди документро ворид намоед',
  'In confirmation': 'Интизори тасдиқ',
  'In verification': 'Дар иҷозат',
  'Partial success': 'Қисман иҷро шудаҳо',
  'Write-off account': 'Аз суратҳисоб',
  'Employees list': 'Рӯйхат коргаро',
  'Postpone': 'Ба таъхир андохтан',
  'Import payments from file': 'Пардохтҳоро аз файл ворид кунед',
  'Download salary file template': 'Зеркашии намунаи файли музди меҳнат',
  'Upload document': 'Ҳуҷҷатро Зеркашӣ кунед',
  'The file format must be xlsx, xls': 'Формати файл бояд xlsx, xls бошад',
  'The file format must be txt': 'Формати файл бояд txt бошад',
  'The file size should not exceed 2MB': 'Андозаи файл набояд аз 2 МБ зиёд бошад',
  'FIO': 'Номи пурра',
  'Company name': 'Номи ширкат',
  'First name': 'Номи корбар',
  'Last name': 'Насаби корбар',
  'User phone number': 'Телефони корбар',
  'International payment': 'Пардохти байналмилалӣ',
  'Transaction date': 'Санаи пардохт',
  'Back': 'Баргаштан',
  'Repeat': 'Бори дигар',
  'Valid': 'Боэътибор',
  'Urgent': 'Таъҷилӣ',
  'Edit account name': 'Номи суратҳисобро таҳрир кунед',
  'Maximum number of characters: 70': 'Шумораи максималии аломатҳо: 70',
  'Confirm new password': 'Рамзи нави худро тасдиқ кунед',
  'Exit': 'Баромадан',
  'Pick': 'Интихоб',
  'Enter number or letter only': 'Танҳо рақам ва ҳарф ворид кунед',
  'Sure to confirm?': 'Шумо мутмаин ҳастед, ки мехоҳед тасдиқ кунед?',
  'Sure to decline?': 'Шумо мутмаин ҳастед, ки мехоҳед бекор кунед?',
  'Cancel': 'Бекор кардан',
  'Make a payment': 'Гузарондани амалиёт',
  'Enter': 'Воридшавӣ',
  'Insufficient funds in the account': 'Маблағи суратҳисоб кифоя нест',
  'Templates': 'Намунаҳо',
  'Internal': 'Дохилибонкӣ',
  'Delete': 'Тоза кардан',
  'BIC': 'БИК',
  'No Templates': 'Намунаҳо нестанд',
  'Sender and Recipient accounts must be same': 'Суратҳисоби ирсолкунанда ва қабулкунанда бояд як асъор бошад',
  'Statement': 'Иқтибос',
  'Credited amount': 'Маблағи воридшуда',
  'Exchange': 'Мубодилаи асъор',
  'Enter rate': 'Қурби асъор',
  'Credit amount': 'Маблағи гирифташуда',
  'Debit amount': 'Маблағи воридшаванда',
  'Credit account': 'Аз суратҳисоб',
  'Debit account': 'Ба суратҳисоб',
  'Beneficiary Bank': 'Бонки қабулкунанда',
  'Exchange amount': 'Маблағи мубодила',
  'Print': 'Чоп кардан',
  'View all': 'Намоиши кул',
  'otpType': 'Лутфан усули гирифтани рамзи тасдиқро интихоб намоед',
  'withSMS': 'Ба воситаи СМС',
  'withEmail': 'Ба воситаи почтаи электрониӣ',
  'Recipient country': 'Мамлакати қабулкунанда',
  'Recipient city': 'Шаҳри қабулкунанда',
  'Recipient address': 'Адреси қабулкунанда',
  'Recipient bank address': 'Адреси бонки қабулкунанда',
  'Recipient bank name': 'Номи бонки қабулкунанда',
  'Recipient bank swift': 'Swift коди бонки қабулкунанда',
  'onlyTwoMonths': 'На зиед аз ду моҳ',
  'Oriyon': 'Ориён',
  'Business': 'Бизнес',
  'Account Balances': 'Тавозуни суратҳисобҳо',
  'Account Balances in somoni': 'Тавозуни суратҳисобҳо бо сомонӣ',
  'Account Balances in dollars': 'Тавозуни суратҳисобҳо бо доллари ИМА',
  'Account Balances in euro': 'Тавозуни суратҳисобҳо бо Евро',
  'Account Balances in ruble': 'Тавозуни суратҳисобҳо бо Рубл',
  'Account Balances in riyal': 'Тавозуни суратҳисобҳо бо риол',
  'Account Balances in yuan': 'Тавозуни суратҳисобҳо бо юан',
  'Free': 'Ройгон',
  'Providing an extract': 'Додани иқтибос аз суратҳисоб оид ба амалиётҳои гузаронидашуда',
  'Opening, closing and keeping an account': 'Кушодани / Бурдани ва Бастани суратҳисоб',
  'Swift transfer info': 'Интиқоли маблағҳо тариқи низомҳои байналмилалӣ интиқоли маблағҳо',
  'Swift cost': 'мувофиқи тарофаҳои низомҳои байналмилалӣ интиқоли маблағҳо',
  'Select format': 'Форматро интихоб намоед',
  'Format': 'Формат',
  'Enter bic code': 'БИК-ро интихоб намоед',
  'Ruble transfer': 'Интиқоли рублӣ',
  'EditionalInfo': 'Маълумоти Иловагӣ',
  'PrepaymentForProduct': 'Пешпардохти мол',
  'PaymentForProduct': 'Оплата за товар',
  'PrepaymentForService': 'Пардохти мол',
  'PaymentForService': 'Пардохт барои хидматҳо',
  'Others': 'Дигар',
  'Enter KPP': 'КПП-ро ворид намоед',
  'City address': 'Шаҳр, суроға',
  'Correspondent account': 'Суратҳисобҳи корреспондентӣ',
  'Select narrative': 'Таъинот интихоб кунед',
  'All currencies': 'Ҳамаи асъорҳо',
  'Swift narrative': 'Танҳо ҳарфҳои калон ва аломатҳои . » « №',
  '2 somoni': '2 сомони',
  'Download extract': 'Зеркашӣ иқтибос',
  'Closed': 'Баста',
  'Loan amount': 'Маблағи қарз',
  'Loan start date': 'Оғози эътибор',
  'Loan end date': 'Санаи анҷом',
  'Loan balance': 'Бақияи қарз',
  'Loan Information': 'Маълумоти қарз',
  'Loan type': 'Намуди қарз',
  'Download payment schedule': 'Зеркашӣ ҷадвали пардохт',
  'Accredits': 'Аккредитив',
  'Overdraft': 'Овердрафт',
  'No loans': 'Қарз нест',
  'Next payment date': 'Санаи пардохти навбатӣ',
  'Amount due': 'Маблағи баргардонидани қарз',
  'Open new deposit': 'Open new deposit',
  'Deposit owner': 'Соҳиби пасандоз',
  'Deposit Information': 'Маълумоти пасандоз',
  'Month': 'Моҳ',
  'Percent': 'Фоиз',
  'No deposits': 'Пасандоз нест',
  'Counterparties': 'Контрагенҳо',
  'Salary': 'Маош',
  'Add': 'Илова кардан',
  'New counterparty': 'Контрагенти нав',
  'Individuals': 'Шахсони воқеӣ',
  'Foreigs': 'Шахсони хориҷӣ',
  'Residents': 'Резидентҳо',
  'Footer': {
    'address':
      'Ҷумҳурии Тоҷикистон, ш. Душанбе, хиёбони Рӯдакӣ 95/1 Ҳ/М: 20402972413691, РМА: 020003038, РЯМ: 350101369',
    'emails': 'Почтаи электронӣ',
    'tj': 'Барои зангҳо дар дохили Тоҷикистон',
    'world': 'Барои зангҳо аз ҳар гӯшаи ҷаҳон',
    'nbt': 'Телефони боварии Бонки миллии Тоҷикистон',
    'workTime': 'Вақти корӣ',
    'workDays': 'Рӯзҳои корӣ: душанбе - ҷумъа аз 08:00 то 17:00',
    'saturday': 'Шанбе: аз 09:00 то 12:00',
    'sunday': 'Якшанбе рузи истироҳат',
    'receptionOfCitizens': 'Қабули шаҳрвандон: ҳар рӯзи шанбе',
    'orb': 'ҶСК "Ориёнбонк"',
    'license': 'Иҷозатномаи БМТ таҳти №0000004 аз 30 январи соли 2014',
    'callcenter': 'Маркази тамос Ориёнбонк',
    'supportNumber': 'Хадамоти дастгирии Ориенбизнес',
    'dayOf': 'Шанбе ва Якшанбе рузҳои истироҳат',
  },
};
