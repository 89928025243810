import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@hooks/redux';

export const UserInfo = () => {
  const { t } = useTranslation();
  const userInfo = useAppSelector(state => state.appReducer.user);
  return (
    <div>
      <div className='text-base leading-8'>
        <p>
          <span className='font-semibold'>{t('First name')}:</span> {userInfo.first_name}
        </p>
        <p>
          <span className='font-semibold'>{t('Last name')}:</span> {userInfo.last_name}
        </p>
        <p>
          <span className='font-semibold'>{t('User phone number')}:</span> {userInfo.mobile_phone}
        </p>
      </div>
    </div>
  );
};

export default UserInfo;
