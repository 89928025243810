import { Tabs, Select, Button, Collapse, Input, Dropdown, Space } from 'antd';

import { useAppDispatch, useAppSelector } from '@hooks/redux';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd/lib';
import getStatus from '@utils/getStatus';

const { Panel } = Collapse;

const counterparties = [
  {
    name: 'ЧДММ Зинг',
    account: '20202840145278965412',
    tin: '010204854',
    status: 'APPROVED',
  },
  {
    name: 'ҶДММ Ширкати ҳавопаймои сомон эйр',
    account: '20202840145278965412',
    tin: '010204854',
    status: 'APPROVED',
  },
  {
    name: 'ҶДММ Ширкати алюминии талко',
    account: '20202840145278965412',
    tin: '010204854',
    status: 'APPROVED',
  },
  {
    name: 'ЧДММ Talco managment',
    account: '20202840145278965412',
    tin: '010204854',
    status: 'APPROVED',
  },
  {
    name: 'ЧДММ Talco managment',
    account: '20202840145278965412',
    tin: '010204854',
    status: 'TO_APPROVE',
  },
  {
    name: 'ЧДММ Talco managment',
    account: '20202840145278965412',
    tin: '010204854',
    status: 'IN_APPROVAL',
  },
  {
    name: 'ЧДММ Talco managment',
    account: '20202840145278965412',
    tin: '010204854',
    status: 'APPROVED',
  },
];

const items: MenuProps['items'] = [
  {
    key: '4',
    danger: true,
    label: 'Удалить',
  },
];

function Counterparties() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <div className='flex justify-center'>
      <div className='lg:w-[1130px] w-full'>
        <div className='flex'>
          <div className='p-4 lg:p-8 w-full h-full bg-white mb-12'>
            <div>
              <div className='flex justify-between items-center'>
                <h1 className='max-md:hidden text-2xl mb-2 font-semibold color-active'>{t('Counterparties')}</h1>
                <Button
                  type='primary'
                  onClick={() => {
                    navigate('/counterparties/create');
                  }}
                >
                  {t('Add')}
                </Button>
              </div>
              <div className='shadow rounded-xl mt-5'>
                <div className='p-5'>
                  <Input size='large' placeholder='Название контрагента' />
                  <div className='mt-5 flex gap-5'>
                    <Button>Резиденты</Button>
                    <Button>Иностранные контрагенты</Button>
                  </div>
                </div>
              </div>

              <div className='shadow rounded-xl mt-7'>
                <div className='px-5'>
                  <div className='p-5 grid grid-cols-12 gap-5 font-semibold text-sm color-active'>
                    <p className='col-span-4'>НАЗВАНИЕ</p>
                    <p className='col-span-3'>СЧЕТ</p>
                    <p className='col-span-2'>ИНН</p>
                    <p className='col-span-2'>СТАТУС</p>
                    <p></p>
                  </div>
                </div>
                <div className='p-5 pt-0'>
                  {counterparties.map((item: any) => (
                    <div
                      className='p-5 grid grid-cols-12 gap-5 listItem text-sm'
                      style={{ borderBottom: '1px solid #eee' }}
                    >
                      <p className='col-span-4 font-semibold'>{item.name}</p>
                      <p className='col-span-3'>{item.account}</p>
                      <p className='col-span-2'>{item.tin}</p>
                      <p className='col-span-2'>{getStatus(item.status, 12, t)}</p>
                      <Dropdown menu={{ items }}>
                        <MenuOutlined className='cursor-pointer' />
                      </Dropdown>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Counterparties;
