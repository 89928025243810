import jwtDecode from 'jwt-decode';
import { $host, $authHost } from './index';
import {
  userAC,
  isAuthAC,
  isSpinAC,
  isOtpAC,
  checkResetPasswordAC,
  changePasswordOtpStateAC,
  notificationModalAC,
  otpLifeTimeAC,
} from '@store/reducers/app';
import openNotification from '@utils/openNotification';
import { AppDispatch } from '@store';

const END_POINT = '/auth';

const loginApi = (params: object, navigate: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $authHost.post(`${END_POINT}/login`, params);

    if (data?.access) {
      localStorage.setItem('otpAt', data.access);
      dispatch(otpLifeTimeAC(Date.now() + 1000 * data.code_lifetime));
      dispatch(isOtpAC(true));
      navigate('/login/verify');
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error.response?.data?.detail,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const verifyApi = (params: any, navigate: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $authHost.post(`${END_POINT}/verify`, params, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('otpAt')}`,
      },
    });

    if (data) {
      const tokenData: any = jwtDecode(data.access);
      if (tokenData.must_reset_password) {
        dispatch(checkResetPasswordAC(tokenData.must_reset_password));
        localStorage.setItem('otpAt', data.access);
        navigate('/login/reset');
      } else {
        localStorage.setItem('at', data.access);
        localStorage.setItem('rt', data.refresh);
        localStorage.removeItem('otpAt');
        dispatch(userAC(tokenData));
        dispatch(isAuthAC(true));
      }
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error.response?.data?.detail,
        type: 'error',
      })
    );
    if (error.response.status === 401) {
      dispatch(isOtpAC(false));
      navigate('/');
    }
  } finally {
    dispatch(isSpinAC(false));
  }
};

const logOutApi = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    await $host.post(`${END_POINT}/logout`);
    localStorage.removeItem('at');
    localStorage.removeItem('rt');
    dispatch(isAuthAC(false));
    dispatch(isOtpAC(false));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error.response?.data?.detail,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const resetPasswordApi =
  (params: any, navigate: any): any =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(isSpinAC(true));
      const { data } = await $authHost.post(`${END_POINT}/reset-password`, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('otpAt')}`,
        },
      });

      if (data) {
        localStorage.removeItem('at');
        localStorage.removeItem('otpAt');
        navigate('/login');
        openNotification('success', { code: '', message: '', detail: 'Пароль успешно изменен!' });
      }
    } catch (error: any) {
      dispatch(
        notificationModalAC({
          isOpen: true,
          message: error.response?.data?.detail,
          type: 'error',
        })
      );
    } finally {
      dispatch(isSpinAC(false));
    }
  };

const resendCodeApi = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $authHost.post(`${END_POINT}/verify/resend-code`, params, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('otpAt')}`,
      },
    });
    dispatch(otpLifeTimeAC(Date.now() + 1000 * data.code_lifetime));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error.response?.data?.detail,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const changePasswordConfirmApi = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    await $host.patch(`${END_POINT}/change-password`, params);
    openNotification('success', { code: '', message: '', detail: 'Пароль успешно изменен!' });
    dispatch(changePasswordOtpStateAC(false));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error.response?.data?.detail,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const changePasswordValidateApi = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/change-password/validate`, params);
    if (data) {
      dispatch(changePasswordOtpStateAC(true));
      return data;
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error.response?.data?.detail,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

export {
  loginApi,
  verifyApi,
  logOutApi,
  resendCodeApi,
  resetPasswordApi,
  changePasswordValidateApi,
  changePasswordConfirmApi,
};
