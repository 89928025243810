import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface paymentsState {
  bankBics: Array<any>;
  searchedSwift: any;
  paymentDetails: any;
  isConfirmPaymentModalOpen: boolean;
  isSaveTemplateModalOpen: boolean;
  isDocumentTypesModalOpen: boolean;
  isSearchSwiftModalOpen: boolean;
  isSearchBicModalOpen: boolean;
  selectedDocumentType: any;
  exchangeXrate: any;
  ownAccountsQuery: any;
  internalOperationsQuery: any;
  newInternalTemplate: any;
  interbankOperationsQuery: any;
  newInterbankTemplate: any;
  newSwiftTemplate: any;
  swiftQuery: any;
  newRubOperationTemplate: any;
  rubOperationQuery: any;
  exchangeQuery: any;
}

const initialState: paymentsState = {
  bankBics: [],
  searchedSwift: [],
  paymentDetails: {},
  isConfirmPaymentModalOpen: false,
  isSaveTemplateModalOpen: false,
  isDocumentTypesModalOpen: false,
  isSearchSwiftModalOpen: false,
  isSearchBicModalOpen: false,
  selectedDocumentType: '',
  exchangeXrate: '',
  swiftQuery: {},
  rubOperationQuery: {},
  exchangeQuery: {
    sending_ac: '',
    receiving_ac: '',
    sending_amount: '',
    xrate: '',
    receiving_amount: '',
    narrative: '',
  },
  interbankOperationsQuery: {
    sending_ac: '',
    receiving_ac: '',
    receiving_ac2: '',
    receiver_name: '',
    sending_amount: '',
    bank_code: '',
    tax_id: '',
    doc_type: '',
    doc_no: '',
    doc_date: '',
    narrative: '',
    doc_code: '',
    doc_type_title: '',
  },
  ownAccountsQuery: {
    sending_ac: '',
    receiving_ac: '',
    sending_amount: '',
    narrative: '',
  },
  internalOperationsQuery: {
    sending_ac: '',
    receiving_ac: '',
    sending_amount: '',
    narrative: '',
  },
  newInternalTemplate: {
    transaction_type: 'INTERNAL',
    title: '',
    sending_ac: '',
    receiving_ac: '',
    sending_amount: '',
    narrative: '',
    sending_ccy: '',
  },
  newInterbankTemplate: {
    transaction_type: 'INTERBANK',
    title: '',
    sending_amount: '',
    sending_ac: '',
    receiving_ac: '',
    receiver_name: '',
    sending_ccy: '',
    bank_code: '',
    tax_id: '',
    narrative: '',
    doc_no: '',
    doc_type: '',
    doc_date: '',
    doc_code: '',
    doc_type_title: '',
  },
  newSwiftTemplate: {
    transaction_type: 'SWIFT',
    title: '',
    sending_amount: '',
    sending_ac: '',
    receiving_ac: '',
    receiver_name: '',
    sending_ccy: '',
    narrative: '',
    doc_no: '',
    sender_country: '',
    sender_city: '',
    sender_address: '',
    receiver_country: '',
    receiver_city: '',
    receiver_bank_swift: '',
    receiver_bank_name: '',
    receiver_bank_country: '',
    receiver_address: '',
  },
  newRubOperationTemplate: {
    transaction_type: 'SWIFT_RUB',
    title: '',
    sending_amount: '',
    sending_ac: '',
    receiving_ac: '',
    receiver_name: '',
    sending_ccy: '',
    narrative: '',
    doc_no: '',
    sender_address: '',
    receiver_country: '',
    bank_code: '',
    receiver_bank_name: '',
    receiver_bank_country: '',
    receiver_address: '',
    tax_id: '',
    receiver_kpp: '',
    bank_corr_acc: '',
    receiver_bank_address: '',
  },
};

const payments = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    exchangeQueryAC(state, action: PayloadAction<any>) {
      state.exchangeQuery = action.payload;
    },
    rubOperationQueryAC(state, action: PayloadAction<any>) {
      state.rubOperationQuery = action.payload;
    },
    newRubOperationTemplateAC(state, action: PayloadAction<any>) {
      state.newRubOperationTemplate = action.payload;
    },
    swiftQueryAC(state, action: PayloadAction<any>) {
      state.swiftQuery = action.payload;
    },
    newSwiftTemplateAC(state, action: PayloadAction<any>) {
      state.newSwiftTemplate = action.payload;
    },
    newInterbankTemplateAC(state, action: PayloadAction<any>) {
      state.newInterbankTemplate = action.payload;
    },
    interbankOperationsQueryAC(state, action: PayloadAction<any>) {
      state.interbankOperationsQuery = action.payload;
    },
    newInternalTemplateAC(state, action: PayloadAction<any>) {
      state.newInternalTemplate = action.payload;
    },
    internalOperationsQueryAC(state, action: PayloadAction<any>) {
      state.internalOperationsQuery = action.payload;
    },
    ownAccountsQueryAC(state, action: PayloadAction<any>) {
      state.ownAccountsQuery = action.payload;
    },
    exchangeXrateAC(state, action: PayloadAction<any>) {
      state.exchangeXrate = action.payload;
    },
    selectedDocumentTypeAC(state, action: PayloadAction<any>) {
      state.selectedDocumentType = action.payload;
    },
    isSearchBicModalOpenAC(state, action: PayloadAction<any>) {
      state.isSearchBicModalOpen = action.payload;
    },
    isSearchSwiftModalOpenAC(state, action: PayloadAction<any>) {
      state.isSearchSwiftModalOpen = action.payload;
    },
    isDocumentTypesModalOpenAC(state, action: PayloadAction<any>) {
      state.isDocumentTypesModalOpen = action.payload;
    },
    isSaveTemplateModalOpenAC(state, action: PayloadAction<any>) {
      state.isSaveTemplateModalOpen = action.payload;
    },
    isConfirmPaymentModalOpenAC(state, action: PayloadAction<any>) {
      state.isConfirmPaymentModalOpen = action.payload;
    },
    paymentDetailsAC(state, action: PayloadAction<any>) {
      state.paymentDetails = action.payload;
    },
    bankBicsAC(state, action: PayloadAction<Array<any>>) {
      state.bankBics = [];
      action.payload.forEach((item: any) => {
        state.bankBics.push({
          value: item.bank_code,
          label: item.bank_name + ' (' + item.bank_code + ')',
        });
      });
    },
    searchedSwiftAC(state, action: PayloadAction<Array<any>>) {
      state.searchedSwift = action.payload;
    },
  },
});

export const {
  exchangeQueryAC,
  newRubOperationTemplateAC,
  rubOperationQueryAC,
  swiftQueryAC,
  newSwiftTemplateAC,
  newInterbankTemplateAC,
  interbankOperationsQueryAC,
  newInternalTemplateAC,
  internalOperationsQueryAC,
  ownAccountsQueryAC,
  bankBicsAC,
  searchedSwiftAC,
  paymentDetailsAC,
  isConfirmPaymentModalOpenAC,
  isSaveTemplateModalOpenAC,
  isDocumentTypesModalOpenAC,
  isSearchSwiftModalOpenAC,
  isSearchBicModalOpenAC,
  selectedDocumentTypeAC,
  exchangeXrateAC,
} = payments.actions;
export default payments.reducer;
