import { Button, Input, Modal, Pagination, Table } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { isSearchBicModalOpenAC } from '@store/reducers/payments';
import { searchSwiftApi } from '@http/payments';

const SearchBicModal = ({ selectBicHandler }: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const searchedSwift = useAppSelector(state => state.paymentsReducer.searchedSwift);
  const isSearchBicModalOpen = useAppSelector(state => state.paymentsReducer.isSearchBicModalOpen);

  const [searchSwiftQuery, setSearchSwiftQuery] = useState({
    bic: '',
    name: '',
    city: '',
    country: '',
    trn_type: 'swift_ru',
  });

  const searchSwift = () => {
    dispatch(searchSwiftApi(searchSwiftQuery));
  };

  const changePage = (page: number) => {
    dispatch(searchSwiftApi({ page, ...searchSwiftQuery }));
  };

  const columns = [
    {
      title: 'BIC код',
      dataIndex: 'bic_code',
      key: 'bic_code',
    },
    {
      title: t('Bank name'),
      dataIndex: 'bank_name',
      key: 'address',
    },
    {
      title: t('Address'),
      dataIndex: 'bank_address',
      key: 'bank_address',
    },
  ];

  return (
    <div>
      <Modal
        width={700}
        footer={null}
        title={t('Find bic code')}
        open={isSearchBicModalOpen}
        onOk={() => dispatch(isSearchBicModalOpenAC(false))}
        onCancel={() => dispatch(isSearchBicModalOpenAC(false))}
      >
        <div>
          <div>
            <p>BIC</p>
            <Input
              placeholder='BIC'
              onChange={e => {
                setSearchSwiftQuery({ ...searchSwiftQuery, bic: e.target.value });
              }}
            />
          </div>
          <div className='mt-5'>
            <p>{t('Bank name')}</p>
            <Input
              placeholder={`${t('Bank name')}`}
              onChange={e => {
                setSearchSwiftQuery({ ...searchSwiftQuery, name: e.target.value });
              }}
            />
          </div>
          <div className='flex justify-end mt-5'>
            <Button type='primary' onClick={searchSwift}>
              {t('Find')}
            </Button>
          </div>
          <div className='mt-5'>
            <Table
              pagination={false}
              rowKey='swift'
              columns={columns}
              dataSource={searchedSwift.data}
              onRow={(item: any) => ({
                onDoubleClick: () => {
                  selectBicHandler(item);
                },
              })}
            />
            {searchedSwift?.data?.length > 0 ? (
              <div className='mt-7 flex justify-end'>
                <Pagination
                  onChange={changePage}
                  current={searchedSwift.page}
                  defaultPageSize={10}
                  total={searchedSwift.total_pages * 10}
                  showSizeChanger={false}
                />
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SearchBicModal;
