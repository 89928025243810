import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface bulkTransactionState {
  bulkTransactionList: any;
  bulkTransactionInfo: any;
  pickedBulkTransactionInfo: any;
  verifyFileId: any;
  isBulkTransactionActionModalOpen: boolean;
  actionModalType: string;
  isBulkTransactionPreviewModalOpen: boolean;
  isImportFileModalOpen: boolean;
}

const initialState: bulkTransactionState = {
  bulkTransactionList: [],
  bulkTransactionInfo: [],
  pickedBulkTransactionInfo: [],
  verifyFileId: '',
  isBulkTransactionActionModalOpen: false,
  actionModalType: '',
  isBulkTransactionPreviewModalOpen: false,
  isImportFileModalOpen: false,
};

const bulkTransaction = createSlice({
  name: 'bulkTransaction',
  initialState,
  reducers: {
    isImportFileModalOpenAC(state, action) {
      state.isImportFileModalOpen = action.payload;
    },
    isBulkTransactionPreviewModalOpenAC(state, action) {
      state.isBulkTransactionPreviewModalOpen = action.payload;
    },
    actionModalTypeAC(state, action) {
      state.actionModalType = action.payload;
    },
    bulkTransactionActionModalOpenAC(state, action) {
      state.isBulkTransactionActionModalOpen = action.payload;
    },
    bulkTransactionListAC(state, action: PayloadAction<any>) {
      state.bulkTransactionList = action.payload;
    },
    bulkTransactionInfoAC(state, action: PayloadAction<any>) {
      state.bulkTransactionInfo = action.payload;
    },
    pickedBulkTransactionInfoAC(state, action: PayloadAction<any>) {
      state.pickedBulkTransactionInfo = action.payload;
    },
    verifyFileIdAC(state, action) {
      state.verifyFileId = action.payload;
    },
  },
});

export const {
  isImportFileModalOpenAC,
  isBulkTransactionPreviewModalOpenAC,
  bulkTransactionListAC,
  bulkTransactionInfoAC,
  pickedBulkTransactionInfoAC,
  verifyFileIdAC,
  bulkTransactionActionModalOpenAC,
  actionModalTypeAC,
} = bulkTransaction.actions;
export default bulkTransaction.reducer;
