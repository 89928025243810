import { Input, Button, Form, Space } from 'antd';

import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useTranslation } from 'react-i18next';
import digitNumber from '@utils/digitNumber';

import { exchangeQueryAC, isConfirmPaymentModalOpenAC } from '@store/reducers/payments';

import { senderAccountsModalOpenAC, recipientAccountsModalOpenAC } from '@store/reducers/accounts';

import { deadlineAC } from '@store/reducers/transactions';

const { TextArea } = Input;

const ExchangeForm = ({ form, senderAccountCurrency, toAccountCurrency }: any) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const senderAccountBalance = useAppSelector(state => state.accountsReducer.senderAccountBalance);
  const toAccountBalance = useAppSelector(state => state.accountsReducer.toAccountBalance);
  const exchangeXrate = useAppSelector(state => state.paymentsReducer.exchangeXrate);
  const exchangeQuery = useAppSelector(state => state.paymentsReducer.exchangeQuery);

  const submit = () => {
    if (+exchangeQuery.sending_amount > +senderAccountBalance) {
      form.setFields([
        {
          name: 'sending_ac',
          errors: [t('Insufficient funds in the account')],
        },
      ]);
    } else {
      dispatch(isConfirmPaymentModalOpenAC(true));
      dispatch(deadlineAC(Date.now() + 1000 * 30));
    }
  };

  return (
    <Form form={form} name='Exchange' onFinish={submit}>
      <p className='text-base color-active font-semibold mb-4'>{t('Payment information')}</p>
      <p className='text-bs color-title md:hidden'>{t('From account')}:</p>
      <div className='md:grid grid-cols-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('From account')}:</p>
        <div className='col-span-5'>
          <Form.Item className='m-0' name='sending_ac' rules={[{ required: true, message: `${t('Required')}` }]}>
            <Space.Compact size='large' className='w-full'>
              <Input disabled style={{ width: 60 }} size='large' value={senderAccountCurrency} />
              <Input
                value={exchangeQuery.sending_ac}
                placeholder={`${t('Select account')}`}
                onClick={() => dispatch(senderAccountsModalOpenAC(true))}
                size='large'
                style={{ width: '100%' }}
                type='text'
                maxLength={20}
              />
            </Space.Compact>
          </Form.Item>
          {senderAccountBalance ? (
            <p className='text-bs color-title font-medium text-end mt-2'>
              {t('Balance')}: {digitNumber(senderAccountBalance)} {senderAccountCurrency}
            </p>
          ) : null}
        </div>
      </div>
      <p className='text-bs color-title mt-5 md:hidden'>{t('To account')}:</p>
      <div className='md:grid grid-cols-7 md:mt-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('To account')}:</p>
        <div className='col-span-5'>
          <Form.Item className='m-0' name='receiving_ac' rules={[{ required: true, message: `${t('Required')}` }]}>
            <Space.Compact size='large' className='w-full'>
              <Input disabled style={{ width: 60 }} size='large' value={toAccountCurrency} />
              <Input
                value={exchangeQuery.receiving_ac}
                placeholder={`${t('Select account')}`}
                onClick={() => dispatch(recipientAccountsModalOpenAC(true))}
                size='large'
                style={{ width: '100%' }}
                type='text'
                maxLength={20}
              />
            </Space.Compact>
          </Form.Item>
          {toAccountBalance ? (
            <p className='text-bs color-title font-medium text-end mt-2'>
              {t('Balance')}: {digitNumber(toAccountBalance)} {toAccountCurrency}
            </p>
          ) : null}
        </div>
      </div>

      <div>
        <p className='text-bs color-title mt-5 md:hidden'>{t('Amount')}:</p>
        <div className='md:grid grid-cols-7 md:mt-7'>
          <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Amount')}:</p>
          <div className='col-span-5 flex justify-between'>
            <Form.Item
              className='m-0'
              name='sending_amount'
              rules={[
                { required: true, message: `${t('Required')}` },
                {
                  pattern: /^[0-9]\d*(?:[.]\d{1,2})?$/,
                  message: `${t('Wrong amount')}`,
                },
                {
                  pattern: /^[1-9]\d*/,
                  message: `${t('Amount must be more 1')}`,
                },
              ]}
            >
              <Space.Compact size='large' className='w-full'>
                <Input
                  disabled
                  style={{ width: 65 }}
                  size='large'
                  value={senderAccountCurrency === 'TJS' ? toAccountCurrency : senderAccountCurrency}
                />
                <Input
                  min={0}
                  maxLength={12}
                  placeholder={`${t('Enter amount')}`}
                  size='large'
                  style={{ width: '100%' }}
                  type='text'
                  onChange={(e: any) => {
                    if (senderAccountCurrency === 'TJS') {
                      dispatch(
                        exchangeQueryAC({
                          ...exchangeQuery,
                          sending_amount: (e.target.value * exchangeXrate).toFixed(2),
                          receiving_amount: e.target.value,
                        })
                      );
                    } else {
                      dispatch(
                        exchangeQueryAC({
                          ...exchangeQuery,
                          sending_amount: e.target.value,
                          receiving_amount: (e.target.value * exchangeXrate).toFixed(2),
                        })
                      );
                    }
                  }}
                />
              </Space.Compact>
            </Form.Item>
            <Form.Item className='m-0 w-1/3 max-sm:ml-3' name='xrate'>
              <Input
                disabled
                value={exchangeXrate}
                placeholder={`${t('Rate')}`}
                size='large'
                style={{ width: '100%' }}
                type='text'
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <div>
        {senderAccountCurrency !== '' ? (
          <div>
            {senderAccountCurrency === 'TJS' ? (
              <div>
                <p className='text-bs color-title mt-5 md:hidden'>{t('Credit amount')}:</p>
                <div className='md:grid grid-cols-7 md:mt-7'>
                  <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Credit amount')}:</p>
                  <div className='col-span-5'>
                    <Form.Item className='m-0' name='receiving_amount'>
                      <Space.Compact size='large' className='w-full'>
                        <Input
                          disabled
                          style={{ width: 60 }}
                          size='large'
                          value={senderAccountCurrency === 'TJS' ? senderAccountCurrency : toAccountCurrency}
                        />
                        <Input
                          value={
                            senderAccountCurrency === 'TJS'
                              ? exchangeQuery.sending_amount
                              : exchangeQuery.receiving_amount
                          }
                          disabled
                          placeholder={`${t('Credit amount')}`}
                          size='large'
                          style={{ width: '100%' }}
                          type='text'
                        />
                      </Space.Compact>
                    </Form.Item>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <p className='text-bs color-title mt-5 md:hidden'>{t('Debit amount')}:</p>
                <div className='md:grid grid-cols-7 md:mt-7'>
                  <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Debit amount')}:</p>
                  <div className='col-span-5'>
                    <Form.Item className='m-0' name='receiving_amount'>
                      <Space.Compact size='large' className='w-full'>
                        <Input
                          disabled
                          style={{ width: 60 }}
                          size='large'
                          value={senderAccountCurrency === 'TJS' ? senderAccountCurrency : toAccountCurrency}
                        />
                        <Input
                          value={exchangeQuery.receiving_amount}
                          disabled
                          placeholder={`${t('Debit amount')}`}
                          size='large'
                          style={{ width: '100%' }}
                          type='text'
                        />
                      </Space.Compact>
                    </Form.Item>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>

      <div className='mt-7'>
        <p className='text-base color-title'>{t('Narrative')}:</p>
        <Form.Item className='m-0' name='narrative'>
          <TextArea
            value={exchangeQuery.narrative}
            maxLength={250}
            onChange={(e: any) => {
              dispatch(exchangeQueryAC({ ...exchangeQuery, narrative: e.target.value }));
            }}
            rows={4}
          />
        </Form.Item>
      </div>
      <div className='flex justify-end mt-5'>
        <Button type='primary' htmlType='submit'>
          {t('Confirm')}
        </Button>
      </div>
    </Form>
  );
};

export default ExchangeForm;
