import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import digitNumber from '@utils/digitNumber';
import { useTranslation } from 'react-i18next';
import { getloansListApi } from '@http/loans';
import { loanInfoAC, selectedLoanAC } from '@store/reducers/loans';

function LoansList(props: any) {
  const { t } = useTranslation();
  const { itemsCount, defaultInfo, data } = props;
  const dispatch = useAppDispatch();
  const selectedLoan = useAppSelector(state => state.loansReducer.selectedLoan);

  useEffect(() => {
    dispatch(getloansListApi());
  }, []);

  useEffect(() => {
    if (data.length > 0 && defaultInfo) {
      dispatch(selectedLoanAC(data[0]));
      dispatch(loanInfoAC(data[0]));
    }
  }, [data]);

  return (
    <div>
      {data?.length > 0 ? (
        <div>
          {data?.slice(0, itemsCount).map((item: any) => (
            <div
              key={item.cust_ac_no}
              onClick={() => {
                dispatch(loanInfoAC(item));
                dispatch(selectedLoanAC(item));
              }}
            >
              <div
                style={{ borderBottom: '1px solid #eee' }}
                className={`flex justify-between items-center p-5 border-b cursor-pointer listItem ${
                  selectedLoan.account_number === item.account_number ? 'bg-yellow-50' : null
                }`}
              >
                <div>
                  <p className='text-base font-medium'>{item.account_number}</p>
                  <div>
                    {item.account_status === 'A' ? (
                      <div className='flex items-center'>
                        <div className='bg-green-500 h-3 w-3 mr-3 rounded-full' />
                        <p className='text-sm'>{t('Active')}</p>
                      </div>
                    ) : (
                      <div className='flex items-center'>
                        <div className='bg-red-400 h-3 w-3 mr-3 rounded-full' />
                        <p className='text-sm'>{t('Closed')}</p>
                      </div>
                    )}
                  </div>
                </div>
                <p className='text-base font-medium'>
                  {digitNumber(item.amount_financed)} <span className='font-bold'>{item.currency}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className='h-24 flex items-center justify-center'>
          <p className='text-base'>{t('No loans')}</p>
        </div>
      )}
    </div>
  );
}

export default LoansList;
