import { Route, Routes } from 'react-router-dom';
import SalaryInfo from '@components/Salary/SalaryInfo';
import SalaryList from '@components/Salary/SalaryList';

const SalaryIndex = () => {
  return (
    <div className='flex justify-center'>
      <div className='lg:w-[1130px] w-full'>
        <div className='flex'>
          <div className='p-4 lg:p-8 w-full h-full bg-white mb-12'>
            <Routes>
              <Route path='list' element={<SalaryList />} key='list' />
              <Route path='info/*' element={<SalaryInfo />} key='info' />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryIndex;
