import Currencies from '@components/Currency/OrienbankRates';

function Rates() {
  return (
    <div className='flex justify-center'>
      <div className='lg:w-[1130px] w-full'>
        <div className='flex'>
          <div className='max-md:p-5 p-8 w-full h-full bg-white mb-12' style={{ minHeight: 600 }}>
            <div className='sm:grid grid-cols-3 gap-10'>
              <div className='col-span-2'>
                <Currencies showAll={true} size='max-lg:text-xs text-sm' />
              </div>
              <div className='col-span-1'>{/* <Converter /> */}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rates;
