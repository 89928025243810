import { Button, Divider, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ResidentsForm = () => {
  const { t } = useTranslation();

  return (
    <div className='mt-3'>
      <p className='text-base color-active font-semibold mb-4'>{t('Основная информация')}</p>
      <div className='md:grid grid-cols-5'>
        <p className='col-span-1 text-bs color-title'>{t('Name')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <div className='md:grid grid-cols-5 mt-7'>
        <p className='col-span-1 text-bs color-title'>{t('TIN')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <Divider />
      <p className='text-base color-active font-semibold mb-4'>{t('Account')}</p>
      <div className='md:grid grid-cols-5'>
        <p className='col-span-1 text-bs color-title'>{t('Account number')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <div className='md:grid grid-cols-5 mt-7'>
        <p className='col-span-1 text-bs color-title'>{t('BIC')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <Divider />
      <p className='text-base color-active font-semibold mb-4'>{t('Contacts')}</p>
      <div className='md:grid grid-cols-5'>
        <p className='col-span-1 text-bs color-title'>{t('Phone number')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <div className='md:grid grid-cols-5 mt-7'>
        <p className='col-span-1 text-bs color-title'>{t('Email')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <div className='md:grid grid-cols-5 mt-7'>
        <div className='col-span-3 flex justify-end'>
          <Button type='primary' size='middle'>
            {t('Confirm')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResidentsForm;
