import { useState } from 'react';
import { Switch, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  pickedInterbankTemplateAC,
  pickedInternalTemplateAC,
  pickedSwiftRubTemplateAC,
  pickedSwiftTemplateAC,
} from '@store/reducers/user';
import { useTranslation } from 'react-i18next';

function Templates(props: any) {
  const { type } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showTemplate, setShowTemplate] = useState(false);
  const templates = useAppSelector(state => state.userReducer.templates);

  const pickedTemplate = (item: any) => {
    if (type === 'INTERBANK') {
      dispatch(pickedInterbankTemplateAC(item));
    }
    if (type === 'INTERNAL') {
      dispatch(pickedInternalTemplateAC(item));
    }
    if (type === 'SWIFT') {
      dispatch(pickedSwiftTemplateAC(item));
    }
    if (type === 'SWIFT_RUB') {
      dispatch(pickedSwiftRubTemplateAC(item));
    }
  };

  return (
    <div>
      <div className='flex'>
        <p className='text-bs mr-4 color-active'>{t('Existing template')}</p>
        <Switch onChange={value => setShowTemplate(value)} />
      </div>
      {showTemplate ? (
        <div className='mt-5'>
          <p className='text-bs color-title mb-1 md:hidden'>{t('Select template')}:</p>
          <div className='md:grid grid-cols-7 md:mt-7'>
            <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Select template')}:</p>
            <div className='col-span-5'>
              <Select
                size='large'
                style={{ width: '100%' }}
                options={templates}
                onChange={(_, item) => pickedTemplate(item)}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Templates;
