import { $host } from './index';
import { AppDispatch } from '@store';
import { isSpinAC, notificationModalAC } from '@store/reducers/app';
import { activePanelAC, deadlineAC, ordersAC, transactionsHistoryAC } from '@store/reducers/transactions';
import openNotification from '@utils/openNotification';
import { getBulkTransactionInfoApi } from './bulkTransaction';
import { getSalaryInfoApi } from './salary';

const END_POINT = '/client';

const getTransactionListApi = (page: number, params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/list?page=${page}`, params);
    dispatch(transactionsHistoryAC(data));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const transactionVerifyApi = (params: any, callback: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/verify`, params);

    openNotification('success', {
      description: 'Транзакция успешно проведена',
    });

    callback();
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const transactionVerifyResendCodeApi = (params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/verify/resend_code`, params);
    if (data) {
      dispatch(deadlineAC(Date.now() + 1000 * data.code_lifetime));
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getAccountOrdersApi = (params: object, page: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/statement/orders?page=${page}`, params);
    dispatch(ordersAC(data));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const dowloadAccountStatementApi = (params: object, format: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const response = await $host.post(`${END_POINT}/transaction/statement/order/download`, params, {
      responseType: 'arraybuffer',
    });
    const dispositionHeader = response.headers['content-disposition'];
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(dispositionHeader);
    let filename = `statement.${format}`;
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
    const url = window.URL.createObjectURL(new Blob([response.data], { type: `application/${format}` }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    link.click();
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const dowloadAccountStatementToPrintApi = (params: object, format: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/statement/order/download`, params, {
      responseType: 'arraybuffer',
    });
    const blob = new Blob([data], { type: `application/${format}` });

    // Создаем URL объекта Blob
    const url = URL.createObjectURL(blob);

    // Открываем содержимое на печать
    const printWindow = window.open(url, '_blank');

    // Печатаем документ после полной загрузки
    printWindow?.addEventListener('load', () => {
      printWindow?.print();
    });
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const orderStatementApi = (params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/statement/order`, params);
    if (data) {
      openNotification('success', {
        description: 'Выписка заказана!',
      });
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const transactionApproveApi = (params: any, callback?: any) => async (dispatch: AppDispatch) => {
  const { params: transactionParams, page, status, fileId } = params;
  try {
    dispatch(isSpinAC(true));
    await $host.post(`${END_POINT}/transaction/approve`, transactionParams);
    if (transactionParams.trn_type === 'BULK') {
      dispatch(getBulkTransactionInfoApi({ file_id: fileId }, 1));
    } else if (transactionParams.trn_type === 'SALARY') {
      dispatch(getSalaryInfoApi({ file_id: fileId }, 1));
    } else {
      dispatch(getTransactionListApi(page, { status: status }));
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error.response?.data?.detail,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
    dispatch(activePanelAC([]));
    callback();
  }
};

const proceedExchangeApi = (params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/fx/proceed`, params);
    if (data) {
      dispatch(activePanelAC([]));
      dispatch(getTransactionListApi(1, {}));
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const cancelExchangeApi = (params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/fx/cancel`, params);
    if (data) {
      dispatch(activePanelAC([]));
      dispatch(getTransactionListApi(1, {}));
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

export {
  getTransactionListApi,
  transactionVerifyApi,
  transactionVerifyResendCodeApi,
  getAccountOrdersApi,
  dowloadAccountStatementApi,
  orderStatementApi,
  transactionApproveApi,
  proceedExchangeApi,
  cancelExchangeApi,
  dowloadAccountStatementToPrintApi,
};
