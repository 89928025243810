import Main from '@components/Landing/main/mainPage';
import Header from '@components/Landing/header/header';
import Vozm from '@components/Landing/vozmojnosti/vozm';
import Products from '@components/Landing/products/products';
import Documents from '@components/Landing/documents/documents';

import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { requestInfoModalAC } from '@store/reducers/app';
import FooterNew from '@components/Container/Footer-new';

const Landing = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const requestInfoModal = useAppSelector(state => state.appReducer.requestInfoModal);
  return (
    <div className='bg-[#F6F7F9] '>
      <div className='mx-auto'>
        <Header />
        <div className='max-w-[1127px] mx-auto '>
          <div>
            <div id='main' className='main'>
              <Main />
            </div>
            <div className='vozmojnosti' id='vozm'>
              <Vozm />
            </div>
            <div className='products' id='products'>
              <Products />
            </div>
            <div className='documents' id='documents'>
              <Documents />
            </div>
          </div>
        </div>
        <div className='bg-white'>
          <FooterNew />
        </div>
        <Modal
          width={680}
          footer={null}
          open={requestInfoModal}
          onOk={() => dispatch(requestInfoModalAC(false))}
          onCancel={() => dispatch(requestInfoModalAC(false))}
        >
          <p className='text-sm lg:text-lg pt-24 font-semibold text-center'>
            <p>{t('For registration')}</p>
            <p>{t('Orienbank Address')}</p>
          </p>
          <div className='flex justify-center'>
            <span className='pt-8' style={{ width: '380px', borderBottom: '1px solid #eee' }} />
          </div>
          <p className='text-sm lg:text-lg pt-7 font-semibold text-center'>{t('For any questions')}</p>
          <div className='text-base lg:text-xl flex justify-between pt-20 font-bold'>
            <p>{t('Call center 2222')}</p>
            <p>www.oriyonbonk.tj</p>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Landing;
