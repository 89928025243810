import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import globus from '@assets/images/icons/globus.svg';
import arrowDown from '@assets/images/icons/arrowDown.svg';
import logoSmall from '@assets/images/logoSmall.svg';

import type { MenuProps } from 'antd';

const Header = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handlerBackLogin = () => {
    localStorage.removeItem('otpAt');
    navigate('/');
  };

  const languages: MenuProps['items'] = [
    {
      key: '1',
      label: 'Русский',
      onClick: () => {
        i18n.changeLanguage('ru');
        localStorage.setItem('lang', 'ru');
      },
    },
    {
      key: '2',
      label: 'Таджикский',
      onClick: () => {
        i18n.changeLanguage('tg');
        localStorage.setItem('lang', 'tg');
      },
    },
    {
      key: '3',
      label: 'Английский',
      onClick: () => {
        i18n.changeLanguage('en');
        localStorage.setItem('lang', 'en');
      },
    },
  ];

  return (
    <div className='lg:flex lg:justify-center py-6 px-4 lg:px-8'>
      <header className='xl:w-2/3 lg:w-9/12 flex justify-between items-center'>
        <div className='cursor-pointer' role='button' tabIndex={0} onClick={handlerBackLogin}>
          <div className='flex items-center'>
            <img src={logoSmall} alt='' className='w-10 h-10' />
            <div className='color-active font-semibold ml-3'>
              <p className='md:text-2xl text-xl leading-4 mt-1'>{t('Oriyon')}</p>
              <p className={`${i18n.language === 'en' ? 'mt-1' : ''} text-xs text-right`} style={{ fontSize: '14px' }}>
                {t('Business')}
              </p>
            </div>
          </div>
        </div>
        <div className='flex cursor-pointer'>
          <Dropdown menu={{ items: languages }}>
            <div className='flex items-center'>
              <img src={globus} alt='' />
              <span className='mx-2'>{t('Language')}</span>
              <img src={arrowDown} alt='' />
            </div>
          </Dropdown>
        </div>
      </header>
    </div>
  );
};

export default Header;
