import { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useAppDispatch } from '@hooks/redux';
import { getSenderAccountsApi } from '@http/accounts';
import { getBulkFilesApi } from '@http/bulkTransaction';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

const BulkTransactionSearchForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState({
    status: '',
    date_from: '',
    date_to: '',
    from_amount: '',
    to_amount: '',
    draw_account: '',
  });

  const handleFinish = () => {
    dispatch(getBulkFilesApi(searchQuery, 1));
  };

  useEffect(() => {
    dispatch(getSenderAccountsApi({ transaction_type: 'SALARY' }));
  }, []);

  return (
    <div>
      <Form onFinish={handleFinish} form={form} className='flex items-center justify-between mt-7'>
        <div className='flex items-start gap-x-4'>
          <Form.Item name='status' className='w-[200px] mb-0'>
            <Select
              allowClear
              size='large'
              placeholder={t('Status')}
              onChange={value => setSearchQuery({ ...searchQuery, status: value })}
            >
              <Select.Option value='SUCCESS'>{t('Successful')}</Select.Option>
              <Select.Option value='IN_PROGRESS'>{t('In progress')}</Select.Option>
              <Select.Option value='IN_CONFIRMATION'>{t('In confirmation')}</Select.Option>
              <Select.Option value='IN_VERIFICATION'>{t('In verification')}</Select.Option>
              <Select.Option value='FAILED'>{t('Failed')}</Select.Option>
              <Select.Option value='PARTIAL_SUCCESS'>{t('Partial success')}</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item className='mb-0' name='asd'>
            <RangePicker
              placeholder={[`${t('Start date')}`, `${t('End date')}`]}
              format='DD.MM.YYYY'
              size='large'
              onChange={(date: any) => {
                if (date) {
                  setSearchQuery({
                    ...searchQuery,
                    date_from: date[0]?.format('YYYY-MM-DD'),
                    date_to: date[1]?.format('YYYY-MM-DD'),
                  });
                } else {
                  setSearchQuery({
                    ...searchQuery,
                    date_from: '',
                    date_to: '',
                  });
                }
              }}
              disabledDate={date => date && date > moment().add('day')}
            />
          </Form.Item>

          <Form.Item className='mb-0'>
            <Button icon={<SearchOutlined />} size='large' type='primary' htmlType='submit'>
              {t('Search')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default BulkTransactionSearchForm;
