const docTypes = [
  // {
  //   value: 1,
  //   label: 'Даромадхо',
  //   code: '',
  // },
  // {
  //   value: 101,
  //   label: 'Воридоти Андози',
  //   code: '',
  // },
  // {
  //   value: 10101,
  //   label: 'Андоз аз даромад',
  //   code: '',
  // },
  {
    value: 1010101,
    label: 'Андоз аз даромади шахсони вокеи',
    code: '65',
  },
  {
    value: 1010102,
    label: 'Андоз аз даромади шахсони хукуки',
    code: '65',
  },
  {
    value: 1010103,
    label: 'Даромад дар шакли музди мехнат',
    code: '65',
  },
  {
    value: 1010104,
    label: 'аз суди сахмияхо (дивидентхо)',
    code: '65',
  },
  {
    value: 1010105,
    label: 'Даромад аз фоизхо',
    code: '65',
  },
  {
    value: 1010106,
    label: 'Даромади ичрои молу мулк ва афзоиши арзиш аз фуруши молу мулк',
    code: '65',
  },
  {
    value: 1010107,
    label: 'Дигар даромадхои шахсони вокеи, ки ба намудхои рамзхои 1.01.01.03-1.01.01.07 шомил нагардидаанд',
    code: '65',
  },
  {
    value: 1010201,
    label: 'Андози ичтимои (1% аз муассисахои бучети)',
    code: '96',
  },
  {
    value: 1010202,
    label: 'Андози ичтимои (25% аз муассисахои бучети)',
    code: '65',
  },
  {
    value: 1010203,
    label: 'Андози ичтимои (2% аз муассисахои гайри бучети)',
    code: '96',
  },
  {
    value: 1010204,
    label: 'Андози ичтимои (20% аз муассисахои гайри бучети)',
    code: '65',
  },
  {
    value: 1010205,
    label: 'Андози ичтимои (2% барои сохибкорони инфироди тибки шаходатнома амалкунанда)',
    code: '65',
  },
  {
    value: 10103,
    label: 'Андоз аз молу мулк',
    code: '',
  },
  {
    value: 1010301,
    label: 'Андоз аз молу мулки гайриманкули шахсони вокеи',
    code: '65',
  },
  {
    value: 1010302,
    label: 'Андоз аз молу мулки гайриманкули шахсони хукуки',
    code: '65',
  },
  {
    value: 1010303,
    label: 'Андози замин аз шахсони хукуки',
    code: '65',
  },
  {
    value: 1010304,
    label: 'Андози замин аз шахсони вокеи',
    code: '65',
  },
  {
    value: 1010305,
    label: 'Андоз аз воситахои наклиёти шахсони вокеи',
    code: '65',
  },
  {
    value: 1010306,
    label: 'Андоз аз воситахои наклиёти шахсони хукуки',
    code: '65',
  },
  {
    value: 10104,
    label: 'Андоз аз фуруш',
    code: '',
  },
  {
    value: 1010402,
    label: 'Андоз фуруши алюминии аввала',
    code: '65',
  },
  {
    value: 1010403,
    label: 'Андоз аз махсулоти коркарди алюмин',
    code: '65',
  },
  {
    value: 10105,
    label: 'Андоз аз арзиши иловашуда',
    code: '65',
  },
  {
    value: 1010501,
    label: 'Андоз аз арзиши иловашудаи дохили',
    code: '65',
  },
  {
    value: 1010502,
    label: 'Андоз аз арзиши иловашудаи беруни',
    code: '70',
  },
  {
    value: 10106,
    label: 'Низомхои махсуси андозбанди',
    code: '',
  },
  {
    value: 1010601,
    label: 'Низоми соддакардашудаи андозбанди барои истехсолкунандагони махсулоти кишоварзи (андози ягонаи кишоварзи)',
    code: '65',
  },
  {
    value: 1010602,
    label: 'Низоми махсуси андозбанди барои субъектхои бизнеси бози',
    code: '65',
  },
  {
    value: 1010603,
    label: 'Низоми соддакардашудаи андозбанди барои субъектхои сохибкории хурд',
    code: '65',
  },
  {
    value: 1010604,
    label: 'Низоми андозбандии шахсони вокеие, ки фаъолияти сохибкориро дар асоси патент ё шаходнома амали менамоянд',
    code: '65',
  },
  {
    value: 1010605,
    label: 'Низоми андозбандии фаъолияти минтакахои озоди иктисоди',
    code: '65',
  },
  {
    value: 1010606,
    label: 'Низоми андозбандии субъектхои бозори когазхои киматнок',
    code: '65',
  },
  {
    value: 1010607,
    label: 'Низоми содакардашудаи андозбандии фаъолияти парандапарвари, мохипарвари ва истехсоли хуроки паранда ва чорво',
    code: '65',
  },
  {
    value: 1010608,
    label: 'Низоми содакардашудаи андозбандии фаъолияти инноватсионию технологи',
    code: '65',
  },
  {
    value: 10107,
    label: 'Аксизхои дохили',
    code: '',
  },
  {
    value: 1010701,
    label: 'Спирт (гайри табии кардашуда)',
    code: '65',
  },
  {
    value: 1010702,
    label: 'Арак',
    code: '65',
  },
  {
    value: 1010703,
    label: 'Шароб',
    code: '65',
  },
  {
    value: 1010704,
    label: 'Шампан',
    code: '65',
  },
  {
    value: 1010705,
    label: 'Коняк',
    code: '65',
  },
  {
    value: 1010706,
    label: 'Пиво',
    code: '65',
  },
  {
    value: 1010707,
    label: 'Махсулоти тамоку',
    code: '65',
  },
  {
    value: 1010708,
    label: 'Масолехи сузишвори ва молидани',
    code: '65',
  },
  {
    value: 1010709,
    label: 'Бензин',
    code: '65',
  },
  {
    value: 1010710,
    label: 'Маснуоти заргари аз тилло, платина ва нукра',
    code: '65',
  },
  {
    value: 1010711,
    label: 'Аксиз аз ташкилотхои мобили',
    code: '65',
  },
  {
    value: 1010712,
    label: 'Аксиз аз бозихои тичорати',
    code: '65',
  },
  {
    value: 1010713,
    label: 'Дигар аксизхои дохили',
    code: '65',
  },
  {
    value: 10108,
    label: 'Аксизхои беруни',
    code: '65',
  },
  {
    value: 1010801,
    label: 'Спирт (гайри табии кардашуда)',
    code: '70',
  },
  {
    value: 1010802,
    label: 'Нушокихои спирти',
    code: '70',
  },
  {
    value: 1010803,
    label: 'Махсулоти тамоку',
    code: '70',
  },
  {
    value: 1010804,
    label: 'Махсулоти сузишвори ва молидани',
    code: '70',
  },
  {
    value: 1010805,
    label: 'Бензин',
    code: '70',
  },
  {
    value: 1010806,
    label: 'Маснуоти заргари аз тилло, платина ва нукра',
    code: '70',
  },
  {
    value: 1010807,
    label: 'Дигар аксизхои беруни',
    code: '70',
  },
  {
    value: 10109,
    label: 'Дигар даромадхои беруни барои савдо ва амалиётхо',
    code: '',
  },
  {
    value: 1010901,
    label: 'Бочхои гумруки',
    code: '70',
  },
  {
    value: 1010902,
    label: 'Пешпардохтхои маблагхои гумруки',
    code: '70',
  },
  {
    value: 1010903,
    label: 'Бочи содироти барои фуруши нахи пахта',
    code: '70',
  },
  {
    value: 10110,
    label: 'Андозхо барои захирахои табии',
    code: '',
  },
  {
    value: 1011001,
    label: 'Бонуси обунави',
    code: '65',
  },
  {
    value: 1011002,
    label: 'Бонуси кашфи тичорати',
    code: '65',
  },
  {
    value: 1011003,
    label: 'Роялти барои истихроч',
    code: '65',
  },
  {
    value: 1011004,
    label: 'Роялти барои об',
    code: '65',
  },
  {
    value: 1011005,
    label: 'Рентаи содироти',
    code: '65',
  },
  {
    value: 10111,
    label: 'Андозхои дигари дохили ва пардохтхо аз молу хизматрасонихо',
    code: '',
  },
  {
    value: 1011102,
    label: 'Бочи давлати',
    code: '65',
  },
  {
    value: 1011103,
    label: 'Фоида аз монополияхои молияви (фискали)',
    code: '65',
  },
  {
    value: 1011104,
    label: 'Бочи давлати аз шиносномахои хоричи',
    code: '92',
  },
  {
    value: 102,
    label: 'Воридоти гайриандози',
    code: '',
  },
  {
    value: 10201,
    label: 'Воридот аз моликият ва фаъолияти сохибкори',
    code: '',
  },
  {
    value: 1020101,
    label: 'Таксимоти дивидентхо аз корхонахои гайримолиявии давлати, аз он чумла 10% дивидент аз фоидаи соф',
    code: '65',
  },
  {
    value: 1020102,
    label: 'Аз хисобидепозити Хукумати Чумхурии Точикистон (фоизхо ва гайрахо)',
    code: '50',
  },
  {
    value: 1020103,
    label: 'Аз муассисахои молияви (фоизхо ва гайрахо)',
    code: '50',
  },
  {
    value: 1020104,
    label: 'Фоизхо аз карздихии хурд барои корхонахо',
    code: '50',
  },
  {
    value: 1020105,
    label: 'Дивидендхо',
    code: '50',
  },
  {
    value: 1020106,
    label: 'Фоизхо аз карзхои додашуда',
    code: '50',
  },
  {
    value: 1020107,
    label: 'Фоидаи софи корхонахои давлати (10%)',
    code: '65',
  },
  {
    value: 1020108,
    label: 'Воридот аз хисоби маблагхои махсуси ташкилотхои бучети',
    code: '50',
  },
  {
    value: 1020109,
    label: 'Воридот аз хисоби риоя нагардидани ташхис, тамгагузории чавохирот ва сангхои киматбахо',
    code: '50',
  },
  {
    value: 10202,
    label: 'Дигар пардохтхои хатми ба бучет',
    code: '',
  },
  {
    value: 1020201,
    label: 'Хироч барои харакати воситахои наклиёти автомобили хоричи дар худуди Чумхурии Точикистон',
    code: '70',
  },
  {
    value: 1020202,
    label: 'Хироч барои бакайдгирии давлатии киштихои хавоии граждани',
    code: '53',
  },
  {
    value: 1020203,
    label: 'Хироч барои бакайдгирии давлатии аэрадромхо ва фурудгоххои хавои',
    code: '53',
  },
  {
    value: 1020204,
    label: 'Хироч барои бакайдгирии давлатии маводи дорувори',
    code: '82',
  },
  {
    value: 1020205,
    label: 'Хироч барои додани ичозати истифодаи спектри радиобасомад',
    code: '78',
  },
  {
    value: 1020206,
    label: 'Хирочи консули',
    code: '60',
  },
  {
    value: 1020207,
    label: 'Хироч барои додани шиносномаи шахрванди Чумхурии Точикистон',
    code: '92',
  },
  {
    value: 1020208,
    label: 'Хироч барои ичозатномадихи',
    code: 'Вобаста ба макомот',
  },
  {
    value: 1020209,
    label: 'Хироч барои амалхои бозрасии давлатии автомобили',
    code: '86',
  },
  {
    value: 1020210,
    label: 'Хироч барои амалхои бозрасии назорати давлатии теникаи кишоварзи',
    code: '56',
  },
  {
    value: 1020211,
    label: 'Хироч барои бакайдгирии давлатии хукук ва амволи гайриманкул ва ахдхо бо он',
    code: '65',
  },
  {
    value: 1020212,
    label: 'Хироч барои бакайдгирии давлатии киштихои дарёгарди хурдхачм',
    code: '53',
  },
  {
    value: 1020213,
    label: 'Хироч барои бакайдгирии давлатии воситахои радиоэлектрони ва дастгоххои баландбасомад',
    code: '78',
  },
  {
    value: 1020214,
    label: 'Пардохт барои амалхои марбут ба хифзи хукукии объектхои моликияти саноати',
    code: '99',
  },
  {
    value: 1020215,
    label: 'Пардохт барои истифодаи объектхои олами хайвонот',
    code: '71',
  },
  {
    value: 1020216,
    label: 'Пардохт барои истифодаи объектхои олами наботот',
    code: '71',
  },
  {
    value: 1020217,
    label: 'Пардохт барои истифодаи чангал',
    code: '71',
  },
  {
    value: 1020218,
    label: 'Пардохт барои чойгиркунии рекламаи беруни',
    code: '65',
  },
  {
    value: 1020219,
    label: 'Пардохт барои истифодаи спектри радиобасомад',
    code: '78',
  },
  {
    value: 1020220,
    label: 'Пардохт барои ифлос кардани мухити табии зист ва дигар намудхои таъсири зарарнок ба он',
    code: '81',
  },
  {
    value: 1020221,
    label: 'Ичозат барои истифодабарии воситахои наклиётии сиёхшиша ва хира',
    code: '86',
  },
  {
    value: 1020222,
    label: 'Ичозат барои истихрочи канданихои фоиданоки пошхурда',
    code: '50',
  },
  {
    value: 1020223,
    label: 'Хирочи сайёхи',
    code: '65',
  },
  {
    value: 1020224,
    label: 'Хироч барои сафари хач ва умра',
    code: '65',
  },
  {
    value: 1020225,
    label: 'Хироч аз хисобои авф бинобар конунигардонии дороихо ва маблагхои щахрвандони Чумхурии Точикистон',
    code: '65',
  },
  {
    value: 1020226,
    label:
      'Пардохти 5 фоиз аз конунигардонии маблагхо хангоми пеш аз мухлат гирифтан пули милли ё асъор аз бонкхо',
    code: '65',
  },
  {
    value: 10203,
    label: 'Чаримахо аз мучозотхо (санксияхо)',
    code: '',
  },
  {
    value: 1020301,
    label: 'Чаримахо ва пеняхо аз БДА',
    code: '86',
  },
  {
    value: 1020302,
    label: 'Чаримахо барои вайронкунии конунгузории андоз',
    code: '65',
  },
  {
    value: 1020303,
    label: 'Чаримахои суд',
    code: '63',
  },
  {
    value: 1020304,
    label: 'Чаримахо ва мучозотхо',
    code: 'Вобаста ба макомот',
  },
  {
    value: 1020305,
    label: 'Чаримахо аз санадхои тафтиши',
    code: 'Вобаста ба макомот',
  },
  {
    value: 1020306,
    label: 'Чаримахо и БДА аз низоми шахри бехатар',
    code: '86',
  },
  {
    value: 1020307,
    label: 'Чаримахое, ки ба гуруххои дигар шомил нашудаанд',
    code: '50',
  },
  {
    value: 1020308,
    label: 'Пеняхо барои вайронкунии конунгузории андоз',
    code: '65',
  },
  {
    value: 1020309,
    label: 'Пеняхое, ки ба гуруххои дигар шомил нашудаанд',
    code: '99',
  },
  {
    value: 10204,
    label: 'Воридоти дигари гайриандози ва пардохтхои маъмури',
    code: '',
  },
  {
    value: 1020401,
    label: 'Пардохт барои хизматрасонии маъмури',
    code: 'Вобаста ба макомот',
  },
  {
    value: 1020402,
    label: 'Барои хизматхои гумруки',
    code: '70',
  },
  {
    value: 1020403,
    label: 'Пардохт барои бланкаи шиносномахо',
    code: '92',
  },
  {
    value: 1020404,
    label: 'Пардохт барои маркахои аксизи дохили',
    code: '65',
  },
  {
    value: 1020405,
    label: 'Пардохт барои маркахои аксизи беруни',
    code: '70',
  },
  {
    value: 1020406,
    label: 'Воридот барои шиносномахои хоричи',
    code: '92',
  },
  {
    value: 1020407,
    label: 'Ичозати кор барои шахрвандони хоричи',
    code: '90',
  },
  {
    value: 1020408,
    label: 'Хизматрасонии додани шиносномаи хоричи',
    code: '92',
  },
  {
    value: 1020409,
    label: 'Шаклхои (бланкахои) нишонадор',
    code: '52',
  },
  {
    value: 1020410,
    label: 'Пардохт барои додани ичозатнома',
    code: 'Вобаста ба макомот',
  },
  {
    value: 1020411,
    label: 'Пардохт барои дида баромадани ариза барои гирифтани ичозатнома',
    code: 'Вобаста ба макомот',
  },
  {
    value: 1020412,
    label: 'Барои китобхои СБХМ',
    code: '50',
  },
  {
    value: 1020413,
    label: 'Пардохт барои додани сертификатхо',
    code: '93',
  },
  {
    value: 1020414,
    label: 'Аз сугуртаи хатми (70%)',
    code: '50',
  },
  {
    value: 1020415,
    label: 'Аз фуруши рақамхои давлатии РБДА (80%)',
    code: '86',
  },
  {
    value: 1020416,
    label: 'Барои ичора',
    code: '69',
  },
  {
    value: 1020417,
    label: 'Чуброни талафоти вобаста ба баровардани заминхо ва гардиши кишоварзи',
    code: '76',
  },
  {
    value: 1020418,
    label: 'Воридоти дигари гайриандози',
    code: 'Вобаста ба макомот',
  },
  {
    value: 1020419,
    label: 'Бакайдгири (ОВИР)',
    code: '92',
  },
  {
    value: 1020420,
    label: 'Дигар бакайдгирихо',
    code: 'Вобаста ба макомот',
  },
  {
    value: 1020421,
    label: 'Хизматрасони барои додани шиносномаи биометри',
    code: '92',
  },
  {
    value: 1020422,
    label: 'Барои китобхо (Вазорати маориф ва илм)',
    code: '59',
  },
  {
    value: 1020423,
    label: 'Барои муоинаи техникии автомашинахо',
    code: '86',
  },
  {
    value: 1020424,
    label: 'Барои назорати техникии давлати',
    code: '56',
  },
  {
    value: 1020425,
    label: 'Хизматрасонии нотариус',
    code: '52',
  },
  {
    value: 1020426,
    label: 'Хизматрасонии БДА',
    code: '56',
  },
  {
    value: 1020427,
    label: 'Барои тахсил',
    code: '59',
  },
  {
    value: 1020428,
    label: 'Барои экология',
    code: '71',
  },
  {
    value: 1020429,
    label: 'Корти мушаххас (Идентификатсиони)',
    code: '92',
  },
  {
    value: 1020430,
    label: 'Баргардонидани маблагхои гирифташуда ва истифоданашуда',
    code: '50',
  },
  {
    value: 10204031,
    label: 'Баргардонидани маблагхо ва ташкилотхои бучети',
    code: '50',
  },
  {
    value: 1020433,
    label: 'Воридоти аз додани шаходатномаи Точикстандарт',
    code: '93',
  },
  {
    value: 1020436,
    label: 'Истифодабарии захирахои  ракамгузории Хадамоти алока',
    code: '78',
  },
  {
    value: 1020437,
    label: 'Стандарти сабикахои тиллои',
    code: '50',
  },
  {
    value: 1020438,
    label: 'Пардохтхои экологи аз воситахои наклиёти автомобилии хоричи хангоми воридшави ба ЧТ',
    code: '70',
  },
  {
    value: 10205,
    label: 'Даромад аз сармоя',
    code: '',
  },
  {
    value: 1020501,
    label: 'Фуруши манзилхои истикомати',
    code: '69',
  },
  {
    value: 1020502,
    label: 'Фуруши молхо аз захирахои давлати',
    code: '50',
  },
  {
    value: 1020503,
    label: 'Фуруши масолехи киматноки дигар',
    code: '69',
  },
  {
    value: 1020504,
    label: 'Трансфертхои капитали аз сарчашмахои гайридавлати',
    code: '50',
  },
  {
    value: 1020505,
    label: 'Аз ташкилотхои байналхалки',
    code: '50',
  },
  {
    value: 1020506,
    label: 'Трансфертхои ихтиёри, бидуни грантхо',
    code: '50',
  },
  {
    value: 1020507,
    label: 'Фуруши бинохои гайриистикомати',
    code: '69',
  },
  {
    value: 1020508,
    label: 'Фуруши дигар иншоотхо',
    code: '69',
  },
  {
    value: 1020509,
    label: 'Фуруши тачхизотхои наклиети',
    code: '69',
  },
  {
    value: 1020510,
    label: 'Фуруши дигар мошин ва тачхизотхо',
    code: '69',
  },
  {
    value: 1020511,
    label: 'Фуруши боигарихои зеризамини',
    code: '69',
  },
  {
    value: 1020512,
    label: 'Фуруши дигар дороихои табий',
    code: '69',
  },
  {
    value: 1020513,
    label: 'Фуруши дороихои гайримоддии истехсолнашуда',
    code: '69',
  },
  {
    value: 1020514,
    label: 'Дастгоххои тахвилдихи',
    code: '50',
  },
  {
    value: 1020515,
    label: 'Захирахо',
    code: '50',
  },
  {
    value: 1020516,
    label: 'Фонди стабилизатсиони',
    code: '50',
  },
  {
    value: 10206,
    label: 'Пардохтхои дохили ва хоричи',
    code: '',
  },
  {
    value: 1020601,
    label: 'Пардохтани дороихои молиявии  кутохмуддати ва дарозмуддати Бонки милли Точикистон ва дигар бонкхо бо асъори милли ва хоричи',
    code: '50',
  },
  {
    value: 1020602,
    label: 'Пардохтани карзхо аз чониби вохидхои бахши давлати, корхона ва ташкилотхои давлати ва хусуси бо асъори милли ва хоричи',
    code: '50',
  },
  {
    value: 1020603,
    label: 'Пардохтани карзхо аз чониби гурезахо',
    code: '50',
  },
  {
    value: 1020604,
    label: 'Пардохтани карзхо аз чониби гурезахои ичбори',
    code: '50',
  },
  {
    value: 1020605,
    label: 'Пардохтани карзхо бо асъори милли ва хоричи дигаре, ки ба дигар гуруххо шомил нашудаанд ',
    code: '50',
  },
  {
    value: 1020606,
    label: 'Пардохтани дороихои молиявии дарозмуддат ва кутохмуддат аз чониби институтхои молиявии хориҷи',
    code: '50',
  },
  {
    value: 1020607,
    label:
      'Пардохатни дороихои молиявии дарозмуддат ва кутохмуддат аз чониби ташкилотхои байналмилали ва трансмилли',
    code: '50',
  },
  {
    value: 1020608,
    label: 'Пардохтани карзхои дарозмуддат ва кутохмуддати хоричие, ки ба дигар гуруххо шомил нашудаанд',
    code: '50',
  },
];

// const formattedDocType:any = [];

// const formatDocType = () => {
//   docTypes.forEach(item => {
//     formattedDocType.push({
//       value: item.value.toString(),
//       label: item.label + ' (' + item.value + ')',
//       code: item.code
//     });
//   });
// };

// formatDocType();

export default docTypes;
