import { useEffect, useState } from 'react';
import { Divider, Form } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getAccountBalanceApi, getSenderAccountsApi } from '@http/accounts';
import { swiftRubTransactionApi } from '@http/payments';
import { getTemplatesApi } from '@http/user';
import { getCountriesApi } from '@http/global';

import SenderAccountsModal from '@components/Payments/modals/SenderAccountsModal';
import SearchBicModal from '@components/Payments/modals/SearchBicModal';
import ConfirmPaymentModal from '@components/Payments/modals/ConfirmPaymentModal';
import Currencies from '@components/Currency/OrienbankRates';
import Templates from '@components/Templates/Templates';
import SaveTemplateModal from '@components/Payments/modals/SaveTemplateModal';

import { otpConfirmAC, repeatSwiftRubTransactionAC, verifyTransIdAC } from '@store/reducers/transactions';
import { pickedSwiftRubTemplateAC } from '@store/reducers/user';
import {
  isConfirmPaymentModalOpenAC,
  isSearchBicModalOpenAC,
  rubOperationQueryAC,
  searchedSwiftAC,
} from '@store/reducers/payments';
import { selectedSenderAccountAC, senderAccountBalanceAC, senderAccountsModalOpenAC } from '@store/reducers/accounts';
import RubOperationsForm from '@components/Payments/forms/RubOperationsForm';

function RubOperations() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const senderAccounts = useAppSelector(state => state.accountsReducer.senderAccounts);
  const pickedSwiftRubTemplate = useAppSelector(state => state.userReducer.pickedSwiftRubTemplate);
  const repeatSwiftRubTransaction = useAppSelector(state => state.transactionsReducer.repeatSwiftRubTransaction);
  const newRubOperationTemplate = useAppSelector(state => state.paymentsReducer.newRubOperationTemplate);
  const rubOperationQuery = useAppSelector(state => state.paymentsReducer.rubOperationQuery);

  const [senderAccountCurrency, setSenderAccountCurrency] = useState('');

  useEffect(() => {
    initializeData();

    return clearFields;
  }, []);

  useEffect(() => {
    pickedSwiftRubTemplateHandler();
  }, [pickedSwiftRubTemplate]);

  useEffect(() => {
    repeatSwiftTransactionHandler();
  }, [repeatSwiftRubTransaction]);

  useEffect(() => {
    dispatch(otpConfirmAC(false));
  }, [rubOperationQuery]);

  const pickedSwiftRubTemplateHandler = () => {
    setSenderAccountCurrency(pickedSwiftRubTemplate.sending_ccy);

    fillQuery(pickedSwiftRubTemplate, 'template');
    fillForms(pickedSwiftRubTemplate, 'template');

    if (+pickedSwiftRubTemplate.sending_amount > 0) {
      dispatch(getAccountBalanceApi('sender', { cust_ac_no: pickedSwiftRubTemplate.sending_ac }));
    }
  };

  const repeatSwiftTransactionHandler = () => {
    setSenderAccountCurrency(repeatSwiftRubTransaction.sending_ccy);

    fillForms(repeatSwiftRubTransaction, 'repeat');
    fillQuery(repeatSwiftRubTransaction, 'repeat');

    if (+repeatSwiftRubTransaction.sending_amount > 0) {
      dispatch(getAccountBalanceApi('sender', { cust_ac_no: repeatSwiftRubTransaction.sending_ac }));
    }
  };

  const fillForms = (data: any, type: any) => {
    form.setFieldValue('sending_ac', data.sending_ac);
    form.setFieldValue('receiving_ac', data.receiving_ac);
    form.setFieldValue('receiving_ac2', data.receiving_ac);
    form.setFieldValue('receiver_name', data.receiver_name);
    form.setFieldValue('sender_address', data.sender_address);
    form.setFieldValue('receiver_country', data.receiver_country);
    form.setFieldValue('bank_code', data.bank_code);
    form.setFieldValue('receiver_bank_name', data.receiver_bank_name);
    form.setFieldValue('receiver_bank_address', data.receiver_bank_address);
    form.setFieldValue('tax_id', data.tax_id);
    form.setFieldValue('receiver_kpp', data.receiver_kpp);
    form.setFieldValue('bank_corr_acc', data.bank_corr_acc);
    form.setFieldValue('receiver_address', data.receiver_address);

    dispatch(selectedSenderAccountAC(data?.sending_ac));
  };

  const fillQuery = (data: any, type: any) => {
    dispatch(
      rubOperationQueryAC({
        sending_ac: data.sending_ac,
        receiving_ac: data.receiving_ac,
        receiving_ac2: data.receiving_ac,
        receiver_name: data.receiver_name,
        sender_address: data.sender_address,
        receiver_country: data.receiver_country,
        bank_code: data.bank_code,
        receiver_bank_name: data.receiver_bank_name,
        receiver_bank_address: data.receiver_bank_address,
        tax_id: data.tax_id,
        receiver_kpp: data.receiver_kpp,
        bank_corr_acc: data.bank_corr_acc,
        receiver_address: data.receiver_address,
      })
    );
  };

  const senderAccountSelect = (account: any) => {
    setSenderAccountCurrency(account.ccy);
    dispatch(rubOperationQueryAC({ ...rubOperationQuery, sending_ac: account.value }));

    form.setFieldValue('sending_ac', account.value);

    dispatch(senderAccountsModalOpenAC(false));
    dispatch(selectedSenderAccountAC(account));
    dispatch(getAccountBalanceApi('sender', { cust_ac_no: account.value }));
  };

  const selectBicHandler = (item: any) => {
    form.setFieldsValue({
      bank_code: item.bic_code.slice(0, 9),
      receiver_bank_name: item.bank_name,
      bank_corr_acc: item.bic_code.slice(10),
      receiver_bank_address: item.bank_address,
    });

    dispatch(isSearchBicModalOpenAC(false));
  };

  const initializeData = () => {
    dispatch(getCountriesApi());
    dispatch(searchedSwiftAC([]));
    dispatch(selectedSenderAccountAC({}));
    dispatch(senderAccountBalanceAC(0));
    dispatch(getTemplatesApi({ transaction_type: 'SWIFT_RUB' }));
    dispatch(
      getSenderAccountsApi({
        transaction_type: 'SWIFT_RUB',
      })
    );
  };

  const swiftRubTransaction = () => {
    dispatch(swiftRubTransactionApi(rubOperationQuery, navigate, () => clearFields()));
  };

  const clearFields = () => {
    dispatch(rubOperationQueryAC({}));
    setSenderAccountCurrency('');

    form.resetFields();

    dispatch(otpConfirmAC(false));
    dispatch(verifyTransIdAC(''));
    dispatch(senderAccountBalanceAC(0));
    dispatch(selectedSenderAccountAC({}));
    dispatch(isConfirmPaymentModalOpenAC(false));
    dispatch(repeatSwiftRubTransactionAC({}));
    dispatch(pickedSwiftRubTemplateAC({}));
  };

  return (
    <div className='md:grid grid-cols-6 gap-10 mt-5'>
      <div className='col-span-4'>
        <div className=' mt-7'>
          <Templates type='SWIFT_RUB' />
        </div>
        <Divider className='my-6' />
        <RubOperationsForm form={form} senderAccountCurrency={senderAccountCurrency} />
        <SenderAccountsModal accounts={senderAccounts} selectHandler={senderAccountSelect} />
        <SearchBicModal selectBicHandler={selectBicHandler} />
        <SaveTemplateModal template={newRubOperationTemplate} />
        <ConfirmPaymentModal
          title={`${t('Ruble transfer')}`}
          paymentInfo={rubOperationQuery}
          currency={senderAccountCurrency}
          paymentTransactionHandler={swiftRubTransaction}
          clearFields={clearFields}
          route={'swiftRubPaymentStatus'}
          type='SWIFT_RUB'
        />
      </div>
      <div className='col-span-2 max-md:hidden'>
        <Currencies size='max-lg:text-xs text-sm' />
      </div>
    </div>
  );
}

export default RubOperations;
