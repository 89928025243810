import { Tabs, Select } from 'antd';

import type { TabsProps } from 'antd';
import TransactionList from '@components/Transactions/TransactionList';
import TransactionFilters from '@components/Transactions/TransactionFilters';
import { useAppDispatch } from '@hooks/redux';
import { transactionStatusAC } from '@store/reducers/transactions';
import { useTranslation } from 'react-i18next';

function Index() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onChange = (status: string) => {
    dispatch(transactionStatusAC(status));
  };

  const items: TabsProps['items'] = [
    {
      key: '',
      label: t('All'),
    },
    {
      key: 'TO_APPROVE',
      label: t('ToSign'),
    },
    {
      key: 'IN_APPROVAL',
      label: t('Signing in'),
    },
    {
      key: 'DECLINED',
      label: t('Rejecteds'),
    },
    {
      key: 'IN_PROGRESS',
      label: t('Processing'),
    },
    {
      key: 'FAILED',
      label: t('Not successful'),
    },
  ];

  return (
    <div className='flex justify-center'>
      <div className='lg:w-[1130px] w-full'>
        <div className='flex'>
          <div className='p-4 lg:p-8 w-full h-full bg-white mb-12'>
            <div>
              <h1 className='max-md:hidden text-2xl mb-2 font-semibold color-active'>{t('Transactions')}</h1>
              <div className='flex justify-between md:hidden'>
                <h1 className='text-xl mb-2 font-semibold'>{t('Transactions')}</h1>
                <Select
                  style={{ width: 210 }}
                  allowClear
                  placeholder={`${t('Status')}`}
                  onChange={status => dispatch(transactionStatusAC(status))}
                >
                  <Select.Option value=''>{t('All')}</Select.Option>
                  <Select.Option value='TO_APPROVE'>{t('ToSign')}</Select.Option>
                  <Select.Option value='IN_APPROVAL'>{t('Signing in')}</Select.Option>
                  <Select.Option value='DECLINED'>{t('Rejecteds')}</Select.Option>
                  <Select.Option value='IN_PROGRESS'>{t('Processing')}</Select.Option>
                  <Select.Option value='FAILED'>{t('Not successful')}</Select.Option>
                </Select>
              </div>
              <TransactionFilters />
              <Tabs
                className='max-md:hidden color-active font-medium'
                defaultActiveKey=''
                items={items}
                onChange={onChange}
              />
              <TransactionList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
