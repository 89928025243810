import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Dropdown, Drawer, Badge } from 'antd';
import type { MenuProps } from 'antd';
import { UserOutlined, MenuOutlined, CloseOutlined, BellOutlined } from '@ant-design/icons';
import logoSmall from '@assets/images/logoSmall.svg';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import ruFlag from '@assets/images/ru.png';
import tjFlag from '@assets/images/tj.png';
import usaFlag from '@assets/images/usa.png';
import zhFlag from '@assets/images/zh.png';
import { logOutApi } from '@http/auth';

function Header() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isSideBarOpen, setisOpenSideBar] = useState(false);
  const user = useAppSelector(state => state.appReducer.user);
  const notificationAlert = useAppSelector(state => state.globalReducer.notificationAlert);
  const transactionAlert = useAppSelector(state => state.globalReducer.transactionAlert);
  const permissions = useAppSelector(state => state.userReducer.permissions);

  let routes: any = [];
  const staticRoutes = [
    {
      key: 'accounts',
      path: '/accounts/bankAccounts',
      label: t('Accounts'),
    },
    {
      key: 'transactions',
      path: '/transactions',
      label: t('Transactions'),
    },
  ];

  const permissionsRoutes = [
    {
      key: 'payments',
      path: '/payments/own',
      label: t('Payments'),
    },
    {
      key: 'salary',
      path: '/salary/list',
      label: t('Salaries'),
    },
    {
      key: 'statement',
      path: '/statement',
      label: t('Statement'),
    },
  ];

  const checkRoutes = () => {
    routes = [...staticRoutes];
    for (let i = 0; i < permissionsRoutes.length; i++) {
      for (const key in permissions) {
        if (permissionsRoutes[i].key === key) {
          routes.push(permissionsRoutes[i]);
        }
      }
    }
  };

  checkRoutes();

  const items: MenuProps['items'] = [
    ...(permissions.deposits
      ? [
          {
            key: '1',
            label: t('Deposits'),
            onClick: () => {
              navigate('/deposits');
            },
          },
        ]
      : []),
    ...(permissions.credits
      ? [
          {
            key: '2',
            label: t('Loans'),
            onClick: () => {
              navigate('/loans');
            },
          },
        ]
      : []),

    // {
    //   key: '3',
    //   label: t('Counterparties'),
    //   onClick: () => {
    //     navigate('/counterparties');
    //   },
    // },
    {
      key: '4',
      label: t('Tariffs'),
      onClick: () => {
        navigate('/commissions');
      },
    },
    {
      key: '5',
      label: t('Exchange Rates'),
      onClick: () => {
        navigate('/rates');
      },
    },
    {
      key: '6',
      label: t('Templates'),
      onClick: () => {
        navigate('/templates');
      },
    },
  ];

  const items2: MenuProps['items'] = [
    {
      key: '1',
      label: user.position,
    },
    {
      key: '2',
      label: t('Profile'),
      onClick: () => {
        navigate('/profile');
      },
    },
    {
      key: '3',
      danger: true,
      label: t('Log out'),
      onClick: () => {
        dispatch(logOutApi());
      },
    },
  ];

  const languages: MenuProps['items'] = [
    {
      key: '1',
      label: 'Русский',
      onClick: () => {
        i18n.changeLanguage('ru');
        localStorage.setItem('lang', 'ru');
      },
    },
    {
      key: '2',
      label: 'Таджикский',
      onClick: () => {
        i18n.changeLanguage('tg');
        localStorage.setItem('lang', 'tg');
      },
    },
    {
      key: '3',
      label: 'English',
      onClick: () => {
        i18n.changeLanguage('en');
        localStorage.setItem('lang', 'en');
      },
    },
    // {
    //   key: '4',
    //   label: 'Chinese',
    //   onClick: () => {
    //     i18n.changeLanguage('zh');
    //     localStorage.setItem('lang', 'zh');
    //   },
    // },
  ];

  const getLanguageIcon = (language: string) => {
    switch (language) {
      case 'ru':
        return ruFlag;
      case 'tg':
        return tjFlag;
      case 'en':
        return usaFlag;
      case 'zh':
        return zhFlag;

      default:
        return ruFlag;
    }
  };

  const navigateHandler = (url: string) => {
    setisOpenSideBar(false);
    navigate(url);
  };

  const checkActiveRoute = (route: any) => {
    if (location.pathname.includes(route)) {
      return 'header__items-active';
    }
    return null;
  };

  return (
    <div className='max-lg:w-full px-5'>
      <header className='flex justify-between lg:w-[1130px] h-20 items-center'>
        <Link to='/' className='flex items-center'>
          <img src={logoSmall} alt='' className='w-10 h-10' />
          <div className='text-white font-bold ml-3'>
            <p className='md:text-2xl text-xl leading-4 mt-1 color-active'>{t('Oriyon')}</p>
            <p className={`${i18n.language === 'en' ? 'mt-0.5' : ''} text-xs md:text-sm text-right color-active`}>
              {t('Business')}
            </p>
          </div>
        </Link>
        {/* <Link to='/' className='md:hidden'>
          <img src={logo} alt='' />
        </Link> */}

        <div className='max-md:hidden w-1/2 h-full'>
          <div
            className={`${
              Object.keys(permissions).length > 1 ? 'justify-between' : 'justify-around'
            } flex  font-medium items-center h-full`}
            style={{ color: '#fff !important' }}
          >
            {routes.map((item: any) => (
              <div className='relative h-full' key={item.key}>
                <Link
                  to={item.path}
                  className={`header__items ${checkActiveRoute(
                    item.key
                  )} h-full flex items-center color-active font-semibold`}
                >
                  {item.label}
                </Link>
                {item.key === 'transactions' && transactionAlert ? (
                  <div className='bg-green-500 h-2 w-2 rounded-full absolute' style={{ top: '17px', right: '-7px' }} />
                ) : null}
              </div>
            ))}
            <Dropdown
              menu={{
                items,
              }}
              trigger={['click']}
              placement='bottomLeft'
              className='cursor-pointer'
            >
              <span className='header__items py-5 text-sm font-semibold'>{t('More')}</span>
            </Dropdown>
          </div>
        </div>
        <div className='flex items-center'>
          <Dropdown
            menu={{
              items: languages,
            }}
            trigger={['click']}
            placement='bottomRight'
            className='cursor-pointer mr-5'
          >
            <div className='flex items-center'>
              <img src={getLanguageIcon(i18n.language)} alt='' className='w-8 h-8' />
              <span className='color-active font-semibold ml-2 text-sm max-md:hidden'>{t('Language')}</span>
            </div>
          </Dropdown>

          <div className='max-md:hidden'>
            <Badge dot={notificationAlert}>
              <BellOutlined style={{ fontSize: '22px', color: '#011957' }} />
            </Badge>
            <Dropdown
              menu={{
                items: items2,
              }}
              trigger={['click']}
              placement='bottomRight'
              className='cursor-pointer ml-3'
            >
              <UserOutlined style={{ fontSize: '22px', color: '#011957' }} />
            </Dropdown>
          </div>

          <div className='md:hidden'>
            {isSideBarOpen ? (
              <CloseOutlined onClick={() => setisOpenSideBar(false)} style={{ fontSize: '24px', color: '#011957' }} />
            ) : (
              <MenuOutlined onClick={() => setisOpenSideBar(true)} style={{ fontSize: '24px', color: '#011957' }} />
            )}
          </div>
        </div>
      </header>
      <Drawer className='color-active font-bold' closable={false} title={null} placement='right' open={isSideBarOpen}>
        <div className='border-b'>
          <div
            className='py-3 text-lg'
            onClick={() => navigateHandler('accounts/bankAccounts')}
            role='button'
            tabIndex={0}
          >
            {t('Accounts')}
          </div>
        </div>
        <div className='border-b'>
          <div className='py-3 text-lg' onClick={() => navigateHandler('transactions/all')} role='button' tabIndex={0}>
            {t('Transactions')}
          </div>
        </div>
        <div className='border-b'>
          <div className='py-3 text-lg' onClick={() => navigateHandler('payments/own')} role='button' tabIndex={0}>
            {t('Payments')}
          </div>
        </div>
        <div className='border-b'>
          <div className='py-3 text-lg' onClick={() => navigateHandler('salary/list')} role='button' tabIndex={0}>
            {t('Salaries')}
          </div>
        </div>
        <div className='border-b'>
          <div className='py-3 text-lg' onClick={() => navigateHandler('statement')} role='button' tabIndex={0}>
            {t('Statement')}
          </div>
        </div>

        <div className='border-b'>
          <div className='py-3 text-lg' onClick={() => navigateHandler('loans')} role='button' tabIndex={0}>
            {t('Loans')}
          </div>
        </div>
        <div className='border-b'>
          <div className='py-3 text-lg' onClick={() => navigateHandler('deposits')} role='button' tabIndex={0}>
            {t('Deposits')}
          </div>
        </div>
        <div className='border-b'>
          <div className='py-3 text-lg' onClick={() => navigateHandler('commissions')} role='button' tabIndex={0}>
            {t('Tariffs')}
          </div>
        </div>
        <div className='border-b'>
          <div className='py-3 text-lg' onClick={() => navigateHandler('rates')} role='button' tabIndex={0}>
            {t('Currency')}
          </div>
        </div>
        <div className='border-b'>
          <div className='py-3 text-lg' onClick={() => dispatch(logOutApi())} role='button' tabIndex={0}>
            {t('Exit')}
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default Header;
