import { Select } from 'antd';
import AccountInfo from '@components/Accounts/AccountInfo';
import BankAccounts from '@components/Accounts/BankAccounts';
import { useAppDispatch } from '@hooks/redux';
import { filterAccountsByCurrencyAC } from '@store/reducers/accounts';
import { useTranslation } from 'react-i18next';

function AllAccounts() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const filterByCurrency = (currency: any) => {
    dispatch(filterAccountsByCurrencyAC(currency));
  };

  return (
    <div className='lg:flex lg:justify-center'>
      <div className='lg:w-[1130px]'>
        <div className='flex'>
          <div className='p-4 lg:p-8 w-full h-full bg-white mb-12'>
            <div className='md:grid lg:grid-cols-6 sm:grid-cols-8 gap-10'>
              <div className='lg:col-span-4 md:col-span-5 mb-7'>
                <div className='flex justify-between mb-5'>
                  <h1 className='text-2xl mb-2 font-semibold'>{t('MyAccounts')}</h1>
                  <div>
                    <p className='text-right text-sm'>{t('Filter')}:</p>
                    <div className='flex justify-end'>
                      <Select
                        onChange={filterByCurrency}
                        placeholder={`${t('MyAccounts')}`}
                        size='middle'
                        style={{ width: '120px' }}
                        options={[
                          {
                            value: 'all',
                            label: 'Все',
                          },
                          {
                            value: 'tjs',
                            label: 'TJS',
                          },
                          {
                            value: 'usd',
                            label: 'USD',
                          },
                          {
                            value: 'eur',
                            label: 'EUR',
                          },
                          {
                            value: 'rub',
                            label: 'RUB',
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <BankAccounts />
              </div>
              <div className='lg:col-span-2 md:col-span-3'>
                <AccountInfo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllAccounts;
