import { Select, DatePicker, Button, Form, Pagination, Popover } from 'antd';
import { DatePicker as DatePickerMobile } from 'antd-mobile';
import { Toast as ToastMobile } from 'antd-mobile';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getAccountsApi } from '@http/accounts';
import { getAccountStatementApi } from '@http/accounts';
import { statementAC } from '@store/reducers/accounts';
import { CloudDownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import digitNumber from '@utils/digitNumber';
import moment from 'moment';
import { getPaymentOrderApi, getPaymentOrderToPrintApi } from '@http/payments';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import { statementOrderModalOpenAC } from '@store/reducers/global';
import OrderStatementModal from '@components/Statement/OrderStatementModal';
import { fixDate, formatDate, formatDateDashed } from '@utils/formatDate';
import getBankName from '@utils/bankName';

function Statement() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const now = new Date();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const statement = useAppSelector(state => state.accountsReducer.statement);
  const accounts = useAppSelector(state => state.accountsReducer.namedAccounts);
  const permissions = useAppSelector(state => state.userReducer.permissions);
  const [pickDateVisible, setPickDateVisible] = useState(false);
  const [pickedDate, setPickedDate] = useState(t('Select date'));

  const [query, setQuery] = useState<any>({
    cust_ac_no: '',
    dt: '',
  });

  const pickDate = (date: any) => {
    const formattedDate = formatDateDashed(date);

    setPickedDate(formattedDate);
    form.setFieldValue('dt', moment(formattedDate));
  };

  const submit = (values: any) => {
    if (isMobileOnly) {
      dispatch(getAccountStatementApi({ cust_ac_no: values.account, dt: pickedDate }, 1));
      setQuery({ cust_ac_no: values.account, dt: pickedDate });
    } else {
      dispatch(getAccountStatementApi({ cust_ac_no: values.account, dt: values.dt?.format('YYYY-MM-DD') }, 1));
      setQuery({ cust_ac_no: values.account, dt: values.dt?.format('YYYY-MM-DD') });
    }
  };

  const changePage = (page: number) => {
    dispatch(getAccountStatementApi(query, page));
  };

  useEffect(() => {
    dispatch(getAccountsApi());
    dispatch(statementAC([]));
  }, []);

  return (
    <div className='lg:flex lg:justify-center'>
      <div className='lg:w-[1130px]'>
        <div className='flex'>
          <div className='w-full h-full bg-white mb-12'>
            <div>
              <div className='md:flex justify-between'>
                <h1 className='text-2xl mb-2 font-semibold color-active'>{t('Statement')}</h1>
                <div>
                  {permissions?.statement[0]?.allow_init ? (
                    <Button className='mr-3' type='dashed' onClick={() => dispatch(statementOrderModalOpenAC(true))}>
                      {t('Order an statement')}
                    </Button>
                  ) : null}
                  <Button className='max-sm:mt-5' type='primary' onClick={() => navigate('/statement/orders')}>
                    {t('View orders')}
                  </Button>
                </div>
              </div>
              <div className='flex mt-7'>
                <Form form={form} name='filterForm' onFinish={submit} className='w-full'>
                  <div className='sm:flex'>
                    <p className='sm:hidden'>{t('Select account')}:</p>
                    <Form.Item
                      className='m-0 sm:mr-3 sm:w-96 max-sm:max-w-[358px]'
                      name='account'
                      rules={[{ required: true, message: `${t('Required')}` }]}
                    >
                      <Select
                        style={{ width: '100%' }}
                        size='large'
                        placeholder={`${t('Select account')}`}
                        options={accounts}
                        allowClear
                      />
                    </Form.Item>
                    <div className='flex max-sm:mt-5 max-sm:items-end'>
                      <div className='w-full'>
                        <p className='sm:hidden'>{t('Select date')}:</p>
                        {isMobileOnly ? (
                          <div>
                            <Form.Item
                              className='m-0 mr-3 sm:hidden'
                              name='dt'
                              rules={[{ required: true, message: `${t('Required')}` }]}
                            >
                              <div>
                                <div
                                  className='w-full h-[40px] px-3 text-base flex items-center rounded-lg bg-[#f6f7f8] text-[#00000040] sm:hidden'
                                  style={{ border: '1px solid #d9d9d9' }}
                                  onClick={() => setPickDateVisible(true)}
                                >
                                  {pickedDate !== '' ? <span>{pickedDate}</span> : null}
                                </div>
                                <DatePickerMobile
                                  confirmText={`${t('Pick')}`}
                                  cancelText={`${t('Cancel')}`}
                                  title={`${t('Select date')}`}
                                  visible={pickDateVisible}
                                  onClose={() => {
                                    setPickDateVisible(false);
                                  }}
                                  max={now}
                                  onConfirm={(val: any) => {
                                    console.log('here', val);

                                    ToastMobile.show(val.toDateString());
                                    pickDate(val);
                                  }}
                                />
                              </div>
                            </Form.Item>
                          </div>
                        ) : (
                          <Form.Item
                            className='m-0 mr-3 max-sm:hidden'
                            name='dt'
                            rules={[{ required: true, message: `${t('Required')}` }]}
                          >
                            <DatePicker
                              style={{ width: '100%' }}
                              format='DD-MM-YYYY'
                              className='mr-3'
                              placeholder={`${t('Select date')}`}
                              size='large'
                              allowClear
                            />
                          </Form.Item>
                        )}
                      </div>
                      <Button type='primary' size='large' htmlType='submit'>
                        {t('Show')}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
              {statement?.data?.length > 0 ? (
                <div>
                  <div>
                    <div
                      className='grid max-md:grid-cols-5 max-lg:grid-cols-6 lg:grid-cols-8 gap-5 max-sm:text-xs text-sm max-sm:py-3 sm:p-5 font-semibold mt-5 color-active'
                      style={{ borderBottom: '1px solid #eee' }}
                    >
                      <p className='col-span-1'>{t('Date')}</p>
                      <p className='col-span-2'>{t('Recipient')}</p>
                      <p className='col-span-1 text-right'>{t('Debit')}</p>
                      <p className='col-span-1 text-right'>{t('Credit')}</p>
                      <p className='col-span-1 lg:col-span-2 max-md:hidden text-right'>{t('Narrative')}</p>
                      <p className='col-span-1'></p>
                    </div>
                    {statement?.data?.map((item: any) => (
                      <div
                        style={{ borderBottom: '1px solid #eee' }}
                        className='grid max-md:grid-cols-5 max-lg:grid-cols-6 lg:grid-cols-8 gap-5 max-sm:text-xs text-sm max-sm:py-3 sm:p-5 border-b cursor-pointer listItem'
                      >
                        <p className='col-span-1 max-sm:text-[11px]'>{item.dt.slice(0, 10)}</p>
                        <div className='col-span-2'>
                          <div>
                            <p className='font-semibold'>{item.shet}</p>
                            <p className='mt-2'>{item.shet_desc}</p>
                            <p>{getBankName(item?.bik)}</p>
                          </div>
                          <div className='col-span-1 lg:col-span-2 mt-2 md:hidden'>
                            <p className={`${item?.nar ? 'mb-2' : ''} break-all`}>{item?.nar}</p>
                            <p className='break-all'>{item?.oper}</p>
                            <div className='mt-2'>
                              <span className='mr-2'>{item?.udf_1}</span>
                              <span className='mr-2'>{item?.udf_2}</span>
                              <span>{item?.udf_3}</span>
                            </div>
                          </div>

                          <Button
                            style={{ fontSize: '12' }}
                            className='mt-2 !text-xs lg:hidden'
                            icon={<CloudDownloadOutlined />}
                            type='primary'
                            size='small'
                            onClick={() =>
                              dispatch(getPaymentOrderApi({ xref: item.trn_ref_no, txn_type: item.module }))
                            }
                          >
                            {t('Download')}
                          </Button>
                        </div>
                        <p className='col-span-1 text-right'>{digitNumber(item?.fdeb ? item?.fdeb : item?.deb)}</p>
                        <p className='col-span-1 text-right'>{digitNumber(item?.fcred ? item?.fcred : item?.cred)}</p>
                        <div className='col-span-1 lg:col-span-2 max-md:hidden'>
                          <p className={`${item?.nar ? 'mb-2' : ''} break-all`}>{item?.nar}</p>
                          <p className='break-all'>{item?.oper}</p>
                          <div className='mt-2'>
                            <span className='mr-2'>{item?.udf_1}</span>
                            <span className='mr-2'>{item?.udf_2}</span>
                            <span>{item?.udf_3}</span>
                          </div>
                        </div>
                        <div className='col-span-1 text-end max-lg:hidden'>
                          <Popover title={t('Download')} overlayStyle={{ width: '90px' }}>
                            <CloudDownloadOutlined
                              onClick={() =>
                                dispatch(getPaymentOrderApi({ xref: item.trn_ref_no, txn_type: item.module }))
                              }
                              style={{ fontSize: '24px' }}
                              className='mr-3 text-[#ffb700] hover:text-[#ffd260]'
                            />
                          </Popover>

                          <Popover title={t('Print')} overlayStyle={{ width: '120px' }}>
                            <PrinterOutlined
                              onClick={() =>
                                dispatch(getPaymentOrderToPrintApi({ xref: item.trn_ref_no, txn_type: item.module }))
                              }
                              style={{ fontSize: '24px' }}
                              className='text-[#ffb700] hover:text-[#ffd260]'
                            />
                          </Popover>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className='mt-7 flex justify-end'>
                    <Pagination
                      onChange={changePage}
                      current={statement.page}
                      defaultPageSize={10}
                      total={statement.total_pages * 10}
                      showSizeChanger={false}
                    />
                  </div>
                </div>
              ) : (
                <div className='h-24 lg:h-72 flex items-center justify-center'>
                  <p>{t('No Transactions')}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <OrderStatementModal />
    </div>
  );
}

export default Statement;
