import { $host } from './index';
import { AppDispatch } from '@store';
import { isSpinAC, notificationModalAC } from '@store/reducers/app';
import {
  countriesAC,
  loadingRatesAC,
  nbtRatesAC,
  notificationAlertAC,
  ratesAC,
  transactionAlertAC,
} from '@store/reducers/global';

const END_POINT = '/client';

const getRatesApi = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(loadingRatesAC(true));
    const { data } = await $host.get(`${END_POINT}/rates`);
    dispatch(ratesAC(data));
  } finally {
    dispatch(loadingRatesAC(false));
  }
};

const getNbtRatesApi = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.get(`${END_POINT}/rates/nbt`);
    dispatch(nbtRatesAC(data));
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getAlertsApi = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.get(`${END_POINT}/alert`);
    dispatch(transactionAlertAC(data?.transaction));
    dispatch(notificationAlertAC(data?.notification));
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getCountriesApi = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.get(`${END_POINT}/transaction/countries`);
    dispatch(countriesAC(data));
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getTarifFileApi = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const response = await $host.post(`${END_POINT}/fees/download`, params, {
      responseType: 'arraybuffer',
    });
    const dispositionHeader = response.headers['content-disposition'];
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(dispositionHeader);
    let filename = '';
    if (matches != null && matches[1]) {
      // Декодирование названия файла из UTF-8
      filename = decodeURIComponent(matches[1].replace(/['"]/g, ''));
    }
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    link.click();
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

export { getRatesApi, getNbtRatesApi, getAlertsApi, getCountriesApi, getTarifFileApi };
