import { useEffect } from 'react';
import { Card, Pagination, Collapse, Button, Divider, Tag } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BulkTransactionInfoSearchForm from './BulkTransactionInfoSearchForm';
import getStatus from '@utils/getStatus';
import { dowloadOriginalBulkFileApi, getBulkTransactionInfoApi } from '@http/bulkTransaction';
import { ArrowLeftOutlined } from '@ant-design/icons';
import digitNumber from '@utils/digitNumber';
import { useTranslation } from 'react-i18next';
import BulkTransactionActionModal from './BulkTransactionActionModal';
import { actionModalTypeAC, bulkTransactionActionModalOpenAC } from '@store/reducers/bulkTransaction';

const { Panel } = Collapse;

export const BulkTransactionInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const pickedBulkTransactionInfo = useAppSelector(state => state.bulkTransactionReducer.pickedBulkTransactionInfo);
  const pickedBulkTransactionFileId = location.pathname.slice(20);

  const changePage = (page: any) => {
    dispatch(getBulkTransactionInfoApi({ file_id: pickedBulkTransactionFileId }, page));
  };

  useEffect(() => {
    dispatch(getBulkTransactionInfoApi({ file_id: pickedBulkTransactionFileId }, 1));
  }, []);

  return (
    <div>
      <Card
        title={
          <div className='flex items-center'>
            <ArrowLeftOutlined className='cursor-pointer' onClick={() => navigate('/payments/bulk/list')} />
            <p className='ml-5 color-active'>{t('File information')}</p>
          </div>
        }
      >
        <div className='leading-8'>
          <p>
            <span className='font-semibold'>{t('Status')}:</span>{' '}
            {getStatus(pickedBulkTransactionInfo?.file_info?.status, 12, t)}
          </p>
          <p>
            <span className='font-semibold'>{t('Date of creation')}:</span>{' '}
            {pickedBulkTransactionInfo?.file_info?.created_at.slice(0, 16)}
          </p>
          <p>
            <span className='font-semibold'>{t('File name')}:</span> {pickedBulkTransactionInfo?.file_info?.file_name}
          </p>
          <p>
            <span className='font-semibold'>{t('Initiator')}:</span> {pickedBulkTransactionInfo?.file_info?.initiator}
          </p>
          <p>
            <span className='font-semibold'>{t('Total amount')}: </span>
            {digitNumber(pickedBulkTransactionInfo?.file_info?.total_amount)}{' '}
            {pickedBulkTransactionInfo?.file_info?.trn_ccy}
          </p>
          <p>
            <span className='font-semibold'>{t('Number of transactions')}:</span>{' '}
            {pickedBulkTransactionInfo?.file_info?.trn_count}
          </p>
          <p>
            <span className='font-semibold'>{t('Own accounts payments')}:</span>{' '}
            {pickedBulkTransactionInfo?.file_info?.internal_trn_count}
          </p>
          <p>
            <span className='font-semibold'>{t('Intrabank payments')}:</span>{' '}
            {pickedBulkTransactionInfo?.file_info?.internal_trn_count}
          </p>
          <p>
            <span className='font-semibold'>{t('Interbank payments')}:</span>{' '}
            {pickedBulkTransactionInfo?.file_info?.interbank_trn_count}
          </p>
          <p>
            <span className='font-semibold'>{t('Successful count')}:</span>{' '}
            {pickedBulkTransactionInfo?.file_info?.success_count}
          </p>
          <p>
            <span className='font-semibold'>{t('Not successful count')}:</span>{' '}
            {pickedBulkTransactionInfo?.file_info?.fail_count}
          </p>
          <div>
            {pickedBulkTransactionInfo?.file_info?.error_message?.map((item: any) => (
              <p className='text-red-400'>{item}</p>
            ))}
          </div>
          {pickedBulkTransactionInfo?.file_info?.authenticator.length > 0 ? (
            <div className=''>
              <p className='mr-3 font-semibold'>{t('Authorizers')}: </p>
              {pickedBulkTransactionInfo?.file_info.authenticator.map((item: any) => (
                <div className='flex items-center '>
                  <p className='mr-3 font-semibold'>{item.authenticator_name}</p>
                  {item.authed === true ? <Tag color='success'>{t('Approved')}</Tag> : null}
                  {item.authed === false ? <Tag color='error'>{t('Rejected')}</Tag> : null}
                  {item.authed === null ? <Tag color='processing'>{t('Signing in')}</Tag> : null}
                </div>
              ))}
            </div>
          ) : null}
          <Divider />
          <div className='flex mt-5'>
            <Button
              type='primary'
              size='middle'
              onClick={() => dispatch(dowloadOriginalBulkFileApi({ file_id: pickedBulkTransactionFileId }))}
            >
              {t('Download file')}
            </Button>
            {pickedBulkTransactionInfo?.file_info?.is_authenticator &&
            pickedBulkTransactionInfo?.file_info?.status === 'TO_APPROVE' ? (
              <div>
                <Button
                  danger
                  className='ml-3'
                  size='middle'
                  onClick={() => {
                    dispatch(bulkTransactionActionModalOpenAC(true));
                    dispatch(actionModalTypeAC('DECLINE'));
                  }}
                >
                  {t('Reject')}
                </Button>
                <Button
                  className='ml-3'
                  type='primary'
                  size='middle'
                  onClick={() => {
                    dispatch(bulkTransactionActionModalOpenAC(true));
                    dispatch(actionModalTypeAC('APPROVE'));
                  }}
                >
                  {t('Confirm')}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </Card>
      <p className='my-5 text-lg font-semibold color-active'>{t('Transactions')}</p>
      <div className='mb-5 mt-7'>
        <BulkTransactionInfoSearchForm />
      </div>

      <div
        className='grid grid-cols-12 text-sm pb-5 p-5 pl-12 gap-3 font-semibold color-active'
        style={{ borderBottom: '1px solid #eee' }}
      >
        <p className='col-span-1 max-lg:col-span-2'>{t('Date')}</p>
        <p className='col-span-2'>{t('Recipient')}</p>
        <p className='col-span-2 max-lg:col-span-3'>{t('Sender account')}</p>
        <p className='col-span-2 max-lg:hidden'>{t('Recipient account')}</p>
        <p className='col-span-1'>{t('Payment type')}</p>
        <p className='col-span-2 text-right'>{t('Amount')}</p>
        <p className='col-span-2 text-right'>{t('Status')}</p>
      </div>

      {pickedBulkTransactionInfo?.results?.length > 0 ? (
        <Collapse ghost className='p-0'>
          {pickedBulkTransactionInfo?.results?.map((item: any) => (
            <Panel
              header={
                <div
                  style={{ borderBottom: '1px solid #eee' }}
                  className='grid grid-cols-12 items-center py-5 border-b cursor-pointer gap-3 text-sm'
                  key={item.id}
                >
                  <p className='col-span-1 max-lg:col-span-2'>{item.created_at.slice(0, 10)}</p>
                  <p className='col-span-2'>{item.receiver_name}</p>
                  <p className='col-span-2 max-lg:col-span-3'>{item.sending_ac}</p>
                  <p className='col-span-2 max-lg:hidden'>{item.receiving_ac}</p>
                  <p className='col-span-1'>{item.type}</p>
                  <p className='col-span-2 text-right'>
                    {digitNumber(item.sending_amount)} {item.sending_ccy}
                  </p>
                  <p className='col-span-2 text-right'>{getStatus(item.status, 12, t)}</p>
                </div>
              }
              key={item.id}
            >
              <div className='bg-slate-50'>
                <div className='grid grid-cols-7 items-center py-5 px-2 border-b max-md:text-ex lg:gap-5'>
                  <div className='col-span-1' />
                  <div className='col-span-6 leading-6 lg:leading-8'>
                    <div className='grid grid-cols-6'>
                      <p>{t('TxID')}: </p>
                      <p className='ml-3 font-bold col-span-5'>{item.trans_id}</p>
                    </div>
                    <div className='grid grid-cols-6'>
                      <p>{t('Transaction type')}: </p>
                      <p className='ml-3 font-bold col-span-5'>{item.type}</p>
                    </div>
                    <div className='grid grid-cols-6'>
                      <p>{t('Date of creation')}: </p>
                      <p className='ml-3 font-bold'>{item.created_at.slice(0, 19)}</p>
                    </div>
                    <div className='grid grid-cols-6'>
                      <p>{t('Sender account')}: </p>
                      <p className='ml-3 font-bold col-span-5'>
                        {item.sending_ac} ({item.sending_ccy})
                      </p>
                    </div>
                    <div className='grid grid-cols-6'>
                      <p>{t('Recipient')}: </p>
                      <p className='ml-3 font-bold col-span-5'>{item.receiver_name}</p>
                    </div>
                    <div className='grid grid-cols-6'>
                      <p>{t('Recipient TIN')}: </p>
                      <p className='ml-3 font-bold col-span-5'>{item.receiver_tax_number}</p>
                    </div>
                    <div className='grid grid-cols-6'>
                      <p>{t('Recipient account')}: </p>
                      <p className='ml-3 font-bold col-span-5'>{item.receiving_ac}</p>
                    </div>
                    <div className='grid grid-cols-6'>
                      <p>{t('Amount')}: </p>
                      <p className='ml-3 font-bold col-span-5'>
                        {digitNumber(item.sending_amount)} {item.sending_ccy}
                      </p>
                    </div>
                    <div className='grid grid-cols-6'>
                      <p>{t('Narrative')}: </p>
                      <p className='ml-3 font-bold col-span-5'>{item.narrative}</p>
                    </div>
                    <div className='grid grid-cols-6'>
                      <p>{t('Status')}: </p>
                      <p className='ml-3 font-bold col-span-5'>{getStatus(item.status, 12, t)}</p>
                    </div>
                    {!item.valid ? (
                      <div className='grid grid-cols-6'>
                        <p>{t('Error')}: </p>
                        <p className='ml-3 font-bold col-span-5'>{item.error_message}</p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </Panel>
          ))}
          <div className='mt-7 flex justify-end'>
            <Pagination
              onChange={changePage}
              current={pickedBulkTransactionInfo.current_page}
              defaultPageSize={10}
              total={pickedBulkTransactionInfo.total_pages * 10}
              showSizeChanger={false}
            />
          </div>
        </Collapse>
      ) : (
        <div className='h-24 lg:h-72 flex items-center justify-center'>
          <p>{t('No Transactions')}</p>
        </div>
      )}
      <BulkTransactionActionModal fileId={pickedBulkTransactionFileId} />
    </div>
  );
};

export default BulkTransactionInfo;
