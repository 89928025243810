import { Button, Modal, Descriptions, Table, Popover, Statistic, Divider, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { isBulkTransactionPreviewModalOpenAC } from '@store/reducers/bulkTransaction';
import digitNumber from '@utils/digitNumber';
import getStatus from '@utils/getStatus';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { confirmBulkTransactionFileApi, getBulkFilesApi } from '@http/bulkTransaction';
import { transactionVerifyApi, transactionVerifyResendCodeApi } from '@http/transactions';
import { otpConfirmAC } from '@store/reducers/transactions';
import { otpLifeTimeAC } from '@store/reducers/app';

const { Countdown } = Statistic;

interface DataType {
  sending_amount: string;
  sending_ccy: string;
  total_amount: string;
  sending_ac: string;
  first_name: string;
  error_message: string;
  valid: boolean;
}

const transactionColumns: ColumnsType<DataType> = [
  {
    title: 'Статус',
    dataIndex: 'valid',
    key: 'valid',
    align: 'center',
    render: (_, item) => (
      <div>
        {item.valid ? (
          <CheckCircleOutlined style={{ color: 'green', fontSize: '20px' }} />
        ) : (
          <Popover title={item.error_message}>
            <CloseCircleOutlined className='cursor-pointer' style={{ color: 'red', fontSize: '20px' }} />
          </Popover>
        )}
      </div>
    ),
  },
  {
    title: 'Счет отправителя',
    dataIndex: 'sending_ac',
    key: 'sending_ac',
  },
  {
    title: 'Счет получателя',
    dataIndex: 'receiving_ac',
    key: 'receiving_ac',
  },
  {
    title: 'Тип операции',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Сумма',
    dataIndex: 'amount',
    key: 'amount',
    align: 'right',
    render: (_, item) => (
      <p>
        {digitNumber(item.sending_amount)} {item.sending_ccy}
      </p>
    ),
  },
];

const BulkTransactionPreviewModal = () => {
  const [confirmForm] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [time, setTime] = useState(false);
  const isBulkTransactionPreviewModalOpen = useAppSelector(
    state => state.bulkTransactionReducer.isBulkTransactionPreviewModalOpen
  );
  const bulkTransactionInfo = useAppSelector(state => state.bulkTransactionReducer.bulkTransactionInfo);
  const otpConfirm = useAppSelector(state => state.transactionsReducer.otpConfirm);
  const otpLifeTime = useAppSelector(state => state.appReducer.otpLifeTime);
  const verifyFileId = useAppSelector(state => state.bulkTransactionReducer.verifyFileId);

  const confirmBulkTransactionFile = () => {
    dispatch(confirmBulkTransactionFileApi({ file_id: bulkTransactionInfo.file_id }));
  };

  const verifyBulkTransactionFile = (values: any) => {
    dispatch(
      transactionVerifyApi({ code: values.code, trans_id: verifyFileId, trn_type: 'BULK' }, () => {
        dispatch(getBulkFilesApi({}, 1));
        confirmForm.resetFields();
        dispatch(otpConfirmAC(false));
        dispatch(isBulkTransactionPreviewModalOpenAC(false));
      })
    );
  };

  return (
    <Modal
      style={{ top: 20 }}
      width={800}
      footer={null}
      title={`${t('File information')}`}
      open={isBulkTransactionPreviewModalOpen}
      onOk={() => dispatch(isBulkTransactionPreviewModalOpenAC(true))}
      onCancel={() => dispatch(isBulkTransactionPreviewModalOpenAC(false))}
    >
      <Descriptions size={'small'} column={6} bordered>
        <Descriptions.Item span={3} label={`${t('Number of transactions')}`}>
          {bulkTransactionInfo?.trn_count}
        </Descriptions.Item>
        <Descriptions.Item span={3} label={`${t('Total amount')}`}>
          {digitNumber(bulkTransactionInfo?.total_amount)}
        </Descriptions.Item>
        <Descriptions.Item span={3} label={`${t('Status')}`}>
          {getStatus(bulkTransactionInfo?.valid, 12, t)}
        </Descriptions.Item>
        <Descriptions.Item span={3} label={`${t('Intrabank payments')}`}>
          {bulkTransactionInfo?.internal_trn_count}
        </Descriptions.Item>
        <Descriptions.Item span={3} label={`${t('Interbank payments')}`}>
          {bulkTransactionInfo?.interbank_trn_count}
        </Descriptions.Item>
        <Descriptions.Item span={3} label={`${t('Own accounts payments')}`}>
          {bulkTransactionInfo?.own_trn_count}
        </Descriptions.Item>
      </Descriptions>
      {bulkTransactionInfo?.error_message?.length > 0 ? (
        <div className='mt-5'>
          {bulkTransactionInfo?.error_message?.map((item: any, index: any) => (
            <div className='text-red-400'>
              {index + 1}) {item}
            </div>
          ))}
        </div>
      ) : null}

      <p className='text-sm font-semibold mt-7'>{t('Transaction list')}</p>
      <Table columns={transactionColumns} dataSource={bulkTransactionInfo.data} className='mt-2' size='small' />
      <div>
        {!otpConfirm ? (
          <div className='flex justify-end mt-5'>
            <Button disabled={!bulkTransactionInfo.valid} type='primary' onClick={confirmBulkTransactionFile}>
              {t('Confirm')}
            </Button>
          </div>
        ) : (
          <div>
            <Divider />
            <div>
              <p className='text-base'>{t('Confirm payment')}</p>
              <span className='text-xs text-slate-400'>{t('ConfirmSmsFromPhone')}</span>

              <Form form={confirmForm} name='internalOperations' onFinish={verifyBulkTransactionFile}>
                <Form.Item className='m-0' name='code' rules={[{ required: true, message: `${t('Required')}` }]}>
                  <Input
                    placeholder={`${t('Enter SMS code')}`}
                    size='large'
                    style={{ width: '100%' }}
                    type='text'
                    maxLength={6}
                  />
                </Form.Item>
                {!time ? (
                  <div className='mt-3 flex items-center text-sm text-slate-400'>
                    <span className='mr-2'>{t('ResendTime')}</span>
                    <Countdown
                      className='!text-xs'
                      style={{ fontSize: 12 }}
                      value={otpLifeTime}
                      onFinish={() => setTime(true)}
                      format='mm:ss'
                    />
                    <span className='ml-2'> {t('Second')}!</span>
                  </div>
                ) : null}
                <div className='flex justify-end mt-5'>
                  {time ? (
                    <Button
                      className='mr-3'
                      onClick={() => {
                        dispatch(otpLifeTimeAC(Date.now() + 1000 * 30));
                        setTime(false);
                        dispatch(transactionVerifyResendCodeApi({ trans_id: verifyFileId, trn_type: 'BULK' }));
                      }}
                    >
                      {t('ResendCode')}
                    </Button>
                  ) : null}
                  <Button type='primary' htmlType='submit'>
                    {t('Confirm')}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BulkTransactionPreviewModal;
