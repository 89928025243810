import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './assets/fonts/stylesheet.css';
import './index.scss';
import ruRU from 'antd/lib/locale/ru_RU';
import dayjs from 'dayjs';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'dayjs/locale/ru';
import './i18n';

import { setupStore } from './store';

dayjs.locale('ru');

const store = setupStore();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ConfigProvider
    locale={ruRU}
    theme={{
      token: {
        colorPrimary: '#ffb700',
      },
    }}
  >
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ConfigProvider>
);

reportWebVitals();
