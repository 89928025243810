import { useState } from 'react';
import { Form, Input, Button, Modal, Radio } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { loginApi } from '@http/auth';
import { useTranslation } from 'react-i18next';
import Header from './components/header';
import FooterNew from '@components/Container/Footer-new';
import { otpTypeAC } from '@store/reducers/app';

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const otpType = useAppSelector(state => state.appReducer.otpType);

  const onFinish = async (values: any) => {
    const query = {
      username: values.username,
      password: values.password,
      otp_delivery: otpType,
    };

    dispatch(loginApi(query, navigate));
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='bg-white'>
      <Header />
      <main className='flex items-center justify-center py-12'>
        <div className='w-[340px] lg:w-[480px]'>
          <div>
            <div className='text-center mb-7 text-2xl'>
              <div className='text-2xl'>
                <p>{t('Sign in')}</p>
              </div>
            </div>
            <Form name='login' initialValues={{ remember: true }} onFinish={onFinish} autoComplete='off'>
              <Form.Item className='mb-7' name='username' rules={[{ required: true, message: `${t('Required')}` }]}>
                <Input
                  className='auth__input-primary text-lg'
                  style={{ width: '100%' }}
                  placeholder={`${t('Login')}`}
                />
              </Form.Item>

              <Form.Item
                className='mb-3'
                name='password'
                rules={[
                  { required: true, message: `${t('Required')}` },
                  { min: 8, message: `${t('Field must contain at least 8 digits')}` },
                ]}
              >
                <Input.Password
                  className='auth__input-primary text-lg'
                  style={{ width: '100%' }}
                  placeholder={`${t('Password')}`}
                />
              </Form.Item>

              <div className='mb-5'>
                <p className='text-sm lg:text-base text-slate-500 mb-3'>{t('otpType')}</p>
                <Radio.Group onChange={e => dispatch(otpTypeAC(e.target.value))} value={otpType}>
                  <Radio value={'sms'}>{t('withSMS')}</Radio>
                  <Radio value={'email'}>{t('withEmail')}</Radio>
                </Radio.Group>
              </div>

              <Form.Item>
                <div className='flex justify-end'>
                  <Button className='w-full h-[57px] text-lg' type='primary' htmlType='submit'>
                    {t('Sign in')}
                  </Button>
                </div>
              </Form.Item>
            </Form>
            <div className='flex justify-center'>
              <p className='cursor-pointer text-slate-500 text-base lg:text-lg' onClick={showModal}>
                {t('Sign up')}
              </p>
            </div>
          </div>
          <Modal width={680} footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <p className='text-sm lg:text-lg pt-24 font-semibold text-center'>
              <p>{t('For registration')}</p>
              <p>{t('Orienbank Address')}</p>
            </p>
            <div className='flex justify-center'>
              <span className='pt-8' style={{ width: '380px', borderBottom: '1px solid #eee' }} />
            </div>
            <p className='text-sm lg:text-lg pt-7 font-semibold text-center'>{t('For any questions')}</p>
            <div className='text-base lg:text-xl flex justify-between pt-20 font-bold'>
              <p>{t('Call center 2222')}</p>
              <p>www.oriyonbonk.tj</p>
            </div>
          </Modal>
        </div>
      </main>
      <div className='mt-10' style={{ borderTop: '1px solid #eee' }}>
        <FooterNew />
      </div>
    </div>
  );
}

export default Login;
