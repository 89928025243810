import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface loansState {
  loansList: any;
  loanInfo: any;
  selectedLoan: any;
  loanStatement: any;
}

const initialState: loansState = {
  loansList: [],
  loanInfo: {},
  selectedLoan: {},
  loanStatement: [],
};

const loans = createSlice({
  name: 'loans',
  initialState,
  reducers: {
    loansListAC(state, action) {
      state.loansList = action.payload;
    },
    loanInfoAC(state, action) {
      state.loanInfo = action.payload;
    },
    selectedLoanAC(state, action) {
      state.selectedLoan = action.payload;
    },
    loanStatementAC(state, action) {
      state.loanStatement = action.payload;
    },
  },
});

export const { loansListAC, loanInfoAC, selectedLoanAC, loanStatementAC } = loans.actions;
export default loans.reducer;
