import { useEffect } from 'react';

import { Divider, Button } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getPaymentDetailsApi, getPaymentDetailsOrderApi } from '@http/payments';
import { useLocation, useNavigate } from 'react-router-dom';
import digitNumber from '@utils/digitNumber';
import getStatus from '@utils/getStatus';
import { useTranslation } from 'react-i18next';

function SwiftPaymentStatus() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const paymentDetails = useAppSelector(state => state.paymentsReducer.paymentDetails);

  const getPaymentDetailsOrder = () => {
    dispatch(getPaymentDetailsOrderApi({ trans_id: location.pathname.slice(20) }));
  };

  useEffect(() => {
    dispatch(getPaymentDetailsApi({ trans_id: location.pathname.slice(20) }));
  }, []);

  return (
    <div className='lg:flex justify-center'>
      <div className='lg:w-[1130px]'>
        <div className='flex'>
          <div className='py-5 px-8 w-full h-full bg-white mb-12' style={{ minHeight: '600px' }}>
            <div className='mt-5'>
              <div>
                <h1 className='text-xl mb-2 font-semibold'>{t('Payment Information')}</h1>
                <Divider />
                <div className='leading-9'>
                  <h1 className='text-xl'>{t('International payment')}</h1>
                  <p className='text-base font-semibold mt-2'>
                    {digitNumber(paymentDetails.receiving_amount)} {paymentDetails.sending_ccy}
                  </p>
                  {getStatus(paymentDetails.status, 12, t)}
                </div>
                <Divider />
                <div className='text-sm'>
                  <div className='mb-3'>
                    <p className='font-light'>{t('From account')}:</p>
                    <p className='font-semibold'>
                      {paymentDetails.sending_ac} ({paymentDetails.sending_ccy})
                      {paymentDetails.sending_ac_name !== '' ? '-' + paymentDetails.sending_ac_name : null}
                    </p>
                  </div>
                  <div className='mb-3'>
                    <p className='font-light'>{t('To account')}:</p>
                    <p className='font-semibold'>{paymentDetails.receiving_ac}</p>
                  </div>
                  <div className='mb-3'>
                    <p className='font-light'>{t('Recipient')}:</p>
                    <p className='font-semibold'>{paymentDetails.receiver_name}</p>
                  </div>
                  <div className='mb-3'>
                    <p className='font-light'>{t('Recipient country')}:</p>
                    <p className='font-semibold'>
                      {paymentDetails.receiver_country} ({paymentDetails.receiver_bank_country})
                    </p>
                  </div>
                  <div className='mb-3'>
                    <p className='font-light'>{t('Recipient city')}:</p>
                    <p className='font-semibold'>{paymentDetails.receiver_city}</p>
                  </div>
                  <div className='mb-3'>
                    <p className='font-light'>{t('Recipient address')}:</p>
                    <p className='font-semibold'>{paymentDetails.receiver_address}</p>
                  </div>
                  <div className='mb-3'>
                    <p className='font-light'>{t('Recipient bank name')}:</p>
                    <p className='font-semibold'>{paymentDetails.receiver_bank_name}</p>
                  </div>
                  <div className='mb-3'>
                    <p className='font-light'>{t('Recipient bank swift')}:</p>
                    <p className='font-semibold'>{paymentDetails.receiver_bank_swift}</p>
                  </div>
                  <div className='mb-3'>
                    <p className='font-light'>{t('Narrative')}:</p>
                    <p className='font-semibold'>{paymentDetails.narrative}</p>
                  </div>
                  <div className='mb-3'>
                    <p className='font-light'>{t('Transaction date')}:</p>
                    <p className='font-semibold'>{paymentDetails.created_at}</p>
                  </div>
                  <div className='mb-3'>
                    <p className='font-light'>{t('TxID')}:</p>
                    <p className='font-semibold'>{paymentDetails.trans_id}</p>
                  </div>
                </div>
                <div className='mt-5 flex'>
                  <Button className='mr-3' onClick={() => navigate('/payments/swift')}>
                    {t('Back')}
                  </Button>
                  {paymentDetails.status === 'SUCCESS' ? (
                    <Button type='primary' onClick={getPaymentDetailsOrder}>
                      {t('Download PDF')}
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SwiftPaymentStatus;
