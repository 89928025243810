import { useState, useEffect } from 'react';
import { Divider, Form } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';

import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { interbankTransactionApi, getBankBicsApi } from '@http/payments';
import { getAccountBalanceApi, getSenderAccountsApi } from '@http/accounts';
import { getTemplatesApi } from '@http/user';

import Templates from '@components/Templates/Templates';
import Currencies from '@components/Currency/OrienbankRates';
import SenderAccountsModal from '@components/Payments/modals/SenderAccountsModal';
import SaveTemplateModal from '@components/Payments/modals/SaveTemplateModal';
import ConfirmPaymentModal from '@components/Payments/modals/ConfirmPaymentModal';
import DocumentTypeModal from '@components/Payments/modals/DocumentTypeModal';

import { pickedInterbankTemplateAC } from '@store/reducers/user';
import { senderAccountBalanceAC, selectedSenderAccountAC, senderAccountsModalOpenAC } from '@store/reducers/accounts';
import { otpConfirmAC, repeatInterbankTransactionAC, verifyTransIdAC } from '@store/reducers/transactions';
import {
  interbankOperationsQueryAC,
  isConfirmPaymentModalOpenAC,
  isDocumentTypesModalOpenAC,
  newInterbankTemplateAC,
  selectedDocumentTypeAC,
} from '@store/reducers/payments';
import InterbankOperationsForm from '@components/Payments/forms/InterbankOperationsForm';

function InterbankOperations() {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const senderAccounts = useAppSelector(state => state.accountsReducer.senderAccounts);
  const pickedInterbankTemplate = useAppSelector(state => state.userReducer.pickedInterbankTemplate);
  const repeatInterbankTransaction = useAppSelector(state => state.transactionsReducer.repeatInterbankTransaction);
  const newInterbankTemplate = useAppSelector(state => state.paymentsReducer.newInterbankTemplate);
  const interbankOperationsQuery = useAppSelector(state => state.paymentsReducer.interbankOperationsQuery);

  const [senderAccountCurrency, setSenderAccountCurrency] = useState('');
  const [selectedDocTypeName, setSelectedDocTypeName] = useState('');

  useEffect(() => {
    initializeData();

    return clearFields;
  }, []);

  useEffect(() => {
    pickedInterbankTemplateHandler();
  }, [pickedInterbankTemplate]);

  useEffect(() => {
    repeatInterbankTransactionHandler();
  }, [repeatInterbankTransaction]);

  useEffect(() => {
    dispatch(otpConfirmAC(false));
  }, [interbankOperationsQuery]);

  const pickedInterbankTemplateHandler = () => {
    setSenderAccountCurrency(pickedInterbankTemplate.sending_ccy);

    fillQuery(pickedInterbankTemplate, 'template');
    fillForms(pickedInterbankTemplate, 'template');

    if (+pickedInterbankTemplate.sending_amount > 0) {
      dispatch(getAccountBalanceApi('sender', { cust_ac_no: pickedInterbankTemplate.sending_ac }));
    }

    dispatch(selectedDocumentTypeAC(pickedInterbankTemplate?.doc_code));
  };

  const repeatInterbankTransactionHandler = () => {
    setSenderAccountCurrency(repeatInterbankTransaction.sending_ccy);

    fillQuery(repeatInterbankTransaction, 'repeat');
    fillForms(repeatInterbankTransaction, 'repeat');

    if (+repeatInterbankTransaction.sending_amount > 0) {
      dispatch(getAccountBalanceApi('sender', { cust_ac_no: repeatInterbankTransaction.sending_ac }));
    }

    dispatch(selectedDocumentTypeAC(repeatInterbankTransaction?.doc_code));
  };

  const fillForms = (data: any, type: any) => {
    form.setFieldValue('sending_ac', data.sending_ac);
    form.setFieldValue('receiving_ac', data.receiving_ac);
    form.setFieldValue('receiving_ac2', data.receiving_ac);
    form.setFieldValue('bank_code', data.bank_code);
    form.setFieldValue('tax_id', data.tax_id);
    form.setFieldValue('receiver_name', data.receiver_name);
    form.setFieldValue('doc_type', data.doc_type);
    form.setFieldValue('sending_amount', data.sending_amount);
    form.setFieldValue('doc_no', data.doc_no);
    form.setFieldValue('narrative', data.narrative);

    if (type === 'repeat') {
      if (data.doc_date) {
        form.setFieldValue('doc_date', dayjs(data.doc_date, 'DD-MM-YYYY'));
      }
    }

    setSelectedDocTypeName(data?.doc_type_title);

    dispatch(selectedSenderAccountAC(data?.sending_ac));
  };

  const fillQuery = (data: any, type: any) => {
    dispatch(
      interbankOperationsQueryAC({
        sending_ac: data.sending_ac,
        receiving_ac: data.receiving_ac,
        receiving_ac2: data.receiving_ac,
        receiver_name: data.receiver_name,
        bank_code: data.bank_code,
        tax_id: data.tax_id,
        doc_no: data.doc_no,
        doc_type: data.doc_type,
        doc_code: data.doc_code,
        doc_type_title: data.doc_type_title,
        doc_date: type === 'repeat' ? data.doc_date : '',
        narrative: data.narrative,
        sending_amount: data.sending_amount,
      })
    );
  };

  const initializeData = () => {
    dispatch(selectedSenderAccountAC({}));
    dispatch(senderAccountBalanceAC(0));
    dispatch(getTemplatesApi({ transaction_type: 'INTERBANK' }));
    dispatch(getBankBicsApi());
    dispatch(
      getSenderAccountsApi({
        transaction_type: 'INTERBANK',
      })
    );
  };

  const pickDocument = (item: any, type: any) => {
    setSelectedDocTypeName(item.label);
    dispatch(
      interbankOperationsQueryAC({
        ...interbankOperationsQuery,
        doc_type: item.code,
        doc_code: item.value,
        doc_type_title: item.label,
      })
    );
    dispatch(newInterbankTemplateAC({ ...newInterbankTemplate, doc_code: item.value, doc_type_title: item.label }));

    form.setFieldsValue({
      doc_type: item.code,
    });

    dispatch(isDocumentTypesModalOpenAC(false));
    dispatch(selectedDocumentTypeAC(item.value));
  };

  const senderAccountSelect = (account: any) => {
    setSenderAccountCurrency(account.ccy);
    dispatch(interbankOperationsQueryAC({ ...interbankOperationsQuery, sending_ac: account.value }));

    form.setFieldValue('sending_ac', account.value);

    dispatch(senderAccountsModalOpenAC(false));
    dispatch(selectedSenderAccountAC(account.value));
    dispatch(getAccountBalanceApi('sender', { cust_ac_no: account.value }));
  };

  const interbankTransaction = () => {
    dispatch(interbankTransactionApi(interbankOperationsQuery, navigate, () => clearFields()));
  };

  const clearFields = () => {
    setSelectedDocTypeName('');
    setSenderAccountCurrency('');
    dispatch(
      interbankOperationsQueryAC({
        sending_ac: '',
        receiving_ac: '',
        receiving_ac2: '',
        receiver_name: '',
        sending_amount: '',
        bank_code: '',
        tax_id: '',
        doc_type: '',
        doc_no: '',
        doc_date: '',
        narrative: '',
      })
    );

    form.resetFields();

    dispatch(isConfirmPaymentModalOpenAC(false));
    dispatch(selectedSenderAccountAC({}));
    dispatch(otpConfirmAC(false));
    dispatch(verifyTransIdAC(''));
    dispatch(repeatInterbankTransactionAC({}));
    dispatch(pickedInterbankTemplateAC({}));
  };

  return (
    <div className='md:grid grid-cols-6 gap-10 mt-5'>
      <div className='col-span-4'>
        <div className=' mt-7'>
          <Templates type='INTERBANK' />
        </div>
        <Divider className='my-6' />
        <InterbankOperationsForm
          form={form}
          senderAccountCurrency={senderAccountCurrency}
          selectedDocTypeName={selectedDocTypeName}
        />
        <SenderAccountsModal accounts={senderAccounts} selectHandler={senderAccountSelect} />
        <SaveTemplateModal template={newInterbankTemplate} />
        <ConfirmPaymentModal
          title='Interbank payment'
          paymentInfo={interbankOperationsQuery}
          currency={senderAccountCurrency}
          paymentTransactionHandler={interbankTransaction}
          clearFields={clearFields}
          route={'interbankPaymentStatus'}
          type={'INTERBANK'}
        />
        <DocumentTypeModal pickDocument={pickDocument} />
      </div>
      <div className='col-span-2 max-md:hidden'>
        <Currencies size='max-lg:text-xs text-sm' />
      </div>
    </div>
  );
}

export default InterbankOperations;
