import React from 'react';
import dots from '@assets/images/dots.svg';
import eclipse from '@assets/images/Ellipse.svg';
import chat from './img/chat.png';
import hour from './img/24hour.png';
import stone from './img/stone.svg';
import mainMacbook2 from './img/mainMacbook2.png';
import mainMacbook3 from './img/mainMacbook3.png';
import { useTranslation } from 'react-i18next';

const Vozm = () => {
  const { t } = useTranslation();
  const arr = [
    {
      id: 1,
      text: t('vozm.title1'),
      img: chat,
    },
    {
      id: 2,
      text: t('vozm.title3'),
      img: mainMacbook3,
    },
  ];
  const arr2 = [
    {
      id: 1,
      text: t('vozm.title1'),
      img: chat,
    },
    {
      id: 2,
      text: t('vozm.title3'),
      img: mainMacbook3,
    },
  ];
  return (
    <div className='pb-10 mx-4'>
      <div className='flex justify-center'>
        <h1 className='lg:text-3xl text-2xl font-semibold mb-10 lg:mb-14 text-center'>{t('vozm.mainTitle')}</h1>
      </div>
      <div className='flex flex-col max-sm:gap-y-7'>
        {arr2.map(e => (
          <div key={e.id}>
            <div key={e.id} className='relative sm:hidden bg-white rounded-3xl p-5 m-auto'>
              <div className='flex gap-5 pt-4'>
                <img src={dots} alt='' />
                <h1 className='text-lg leading-6 font-semibold'>{e.text}</h1>
              </div>
              <div className='flex justify-center py-5 md:px-[10px] '>
                <img src={e.img} alt='' className='z-40 w-full max-sm:w-2/3' />
              </div>
              <div className='absolute bottom-0 z-0'>
                <img src={eclipse} className='w-full' alt='' />
              </div>
              <div className='absolute max-sm:left-[50px] bottom-0 px-3 z-10'>
                <img src={stone} alt='' className='w-full max-sm:w-4/5' />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='hidden sm:flex justify-between gap-7 pb-8 '>
        {arr.map(e => (
          <div key={e.id} className='relative bg-white rounded-3xl w-[100%] lg:px-10 px-5 pt-5'>
            <div className='flex gap-5 justify-center pt-4 '>
              <img src={dots} alt='' />
              <h1 className='text-[20px] w-[225px] md:text-[18px] leading-6 font-semibold'>{e.text}</h1>
            </div>
            <div className='flex justify-center py-7 md:px-[10px] '>
              <img src={e.img} alt='' className='z-40 w-2/3' />
            </div>
            <div className='absolute bottom-0 z-0 '>
              <img src={eclipse} className='w-full' alt='' />
            </div>
            <div className='absolute bottom-0 left-20 md:left-[10%] px-3 z-10 lg:left-[80px] '>
              <img src={stone} alt='' className='w-4/5' />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Vozm;
