import React, { useState } from 'react';
import docs from './img/files2.png';
import { Collapse } from 'antd';
import pdfFile from './docs/pdf (2).png';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@hooks/redux';
import { getTarifFileApi } from '@http/global';

const { Panel } = Collapse;

const Documents = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [activePanel, setActivePanel] = useState([1]);

  const openPanel = (key: any) => {
    setActivePanel(key);
  };

  return (
    <div className='pt-5 mt-24 sm:mb-48 mb-16 rounded-xl relative bg-[#D2DEEA]'>
      <div className='flex justify-between sm:px-6 px-[10px]  z-0'>
        <div className='sm:p-10 py-10 max-sm:px-5'>
          <h1 className='text-2xl sm:text-3xl font-semibold pb-6 '>{t('documents.mainTitle')}</h1>
        </div>
        <div className='flex max-sm:justify-end z-0 md:my-auto '>
          <img src={docs} alt='' className='z-0 w-4/5' />
        </div>
      </div>
      <div className='z-10 relative'>
        <Collapse activeKey={activePanel} onChange={openPanel} ghost className='p-0'>
          <Panel header={<div className='py-5 text-xl'>{t('documents.title1')}</div>} key={1}>
            <div className='flex flex-col gap-y-10 sm:pl-10 pl-3 text-base bg-white pt-10'>
              <div
                onClick={e => {
                  e.preventDefault();
                }}
                className='flex gap-3  cursor-pointer hover:text-[#69B1FF]'
              >
                <div className='my-auto '>
                  <img src={pdfFile} alt='' className='w-[35px]  ' />
                </div>

                <p className='color-active'>
                  <a href='#' onClick={() => dispatch(getTarifFileApi({ file_lang: 'tg' }))}>
                    Тарофаҳо барои хизматрасонӣ ба шахсони ҳуқуқӣ ва соҳибкорони инфиродӣ
                  </a>
                </p>
              </div>
              <div
                onClick={e => {
                  e.preventDefault();
                }}
                className='flex gap-3 cursor-pointer hover:text-[#69B1FF] '
              >
                <div className='my-auto '>
                  <img src={pdfFile} alt='' className=' w-[35px] ' />
                </div>
                <p className='' onClick={() => dispatch(getTarifFileApi({ file_lang: 'ru' }))}>
                  <a href='#'>Тарифы для обслуживания юридических лиц и частных предпринимателей</a>
                </p>
              </div>
              <div
                onClick={e => {
                  e.preventDefault();
                }}
                className='flex gap-3 mb-5 cursor-pointer hover:text-[#69B1FF]'
              >
                <div className='flex justify-center my-auto '>
                  <img src={pdfFile} alt='' className='w-[35px]' />
                </div>
                <p className='my-auto '>
                  <a href='#' onClick={() => dispatch(getTarifFileApi({ file_lang: 'en' }))}>
                    Rates for Corporate and Sole Proprietor Services
                  </a>
                </p>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default Documents;
