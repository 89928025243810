import { createSlice } from '@reduxjs/toolkit';

interface transactionsState {
  transactionsHistory: any;
  otpConfirm: boolean;
  verifyTransId: string;
  orders: any;
  transactionStatus: string;
  searchQuery: any;
  activePanel: any;
  repeatOwnTransaction: any;
  repeatExchangeTransaction: any;
  repeatInternalTransaction: any;
  repeatInterbankTransaction: any;
  repeatSwiftTransaction: any;
  repeatSwiftRubTransaction: any;
  isTransactionActionModalOpen: boolean;
  actionModalType: string;
  deadline: any;
}

const initialState: transactionsState = {
  transactionsHistory: [],
  otpConfirm: false,
  verifyTransId: '',
  orders: [],
  transactionStatus: '',
  searchQuery: {},
  activePanel: '',
  repeatOwnTransaction: {},
  repeatExchangeTransaction: {},
  repeatInternalTransaction: {},
  repeatInterbankTransaction: {},
  repeatSwiftTransaction: {},
  repeatSwiftRubTransaction: {},
  isTransactionActionModalOpen: false,
  actionModalType: '',
  deadline: 0,
};

const transactions = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    deadlineAC(state, action) {
      state.deadline = action.payload;
    },
    actionModalTypeAC(state, action) {
      state.actionModalType = action.payload;
    },
    transactionActionModalOpenAC(state, action) {
      state.isTransactionActionModalOpen = action.payload;
    },
    transactionsHistoryAC(state, action) {
      state.transactionsHistory = [];
      state.transactionsHistory = action.payload;
    },
    otpConfirmAC(state, action) {
      state.otpConfirm = action.payload;
    },
    verifyTransIdAC(state, action) {
      state.verifyTransId = action.payload;
    },
    ordersAC(state, action) {
      state.orders = action.payload;
    },
    transactionStatusAC(state, action) {
      state.transactionStatus = action.payload;
    },
    searchQueryAC(state, action) {
      state.searchQuery = action.payload;
    },
    activePanelAC(state, action) {
      state.activePanel = action.payload;
    },
    repeatOwnTransactionAC(state, action) {
      state.repeatOwnTransaction = action.payload;
    },
    repeatExchangeTransactionAC(state, action) {
      state.repeatExchangeTransaction = action.payload;
    },
    repeatInternalTransactionAC(state, action) {
      state.repeatInternalTransaction = action.payload;
    },
    repeatInterbankTransactionAC(state, action) {
      state.repeatInterbankTransaction = action.payload;
    },
    repeatSwiftTransactionAC(state, action) {
      state.repeatSwiftTransaction = action.payload;
    },
    repeatSwiftRubTransactionAC(state, action) {
      state.repeatSwiftRubTransaction = action.payload;
    },
  },
});

export const {
  repeatSwiftRubTransactionAC,
  repeatSwiftTransactionAC,
  transactionsHistoryAC,
  otpConfirmAC,
  verifyTransIdAC,
  ordersAC,
  transactionStatusAC,
  searchQueryAC,
  activePanelAC,
  repeatOwnTransactionAC,
  repeatInternalTransactionAC,
  repeatInterbankTransactionAC,
  transactionActionModalOpenAC,
  actionModalTypeAC,
  deadlineAC,
  repeatExchangeTransactionAC,
} = transactions.actions;

export default transactions.reducer;
