import { useTranslation } from 'react-i18next';
import pdfFile from '@components/Landing/documents/docs/pdf (2).png';
import { useAppDispatch } from '@hooks/redux';
import { getTarifFileApi } from '@http/global';

function Commissions() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <div className='flex justify-center'>
      <div className='lg:w-[1130px] w-full'>
        <div className='flex'>
          <div className='p-4 lg:p-8 w-full h-full bg-white mb-12'>
            <div>
              <div>
                <h1 className='text-2xl mb-2 font-semibold color-active'>{t('Tariffs')}</h1>

                <div className='mt-10 text-xs sm:text-base'>
                  <div className='flex grid grid-cols-8'>
                    <div className='col-span-2 text-xs sm:text-xl font-bold color-active'>
                      <p>{t('Bank account')}</p>
                    </div>
                    <div className='col-span-6' style={{ borderBottom: '1px solid #eee' }}>
                      <div className='w-full grid grid-cols-6 items-center'>
                        <p className='col-span-4'>{t('Opening, closing and keeping an account')}</p>
                        <p className='col-span-2 w-full bg-yellow-50 text-center text-sm max-sm:text-xs h-12 flex justify-center items-center'>
                          {t('Free')}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='flex grid grid-cols-8'>
                    <div className='col-span-2' />
                    <div className='col-span-6' style={{ borderBottom: '1px solid #eee' }}>
                      <div className='w-full grid grid-cols-6 items-center'>
                        <p className='col-span-4'>{t('Providing an extract')}</p>
                        <p className='col-span-2 w-full bg-yellow-50 text-center text-sm max-sm:text-xs h-12 flex justify-center items-center'>
                          {t('Free')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mt-10 text-xs sm:text-base'>
                  <div className='flex grid grid-cols-8'>
                    <div className='col-span-2 text-xs sm:text-xl font-bold color-active'>
                      <p>{t('Transfer')}</p>
                    </div>
                    <div className='col-span-6' style={{ borderBottom: '1px solid #eee' }}>
                      <div className='w-full grid grid-cols-6 items-center'>
                        <p className='col-span-4'>{t('Transfer between own accounts')}</p>
                        <p className='col-span-2 w-full bg-yellow-50 text-center text-sm max-sm:text-xs h-12 flex justify-center items-center'>
                          {t('Free')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='flex grid grid-cols-8'>
                    <div className='col-span-2' />
                    <div className='col-span-6' style={{ borderBottom: '1px solid #eee' }}>
                      <div className='w-full grid grid-cols-6 items-center'>
                        <p className='col-span-4'>{t('Intrabank payments')}</p>
                        <p className='col-span-2 w-full bg-yellow-50 text-center text-sm max-sm:text-xs h-12 flex justify-center items-center'>
                          {t('Free')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='flex grid grid-cols-8'>
                    <div className='col-span-2' />
                    <div className='col-span-6' style={{ borderBottom: '1px solid #eee' }}>
                      <div className='w-full grid grid-cols-6 items-center'>
                        <p className='col-span-4'>{t('Interbank payments')}</p>
                        <p className='col-span-2 w-full bg-yellow-50 text-center text-sm max-sm:text-xs h-12 flex justify-center items-center'>
                          {t('2 somoni')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='flex grid grid-cols-8'>
                    <div className='col-span-2' />
                    <div className='col-span-6' style={{ borderBottom: '1px solid #eee' }}>
                      <div className='w-full grid grid-cols-6 items-center'>
                        <p className='col-span-4'>{t('Swift transfer info')}</p>
                        <p className='col-span-2 w-full bg-yellow-50 text-center text-sm max-sm:text-xs h-12 flex justify-center items-center'>
                          {t('Swift cost')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-10'>
              <div className='flex flex-col gap-y-10 pl-5 text-base bg-white pt-10'>
                <div
                  onClick={e => {
                    e.preventDefault();
                  }}
                  className='flex gap-3  cursor-pointer hover:text-[#69B1FF]'
                >
                  <div className='my-auto '>
                    <img src={pdfFile} alt='' className='w-[35px]' />
                  </div>

                  <p className='color-active'>
                    <a href='#' onClick={() => dispatch(getTarifFileApi({ file_lang: 'tg' }))}>
                      Тарофаҳо барои хизматрасонӣ ба шахсони ҳуқуқӣ ва соҳибкорони инфиродӣ
                    </a>
                  </p>
                </div>
                <div
                  onClick={e => {
                    e.preventDefault();
                  }}
                  className='flex gap-3 cursor-pointer hover:text-[#69B1FF] '
                >
                  <div className='my-auto '>
                    <img src={pdfFile} alt='' className=' w-[35px] ' />
                  </div>
                  <p className='' onClick={() => dispatch(getTarifFileApi({ file_lang: 'ru' }))}>
                    <a href='#'>Тарифы для обслуживания юридических лиц и частных предпринимателей</a>
                  </p>
                </div>
                <div
                  onClick={e => {
                    e.preventDefault();
                  }}
                  className='flex gap-3 mb-5 cursor-pointer hover:text-[#69B1FF]'
                >
                  <div className='flex justify-center my-auto '>
                    <img src={pdfFile} alt='' className='w-[35px]' />
                  </div>
                  <p className='my-auto '>
                    <a href='#' onClick={() => dispatch(getTarifFileApi({ file_lang: 'en' }))}>
                      Rates for Corporate and Sole Proprietor Services
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Commissions;
