import { Tabs, Select, Button, Collapse } from 'antd';

import type { TabsProps } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  activePanelAC,
  repeatInterbankTransactionAC,
  repeatInternalTransactionAC,
  repeatOwnTransactionAC,
  repeatSwiftRubTransactionAC,
  repeatSwiftTransactionAC,
  transactionStatusAC,
} from '@store/reducers/transactions';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { deleteTemplateApi, getTemplatesApi } from '@http/user';
import { useNavigate } from 'react-router-dom';

const { Panel } = Collapse;

function Templates() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const templates = useAppSelector(state => state.userReducer.templates);
  const activePanel = useAppSelector(state => state.transactionsReducer.activePanel);

  const onChange = (type: string) => {
    dispatch(getTemplatesApi({ transaction_type: type }));
  };

  const openPanel = (key: any) => {
    dispatch(activePanelAC(key));
  };

  const items: TabsProps['items'] = [
    {
      key: 'INTERNAL',
      label: t('Internal'),
    },
    {
      key: 'INTERBANK',
      label: t('Interbank'),
    },
    {
      key: 'SWIFT',
      label: t('International'),
    },
    {
      key: 'SWIFT_RUB',
      label: t('Ruble transfer'),
    },
  ];

  useEffect(() => {
    dispatch(activePanelAC(''));
    dispatch(getTemplatesApi({ transaction_type: 'INTERNAL' }));
  }, []);

  return (
    <div className='flex justify-center'>
      <div className='lg:w-[1130px] w-full'>
        <div className='flex'>
          <div className='p-4 lg:p-8 w-full h-full bg-white mb-12'>
            <div>
              <h1 className='max-md:hidden text-2xl mb-2 font-semibold color-active'>{t('Templates')}</h1>
              <div className='flex justify-between md:hidden'>
                <h1 className='text-xl mb-2 font-semibold'>{t('Templates')}</h1>
                <Select
                  style={{ width: 210 }}
                  allowClear
                  placeholder={`${t('Status')}`}
                  onChange={status => dispatch(transactionStatusAC(status))}
                >
                  <Select.Option value='INTERNAL'>{t('Internal')}</Select.Option>
                  <Select.Option value='INTERBANK'>{t('Interbank')}</Select.Option>
                </Select>
              </div>
              <Tabs
                className='max-md:hidden color-active font-medium'
                defaultActiveKey=''
                items={items}
                onChange={onChange}
              />
              <div className='py-5'>
                {templates.length > 0 ? (
                  <Collapse activeKey={activePanel} onChange={openPanel} ghost className='p-0'>
                    {templates?.map((item: any) => (
                      <Panel
                        header={
                          <div className='flex justify-between items-center p-5 border-b cursor-pointer'>
                            <p>{item?.title}</p>
                            <div>
                              <Button
                                type='primary'
                                size='small'
                                danger
                                onClick={e => {
                                  dispatch(deleteTemplateApi({ id: item?.id }, item?.transaction_type));
                                  dispatch(activePanelAC({}));
                                  e.stopPropagation();
                                }}
                              >
                                {t('Delete')}
                              </Button>
                              <Button
                                className='ml-3'
                                size='small'
                                type='primary'
                                onClick={() => {
                                  navigate(`/payments/${item.transaction_type.toLowerCase()}`);
                                  if (item.transaction_type === 'OWN') {
                                    dispatch(repeatOwnTransactionAC(item));
                                  } else if (item.transaction_type === 'INTERNAL') {
                                    dispatch(repeatInternalTransactionAC(item));
                                  } else if (item.transaction_type === 'INTERBANK') {
                                    dispatch(repeatInterbankTransactionAC(item));
                                  } else if (item.transaction_type === 'SWIFT') {
                                    dispatch(repeatSwiftTransactionAC(item));
                                  } else if (item.transaction_type === 'SWIFT_RUB') {
                                    dispatch(repeatSwiftRubTransactionAC(item));
                                  }
                                }}
                              >
                                {t('Make a payment')}
                              </Button>
                            </div>
                          </div>
                        }
                        key={item?.id}
                      >
                        <div className='bg-slate-50'>
                          <div className='grid grid-cols-7 items-center py-5 max-sm:px-8 px-2 border-b lg:gap-5 max-sm:text-xs'>
                            <div />
                            <div className='max-sm:col-span-7 max-sm:leading-6 col-span-5 leading-8'>
                              <div className='grid grid-cols-6'>
                                <p className='col-span-2'>{t('Date of creation')}: </p>
                                <p className='ml-3 font-bold col-span-4'>{item?.created_at}</p>
                              </div>
                              <div className='grid grid-cols-6'>
                                <p className='col-span-2'>{t('Sender')}: </p>
                                <p className='ml-3 font-bold col-span-4'>{item?.sending_ac}</p>
                              </div>
                              <div className='grid grid-cols-6'>
                                <p className='col-span-2'>{t('Recipient')}: </p>
                                <p className='ml-3 font-bold col-span-4'>{item?.receiving_ac}</p>
                              </div>
                              <div className='grid grid-cols-6'>
                                <p className='col-span-2'>{t('Amount')}: </p>
                                <p className='ml-3 font-bold col-span-4'>
                                  {item?.sending_amount} {item?.sending_ccy}
                                </p>
                              </div>
                              {item?.transaction_type === 'INTERBANK' ? (
                                <div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Recipient')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.receiver_name}</p>
                                  </div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('TIN')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.tax_id}</p>
                                  </div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('BIC')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.bank_code}</p>
                                  </div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Document type')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.doc_type}</p>
                                  </div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Document number')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.doc_no}</p>
                                  </div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Document date')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.doc_date}</p>
                                  </div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Narrative')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.narrative}</p>
                                  </div>
                                </div>
                              ) : null}
                              {item?.transaction_type === 'SWIFT' ? (
                                <div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Recipient')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.receiver_name}</p>
                                  </div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Recipient address')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.receiver_address}</p>
                                  </div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Recipient country')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.receiver_bank_country}</p>
                                  </div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Recipient city')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.receiver_city}</p>
                                  </div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Recipient bank name')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.receiver_bank_name}</p>
                                  </div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Recipient bank swift')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.receiver_bank_swift}</p>
                                  </div>

                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Narrative')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.narrative}</p>
                                  </div>
                                </div>
                              ) : null}
                              {item?.transaction_type === 'SWIFT_RUB' ? (
                                <div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Recipient')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.receiver_name}</p>
                                  </div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Recipient address')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.receiver_address}</p>
                                  </div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Recipient bank name')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.receiver_bank_name}</p>
                                  </div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Recipient bank address')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.receiver_bank_address}</p>
                                  </div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('BIC')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.bank_code}</p>
                                  </div>
                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('KPP')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.receiver_kpp}</p>
                                  </div>

                                  <div className='grid grid-cols-6'>
                                    <p className='col-span-2'>{t('Narrative')}: </p>
                                    <p className='ml-3 font-bold col-span-4'>{item?.narrative}</p>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Panel>
                    ))}
                  </Collapse>
                ) : (
                  <div className='h-24 flex items-center justify-center'>
                    <p>{t('No Templates')}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Templates;
