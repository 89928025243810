import { $host } from './index';
import { AppDispatch } from '@store';
import { isSpinAC, notificationModalAC, otpLifeTimeAC } from '@store/reducers/app';
import {
  bulkTransactionInfoAC,
  bulkTransactionListAC,
  pickedBulkTransactionInfoAC,
  verifyFileIdAC,
} from '@store/reducers/bulkTransaction';

import { otpConfirmAC } from '@store/reducers/transactions';

const END_POINT = '/client';

const getBulkFilesApi = (params: any, page: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/bulk/files?page=${page}`, params);
    dispatch(bulkTransactionListAC(data));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const uploadBulkTransactionFileApi = (file: any, callback: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await $host.post(`${END_POINT}/transaction/bulk/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (data) {
      callback();
      dispatch(bulkTransactionInfoAC(data));
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const confirmBulkTransactionFileApi = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/bulk/upload/confirm`, params);
    if (data?.otp_required) {
      dispatch(otpConfirmAC(true));
      dispatch(verifyFileIdAC(data.file_id));
      dispatch(otpLifeTimeAC(Date.now() + 1000 * data.code_lifetime));
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getBulkTransactionInfoApi = (params: any, page: any, callback?: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/bulk/trns?page=${page}`, params);
    if (data) {
      dispatch(pickedBulkTransactionInfoAC(data));
      if (callback) {
        callback();
      }
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const dowloadOriginalBulkFileApi = (params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const response = await $host.post(`${END_POINT}/transaction/bulk/download`, params, {
      responseType: 'arraybuffer',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', '');
    link.click();
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

export {
  getBulkFilesApi,
  uploadBulkTransactionFileApi,
  confirmBulkTransactionFileApi,
  getBulkTransactionInfoApi,
  dowloadOriginalBulkFileApi,
};
