/* eslint import/no-anonymous-default-export: [2, {'allowObject': true}] */
export default {
  'header': {
    'main': 'Главная',
    'vozm': 'Возможности',
    'products': 'Продукты',
    'tarifs': 'Тарифы',
    'docs': 'Документы',
    'request': 'Отправить запрос',
    'logIn': 'Войти',
  },
  'mainPage': {
    'title': 'Банк в одном устройстве!',
    'desc': 'Контролируй свои финансы легко и удобно!',
    'request': 'Отправить запрос',
  },
  'vozm': {
    'mainTitle': 'Возможности Ориён Бизнеса',
    'title1': 'Простое подписание платежей с SМS- кодом',
    'title2': 'Доступ к платформе 24/7',
    'title3': 'Работает на любом устройстве',
  },
  'products': {
    'mainTitle': 'Продукты для вашего бизнеса',
    'title1': 'Visa business',
    'title2': 'Торговый эквайринг',
    'title3': 'Зарплатный проект',
    'title4': 'Расчётно-кассовое обслуживание',
    'title5': 'Сейфовые ячейки',
  },
  'tarifs': {
    'mainTitle': 'Тарифы',
    'title1': 'Стандартные',
    'title1Description': 'Это тариф стандарт',
    'title2': 'Платежи',
    'title2Description': 'Это тариф Платежи',
    'title3': 'Операции с платежами',
    'title3Description': 'Это тариф Операции с наличностью',
  },
  'documents': {
    'mainTitle': 'Документы',
    'title1': 'Тарифы',
    'downloadLink': 'Тарифы_для_обслуживания_юридических_лиц_и_частных_предпринимателей',
    'title2': 'Договор зарплатного проекта',
    'title2Description': '',
    'title3': 'Правила информационной безопасности',
    'title3Description': '',
    'title4': 'Валютный контроль',
    'title4Description': '',
  },
  'footer': {
    'forClients': {
      'title': 'Клиентам',
      'desc1': 'Платежи и переводы',
      'desc2': 'Кредиты',
      'desc3': 'Вклады',
      'desc4': 'Банковские карты',
    },
    'aboutBank': {
      'title': 'О банке',
      'desc1': 'Главная страница',
      'desc2': 'Работа в банке',
      'desc3': 'Помощь',
      'desc4': 'Карта сайта',
    },
    'products': {
      'title': 'Продукты',
      'desc1': 'OrienPay',
      'desc2': 'Мобильный банкинг',
      'desc3': 'Интернет банкинг',
    },
    'contacts': {
      'title': 'Контакты',
      'address': 'г. Душанбе, Рудаки, 95/1',
      'callCenter': 'Контактный центр 2222',
    },
  },
  'RECEIVED': 'Принят',
  'IN_PROGRESS': 'В обработке',
  'IN_VERIFICATION': 'СМС подтвеждение',
  'IN_CONFIRMATION': 'Ждет подтверждения',
  'READY': 'Готов',
  'SUCCESS': 'Успешно',
  'FAILED': 'Не успешно',
  'DECLINED': 'Отклонен',
  'INVALID_FILE': 'Не валидный',
  'INVALID': 'Не валидный',
  'IN_APPROVAL': 'На авторизации',
  'TO_APPROVE': 'Ждет авторизации',
  'APPROVED': 'Одобрен',
  'PARTIAL_SUCCESS': 'Частично проведен',
  'POSTPONED': 'Отложен',
  'IN_BANK_REVIEW': 'На расмотрении',
  'IN_REVIEW': ' На расмотрении',
  'BANK_APPROVED': 'Одобрен',
  'CANCELED': 'Отменен',
  'BANK_DECLINED': 'Отклонен банком',
  'REVERSED': 'Возвращен',
  'IN_VALIDATION': 'Проверяется',
  'READY_TO_PROCEED': 'Готов к проведению',
  'Language': 'Русский',
  'Main page': 'Главная',
  'Clients': 'Клиентов',
  'Legal entity': 'Юридические лицо',
  'Transactions': 'Транзакции',
  'Registration': 'Регистрация',
  'List': 'Список',
  'Restrictions': 'Ограничения',
  'Creating a client': 'Создание клиента',
  'Accounts': 'Счета',
  'Accesses': 'Доступы',
  'Filter': 'Фильтр',
  'By client ID': 'По ID - клиента',
  'By username': 'По имени пользователя',
  'By phone number': 'По номеру телефона',
  'Status': 'Статус',
  'Find': 'Поиск',
  'ID': 'ID',
  'Name': 'Название',
  'Surname': 'Фамилия',
  'Patronymic': 'Отчество',
  'Date of Birth': 'Дата рождения',
  'Phone number': 'Номер телефона',
  'Intrabank': 'Внутрибанковский',
  'Own accounts': 'Свои счета',
  'Salaries': 'Зарплаты',
  'Interbank': 'Межбанковский',
  'Interbank payment': 'Межбанковский перевод',
  'SWIFT': 'СВИФТ',
  'Ruble': 'Рублевый',
  'view': 'view',
  'init': 'init',
  'outh': 'outh',
  'Account': 'Счет',
  'Legal entities': 'Юридические лица',
  'Customer _ no': 'Номер клиента',
  'Company': 'Компания',
  'Number of employees': 'Количество сотрудников',
  'Transactions List of legal entity transactions': 'Транзакции  Список транзакций  юридических лиц',
  'Search': 'Поиск',
  'All': 'Все',
  'Sign in': 'Войти в систему',
  'Signing in': 'На подписании',
  'Processing': 'В обработке',
  'Not successful': 'Не успешные',
  'Successful': 'Успешные',
  'Date': 'Дата',
  'Sender': 'Отправитель',
  'Recipient': 'Получатель',
  'Sender account': 'Счет отправителя',
  'Recipient account': 'Счет получателя',
  'Transaction type': 'Тип транзакции',
  'Amount': 'Сумма',
  'Hello': 'Здравствуйте',
  'WannaEnter': 'Хотите войти в свой личный кабинет',
  'Login': 'Логин',
  'Password': 'Пароль',
  'Sign up': 'Зарегистрироваться',
  'Exchange Rates': 'Курс валют',
  'Orienbank': 'Ориёнбонк',
  'NBT': 'НБТ',
  'Currency': 'Валюта',
  'Purchase': 'Покупка',
  'Sale': 'Продажа',
  'USD': 'USD',
  'EUR': 'EUR',
  'RUB': 'RUB',
  'US dollars': 'Доллар',
  'Euro': 'Евро',
  'Russian ruble': 'Российский Рубль',
  'Successfully': 'Успешно',
  'All accounts': 'Все счета',
  'TJS': 'TJS',
  'Somoni': 'Сомони',
  'Waiting for authorization': 'Ждем авторизации',
  'Last operations': 'Последние операции',
  'Between own accounts': 'Между своими счетами',
  'Intrabank payment': 'Внутрибанковский платеж',
  'MyAccounts': 'Мои счета',
  'Active': 'Активный ',
  'Not active': 'Не активный',
  'Update': 'Обновить',
  'Updated': 'Обновлен',
  'Hidden': 'Скрыто',
  'Russian': 'Руский',
  'English': 'Англиский',
  'Tajik': 'Таджикский',
  'Bank accounts': 'Банковские счета',
  'Visa Business': 'Visa Business',
  'Payment Information': 'Информация о платеже',
  'Information': 'Информация',
  'Account Information': 'Информация о счете',
  'File information': 'Информация о файле',
  'Account name': 'Название счета',
  'Balance': 'Баланс',
  'Account type': 'Тип счета',
  'Term': 'Срочный',
  'Address': 'Адрес',
  'TIN': 'ИНН',
  'Date of opening': 'Дата открытия',
  'Requisites': 'Реквизиты',
  'Download': 'Скачать',
  'Upload': 'Загрузить',
  'Upload file': 'Загрузка файла',
  'Description': 'Описание',
  'Show filters': 'Показать фильтры',
  'Next': 'Далее',
  'No transaction': 'Транзакций нет',
  'TxID': 'ID транзакции',
  'Commission': 'Комиссия',
  'Create': 'Создать',
  'Date of creation': 'Дата создания',
  'Retry payment': 'Повторить платеж',
  'Download PDF': 'Скачать PDF',
  'Authorizers': 'Авторизаторы',
  'Reject': 'Отклонить',
  'Confirm': 'Подтвердить',
  'Approve': 'Одобрить',
  'Payment information': 'Сведения о платеже',
  'Bank information': 'Сведения о банке',
  'From account': 'Со счета',
  'To account': 'На счет',
  'Transfer amount': 'Сумма перевода',
  'Narrative': 'Назначение',
  'Transfer': 'Перевод',
  'Multiple transfers': 'Множественные переводы',
  'Template': 'Шаблон',
  'Existing template': 'Существующий шаблон',
  'Sender information': 'Сведения о отправителе',
  'Recipient information': 'Сведения о получателе ',
  'Number': 'Номер ',
  'Account number': 'Номер счета',
  'Confirm account number': 'Подтвердить номер счета',
  'Payment details': 'Подробности оплаты',
  'Other details': 'Другие детали',
  'Document type': 'Тип документа',
  'Document number': 'Номер документа',
  'Document date': 'Дата документа',
  'Save template': 'Сохранить шаблон',
  'Country': 'Страна',
  'City': 'Город',
  'About Bank': 'О банке',
  'Purpose of the bank': 'Назначение банка',
  'Enter amount': 'Введите сумму',
  'Document': 'Документ',
  'Enter document number': 'Введите номер документа',
  'Initiator': 'Инициатор',
  'Number of transactions': 'Количество транзакций',
  'Not valid': 'Не валидный',
  'Import': 'Импорт',
  'From the beginning': 'От начала',
  'To the end': 'До конца',
  'Rejected': 'Отклонен',
  'Importing payments from a file': 'Импорт платежей из файла',
  'Download template for multiple transactions': 'Скачать шаблон для множественных транзакций',
  'Download document': 'Загрузить документ',
  'Salary project': 'Зарплатный проект',
  'Not authorized': 'Не авторизован',
  'Select account': 'Выбрать счет',
  'Select date': 'Выбрать дату',
  'View': 'Просмотреть',
  'Order an statement': 'Заказать выписку',
  'View orders': 'Просмотреть заказы',
  'Orders': 'Заказы',
  'Ordering a statement': 'Заказ выписки',
  'Enter the title': 'Введите название',
  'Order': 'Заказать',
  'Ready': 'Готов',
  'In progress': 'Обрабатывается',
  'Failed': 'Неуспешно',
  'Period': 'Период',
  'Order date': 'Дата заказа',
  'Tariffs': 'Тарифы',
  'Bank account': 'Банковский счет',
  'free': 'Бесплатно',
  'Opening / Maintaining and closing a current account': 'Открытие / Ведение и закрытие текущего расчетного счета',
  'Receiving account statements': 'Получение выписки со счета',
  'Transfer between own accounts': 'Перевод между своими счетами',
  'Transfer to accounts at other banks': 'Перевод на счета в других банков',
  'Show all currencies': 'Показать все валюты',
  'Rate': 'Курс',
  'Currency сode': 'Код валюты',
  'Unit': 'Единица измерения',
  'Currency name': 'Наименавание валюты',
  'No.': '№',
  'Change Password': 'Смена пароля',
  'Log out': 'Выйти',
  'Profile': 'Профиль',
  'Notification': 'Уведомление',
  'Enter a new password': 'Введите новый пароль',
  'Enter old password': 'Введите старый пароль',
  'Сonfirm new password': 'Падвердите новый пароль',
  'Change': 'Поменять',
  'Products': 'Продукты',
  'Contacts': 'Контакты',
  'Home page': 'Главная страница',
  'Payments and transfers': 'Платежи и переводы',
  'OrienPay': 'OrienPay',
  'oriyon-business@oriyonbonk.tj': 'oriyon-business@oriyonbonk.tj',
  '95/1, Rudaki Ave., Dushanbe': 'г. Душанбе, пр. Рудаки, 95/1',
  'Call center 2222': 'Контактный центр 2222',
  'Bank jobs': 'Работа в банке',
  'Help': 'Помощь',
  'Site map': 'Карта сайта',
  'Loans': 'Кредиты',
  'Deposits': 'Вклады',
  'Bank cards': 'Банковские карты',
  'Mobile banking': 'Мобильный банкинг',
  'Internet banking': 'Интернет банкинг',
  'OtpCode': 'Код подтверждения',
  'ResendTime': 'Повторная отправка будет доступна через',
  'Second': 'секунд',
  'ResendCode': 'Отправить код ещё раз',
  'Save': 'Сохранить',
  'All operations': 'Все операции',
  'Update operations': 'Обновить операции',
  'Operation': 'Операция',
  'No Transactions': 'Транзакций нет',
  'Payments': 'Платежи',
  'More': 'Ещё',
  'CurrienciesToSomoni': 'Официальные курсы валют к сомони (НБТ):',
  'Hide all currencies': 'Скрыть все валюты',
  'ToSign': 'Подпишите',
  'Rejecteds': 'Отклоненные',
  'Enter Recipient Account': 'Введите счет получателя',
  'Confirm payment': 'Подтверждение платежа',
  'Payment type': 'Тип операции',
  'Enter Recipient': 'Введите получателя',
  'Enter TIN': 'Введите ИНН',
  'Select document type': 'Выберите тип документа',
  'Enter template name': 'Введите название шаблона',
  'Recipient TIN': 'ИНН получателя',
  'Show balance': 'Показать баланс',
  'Hide balance': 'Скрыть баланс',
  'Hide filters': 'Скрыть фильтр',
  'Swift': 'СВИФТ',
  'Bank name': 'Название банка',
  'Filters': 'Фильтры',
  'Show': 'Показать',
  'Select template': 'Выбрать шаблон',
  'One Dollar': '1 Доллар',
  'One Ruble': '1 Рубль',
  'One Euro': '1 Евро',
  'Orienbank Address': 'г.Душанбе, Рудаки, 95/1',
  'Call Center': 'Контактный центр',
  'Oriyonbank': 'Ориёнбонк',
  'Enter City': 'Введите город',
  'Enter Country': 'Введите страну',
  'Enter Address': 'Введите адрес',
  'Enter Account': 'Введите счет',
  'Enter swift code': 'Введите свифт код',
  'Enter bank name': 'Введите название банка',
  'Enter doc number': 'Введите номер документа',
  'Enter SMS code': 'Введите СМС код',
  'Find swift code': 'Найти свифт код',
  'Find bic code': 'Найти БИК код',
  'Required': 'Обязательное поле',
  'Enter symbol only': 'Ввод только символов',
  'Amount must be more 1': 'Сумма должна быть больше 1',
  'Wrong amount': 'Неправильная сумма',
  'Latin Only': 'Ввод только латиницы',
  'Account does not match': 'Введенный вами счет не совпадает!',
  'Account must contain no more than 20 digits': 'Счет должен содержать не больше 20 цифр',
  'Account must contain no more than 21 digits': 'Счет должен содержать не больше 21 цифр',
  'Account must contain no more than 35 digits': 'Счет должен содержать не больше 35 цифр',
  'Account must contain at least 20 digits': 'Счет должен содержать не меньше 20 цифр',
  'Account must contain at least 10 digits': 'Счет должен содержать не меньше 10 цифр',
  'Account must contain at least 8 digits': 'Счет должен содержать не меньше 8 цифр',
  'Enter number only': 'Ввод только цифр',
  'Enter letters only': 'Ввод только букв',
  'ConfirmSmsFromPhone': 'Для подтверждения транзакции на номер вашего телефона отправлен СМС код',
  'No accounts': 'Нет счетов',
  'Account does not found': 'Счет не найден!',
  'Must be 6 numbers': 'Должно быть 6 цифр',
  'Must be 9 numbers': 'Должно быть 9 цифр',
  'Login to account': 'Вход в кабинет',
  'Field must contain at least 8 digits': 'Поле должно содержать не менее 8 символов!',
  'For registration':
    'Для регистрации в системе Интернет-Банкинга, Вам необходимо обратиться в Головной офис Ориёнбонка, по адресу',
  'For any questions': 'По всем вопросам обращайтесь в наш Контактный центр мы с удовольствием поможем Вам',
  'Code': 'Код',
  'Sent code to phone for sequirity': 'Для защиты Вашего профиля мы отправим код подтверждения на Ваш номер телефона',
  'Sent code to email for sequirity': 'Для защиты Вашего профиля мы отправим код подтверждения на Вашу почту',
  'Change your password': 'Поменяйте свой пароль',
  'Password does not match': 'Введенный вами пароль не совпадает!',
  'Confirm password': 'Подтвердите пароль',
  'Change and remind password': 'Поменяйте и запомните свой пароль',
  'International': 'Валютный перевод',
  'File date': 'Дата файла',
  'Total amount': 'Общая сумма',
  'Debit': 'Дебит',
  'Credit': 'Кредит',
  'Own accounts payments': 'Транзакций между своими счетами',
  'Intrabank payments': 'Внутрибанковские платежи',
  'Interbank payments': 'Межбанковские платежи',
  'File name': 'Название файла',
  'Successful count': 'Количество успешных',
  'Not successful count': 'Количество неуспешных',
  'Download file': 'Скачать файл',
  'Error': 'Ошибка',
  'Transaction list': 'Список транзакций',
  'Branch': 'Филиал',
  'Card accounts': 'Карточные счета',
  'Approved': 'Одобрил',
  'From amount': 'Сумма от',
  'To amount': 'Сумма до',
  'Start date': 'Дата начала',
  'End date': 'Дата конца',
  'Start period': 'Начало периода',
  'End period': 'Конец периода',
  'No data': 'Нет данных',
  'Enter document type': 'Введите тип документа',
  'In confirmation': 'Ждет подтверждения',
  'In verification': 'На авторизации',
  'Partial success': 'Частично проведеные',
  'Write-off account': 'Счет списания',
  'Employees list': 'Список сотрудников',
  'Postpone': 'Отложить',
  'Import payments from file': 'Импорт платежей из файла',
  'Download salary file template': 'Скачать шаблон зарплатного файла',
  'Upload document': 'Загрузить документ',
  'The file format must be xlsx, xls': 'Формат файла должен быть xlsx, xls',
  'The file format must be txt': 'Формат файла должен быть txt',
  'The file size should not exceed 2MB': 'Размер файла не должен превышать 2мб',
  'FIO': 'ФИО',
  'Company name': 'Название компании',
  'First name': 'Имя пользователя',
  'Last name': 'Фамилия пользователя',
  'User phone number': 'Телефон пользователя',
  'International payment': 'Международный платеж',
  'Transaction date': 'Дата транзакции',
  'Back': 'Вернуться',
  'Repeat': 'Повторить',
  'Valid': 'Валидный',
  'Urgent': 'Срочный',
  'Edit account name': 'Редактировать название счета',
  'Maximum number of characters: 70': 'Максимальное количество символов: 70',
  'Confirm new password': 'Подтвердите новый пароль',
  'Exit': 'Выход',
  'Pick': 'Выбрать',
  'Enter number or letter only': 'Ввод только букв или цифр',
  'Sure to confirm?': 'Вы уверены что хотите подтвердить?',
  'Sure to decline?': 'Вы уверены что хотите отклонить?',
  'Cancel': 'Отмена',
  'Make a payment': 'Провести платеж',
  'Enter': 'Войти',
  'Insufficient funds in the account': 'Недостаточно средств на счете',
  'Templates': 'Шаблоны',
  'Internal': 'Внутрибанковский',
  'Delete': 'Удалить',
  'BIC': 'БИК',
  'No Templates': 'Шаблонов нет',
  'Sender and Recipient accounts must be same': 'Счет отправителя и получателя должны быть одной валюты',
  'Statement': 'Выписка',
  'Exchange': 'Конвертация',
  'Enter rate': 'Введите курс',
  'Credit amount': 'Сумма к снятию',
  'Debit amount': 'Сумма к пополнению',
  'Credit account': 'Счет снятия',
  'Debit account': 'Счет пополнения',
  'Beneficiary Bank': 'Банк получателя',
  'Exchange amount': 'Сумма к конвертации',
  'Print': 'Распечатать',
  'View all': 'Просмотреть все',
  'otpType': 'Выберите способ получения кода подтверждения',
  'withSMS': 'Через СМС',
  'withEmail': 'На почту',
  'Recipient country': 'Страна получателя',
  'Recipient city': 'Город получателя',
  'Recipient address': 'Адрес получателя',
  'Recipient bank address': 'Адрес банка получателя',
  'Recipient bank name': 'Название банка получателя',
  'Recipient bank swift': 'СВИФТ код банка получателя',
  'onlyTwoMonths': 'Не более двух месяцев',
  'Oriyon': 'Ориён',
  'Business': 'Бизнес',
  'Account Balances': 'Остатки счетов',
  'Account Balances in somoni': 'Остатки счетов в сомони',
  'Account Balances in dollars': 'Остатки счетов в долларах',
  'Account Balances in euro': 'Остатки счетов в евро',
  'Account Balances in ruble': 'Остатки счетов в рублях',
  'Account Balances in riyal': 'Остатки счетов в риялах',
  'Account Balances in yuan': 'Остатки счетов в юанях',
  'Free': 'бесплатно',
  'Providing an extract': 'Предоставление выписки по счету по мере совершения операций',
  'Opening, closing and keeping an account': 'Открытие / Ведение и закрытие расчетного счета',
  'Swift transfer info': 'Переводы средств через международные системы денежных переводов',
  'Swift cost': 'в соответствии с тарифами международных систем денежных переводов',
  'Select format': 'Выберите формат',
  'Format': 'Формат',
  'Enter bic code': 'Выберите БИК код',
  'Ruble transfer': 'Рублевый перевод',
  'PrepaymentForProduct': 'Предоплата за товар',
  'PaymentForProduct': 'Оплата за товар',
  'PrepaymentForService': 'Предоплата за услуги',
  'PaymentForService': 'Оплата за услуги',
  'Others': 'Прочие',
  'Select narrative': 'Выберите назначение',
  'EditionalInfo': 'Дополнительная информация',
  'Enter KPP': 'Введите КПП',
  'City address': 'Город, адрес',
  'Correspondent account': 'Счет корреспондента',
  'All currencies': 'Все валюты',
  'Swift narrative': 'Только заглавные буквы и символы . » « №',
  '2 somoni': '2 сомони',
  'Download extract': 'Скачать выписку',
  'Closed': 'Закрыт',
  'Loan amount': 'Сумма кредита',
  'Loan start date': 'Дата выдачи',
  'Loan end date': 'Дата окончания',
  'Loan balance': 'Остаток кредита',
  'Loan Information': 'Информация о кредите',
  'Loan type': 'Тип кредита',
  'Download payment schedule': 'Скачать график платежей',
  'Accredits': 'Аккредитив',
  'Overdraft': 'Овердрафт',
  'No loans': 'Кредитов нет',
  'Next payment date': 'Дата следующего погашения',
  'Amount due': 'Сумма погашения',
  'Open new deposit': 'Открыть новый вклад',
  'Deposit owner': 'Владелец депозита',
  'Deposit Information': 'Информация о депозите',
  'Month': 'Месяц',
  'Percent': 'Процент',
  'No deposits': 'Вкладов нет',
  'Counterparties': 'Контрагенты',
  'Salary': 'Зарплата',
  'Add': 'Добавить',
  'New counterparty': 'Новый контрагент',
  'Individuals': 'Физические лица',
  'Foreigs': 'Иностранные контрагенты',
  'Residents': 'Резиденты',
  'Footer': {
    'address':
      'Республика Таджикистан, г. Душанбе, проспект Рудаки 95/1 К/С: 20402972413691, ИНН: 020003038, БИК: 350101369',
    'emails': 'Эл. адрес',
    'tj': 'Для звонков по Таджикистану',
    'world': 'Для звонков из любой точки мира',
    'nbt': 'Горячая линия Национального Банка Таджикистана',
    'workTime': 'Рабочее время',
    'workDays': 'Рабочие дни: с понедельника по пятницу с 08:00 до 17:00',
    'saturday': 'Суббота: выходной',
    'sunday': 'Воскресенье: выходной',
    'receptionOfCitizens': 'Прием граждан: каждую субботу',
    'orb': 'ОАО "Ориёнбонк"',
    'license': 'Лицензия НБТ №0000004 от 30 января 2014г',
    'callcenter': 'Контактный центр Ориёнбонка',
    'supportNumber': 'Служба поддержки Ориенбизнес',
    'dayOf': 'Суббота и Воскресенье - выходные',
  },
};
