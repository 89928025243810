import { useAppSelector } from '@hooks/redux';
import { useTranslation } from 'react-i18next';

export const AllRates = () => {
  const { t } = useTranslation();
  const nbtRates = useAppSelector(state => state.globalReducer.nbtRates);
  return (
    <div>
      <p className='font-bold color-active text-lg mb-5'>{t('CurrienciesToSomoni')}</p>
      <div className='grid grid-cols-12 border-b p-3 gap-3 text-sm' style={{ borderBottom: '1px solid #eee' }}>
        <p className='col-span-1 font-semibold'>№</p>
        <p className='col-span-2 font-semibold'>{t('Currency сode')}</p>
        <p className='col-span-3 font-semibold'>{t('Unit')}</p>
        <p className='col-span-4 font-semibold'>{t('Currency name')}</p>
        <p className='col-span-2 font-semibold'>{t('Rate')}</p>
      </div>
      {nbtRates.map((item: any, index: any) => (
        <div
          className={`${index % 2 === 0 ? 'bg-slate-100' : null} grid grid-cols-12 p-3 gap-3 text-sm color-active`}
          style={{ borderBottom: '1px solid #eee' }}
        >
          <span className='col-span-1'>{index + 1}</span>
          <span className='col-span-2'>{item.code}</span>
          <span className='col-span-3'>{item.unit}</span>
          <span className='col-span-4'>{item.name}</span>
          <span className='col-span-2'>{item?.rate}</span>
        </div>
      ))}
    </div>
  );
};
