import { Divider, Input, Tabs } from 'antd';
import { useAppSelector } from '@hooks/redux';
import userProfileIcon from '@assets/images/profileIcon.png';
import ChangePassword from './ChangePassword';
import UserInfo from './UserInfo';
import { useTranslation } from 'react-i18next';

function Profile() {
  const user = useAppSelector(state => state.appReducer.user);
  const { t } = useTranslation();

  const items = [
    {
      key: 'userInfo',
      label: t('Information'),
      children: <UserInfo />,
    },
    {
      key: 'changePassword',
      label: t('Change Password'),
      children: <ChangePassword />,
    },
  ];

  return (
    <div className='flex justify-center'>
      <div style={{ width: '1130px' }}>
        <div className='flex'>
          <div className='py-5 px-8 w-full h-full bg-white mb-12' style={{ minHeight: '600px' }}>
            <div className='mt-5'>
              <div>
                <h1 className='text-2xl mb-2 font-semibold color-active'>{t('Profile')}</h1>
                <Divider />
              </div>
              <div className='grid grid-cols-8 gap-7'>
                <div className='col-span-2 p-5 rounded'>
                  <div className='flex justify-center items-center'>
                    <div className='flex justify-center items-center'>
                      <img className='w-16' src={userProfileIcon} alt='' />
                    </div>
                  </div>
                  <h2 className='text-center font-semibold mt-4'>{user.username}</h2>
                  <Divider />
                </div>
                <div className='col-span-6'>
                  <Tabs defaultActiveKey='1' items={items} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
