import { $host } from './index';
import { AppDispatch } from '@store';
import { loansListAC, loanStatementAC } from '@store/reducers/loans';
import { isSpinAC, notificationModalAC } from '@store/reducers/app';

const END_POINT = '/client';

const getloansListApi = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/loan/list`);
    dispatch(loansListAC(data));
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getLoanStatementApi = (query: any, page: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loanStatementAC([]));
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/loan/statement?page=${page}`, query);
    dispatch(loanStatementAC(data));
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getLoanPaymentScheduleApi = (query: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/loan/payment/schedule`, query);
  } finally {
    dispatch(isSpinAC(false));
  }
};

const downloadLoanPaymentScheduleApi = (params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/loan/payment/schedule/file`, params, {
      responseType: 'arraybuffer',
    });
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/xlsx' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'График платежей.xlsx');
    link.click();
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

export { getloansListApi, getLoanStatementApi, getLoanPaymentScheduleApi, downloadLoanPaymentScheduleApi };
