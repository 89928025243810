import { Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { recipientAccountsModalOpenAC } from '@store/reducers/accounts';
import digitNumber from '@utils/digitNumber';

const RecipientAccountsModal = ({ accounts, selectHandler }: any) => {
  const { t } = useTranslation();
  const recipientAccountsModalOpen = useAppSelector(state => state.accountsReducer.recipientAccountsModalOpen);
  const selectedRecipientAccount = useAppSelector(state => state.accountsReducer.selectedRecipientAccount);

  const dispatch = useAppDispatch();

  return (
    <div>
      <Modal
        width={600}
        title={t('All accounts')}
        open={recipientAccountsModalOpen}
        onOk={() => dispatch(recipientAccountsModalOpenAC(false))}
        onCancel={() => dispatch(recipientAccountsModalOpenAC(false))}
        footer={null}
      >
        <div className='h-96 overflow-auto'>
          <div className='my-5'>
            <Select
              style={{ width: '100%' }}
              showSearch
              optionFilterProp='children'
              onChange={(_, item) => selectHandler(item)}
              filterOption={(input: any, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={accounts.map((account: any) => ({
                value: account.value,
                label: account.label,
                ccy: account.ccy,
                account: account.value,
              }))}
            />
          </div>
          {accounts.length > 0 ? (
            <div>
              {accounts.map((item: any) => (
                <div key={item.value} onClick={() => selectHandler(item)}>
                  <div
                    className={`p-3 lg:p-5 flex justify-between gap-2 border-b cursor-pointer listItem ${
                      selectedRecipientAccount === item.value ? 'bg-yellow-50' : null
                    }`}
                  >
                    <div>
                      <p className='lg:text-lg font-medium'>{item.value}</p>
                      <p className='text-sm text-slate-500'>{item.name}</p>
                    </div>
                    <div>
                      <p className='lg:text-base font-medium text-end'>
                        {digitNumber(item.acy_avl_bal)} <span className='font-bold'>{item.ccy}</span>
                      </p>
                      <div>
                        {item.record_stat === 'O' ? (
                          <div>
                            <div className='flex items-center justify-end'>
                              <div className='bg-green-500 h-3 w-3 mr-3 rounded-full' />
                              <p className='lg:text-base'>{t('Active')}</p>
                            </div>
                          </div>
                        ) : (
                          <div className='flex items-center justify-end'>
                            <div className='bg-red-400 h-3 w-3 mr-3 rounded-full' />
                            <p className='lg:text-base'>{t('Not active')}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className='h-24 text-base flex items-center justify-center'>
              <p>{t('No accounts')}</p>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default RecipientAccountsModal;
