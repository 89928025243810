import React from 'react';
import dots from '@assets/images/dots.svg';
import visa from './img/VISA.png';
import ekvairing from './img/ekvairing.png';
import salary from './img/sallary.png';
import kassa from './img/kassa2.png';
import safe from './img/safe2.png';
import { Button } from 'antd';
import eclipse from './img/productEclipse.svg';
import eclipse2 from './img/EllipseSafe.svg';
import { useTranslation } from 'react-i18next';
import { requestInfoModalAC } from '@store/reducers/app';
import { useAppDispatch } from '@hooks/redux';

const Products = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const arr = [
    {
      id: 1,
      text: 'Visa business',
      img: visa,
    },
    {
      id: 2,
      text: t('products.title2'),
      img: ekvairing,
    },
    {
      id: 3,
      text: t('products.title3'),
      img: salary,
    },
    {
      id: 4,
      text: t('products.title4'),
      img: kassa,
    },
  ];

  const arr2 = [
    {
      id: 1,
      text: 'Visa business',
      img: visa,
    },
    {
      id: 2,
      text: t('products.title2'),
      img: ekvairing,
    },
    {
      id: 3,
      text: t('products.title3'),
      img: salary,
    },
    {
      id: 4,
      text: t('products.title4'),
      img: kassa,
    },
  ];

  return (
    <div className='mx-4'>
      <h1 className='lg:text-3xl text-2xl font-semibold mb-10 lg:mb-14 text-center'>{t('products.mainTitle')}</h1>
      <div className='sm:grid grid-cols-2 hidden mx-auto gap-7'>
        {arr.map(e => (
          <div
            key={e.id}
            className='relative hover:scale-[1.02] w-[100%] transition-transform flex max-lg:flex-wrap justify-around bg-white rounded-3xl'
          >
            <div className='z-20 py-8'>
              <div className='flex gap-5 justify-center  '>
                <img src={dots} alt='' />
                <h1 className='text-[20px] w-[225px] md:text-[18px] leading-6 font-semibold'>{e.text}</h1>
              </div>
              <div className=''>
                <img src={e.img} alt='' className='w-2/3 h-[180px]' />
              </div>
            </div>
            <div className='flex justify-items-end max-md:justify-center mt-auto py-7 z-10 '>
              <Button type='primary' size='large' onClick={() => dispatch(requestInfoModalAC(true))}>
                {t('mainPage.request')}
              </Button>
            </div>
            <img src={eclipse} alt='' className='absolute bottom-0 left-0 z-0 rounded-l-3xl max-lg:w-full' />
          </div>
        ))}
      </div>
      <div className='flex sm:hidden overflow-x-auto  mx-auto gap-5 pb-8'>
        {arr2.map(e => (
          <div
            key={e.id}
            className=' relative hover:scale-[1.02] sm:hidden flex flex-col transition-transform  justify-between md:flex-col bg-white w-[546px] md:min-w-[306px]  md: rounded-3xl mx-auto'
          >
            <div className='z-20 p-[10px] '>
              <div className='flex gap-5 md:px-5 md:pb-3 md: justify-center pt-4 md:pt-5  '>
                <img src={dots} alt='' />
                <h1 className='text-lg font-semibold w-[225px] md:text-[18px] leading-6'>{e.text}</h1>
              </div>
              <div className='flex justify-center  my-auto '>
                <img src={e.img} alt='' className='w-1/2 h-[150px]' />
              </div>
            </div>
            <div className='flex justify-center py-7 md:pb-5 px-5 z-10 '>
              <Button type='primary' size='large' onClick={() => dispatch(requestInfoModalAC(true))}>
                {t('mainPage.request')}
              </Button>
            </div>
            <img src={eclipse} alt='' className='absolute bottom-0 left-0 z-0 rounded-l-3xl max-lg:w-full' />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
