import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@hooks/redux';
import { formatDate } from '@utils/formatDate';

function DepositInfo() {
  const { t } = useTranslation();
  const depositInfo = useAppSelector(state => state.depositsReducer.depositInfo);

  return (
    <div className='bg-orange-50 p-5 rounded text-sm'>
      <h1 className='text-lg font-semibold'>{t('Deposit Information')}</h1>
      <div className='mt-5'>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Deposit owner')}:</p>
          <p className='font-medium'>{depositInfo?.full_name}</p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Account')}:</p>
          <p className='font-medium'>{depositInfo?.acc}</p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Period')}:</p>
          <p className='font-medium'>
            {depositInfo?.month} {t('Month')}
          </p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Percent')}:</p>
          <p className='font-medium'>{depositInfo?.perc}%</p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Start period')}:</p>
          <p className='font-medium'>{formatDate(depositInfo?.int_start_date)}</p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('End period')}:</p>
          <p className='font-medium'>{formatDate(depositInfo?.maturity_date)}</p>
        </div>
      </div>
    </div>
  );
}

export default DepositInfo;
