/* eslint import/no-anonymous-default-export: [2, {'allowObject': true}] */
export default {
  'header': {
    'main': 'Main',
    'vozm': 'Possibilities',
    'products': 'Products',
    'tarifs': 'Tarifs',
    'docs': 'Documents',
    'request': 'Send a request',
    'logIn': 'Log in',
  },
  'mainPage': {
    'title': 'Bank in one device!',
    'desc': 'Control your finances easily and conveniently!',
    'request': 'Send a request',
  },
  'vozm': {
    'mainTitle': 'Oriyon business opportunities',
    'title1': 'Simple signing of payments with SMS code',
    'title2': 'Access to the platform 24/7',
    'title3': 'Works on any device',
  },
  'products': {
    'mainTitle': 'Products for your business',
    'title1': 'Visa business',
    'title2': 'Trade acquiring',
    'title3': 'Salary project',
    'title4': 'Settlement and cash services',
    'title5': 'Safe deposit boxes',
  },
  'tarifs': {
    'mainTitle': 'Rates',
    'title1': 'Standard',
    'title1Description': '',
    'title2': 'Payments',
    'title2Description': '',
    'title3': 'Payment transactions',
    'title3Description': '',
  },
  'documents': {
    'mainTitle': 'Documents',
    'title1': 'Rates',
    'downloadLink': 'RATES FOR SERVICING LEGAL ENTITIES',
    'title2': 'Salary project agreement',
    'title2Description': '',
    'title3': 'Information security rules',
    'title3Description': '',
    'title4': 'Currency control',
    'title4Description': '',
  },
  'RECEIVED': 'Received',
  'IN_PROGRESS': 'In progress',
  'IN_VERIFICATION': 'In verification',
  'IN_CONFIRMATION': 'In confirmation',
  'READY': 'Ready',
  'SUCCESS': 'Success',
  'FAILED': 'Failed',
  'DECLINED': 'Declined',
  'INVALID_FILE': 'Invalid file',
  'INVALID': 'Invalid',
  'IN_APPROVAL': 'In approval',
  'TO_APPROVE': 'To approve',
  'APPROVED': 'Approved',
  'PARTIAL_SUCCESS': 'Partial success',
  'POSTPONED': 'Postponed',
  'IN_BANK_REVIEW': 'In bank review',
  'IN_REVIEW': 'In review',
  'BANK_APPROVED': 'Bank approved',
  'CANCELED': 'Canceled',
  'BANK_DECLINED': 'Bank declined',
  'REVERSED': 'Reversed',
  'IN_VALIDATION': 'In validation',
  'READY_TO_PROCEED': 'Ready to proceed',
  'Language': 'English',
  'Main page': 'Main page',
  'Clients': 'Clients',
  'Legal entity': 'Legal entity',
  'Transactions': 'Transactions',
  'Registration': 'Registration',
  'List': 'List',
  'Restrictions': 'Restrictions',
  'Creating a client': 'Creating a client',
  'Accounts': 'Accounts',
  'Accesses': 'Accesses',
  'Filter': 'Filter',
  'By client ID': 'By client ID',
  'By username': 'By username',
  'By phone number': 'By phone number',
  'Status': 'Status',
  'Find': 'Find',
  'ID': 'ID',
  'Name': 'Name',
  'Surname': 'Surname',
  'Patronymic': 'Patronymic',
  'Date of birth': 'Date of birth',
  'Phone number': 'Phone number',
  'Intrabank': 'Intrabank',
  'Own accounts': 'Own accounts',
  'Salaries': 'Salaries',
  'Interbank': 'Interbank',
  'Interbank payment': 'Interbank payment',
  'SWIFT': 'SWIFT',
  'Ruble': 'Ruble',
  'view': 'view',
  'init': 'init',
  'outh': 'outh',
  'Account': 'Account',
  'Legal entities': 'Legal entities',
  'Customer _ no': 'Customer _ no',
  'Company': 'Company',
  'Number of employees': 'Number of employees',
  'Transactions List of legal entity transactions': 'Transactions List of legal entity transactions',
  'Search': 'Search',
  'All': 'All',
  'Sign in': 'Sign in',
  'Signing in': 'Signing in',
  'Processing': 'Processing',
  'Not successful': 'Not successful',
  'Successful': 'Successful',
  'Date': 'Date',
  'Sender': 'Sender',
  'Recipient': 'Recipient',
  'Sender account': 'Sender acccount',
  'Recipient account': 'Recipient account',
  'Transaction type': 'Transaction type',
  'Amount': 'Amount',
  'Hello': 'Hello',
  'WannaEnter': 'Do you want to log into your personal account?',
  'Login': 'Login',
  'Password': 'Password',
  'Sign up': 'Sign up',
  'Exchange Rates': 'Exchange Rates',
  'Orienbank': 'Orienbank',
  'NBT': 'NBT',
  'Currency': 'Currency',
  'Purchase': 'Buy',
  'Sale': 'Sale',
  'USD': 'USD',
  'EUR': 'EUR',
  'RUB': 'RUB',
  'US dollars': 'US dollars',
  'Euro': 'Euro',
  'Russian ruble': 'Russian ruble',
  'Successfully': 'Successfully',
  'All accounts': 'All accounts',
  'TJS': 'TJS',
  'Somoni': 'Somoni',
  'Waiting for authorization': 'Waiting for authorization',
  'Last operations': 'Last operations',
  'Between own accounts': 'Between own accounts',
  'Intrabank payment': 'Intrabank payment',
  'MyAccounts': 'My accounts',
  'Active': 'Active',
  'Not active': 'Not active',
  'Update': 'Update',
  'Updated': 'Updated',
  'Hidden': 'Hidden',
  'Russian': 'Russian',
  'English': 'English',
  'Tajik': 'Tajik',
  'Bank accounts': 'Bank accounts',
  'Visa Business': 'Visa Business',
  'Payment Information': 'Payment Information',
  'Information': 'Information',
  'Account Information': 'Account Information',
  'File information': 'File Information',
  'Account name': 'Account name',
  'Balance': 'Balance',
  'Account type': 'Account type',
  'Term': 'Term',
  'Address': 'Address',
  'TIN': 'TIN',
  'Date of opening': 'Date of opening',
  'Requisites': 'Requisites',
  'Download': 'Download',
  'Upload': 'Upload',
  'Upload file': 'Upload file',
  'Description': 'Description',
  'Show filters': 'Show filters',
  'Next': 'Next',
  'No transaction': 'No transaction',
  'TxID': 'TxID',
  'Commission': 'Commission',
  'Create': 'Create',
  'Date of creation': 'Date of creation',
  'Retry payment': 'Retry payment',
  'Download PDF': 'Download PDF',
  'Authorizers': 'Authorizers',
  'Reject': 'Reject',
  'Confirm': 'Confirm',
  'Approve': 'Approve',
  'Payment information': 'Payment information',
  'Bank information': 'Bank information',
  'From account': 'From account',
  'To account': 'To account',
  'Transfer amount': 'Transfer amount',
  'Narrative': 'Narrative',
  'Transfer': 'Transfer',
  'Multiple transfers': 'Multiple transfers',
  'Template': 'Template',
  'Existing template': 'Existing template',
  'Sender information': 'Sender information',
  'Recipient information': 'Recipient information',
  'Number': 'Number',
  'Account number': 'Account number',
  'Confirm account number': 'Confirm account number',
  'Payment details': 'Payment details',
  'Other details': 'Other details',
  'Document type': 'Document type',
  'Document number': 'Document number',
  'Document date': 'Document date',
  'Save template': 'Save template',
  'Country': 'Country',
  'City': 'City',
  'About Bank': 'About Bank',
  'Purpose of the bank': 'Purpose of the bank',
  'Enter amount': 'Enter amount',
  'Document': 'Document',
  'Enter document number': 'Enter document number',
  'Initiator': 'Initiator',
  'Number of transactions': 'Number of transactions',
  'Not valid': 'Not valid',
  'Import': 'Import',
  'From the beginning': 'From the beginning',
  'To the end': 'To the end',
  'Rejected': 'Rejected',
  'Importing payments from a file': 'Importing payments from a file',
  'Download template for multiple transactions': 'Download template for multiple transactions',
  'Download document': 'Download document',
  'Salary project': 'Salary project',
  'Not authorized': 'Not authorized',
  'Select account': 'Select account',
  'Select date': 'Select date',
  'View': 'View',
  'Order an statement': 'Order an statement',
  'View reports': 'View reports',
  'View orders': 'View orders',
  'Ordering a statement': 'Ordering a statement',
  'Enter the title': 'Enter the title',
  'Order': 'Order',
  'Ready': 'Ready',
  'Failed': 'Failed',
  'In progress': 'In progress',
  'Period': 'Period',
  'Order date': 'Order date',
  'Orders': 'Orders',
  'Tariffs': 'Tariffs',
  'Bank account': 'Bank account',
  'free': 'free',
  'Opening / Maintaining and closing a current account': 'Opening / Maintaining and closing a current account',
  'Receiving account statements': 'Receiving account statements',
  'Transfer between own accounts': 'Transfer between own accounts',
  'Transfer to accounts at other banks': 'Transfer to accounts at other banks',
  'Show all currencies': 'Show all currencies',
  'Rate': 'Rate',
  'Currency code': 'Currency code',
  'Unit': 'Unit',
  'Currency name (all currencies must be written)': 'Currency name (all currencies must be written)',
  'No.': 'No.',
  'Change Password': 'Change Password',
  'Log out': 'Log out',
  'Profile': 'Profile',
  'Notification': 'Notification',
  'Enter a new password': 'Enter a new password',
  'Enter old password': 'Enter old password',
  'Confirm new password': 'Confirm new password',
  'Change': 'Change',
  'Products': 'Products',
  'Contacts': 'Contacts',
  'Home page': 'Home page',
  'Payments and transfers': 'Payments and transfers',
  'OrienPay': 'OrienPay',
  'oriyon-business@oriyonbonk.tj': 'oriyon-business@oriyonbonk.tj',
  '95/1, Rudaki Ave., Dushanbe': '95/1, Rudaki Ave., Dushanbe',
  'Call center 2222': 'Call center 2222',
  'Bank jobs': 'Bank jobs',
  'Help': 'Help',
  'Site map': 'Site map',
  'Loans': 'Loans',
  'Deposits': 'Deposits',
  'Bank cards': 'Bank cards',
  'Mobile banking': 'Mobile banking',
  'Internet banking': 'Internet banking',
  'OtpCode': 'Confirmation code',
  'ResendTime': 'Resending will be available via',
  'Second': 'second',
  'ResendCode': 'Resend code',
  'Save': 'Save',
  'All operations': 'All operations',
  'Update operations': 'Update operations',
  'Operation': 'Operation',
  'No Transactions': 'No Transactions',
  'Payments': 'Payments',
  'More': 'More',
  'CurrienciesToSomoni': 'Official exchange rate of currencies against Somoni:',
  'Hide all currencies': 'Hide all currencies',
  'ToSign': 'To sign',
  'Rejecteds': 'Rejected',
  'Enter Recipient Account': 'Enter Recipient Account',
  'Confirm payment': 'Confirm payment',
  'Payment type': 'Payment type',
  'Enter Recipient': 'Enter Recipient',
  'Enter TIN': 'Enter TIN',
  'Select document type': 'Select document type',
  'Enter template name': 'Enter template name',
  'Recipient TIN': 'Recipient TIN',
  'Show balance': 'Show balance',
  'Hide balance': 'Hide balance',
  'Hide filters': 'Hide filters',
  'Swift': 'Swift',
  'Bank name': 'Bank name',
  'Filters': 'Filters',
  'Show': 'Show',
  'Select template': 'Select template',
  'One Dollar': '1 Dollar',
  'One Ruble': '1 Ruble',
  'One Euro': '1 Euro',
  'Orienbank Address': 'Dushanbe, Rudaki Avenue 95/1',
  'Call Center': 'Call center',
  'Oriyonbank': 'Oriyonbank',
  'Enter City': 'Enter City',
  'Enter Country': 'Enter Country',
  'Enter Address': 'Enter Address',
  'Enter Account': 'Enter Account',
  'Enter swift code': 'Enter swift code',
  'Enter bank name': 'Enter bank name',
  'Enter doc number': 'Enter doc number',
  'Enter SMS code': 'Enter SMS code',
  'Find swift code': 'Find swift code',
  'Find bic code': 'Find bic code',
  'Required': 'Required',
  'Enter symbol only': 'Enter symbol only',
  'Amount must be more 1': 'Amount must be more 1',
  'Wrong amount': 'Wrong amount',
  'Latin Only': 'Latin Only',
  'Account does not match': 'Account does not match!',
  'Account must contain no more than 20 digits': 'Account must contain no more than 20 digits',
  'Account must contain no more than 21 digits': 'Account must contain no more than 21 digits',
  'Account must contain no more than 35 digits': 'Account must contain no more than 35 digits',
  'Account must contain at least 8 digits': 'Account must contain at least 8 digits',
  'Account must contain at least 10 digits': 'Account must contain at least 10 digits',
  'Account must contain at least 20 digits': 'Account must contain at least 20 digits',
  'Enter number only': 'Enter number only',
  'Enter letters only': 'Enter letters only',
  'ConfirmSmsFromPhone': 'To confirm the transaction, an SMS code has been sent to your phone number',
  'No accounts': 'No accounts',
  'Account does not found': 'Account does not found!',
  'Must be 6 numbers': 'Must be 6 numbers',
  'Must be 9 numbers': 'Must be 9 numbers',
  'Login to account': 'Login to account',
  'Field must contain at least 8 digits': 'Field must contain at least 8 digits!',
  'For registration':
    'To register in the Internet Banking system, you need to contact the central office of Orienbank, at',
  'For any questions': 'For any questions, please contact our contact center, we will be happy to help you',
  'Code': 'Code',
  'Sent code to phone for sequirity': 'To protect your profile, we will send a verification code to your phone number',
  'Sent code to email for sequirity': 'To protect your profile, we will send a verification code to your email',
  'Change your password': 'Change your password',
  'Password does not match': 'Password does not match',
  'Confirm password': 'Confirm password',
  'Change and remind password': 'Change and remember your password',
  'International': 'International transfer',
  'File date': 'File date',
  'Total amount': 'Total amount',
  'Debit': 'Debit',
  'Credit': 'Debit',
  'Own accounts payments': 'Own accounts payments',
  'Intrabank payments': 'Intrabank payments',
  'Interbank payments': 'Interbank payments',
  'File name': 'File name',
  'Successful count': 'Successful count',
  'Not successful count': 'Not successful count',
  'Download file': 'Download file',
  'Error': 'Error',
  'Transaction list': 'Transaction list',
  'Branch': 'Branch',
  'Approved': 'Approved',
  'From amount': 'From amount',
  'To amount': 'To amount',
  'Start date': 'Start date',
  'End date': 'End date',
  'Start period': 'Start period',
  'End period': 'End Period',
  'No data': 'No data',
  'Enter document type': 'Enter document type',
  'In confirmation': 'In confirmation',
  'In verification': 'In verification',
  'Partial success': 'Partial success',
  'Write-off account': 'Write-off account',
  'Employees list': 'Employees list',
  'Postpone': 'Postpone',
  'Import payments from file': 'Import payments from file',
  'Download salary file template': 'Download salary file template',
  'Upload document': 'Upload document',
  'The file format must be xlsx, xls': 'The file format must be xlsx, xls',
  'The file format must be txt': 'The file format must be txt',
  'The file size should not exceed 2MB': 'The file size should not exceed 2MB',
  'FIO': 'Full name',
  'Company name': 'Company name',
  'First name': 'First name',
  'Last name': 'Last name',
  'User phone number': 'User phone number',
  'International payment': 'International payment',
  'Transaction date': 'Transaction date',
  'Back': 'Back',
  'Repeat': 'Repeat',
  'Valid': 'Valid',
  'Urgent': 'Urgent',
  'Edit account name': 'Edit account name',
  'Maximum number of characters: 70': 'Maximum number of characters: 70',
  'Exit': 'Exit',
  'Pick': 'Pick',
  'Enter number or letter only': 'Enter number or letter only',
  'Sure to confirm?': 'Are you sure you want to confirm?',
  'Sure to decline?': 'Are you sure you want to decline?',
  'Cancel': 'Cancel',
  'Make a payment': 'Make a payment',
  'Enter': 'Enter',
  'Insufficient funds in the account': 'Insufficient funds in the account',
  'Templates': 'Templates',
  'Internal': 'Internal',
  'Delete': 'Delete',
  'BIC': 'BIC',
  'No Templates': 'No Templates',
  'Sender and Recipient accounts must be same': 'The sender and recipients account must be the same currency',
  'Statement': 'Statement',
  'Credited amount': 'Credited amount',
  'Exchange': 'Exchange',
  'Enter rate': 'Enter rate',
  'Credit amount': 'Credit amount',
  'Debit amount': 'Debit amount',
  'Credit account': 'Credit account',
  'Debit account': 'Debit account',
  'Beneficiary Bank': 'Beneficiary Bank',
  'Exchange amount': 'Exchange amount',
  'Print': 'Print',
  'View all': 'View all',
  'otpType': 'Choose a method to receive the verification code',
  'withSMS': 'via SMS',
  'withEmail': 'via Email',
  'Recipient country': 'Recipient country',
  'Recipient city': 'Recipient city',
  'Recipient address': 'Recipient address',
  'Recipient bank address': 'Recipient bank address',
  'Recipient bank name': 'Recipient bank name',
  'Recipient bank swift': 'Recipient bank swift',
  'onlyTwoMonths': 'No more than two months',
  'Oriyon': 'Oriyon',
  'Business': 'Business',
  'Account Balances': 'Account balances',
  'Account Balances in somoni': 'Account balances in somoni',
  'Account Balances in dollars': 'Account balances in dollars',
  'Account Balances in euro': 'Account balances in euro',
  'Account Balances in ruble': 'Account balances in ruble',
  'Account Balances in riyal': 'Account balances in riyal',
  'Account Balances in yuan': 'Account balances in yuan',
  'Free': 'free',
  'Providing an extract': 'Providing bank statements when performing transactions',
  'Opening, closing and keeping an account': 'Opening / Closing and keeping an account',
  'Swift transfer info': 'Transfers of funds through international money transfer systems',
  'Swift cost': 'in accordance with the tariffs of international money transfer systems',
  'Select format': 'Select format',
  'Format': 'Format',
  'PrepaymentForProduct': 'Prepayment for product',
  'PaymentForProduct': 'Payment for product',
  'PrepaymentForService': 'Prepayment for service',
  'PaymentForService': 'Payment for service',
  'Others': 'Others',
  'Enter bic code': 'Enter BIC code',
  'Ruble transfer': 'Ruble transfer',
  'EditionalInfo': 'EditionalInfo',
  'Enter KPP': 'Enter KPP',
  'City address': 'City, address',
  'Correspondent account': 'Correspondent account',
  'All currencies': 'All currencies',
  'Swift narrative': 'Only uppercase letters and symbols . » « №',
  '2 somoni': '2 somoni',
  'Download extract': 'Download extract',
  'Closed': 'Closed',
  'Loan amount': 'Loan amount',
  'Loan end date': 'Loan end date',
  'Loan balance': 'Loan balance',
  'Loan Information': 'Loan Information',
  'Loan type': 'Loan type',
  'Download payment schedule': 'Download payment schedule',
  'Accredits': 'Letter of credit',
  'Overdraft': 'Overdraft',
  'No loans': 'No loans',
  'Next payment date': 'Next payment date',
  'Amount due': 'Repayment amount',
  'Open new deposit': 'Кушодани пасандоз',
  'Deposit owner': 'Deposit owner',
  'Deposit Information': 'Deposit Information',
  'Month': 'Month',
  'Percent': 'Percent',
  'No deposits': 'No deposits',
  'Counterparties': 'Counterparties',
  'Salary': 'Salary',
  'Add': 'Add',
  'New counterparty': 'New counterparty',
  'Individuals': 'Individuals',
  'Foreigs': 'Foreigs',
  'Residents': 'Резиденты',
  'Footer': {
    'address':
      'Republic of Tajikistan, Dushanbe, Rudaki Avenue 95/1 C/A: 20402972413691, TIN: 020003038, BIC: 350101369',
    'emails': 'Email address',
    'tj': 'For calls within Tajikistan',
    'world': 'For calls from anywhere in the world',
    'nbt': 'Hotline of the National Bank of Tajikistan',
    'workTime': 'Work time',
    'workDays': 'Working days: Monday to Friday from 08:00 to 17:00',
    'saturday': 'Saturday: is a day off',
    'sunday': 'Sunday is a day off',
    'receptionOfCitizens': 'Reception of citizens: every Saturday',
    'orb': 'OJSC "Oriyonbonk"',
    'license': 'NBT License No. 0000004 dated January 30, 2014',
    'callcenter': 'Contact Center of Orienbank',
    'supportNumber': 'Orienbusiness Support Service',
    'dayOf': 'Saturday and Sunday are day off',
  },
};
