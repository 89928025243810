const digitNumber = (number: any) => {
  if (number) {
    return Number(number)
      .toFixed(2)
      ?.toString()
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  } else {
    return '0.00';
  }
};

export default digitNumber;
