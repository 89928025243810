import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from '@hooks/redux';

import Login from './Login';
import OtpCode from './OtpCode';
import ResetPassword from './ResetPassword';
import Landing from '@pages/Landing/Landing';

function Auth() {
  const isOtp = useAppSelector(state => state.appReducer.isOtp);
  const checkResetPassword = useAppSelector(state => state.appReducer.checkResetPassword);

  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      {!!isOtp && <Route path='/login/verify' element={<OtpCode />} />}
      {checkResetPassword && <Route path='/login/reset' element={<ResetPassword />} />}
      <Route path='/' element={<Landing />} />
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  );
}

export default Auth;
