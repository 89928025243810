import { useEffect, useState } from 'react';
import { Divider, Form } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getAccountBalanceApi, getSenderAccountsApi } from '@http/accounts';
import { swiftTransactionApi } from '@http/payments';
import { getTemplatesApi } from '@http/user';
import { getCountriesApi } from '@http/global';

import SenderAccountsModal from '@components/Payments/modals/SenderAccountsModal';
import SearchSwiftModal from '@components/Payments/modals/SearchSwiftModal';
import ConfirmPaymentModal from '@components/Payments/modals/ConfirmPaymentModal';
import Currencies from '@components/Currency/OrienbankRates';
import Templates from '@components/Templates/Templates';
import SaveTemplateModal from '@components/Payments/modals/SaveTemplateModal';

import { otpConfirmAC, repeatSwiftTransactionAC, verifyTransIdAC } from '@store/reducers/transactions';
import { pickedSwiftTemplateAC } from '@store/reducers/user';
import {
  isConfirmPaymentModalOpenAC,
  isSearchSwiftModalOpenAC,
  searchedSwiftAC,
  swiftQueryAC,
} from '@store/reducers/payments';
import { senderAccountBalanceAC, selectedSenderAccountAC, senderAccountsModalOpenAC } from '@store/reducers/accounts';
import SwiftForm from '@components/Payments/forms/SwiftForm';

function Swift() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const senderAccounts = useAppSelector(state => state.accountsReducer.senderAccounts);

  const pickedSwiftTemplate = useAppSelector(state => state.userReducer.pickedSwiftTemplate);
  const repeatSwiftTransaction = useAppSelector(state => state.transactionsReducer.repeatSwiftTransaction);
  const newSwiftTemplate = useAppSelector(state => state.paymentsReducer.newSwiftTemplate);
  const swiftQuery = useAppSelector(state => state.paymentsReducer.swiftQuery);

  const [senderAccountCurrency, setSenderAccountCurrency] = useState('');

  useEffect(() => {
    initializeData();

    return clearFields;
  }, []);

  useEffect(() => {
    pickedSwiftTemplateHandler();
  }, [pickedSwiftTemplate]);

  useEffect(() => {
    repeatSwiftTransactionHandler();
  }, [repeatSwiftTransaction]);

  useEffect(() => {
    dispatch(otpConfirmAC(false));
  }, [swiftQuery]);

  const initializeData = () => {
    dispatch(getCountriesApi());
    dispatch(searchedSwiftAC([]));
    dispatch(selectedSenderAccountAC({}));
    dispatch(senderAccountBalanceAC(0));
    dispatch(getTemplatesApi({ transaction_type: 'SWIFT' }));
    dispatch(
      getSenderAccountsApi({
        transaction_type: 'SWIFT',
      })
    );
  };

  const pickedSwiftTemplateHandler = () => {
    setSenderAccountCurrency(pickedSwiftTemplate.sending_ccy);

    fillQuery(pickedSwiftTemplate, 'template');
    fillForms(pickedSwiftTemplate, 'template');

    if (+pickedSwiftTemplate.sending_amount > 0) {
      dispatch(getAccountBalanceApi('sender', { cust_ac_no: pickedSwiftTemplate.sending_ac }));
    }
  };

  const repeatSwiftTransactionHandler = () => {
    setSenderAccountCurrency(repeatSwiftTransaction.sending_ccy);

    fillForms(repeatSwiftTransaction, 'repeat');
    fillQuery(repeatSwiftTransaction, 'repeat');

    if (+repeatSwiftTransaction.sending_amount > 0) {
      dispatch(getAccountBalanceApi('sender', { cust_ac_no: repeatSwiftTransaction.sending_ac }));
    }
  };

  const fillForms = (data: any, type: any) => {
    form.setFieldValue('sending_ac', data.sending_ac);
    form.setFieldValue('receiving_ac', data.receiving_ac);
    form.setFieldValue('receiving_ac2', data.receiving_ac);
    form.setFieldValue('receiver_name', data.receiver_name);
    form.setFieldValue('sender_address', data.sender_address);
    form.setFieldValue('sender_city', data.sender_city);
    form.setFieldValue('receiver_city', data.receiver_city);
    form.setFieldValue('receiver_country', data.receiver_country);
    form.setFieldValue('receiver_bank_swift', data.receiver_bank_swift);
    form.setFieldValue('receiver_bank_name', data.receiver_bank_name);
    form.setFieldValue('receiver_bank_country', data.receiver_bank_country);
    form.setFieldValue('receiver_address', data.receiver_address);

    dispatch(selectedSenderAccountAC(data?.sending_ac));
  };

  const fillQuery = (data: any, type: any) => {
    dispatch(
      swiftQueryAC({
        sending_ac: data.sending_ac,
        receiving_ac: data.receiving_ac,
        receiving_ac2: data.receiving_ac,
        receiver_name: data.receiver_name,
        doc_no: data.doc_no,
        receiver_bank_name: data.receiver_bank_name,
        receiver_bank_swift: data.receiver_bank_swift,
        receiver_country: data.receiver_country,
        receiver_city: data.receiver_city,
        sender_city: data.sender_city,
        sender_address: data.sender_address,
        receiver_bank_country: data.receiver_bank_country,
        receiver_address: data.receiver_address,
      })
    );
  };

  const senderAccountSelect = (account: any) => {
    setSenderAccountCurrency(account.ccy);
    dispatch(swiftQueryAC({ ...swiftQuery, sending_ac: account.value }));

    form.setFieldValue('sending_ac', account.value);

    dispatch(senderAccountsModalOpenAC(false));
    dispatch(selectedSenderAccountAC(account));
    dispatch(getAccountBalanceApi('sender', { cust_ac_no: account.value }));
  };

  const selectSwiftHandler = (item: any) => {
    form.setFieldsValue({
      receiver_bank_swift: item.bic_code,
      receiver_bank_name: item.bank_name,
    });

    dispatch(isSearchSwiftModalOpenAC(false));
  };

  const swiftTransaction = () => {
    dispatch(swiftTransactionApi(swiftQuery, navigate, () => clearFields()));
  };

  const clearFields = () => {
    dispatch(swiftQueryAC({}));
    setSenderAccountCurrency('');

    form.resetFields();

    dispatch(otpConfirmAC(false));
    dispatch(verifyTransIdAC(''));
    dispatch(senderAccountBalanceAC(0));
    dispatch(selectedSenderAccountAC({}));
    dispatch(isConfirmPaymentModalOpenAC(false));
    dispatch(repeatSwiftTransactionAC({}));
    dispatch(pickedSwiftTemplateAC({}));
  };

  return (
    <div className='md:grid grid-cols-6 gap-10 mt-5'>
      <div className='col-span-4'>
        <div className=' mt-7'>
          <Templates type='SWIFT' />
        </div>
        <Divider className='my-6' />
        <SwiftForm form={form} senderAccountCurrency={senderAccountCurrency} />
        <SenderAccountsModal accounts={senderAccounts} selectHandler={senderAccountSelect} />
        <SearchSwiftModal selectSwiftHandler={selectSwiftHandler} />
        <SaveTemplateModal template={newSwiftTemplate} />
        <ConfirmPaymentModal
          title={`${t('International')}`}
          paymentInfo={swiftQuery}
          currency={senderAccountCurrency}
          paymentTransactionHandler={swiftTransaction}
          clearFields={clearFields}
          route={'swiftPaymentStatus'}
          type='SWIFT'
        />
      </div>
      <div className='col-span-2 max-md:hidden'>
        <Currencies size='max-lg:text-xs text-sm' />
      </div>
    </div>
  );
}

export default Swift;
