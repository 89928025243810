import { useEffect } from 'react';
import { Collapse } from 'antd';
import visaIcon from '@assets/images/Visa_Inc._logo.svg.png';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getCardAccountsApi } from '@http/accounts';
import digitNumber from '@utils/digitNumber';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

function VisaBusiness() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const cardAccounts = useAppSelector(state => state.accountsReducer.cardAccounts);

  useEffect(() => {
    dispatch(getCardAccountsApi({ account_type: 'CARD' }));
  }, []);

  return (
    <div>
      {cardAccounts.length > 0 ? (
        <div>
          {cardAccounts.map(item => (
            <Collapse ghost>
              <Panel
                header={
                  <div className='flex justify-between items-center py-5 cursor-pointer'>
                    <div>
                      <p className='sm:text-sm text-base font-medium'>{item.cust_ac_no}</p>
                      <div className='flex items-center'>
                        <div className='bg-green-500 h-3 w-3 mr-3 rounded-full' />
                        <p className='text-sm'>{t('Active')}</p>
                      </div>
                    </div>
                    <p className='text-base font-medium'>
                      {digitNumber(item.acy_avl_bal)} <span className='font-bold'>{item.ccy}</span>
                    </p>
                  </div>
                }
                key='1'
              >
                <div>
                  <div className='flex justify-between items-center p-5 border-b cursor-pointer'>
                    <div>
                      <p className='text-base font-medium'>{item.customer_name}</p>
                      <p className='text-sm text-slate-500'>{item.description}</p>
                    </div>
                    <div className='flex items-center'>
                      <img className='w-16 h-4' src={visaIcon} alt='' />
                      <p className='text-base font-bold ml-2' style={{ color: '#00579f' }}>
                        Business
                      </p>
                    </div>
                  </div>
                </div>
              </Panel>
            </Collapse>
          ))}
        </div>
      ) : (
        <div className='h-24 flex items-center justify-center'>
          <p>Счетов нет</p>
        </div>
      )}
    </div>
  );
}

export default VisaBusiness;
