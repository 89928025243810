import { Modal, Input, DatePicker, Form, Select, Button } from 'antd';
import { DatePicker as DatePickerMobile } from 'antd-mobile';
import { Toast as ToastMobile } from 'antd-mobile';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { isMobileOnly } from 'react-device-detect';
import { orderStatementApi } from '@http/transactions';
import moment from 'moment';
import { statementOrderModalOpenAC } from '@store/reducers/global';
import { fixDate, formatDateDashed } from '@utils/formatDate';

const { RangePicker } = DatePicker;
const now = new Date();

const OrderStatementModal = () => {
  const { t } = useTranslation();
  const [statementOrderForm] = Form.useForm();
  const dispatch = useAppDispatch();

  const accounts = useAppSelector(state => state.accountsReducer.namedAccounts);
  const statementOrderModalOpen = useAppSelector(state => state.globalReducer.statementOrderModalOpen);

  const [pickStartDateVisible, setPickStartDateVisible] = useState(false);
  const [pickEndDateVisible, setPickEndDateVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [pickedStartDate, setPickedStartDate] = useState(t('Start date'));
  const [pickedEndDate, setPickedEndDate] = useState(t('End date'));

  const statementOrder = (values: any) => {
    const startMoment = isMobileOnly ? values.from_date : values.date[0];
    const endMoment = isMobileOnly ? values.to_date : values.date[1];

    const diffInMonths = endMoment.diff(startMoment, 'months');

    if (diffInMonths > 2) {
      setError(t('onlyTwoMonths'));
    } else {
      setError(null);
      dispatch(
        orderStatementApi({
          cust_ac_no: values.account,
          from_date: isMobileOnly ? pickedStartDate : values.date[0]?.format('YYYY-MM-DD'),
          to_date: isMobileOnly ? pickedEndDate : values.date[1]?.format('YYYY-MM-DD'),
          name: values.name,
          file_type: values.file_type,
        })
      );
      dispatch(statementOrderModalOpenAC(false));
      statementOrderForm.resetFields();
      setPickedStartDate(t('Start date'));
      setPickedEndDate(t('End date'));
    }
  };

  const pickStartDate = (date: any) => {
    const formattedDate = formatDateDashed(date);

    setPickedStartDate(formattedDate);
    statementOrderForm.setFieldValue('from_date', moment(formattedDate));
  };

  const pickEndDate = (date: any) => {
    const formattedDate = formatDateDashed(date);

    setPickedEndDate(formattedDate);
    statementOrderForm.setFieldValue('to_date', moment(formattedDate));
  };

  const checkPeriod = (period: any) => {
    if (period) {
      const startMoment = period[0];
      const endMoment = period[1];

      const diffInMonths = endMoment.diff(startMoment, 'months');

      if (diffInMonths > 2) {
        setError(t('onlyTwoMonths'));
      } else {
        setError(null);
      }
    }
  };

  return (
    <div>
      <Modal
        title={`${t('Ordering a statement')}`}
        open={statementOrderModalOpen}
        onOk={() => dispatch(statementOrderModalOpenAC(false))}
        onCancel={() => dispatch(statementOrderModalOpenAC(false))}
        footer={null}
        width={450}
      >
        <Form form={statementOrderForm} name='statementOrder' onFinish={statementOrder}>
          <Form.Item className='m-0 mt-5' name='name'>
            <Input size='large' placeholder={`${t('Enter the title')}`} />
          </Form.Item>
          <Form.Item className='m-0 mt-5' name='account' rules={[{ required: true, message: `${t('Required')}` }]}>
            <Select size='large' placeholder={`${t('Select account')}`} options={accounts} />
          </Form.Item>
          <Form.Item name='file_type' className='mb-0 mt-5' rules={[{ required: true, message: `${t('Required')}` }]}>
            <Select allowClear size='large' placeholder={`${t('Select format')}`}>
              <Select.Option value='pdf'>{t('PDF')}</Select.Option>
              <Select.Option value='xlsx'>{t('EXCEL')}</Select.Option>
            </Select>
          </Form.Item>
          {isMobileOnly ? (
            <div>
              <Form.Item
                className='m-0 mt-5 sm:hidden'
                name='from_date'
                rules={[{ required: true, message: `${t('Required')}` }]}
              >
                <div>
                  <div
                    className='w-full h-[40px] px-3 text-base flex items-center rounded-lg bg-[#f6f7f8] text-[#00000040] sm:hidden'
                    style={{ border: '1px solid #d9d9d9' }}
                    onClick={() => setPickStartDateVisible(true)}
                  >
                    <span>{pickedStartDate}</span>
                  </div>
                  <DatePickerMobile
                    confirmText={`${t('Pick')}`}
                    cancelText={`${t('Cancel')}`}
                    title={`${t('Select date')}`}
                    visible={pickStartDateVisible}
                    onClose={() => {
                      setPickStartDateVisible(false);
                    }}
                    max={now}
                    onConfirm={(val: any) => {
                      ToastMobile.show(val.toDateString());
                      pickStartDate(val);
                    }}
                  />
                </div>
              </Form.Item>
              <Form.Item
                className='m-0 mt-5 sm:hidden'
                name='to_date'
                rules={[{ required: true, message: `${t('Required')}` }]}
              >
                <div>
                  <div
                    className='w-full h-[40px] px-3 text-base flex items-center rounded-lg bg-[#f6f7f8] text-[#00000040] sm:hidden'
                    style={{ border: '1px solid #d9d9d9' }}
                    onClick={() => setPickEndDateVisible(true)}
                  >
                    <span>{pickedEndDate}</span>
                  </div>
                  <DatePickerMobile
                    confirmText={`${t('Pick')}`}
                    cancelText={`${t('Cancel')}`}
                    title={`${t('Select date')}`}
                    visible={pickEndDateVisible}
                    onClose={() => {
                      setPickEndDateVisible(false);
                    }}
                    max={now}
                    onConfirm={(val: any) => {
                      ToastMobile.show(val.toDateString());
                      pickEndDate(val);
                    }}
                  />
                  {error ? <span className='text-red-400'>{t('onlyTwoMonths')}</span> : null}
                </div>
              </Form.Item>
            </div>
          ) : (
            <Form.Item
              className='m-0 mt-5'
              name='date'
              rules={[{ required: true, message: `${t('Required')}` }]}
              validateStatus={error ? 'error' : undefined}
              help={error}
            >
              <RangePicker
                placeholder={[`${t('Start date')}`, `${t('End date')}`]}
                format='DD.MM.YYYY'
                size='large'
                style={{ width: '100%' }}
                onChange={e => checkPeriod(e)}
              />
            </Form.Item>
          )}
          <div className='flex justify-end mt-5'>
            <Button type='primary' size='large' htmlType='submit'>
              {t('Order')}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default OrderStatementModal;
