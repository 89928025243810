import { $host } from './index';
import { AppDispatch } from '@store';
import { isSpinAC } from '@store/reducers/app';
import { depositListAC } from '@store/reducers/deposits';

const END_POINT = '/client';

const getDepositListApi = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/deposit/list`);
    dispatch(depositListAC(data));
  } finally {
    dispatch(isSpinAC(false));
  }
};

export { getDepositListApi };
