import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface salaryState {
  salaryInfo: any;
  salaryList: any;
  pickedSalaryInfo: any;
  salaryListSearchQuery: any;
  salaryInfoSearchQuery: any;
  verifyFileId: any;
  actionModalType: any;
  isSalaryActionModalOpen: any;
}

const initialState: salaryState = {
  salaryInfo: [],
  salaryList: [],
  pickedSalaryInfo: [],
  salaryListSearchQuery: {},
  salaryInfoSearchQuery: {},
  verifyFileId: '',
  actionModalType: '',
  isSalaryActionModalOpen: false,
};

const salary = createSlice({
  name: 'salary',
  initialState,
  reducers: {
    actionModalTypeAC(state, action) {
      state.actionModalType = action.payload;
    },
    salaryActionModalOpenAC(state, action) {
      state.isSalaryActionModalOpen = action.payload;
    },
    salaryInfoAC(state, action: PayloadAction<any>) {
      state.salaryInfo = action.payload;
    },
    salaryListAC(state, action: PayloadAction<any>) {
      state.salaryList = action.payload;
    },
    pickedSalaryInfoAC(state, action: PayloadAction<any>) {
      state.pickedSalaryInfo = action.payload;
    },
    salaryListSearchQueryAC(state, action: PayloadAction<any>) {
      state.salaryListSearchQuery = action.payload;
    },
    salaryInfoSearchQueryAC(state, action: PayloadAction<any>) {
      state.salaryInfoSearchQuery = action.payload;
    },
    verifyFileIdAC(state, action: PayloadAction<any>) {
      state.verifyFileId = action.payload;
    },
  },
});

export const {
  salaryInfoAC,
  salaryListAC,
  pickedSalaryInfoAC,
  salaryListSearchQueryAC,
  salaryInfoSearchQueryAC,
  verifyFileIdAC,
  actionModalTypeAC,
  salaryActionModalOpenAC,
} = salary.actions;
export default salary.reducer;
