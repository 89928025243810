import { $host } from './index';
import { AppDispatch } from '@store';
import { isSpinAC, notificationModalAC, otpLifeTimeAC } from '@store/reducers/app';
import { pickedSalaryInfoAC, salaryInfoAC, salaryListAC } from '@store/reducers/salary';
import { otpConfirmAC } from '@store/reducers/transactions';
import { verifyFileIdAC } from '@store/reducers/salary';

const END_POINT = '/client';

const getSalaryTemplateApi = (callback: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.get(`${END_POINT}/salary/file/template`, {
      responseType: 'arraybuffer',
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Шаблон зарплат.xlsx');
    link.click();
    callback();
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getSalaryListApi = (params: any, page: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/salary/files?page=${page}`, params);
    dispatch(salaryListAC(data));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const refreshSalaryListApi = (params: any, page: any) => async (dispatch: AppDispatch) => {
  try {
    const { data } = await $host.post(`${END_POINT}/salary/files?page=${page}`, params);
    dispatch(salaryListAC(data));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const uploadSalaryFileApi = (file: any, callback: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await $host.post(`${END_POINT}/salary/file/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (data) {
      callback();
      dispatch(salaryInfoAC(data));
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const confirmSalaryFileApi = (params: any, callback?: any, needCallback?: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/salary/file/upload/confirm`, params);

    if (data?.otp_required) {
      dispatch(otpConfirmAC(true));
      dispatch(verifyFileIdAC(data?.file_id));
      dispatch(otpLifeTimeAC(Date.now() + 1000 * data.code_lifetime));
    }
    if (needCallback) {
      callback();
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getSalaryInfoApi = (params: any, page: any, callback?: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/salary/files/trns?page=${page}`, params);
    if (data) {
      dispatch(pickedSalaryInfoAC(data));
      if (callback) {
        callback();
      }
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const refreshSalaryInfoApi = (params: any, page: any, callback?: any) => async (dispatch: AppDispatch) => {
  try {
    const { data } = await $host.post(`${END_POINT}/salary/files/trns?page=${page}`, params);
    if (data) {
      dispatch(pickedSalaryInfoAC(data));
      if (callback) {
        callback();
      }
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const dowloadOriginalSalaryFileApi = (params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/salary/file/download`, params, {
      responseType: 'arraybuffer',
    });
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/xlsx' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Файл зарплаты.xlsx');
    link.click();
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const downloadSalaryExtractFileApi = (params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/salary/file/stat/download`, params, {
      responseType: 'arraybuffer',
    });
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'выписка зарплаты.pdf');
    link.click();
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

export {
  refreshSalaryInfoApi,
  refreshSalaryListApi,
  getSalaryTemplateApi,
  uploadSalaryFileApi,
  getSalaryListApi,
  confirmSalaryFileApi,
  getSalaryInfoApi,
  dowloadOriginalSalaryFileApi,
  downloadSalaryExtractFileApi,
};
