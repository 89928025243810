/* eslint import/no-anonymous-default-export: [2, {'allowObject': true}] */
export default {
  'Language': 'Chinese',
  'Main page': 'Main page',
  'Clients': 'Clients',
  'Legal entity': 'Legal entity',
  'Transactions': '交易',
  'Registration': 'Registration',
  'List': 'List',
  'Restrictions': 'Restrictions',
  'Creating a client': 'Creating a client',
  'Accounts': '帳戶',
  'Accesses': 'Accesses',
  'Filter': 'Filter',
  'By client ID': 'By client ID',
  'By username': 'By username',
  'By phone number': 'By phone number',
  'Status': 'Status',
  'Find': 'Find',
  'ID': 'ID',
  'Name': 'Name',
  'Surname': 'Surname',
  'Patronymic': 'Patronymic',
  'Date of birth': 'Date of birth',
  'Phone number': 'Phone number',
  'Intrabank': '銀行內部',
  'Own accounts': 'Own accounts',
  'Salaries': '薪資',
  'Statement': '提煉',
  'Interbank': '銀行同業拆借',
  'Ruble transfer': '盧布轉帳',
  'Interbank payment': 'Interbank payment',
  'SWIFT': 'SWIFT',
  'Ruble': 'Ruble',
  'view': 'view',
  'init': 'init',
  'outh': 'outh',
  'Account': 'Account',
  'Legal entities': 'Legal entities',
  'Customer _ no': 'Customer _ no',
  'Company': 'Company',
  'Number of employees': 'Number of employees',
  'Transactions List of legal entity transactions': 'Transactions List of legal entity transactions',
  'Search': 'Search',
  'All': 'All',
  'Sign in': 'Sign in',
  'Signing in': 'Signing in',
  'Processing': 'Processing',
  'Not successful': 'Not successful',
  'Successful': 'Successful',
  'Date': 'Date',
  'Sender': 'Sender',
  'Recipient': 'Recipient',
  'Sender account': 'Sender acccount',
  'Recipient account': 'Recipient account',
  'Transaction type': 'Transaction type',
  'Amount': 'Amount',
  'Hello': 'Hello',
  'WannaEnter': 'Do you want to log into your personal account?',
  'Login': 'Login',
  'Password': 'Password',
  'Sign up': 'Sign up',
  'Exchange Rates': '匯率',
  'Exchange': '轉換',
  'Orienbank': 'Orienbank',
  'NBT': 'NBT',
  'Currency': '洋流',
  'Purchase': '鬥爭',
  'Sale': '銷售',
  'USD': 'USD',
  'EUR': 'EUR',
  'RUB': 'RUB',
  'US dollars': 'US dollars',
  'Euro': 'Euro',
  'Russian ruble': 'Russian ruble',
  'Successfully': 'Successfully',
  'All accounts': 'All accounts',
  'TJS': 'TJS',
  'Somoni': 'Somoni',
  'Waiting for authorization': 'Waiting for authorization',
  'Last operations': 'Last operations',
  'Between own accounts': '自有帳戶之間',
  'Intrabank payment': 'Intrabank payment',
  'MyAccounts': 'My accounts',
  'Active': 'Active',
  'Not active': 'Not active',
  'Update': 'Update',
  'Updated': '已更新',
  'Hidden': 'Hidden',
  'Russian': 'Russian',
  'English': 'English',
  'Tajik': 'Tajik',
  'Bank accounts': 'Bank accounts',
  'Visa Business': 'Visa Business',
  'Information': 'Information',
  'Account Information': 'Account Information',
  'File information': 'File Information',
  'Account name': 'Account name',
  'Balance': 'Balance',
  'Account type': 'Account type',
  'Term': 'Term',
  'Address': 'Address',
  'TIN': 'TIN',
  'Date of opening': 'Date of opening',
  'Requisites': 'Requisites',
  'Download': 'Download',
  'Upload': 'Upload',
  'Upload file': '文件上傳',
  'Description': 'Description',
  'Show filters': 'Show filters',
  'Next': 'Next',
  'No transaction': 'No transaction',
  'TxID': 'TxID',
  'Payment information': '付款資訊',
  'Commission': 'Commission',
  'Create': 'Create',
  'Date of creation': 'Date of creation',
  'Retry payment': 'Retry payment',
  'Download PDF': 'Download PDF',
  'Authorizers': 'Authorizers',
  'Reject': 'Reject',
  'Confirm': '確認',
  'Approve': 'Approve',
  'Payment Information': 'asfasfasf',
  'Bank information': 'Bank information',
  'From account': '來自帳戶',
  'To account': '就是這個帳號',
  'Transfer amount': '轉帳金額',
  'Narrative': '敘述',
  'Transfer': 'Transfer',
  'Multiple transfers': 'Multiple transfers',
  'Template': 'Template',
  'Existing template': 'Existing template',
  'Sender information': 'Sender information',
  'Recipient information': 'Recipient information',
  'Number': 'Number',
  'Account number': 'Account number',
  'Confirm account number': 'Confirm account number',
  'Payment details': 'Payment details',
  'Other details': 'Other details',
  'Document type': 'Document type',
  'Document number': 'Document number',
  'Document date': 'Document date',
  'Save template': 'Save template',
  'Country': 'Country',
  'City': 'City',
  'About Bank': 'About Bank',
  'Purpose of the bank': 'Purpose of the bank',
  'Enter amount': '輸入金額',
  'Document': 'Document',
  'Enter document number': 'Enter document number',
  'Initiator': 'Initiator',
  'Number of transactions': 'Number of transactions',
  'Not valid': 'Not valid',
  'Import': 'Import',
  'From the beginning': 'From the beginning',
  'To the end': 'To the end',
  'Rejected': 'Rejected',
  'Importing payments from a file': 'Importing payments from a file',
  'Download template for multiple transactions': 'Download template for multiple transactions',
  'Download document': 'Download document',
  'Salary project': 'Salary project',
  'Not authorized': 'Not authorized',
  'Select account': '選擇帳戶',
  'Select date': 'Select date',
  'View': 'View',
  'Order an statement': 'Order an statement',
  'View reports': 'View reports',
  'View orders': 'View orders',
  'Ordering a statement': 'Ordering a statement',
  'Enter the title': 'Enter the title',
  'Order': 'Order',
  'Ready': 'Ready',
  'Failed': 'Failed',
  'In progress': 'In progress',
  'Period': 'Period',
  'Order date': 'Order date',
  'Orders': 'Orders',
  'Tariffs': 'Tariffs',
  'Bank account': 'Bank account',
  'free': 'free',
  'Opening / Maintaining and closing a current account': 'Opening / Maintaining and closing a current account',
  'Receiving account statements': 'Receiving account statements',
  'Transfer between own accounts': 'Transfer between own accounts',
  'Transfer to accounts at other banks': 'Transfer to accounts at other banks',
  'Show all currencies': 'Show all currencies',
  'Rate': 'Rate',
  'Currency сode': 'Currency сode',
  'Unit': 'Unit',
  'Currency name (all currencies must be written)': 'Currency name (all currencies must be written)',
  'No.': 'No.',
  'Change Password': 'Change Password',
  'Log out': 'Log out',
  'Profile': 'Profile',
  'Notification': 'Notification',
  'Enter a new password': 'Enter a new password',
  'Enter old password': 'Enter old password',
  'Сonfirm new password': 'Сonfirm new password',
  'Change': 'Change',
  'Products': 'Products',
  'Contacts': 'Contacts',
  'Home page': 'Home page',
  'Payments and transfers': 'Payments and transfers',
  'OrienPay': 'OrienPay',
  'oriyon-business@oriyonbonk.tj': 'oriyon-business@oriyonbonk.tj',
  '95/1, Rudaki Ave., Dushanbe': '95/1, Rudaki Ave., Dushanbe',
  'Call center 2222': 'Call center 2222',
  'Bank jobs': 'Bank jobs',
  'Help': 'Help',
  'Site map': 'Site map',
  'Loans': 'Loans',
  'Deposits': 'Deposits',
  'Bank cards': 'Bank cards',
  'Mobile banking': 'Mobile banking',
  'Internet banking': 'Internet banking',
  'OtpCode': 'Confirmation code',
  'ResendTime': 'Resending will be available via',
  'Second': 'second',
  'ResendCode': 'Resend code',
  'Save': 'Save',
  'All operations': 'All operations',
  'Update operations': 'Update operations',
  'Operation': 'Operation',
  'No Transactions': 'No Transactions',
  'Payments': '付款方式',
  'More': '更多的',
  'CurrienciesToSomoni': 'Official exchange rate of currencies against Somoni:',
  'Hide all currencies': 'Hide all currencies',
  'ToSign': 'To sign',
  'Rejecteds': 'Rejected',
  'Enter Recipient Account': 'Enter Recipient Account',
  'Confirm payment': 'Confirm payment',
  'Payment type': 'Payment type',
  'Enter Recipient': 'Enter Recipient',
  'Enter TIN': 'Введите TIN',
  'Select document type': 'Select document type',
  'Enter template name': 'Enter template name',
  'Recipient TIN': 'Recipient TIN',
  'Show balance': 'Show balance',
  'Hide balance': 'Hide balance',
  'Hide filters': 'Hide filters',
  'Swift': '貨幣轉帳',
  'Bank name': 'Bank name',
  'Filters': 'Filters',
  'Show': 'Show',
  'Select template': 'Select template',
  'One Dollar': '1 Dollar',
  'One Ruble': '1 Ruble',
  'One Euro': '1 Euro',
  'Orienbank Address': 'Dushanbe, Rudaki Avenue 95/1',
  'Call Center': 'Call center',
  'Oriyonbank': 'Oriyonbank',
  'Enter City': 'Enter City',
  'Enter Country': 'Enter Country',
  'Enter Address': 'Enter Address',
  'Enter Account': 'Enter Account',
  'Enter swift code': 'Enter swift code',
  'Enter bank name': 'Enter bank name',
  'Enter doc number': 'Enter doc number',
  'Enter SMS code': 'Enter SMS code',
  'Find swift code': 'Find swift code',
  'Required': 'Required',
  'Enter symbol only': 'Enter symbol only',
  'Amount must be more 1': 'Amount must be more 1',
  'Wrong amount': 'Wrong amount',
  'Latin Only': 'Latin Only',
  'Account does not match': 'Account does not match!',
  'Account must contain no more than 20 digits': 'Account must contain no more than 20 digits',
  'Account must contain at least 10 digits': 'Account must contain at least 10 digits',
  'Account must contain at least 20 digits': 'Account must contain at least 20 digits',
  'Enter number only': 'Enter number only',
  'ConfirmSmsFromPhone': 'To confirm the transaction, an SMS code has been sent to your phone number',
  'No accounts': 'No accounts',
  'Account does not found': 'Account does not found!',
  'Must be 6 numbers': 'Must be 6 numbers',
  'Must be 9 numbers': 'Must be 9 numbers',
  'Enter': 'Enter',
  'Login to account': 'Login to account',
  'Field must contain at least 8 digits': 'Field must contain at least 8 digits!',
  'For registration':
    'To register in the Internet Banking system, you need to contact the central office of Orienbank, at',
  'For any questions': 'For any questions, please contact our contact center, we will be happy to help you',
  'Code': 'Code',
  'Sent code to phone for sequirity': 'To protect your profile, we will send a verification code to your phone number',
  'Change your password': 'Change your password',
  'Password does not match': 'Password does not match',
  'Confirm password': 'Confirm password',
  'Change and remind password': 'Change and remember the password that is more convenient for you',
  'International': '貨幣轉帳',
  'File date': 'File date',
  'Total amount': 'Маблаг',
  'Debit': 'Debit',
  'Credit': 'Debit',
  'Own accounts payments': 'Own accounts payments',
  'Intrabank payments': 'Intrabank payments',
  'Interbank payments': 'Interbank payments',
  'File name': 'Номи файл',
  'Successful count': 'Successful count',
  'Not successful count': 'Not successful count',
  'Download file': 'Download file',
  'Error': 'Error',
  'Transaction list': 'Transaction list',
  'Branch': 'Branch',
  'Approved': 'Approved',
  'From amount': 'From amount',
  'To amount': 'To amount',
  'Start date': 'Start date',
  'End date': 'End date',
  'No data': 'No data',
  'Enter document type': 'Enter document type',
  'In confirmation': 'In confirmation',
  'In verification': 'In verification',
  'Partial success': 'Partial success',
  'Write-off account': 'Write-off account',
  'Employees list': 'Employees list',
  'Postpone': 'Postpone',
  'Import payments from file': 'Import payments from file',
  'Download salary file template': 'Download salary file template',
  'Upload document': 'Upload document',
  'The file format must be xlsx, xls': 'The file format must be xlsx, xls',
  'The file format must be txt': 'The file format must be txt',
  'The file size should not exceed 2MB': 'The file size should not exceed 2MB',
  'FIO': 'Full name',
  'Company name': 'Company name',
  'First name': 'First name',
  'Last name': 'Last name',
  'User phone number': 'User phone number',
  'International payment': 'International payment',
  'Transaction date': 'Transaction date',
  'Back': 'Back',
  'Repeat': 'Repeat',
  'Valid': 'Valid',
  'Urgent': 'Urgent',
  'Edit account name': 'Edit account name',
  'Maximum number of characters: 70': 'Maximum number of characters: 70',
  'Confirm new password': 'Confirm new password',
  'Exit': 'Exit',
};
