import { Modal, Select } from 'antd';
import docTypes from '@assets/constants/docTypes';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { isDocumentTypesModalOpenAC } from '@store/reducers/payments';

const DocumentTypeModal = ({ pickDocument }: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isDocumentTypesModalOpen = useAppSelector(state => state.paymentsReducer.isDocumentTypesModalOpen);
  const selectedDocumentType = useAppSelector(state => state.paymentsReducer.selectedDocumentType);

  return (
    <div>
      <Modal
        width={600}
        footer={null}
        title={t('Document type')}
        open={isDocumentTypesModalOpen}
        onOk={() => dispatch(isDocumentTypesModalOpenAC(false))}
        onCancel={() => dispatch(isDocumentTypesModalOpenAC(false))}
      >
        <div className='h-96 overflow-auto'>
          <div className='my-5'>
            <Select
              size='large'
              style={{ width: '100%' }}
              allowClear
              showSearch
              optionFilterProp='children'
              onChange={(value: any, item: any) => {
                pickDocument(item, 'Select');
              }}
              filterOption={(input: any, option: any) =>
                (option?.label ?? '')?.toLowerCase().includes(input.toLowerCase())
              }
              options={docTypes.map(item => ({
                value: item.value.toString(),
                label: item.label + ' (' + item.code + ')',
                code: item.code,
              }))}
            />
          </div>
          {docTypes.length > 0 ? (
            <div>
              {docTypes.map((item: any) => (
                <div
                  key={item.value}
                  role='button'
                  tabIndex={0}
                  onClick={() => {
                    pickDocument(item);
                  }}
                >
                  <div
                    className={`p-3 lg:p-5 grid grid-cols-12 gap-5 border-b cursor-pointer listItem ${
                      selectedDocumentType === item.value ? 'bg-yellow-50' : null
                    }`}
                  >
                    <p className='lg:text-base font-medium col-span-3'>{item.value}</p>
                    <p className='lg:text-base font-medium col-span-6'>{item.label}</p>
                    <p className='lg:text-base font-medium col-span-3 text-end'>{item.code}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className='h-24 text-base flex items-center justify-center'>
              <p>{t('No accounts')}</p>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default DocumentTypeModal;
