import { useEffect, useState } from 'react';
import { Select, DatePicker, Button, Pagination, Drawer, Popover } from 'antd';
import { getAccountsApi } from '@http/accounts';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { dowloadAccountStatementApi, dowloadAccountStatementToPrintApi, getAccountOrdersApi } from '@http/transactions';
import { ArrowLeftOutlined, CloudDownloadOutlined, FilterOutlined, PrinterOutlined } from '@ant-design/icons';
import getStatus from '@utils/getStatus';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

function Orders() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const accounts = useAppSelector(state => state.accountsReducer.selectAccounts);
  const orders = useAppSelector(state => state.transactionsReducer.orders);
  const dispatch = useAppDispatch();

  const [showFilters, setShowFilters] = useState(false);
  const [open, setOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState({
    cust_ac_no: '',
    from_date: '',
    to_date: '',
    status: '',
  });

  const changePage = (page: any) => {
    dispatch(getAccountOrdersApi(searchQuery, page));
  };

  const getAccountOrders = (page: any) => {
    dispatch(getAccountOrdersApi(searchQuery, page));
    onClose();
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setShowFilters(false);
  };

  useEffect(() => {
    dispatch(getAccountsApi());
    getAccountOrders(1);
  }, []);

  return (
    <div className='flex justify-center'>
      <div className='w-full'>
        <div className='flex items-center'>
          <ArrowLeftOutlined className='cursor-pointer' onClick={() => navigate('/statement')} />
          <div className='flex justify-between w-full'>
            <h1 className='text-2xl ml-5 font-semibold'>{t('Orders')}</h1>
            <Button
              className='md:hidden'
              icon={<FilterOutlined />}
              type='primary'
              onClick={() => {
                setShowFilters(!showFilters);
                showDrawer();
              }}
            >
              {!showFilters ? t('Show filters') : t('Hide filters')}
            </Button>
          </div>
        </div>
        <div className='flex mt-7 max-md:hidden'>
          <Select
            allowClear
            style={{
              width: 230,
            }}
            size='large'
            onChange={value => {
              setSearchQuery({ ...searchQuery, cust_ac_no: value });
            }}
            options={accounts}
            placeholder={`${t('Select account')}`}
          />
          <Select
            allowClear
            className='ml-3'
            style={{
              width: 180,
            }}
            size='large'
            onChange={value => {
              setSearchQuery({ ...searchQuery, status: value });
            }}
            placeholder={`${t('Status')}`}
          >
            <Select.Option value='READY'>{t('Ready')}</Select.Option>
            <Select.Option value='IN_PROGRESS'>{t('In progress')}</Select.Option>
            <Select.Option value='FAILED'>{t('Failed')}</Select.Option>
          </Select>
          <RangePicker
            allowClear
            className='ml-3'
            size='large'
            style={{
              width: 270,
            }}
            placeholder={[`${t('Start date')}`, `${t('End date')}`]}
            onChange={(date: any) => {
              if (date) {
                setSearchQuery({
                  ...searchQuery,
                  from_date: date[0]?.format('YYYY-MM-DD'),
                  to_date: date[1]?.format('YYYY-MM-DD'),
                });
              } else {
                setSearchQuery({
                  ...searchQuery,
                  from_date: '',
                  to_date: '',
                });
              }
            }}
            disabledDate={date => date && date > moment().add('day')}
          />
          <Button type='primary' size='large' className='ml-3' onClick={() => getAccountOrders(1)}>
            {t('Show')}
          </Button>
          <Button type='primary' size='large' className='ml-3' onClick={() => getAccountOrders(1)}>
            {t('Update')}
          </Button>
        </div>
        <div className='grid md:grid-cols-12 grid-cols-11 text-sm font-semibold border-b max-sm:py-3 sm:p-5 gap-5 mt-5 max-sm:text-xs'>
          <p className='col-span-2'>{t('Date')}</p>
          <p className='col-span-2 max-md:col-span-6'>{t('Name')}</p>
          <p className='col-span-2 max-md:col-span-3 max-md:hidden'>{t('Account')}</p>
          <p className='col-span-2'>{t('Period')}</p>
          <p className='col-span-1 text-center max-md:hidden'>{t('Format')}</p>
          <p className='col-span-2 text-right max-md:hidden'>{t('Status')}</p>
          <p className='col-span-1'></p>
        </div>
        {orders?.results?.length > 0 ? (
          <div>
            {orders?.results?.map((item: any) => (
              <div
                style={{ borderBottom: '1px solid #eee' }}
                className='grid md:grid-cols-12 grid-cols-11 max-sm:py-5 sm:p-5 border-b cursor-pointer gap-5 listItem max-md:text-sm max-sm:text-xs md:text-xs lg:text-sm'
                key={item.id}
              >
                <p className='col-span-2 max-sm:text-center'>{item.created_at.slice(0, 19)}</p>
                <p className='col-span-2 font-medium max-md:hidden'>{item.name}</p>
                <div className='col-span-2 max-md:col-span-6 md:hidden'>
                  <p className='max-sm:text-xs font-semibold text-base'>{item.name}</p>
                  <p>{item.cust_ac_no}</p>
                  <div className='flex mt-3'>
                    <p>{getStatus(item.status, 12, t)}</p>
                    <Button
                      className='ml-3 max-lg:!text-xs'
                      disabled={item.status !== 'READY'}
                      icon={<CloudDownloadOutlined />}
                      type='primary'
                      size='small'
                      onClick={() =>
                        dispatch(dowloadAccountStatementApi({ correlation_id: item.correlation_id }, item.file_type))
                      }
                    >
                      {t('Download')}
                    </Button>
                  </div>
                </div>
                <div className='col-span-2 max-md:col-span-3 max-md:hidden'>{item.cust_ac_no}</div>
                <p className='col-span-2 max-md:col-span-3'>
                  {item.from_date} - {item.to_date}
                </p>
                <p className='col-span-1 text-center max-md:hidden'>{item.file_type}</p>
                <p className='col-span-2 max-md:hidden text-right'>{getStatus(item.status, 12, t)}</p>

                {item.status === 'READY' ? (
                  <div className='col-span-1 max-md:hidden'>
                    <Popover title={t('Download')} overlayStyle={{ width: '90px' }}>
                      <CloudDownloadOutlined
                        onClick={() =>
                          dispatch(dowloadAccountStatementApi({ correlation_id: item.correlation_id }, item.file_type))
                        }
                        style={{ fontSize: '24px' }}
                        className='mr-3 text-[#ffb700] hover:text-[#ffd260]'
                      />
                    </Popover>

                    {item.file_type === 'pdf' ? (
                      <Popover title={t('Print')} overlayStyle={{ width: '120px' }}>
                        <PrinterOutlined
                          onClick={() =>
                            dispatch(
                              dowloadAccountStatementToPrintApi({ correlation_id: item.correlation_id }, item.file_type)
                            )
                          }
                          style={{ fontSize: '24px' }}
                          className='text-[#ffb700] hover:text-[#ffd260]'
                        />
                      </Popover>
                    ) : null}
                  </div>
                ) : null}
              </div>
            ))}
            <div className='mt-7 flex justify-end'>
              <Pagination
                onChange={changePage}
                current={orders.current_page}
                defaultPageSize={10}
                total={orders.total_pages * 10}
                showSizeChanger={false}
              />
            </div>
          </div>
        ) : (
          <div className='h-24 flex items-center justify-center'>
            <p>{t('No data')}</p>
          </div>
        )}
      </div>
      <Drawer title={t('Filters')} placement='bottom' closable={false} onClose={onClose} open={open} key='bottom'>
        <Select
          allowClear
          style={{
            width: '100%',
          }}
          size='large'
          onChange={value => {
            setSearchQuery({ ...searchQuery, cust_ac_no: value });
          }}
          options={accounts}
          placeholder={`${t('Select account')}`}
        />
        <Select
          allowClear
          className='mt-5'
          style={{
            width: '100%',
          }}
          size='large'
          onChange={value => {
            setSearchQuery({ ...searchQuery, status: value });
          }}
          placeholder={`${t('Status')}`}
        >
          <Select.Option value='READY'>{t('Ready')}</Select.Option>
          <Select.Option value='IN_PROGRESS'>{t('In progress')}</Select.Option>
          <Select.Option value='FAILED'>{t('Failed')}</Select.Option>
        </Select>
        <RangePicker
          allowClear
          className='mt-5'
          size='large'
          style={{
            width: '100%',
          }}
          placeholder={[`${t('Start date')}`, `${t('End date')}`]}
          onChange={(date: any) => {
            if (date) {
              setSearchQuery({
                ...searchQuery,
                from_date: date[0]?.format('YYYY-MM-DD'),
                to_date: date[1]?.format('YYYY-MM-DD'),
              });
            } else {
              setSearchQuery({
                ...searchQuery,
                from_date: '',
                to_date: '',
              });
            }
          }}
          disabledDate={date => date && date > moment().add('day')}
        />
        <div className='flex justify-end mt-5'>
          <Button type='primary' size='large' onClick={() => getAccountOrders(1)}>
            {t('Confirm')}
          </Button>
        </div>
      </Drawer>
    </div>
  );
}

export default Orders;
