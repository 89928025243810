import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/user';
import appReducer from './reducers/app';
import paymentsReducer from './reducers/payments';
import accountsReducer from './reducers/accounts';
import transactionsReducer from './reducers/transactions';
import salaryReducer from './reducers/salary';
import bulkTransactionReducer from './reducers/bulkTransaction';
import globalReducer from './reducers/global';
import loansReducer from './reducers/loans';
import depositsReducer from './reducers/deposits';

const rootReducer = combineReducers({
  appReducer,
  userReducer,
  paymentsReducer,
  accountsReducer,
  transactionsReducer,
  salaryReducer,
  bulkTransactionReducer,
  globalReducer,
  loansReducer,
  depositsReducer,
});

export const setupStore = () =>
  configureStore({
    reducer: rootReducer,
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppState = AppStore['getState'];
