import React from 'react';
import phone from './img/payment_analytic_report 1.png';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@hooks/redux';
import { requestInfoModalAC } from '@store/reducers/app';

function MainPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <div className=''>
      <div className='sm:flex justify-around bg-white my-10 sm:mt-16 rounded-3xl py-7 mx-4 px-1'>
        <h1 className='sm:hidden text-center font-[800] text-2xl'>{t('mainPage.title')}</h1>
        <p className='sm:hidden text-center text-lg w-[80%] mx-auto mb-4 mt-2'>{t('mainPage.desc')}</p>
        <div className='flex justify-center '>
          <img src={phone} alt='' className='w-2/3 max-sm:w-1/2' />
        </div>
        <div className=' flex-col sm:flex md:pt-3 gap-5 my-auto max-lg:ml-5'>
          <h1 className=' hidden sm:block w-[80%] font-[800] text-[32px]  leading-10 '>{t('mainPage.title')}</h1>
          <h1 className='w-[60%]  hidden sm:block :w-full :text-center text-20px leading-6 font-medium  '>
            {t('mainPage.desc')}
          </h1>
          <div id='main' className='flex sm:justify-start justify-center mt-5'>
            <Button
              type='primary'
              size='large'
              className='w-[300px] my-auto'
              onClick={() => dispatch(requestInfoModalAC(true))}
            >
              {t('mainPage.request')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
