import { $host } from './index';
import { AppDispatch } from '@store';
import { isSpinAC, isSwitchRouteLoadingAC, notificationModalAC, userAC } from '@store/reducers/app';
import { permissionsAC, templatesAC } from '@store/reducers/user';
import openNotification from '@utils/openNotification';

const END_POINT = '/client';

const getUserInfoApi = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.get(`${END_POINT}/me`);
    dispatch(userAC(data));
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getTemplatesApi = (params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/templates`, params);
    dispatch(templatesAC(data));
  } finally {
    dispatch(isSpinAC(false));
  }
};

const deleteTemplateApi = (params: object, type: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    await $host.post(`${END_POINT}/transaction/template/delete`, params);
    dispatch(getTemplatesApi({ transaction_type: type }));
  } finally {
    dispatch(isSpinAC(false));
  }
};

const saveTemplateApi = (params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    await $host.post(`${END_POINT}/transaction/template`, params);
    openNotification('success', { description: 'Шаблон успешно создан!' });
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getUserPermissionsApi = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.get(`${END_POINT}/permissions/me`);
    dispatch(permissionsAC(data));
    dispatch(isSwitchRouteLoadingAC(false));
  } finally {
    dispatch(isSpinAC(false));
  }
};

export { getTemplatesApi, saveTemplateApi, getUserInfoApi, getUserPermissionsApi, deleteTemplateApi };
