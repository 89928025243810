import dayjs from 'dayjs';

export const fixDate = (date: any) => {
  let day: any = '';
  let month: any = '';

  if (date.getDate() < 9) {
    day = '0' + +date.getDate();
  } else {
    day = date.getDate();
  }

  if (date.getMonth() < 9) {
    month = '0' + (date.getMonth() + 1);
  } else {
    month = date.getMonth() + 1;
  }

  return day + '-' + month + '-' + date.getFullYear();
};

export const formatDate = (isoDate: Date | string) => {
  return isoDate ? dayjs(isoDate).format('DD.MM.YYYY') : '-';
};

export const formatDateDashed = (isoDate: Date | string) => {
  return isoDate ? dayjs(isoDate).format('YYYY-MM-DD') : '-';
};

export const formatDateTime = (isoDate: Date | string) => {
  return isoDate ? dayjs(isoDate).format('DD.MM.YYYY HH:mm:ss') : '-';
};
