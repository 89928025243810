import { Modal, Upload } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { isBulkTransactionPreviewModalOpenAC, isImportFileModalOpenAC } from '@store/reducers/bulkTransaction';
import { FileAddOutlined, FileDoneOutlined } from '@ant-design/icons';
import { uploadBulkTransactionFileApi } from '@http/bulkTransaction';
import { useTranslation } from 'react-i18next';

const ImportFileModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isImportFileModalOpen = useAppSelector(state => state.bulkTransactionReducer.isImportFileModalOpen);

  return (
    <Modal
      open={isImportFileModalOpen}
      onOk={() => dispatch(isImportFileModalOpenAC(false))}
      onCancel={() => dispatch(isImportFileModalOpenAC(false))}
      footer={null}
    >
      <p className='text-center text-lg mb-7 font-bold color-active'>{t('Importing payments from a file')}</p>

      {/* <div className='p-5 flex items-center mb-5 rounded-lg' style={{ border: '1px solid #eee' }}>
        <FileDoneOutlined style={{ fontSize: '38px' }} className='color-active' />
        <p className='font-medium color-active text-base ml-3'>{t('Download template for multiple transactions')}</p>
      </div> */}

      <Upload
        maxCount={1}
        showUploadList={false}
        name='logo'
        onChange={e => {
          dispatch(
            uploadBulkTransactionFileApi(e.file, () => {
              dispatch(isBulkTransactionPreviewModalOpenAC(true));
              dispatch(isImportFileModalOpenAC(false));
            })
          );
        }}
        beforeUpload={() => false}
        listType='picture'
        accept='.txt'
      >
        <div className='p-5 flex items-center rounded-lg cursor-pointer' style={{ border: '1px solid #eee' }}>
          <div className='flex items-center'>
            <FileAddOutlined style={{ fontSize: '38px' }} className='color-active' />
            <div className='ml-3'>
              <p className='font-medium color-active text-base'>{t('Download document')}</p>
              <p className='text-slate-500'>{t('The file format must be txt')}</p>
              <p className='text-slate-500'>{t('The file size should not exceed 2MB')}</p>
            </div>
          </div>
        </div>
      </Upload>
    </Modal>
  );
};

export default ImportFileModal;
