import { Routes, Route, Link, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { Select } from 'antd';

import OwnAccounts from './OwnAccounts';
import InternalOperations from './InternalOperations';
import InterbankOperations from './InterbankOperations';
import Swift from './Swift';

// import BulkTransactionIndex from '@BulkTransaction/Index';
import { useAppSelector } from '@hooks/redux';
import { useTranslation } from 'react-i18next';
import RubOperations from './RubOperations';
import Exchange from './Exchange';
import BulkTransactionIndex from '../BulkTransaction/Index';

function PaymentsIndex() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const paymentsPermissions = useAppSelector(state => state.userReducer.paymentsPermissions);

  const routes: any = [];

  const permissionsRoutes = [
    {
      key: 'own',
      label: t('Between own accounts'),
      value: '/payments/own',
      element: <OwnAccounts />,
    },

    {
      key: 'internal',
      label: t('Intrabank'),
      value: '/payments/internal',
      element: <InternalOperations />,
    },
    {
      key: 'interbank',
      label: t('Interbank'),
      value: '/payments/interbank',
      element: <InterbankOperations />,
    },
    {
      key: 'swift',
      label: t('International'),
      value: '/payments/swift',
      element: <Swift />,
    },
    {
      key: 'swift_rub',
      label: t('Ruble transfer'),
      value: '/payments/swift_rub',
      element: <RubOperations />,
    },
    {
      key: 'exchange',
      label: t('Exchange'),
      value: '/payments/exchange',
      element: <Exchange />,
    },
    {
      key: 'bulk',
      label: t('Upload file'),
      value: '/payments/bulk/list',
      element: <BulkTransactionIndex />,
    },
  ];

  const permissionsRoutesMobile = [
    {
      key: 'own',
      label: t('Between own accounts'),
      value: '/payments/own',
      element: <OwnAccounts />,
    },

    {
      key: 'internal',
      label: t('Intrabank'),
      value: '/payments/internal',
      element: <InternalOperations />,
    },
    {
      key: 'interbank',
      label: t('Interbank'),
      value: '/payments/interbank',
      element: <InterbankOperations />,
    },
    {
      key: 'swift',
      label: t('International'),
      value: '/payments/swift',
      element: <Swift />,
    },
    {
      key: 'swift_rub',
      label: t('Ruble transfer'),
      value: '/payments/swift_rub',
      element: <RubOperations />,
    },
    {
      key: 'exchange',
      label: t('Exchange'),
      value: '/payments/exchange',
      element: <Exchange />,
    },
  ];

  const checkActiveRoute = (route: any) => {
    if (location.pathname === route) {
      return 'header__items-active';
    }
    return null;
  };

  const checkRoutes = () => {
    if (window.innerWidth < 768) {
      for (let i = 0; i < permissionsRoutesMobile.length; i++) {
        for (let k = 0; k < paymentsPermissions.length; k++) {
          if (permissionsRoutes[i].key === paymentsPermissions[k]?.operation_short_name) {
            routes.push(permissionsRoutes[i]);
          }
        }
      }
    } else {
      for (let i = 0; i < permissionsRoutes.length; i++) {
        for (let k = 0; k < paymentsPermissions.length; k++) {
          if (permissionsRoutes[i].key === paymentsPermissions[k]?.operation_short_name) {
            routes.push(permissionsRoutes[i]);
          }
        }
      }
    }
  };

  checkRoutes();

  return (
    <div className='flex justify-center'>
      <div className='lg:w-[1130px] w-full'>
        <div className='flex'>
          <div className='p-4 lg:p-8 w-full h-full bg-white mb-12'>
            <div className='flex justify-between md:hidden'>
              <p className='text-xl font-semibold'>{t('Payments')}</p>
              <Select
                defaultValue='/payments/own'
                style={{ width: 220 }}
                options={routes}
                onChange={(item: any) => navigate(item)}
              />
            </div>

            <div className='flex max-md:hidden' style={{ borderBottom: '1px solid #eee' }}>
              {routes.map((item: any) => (
                <Link
                  key={item.key}
                  to={item.value}
                  className={`header__items py-5 ${checkActiveRoute(item.value)} mr-5`}
                  style={{ fontSize: '15px' }}
                >
                  {item.label}
                </Link>
              ))}
            </div>
            <Routes>
              {routes.map((item: any) => (
                <Route path={`${item.key}/*`} element={item.element} key={item.key} />
              ))}
              <Route path='*' element={<Navigate to='/' replace />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentsIndex;
