import { useState } from 'react';
import { Divider, Form, Select, Input, Button, Upload, Space } from 'antd';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useTranslation } from 'react-i18next';
import digitNumber from '@utils/digitNumber';

import { deadlineAC } from '@store/reducers/transactions';
import {
  isConfirmPaymentModalOpenAC,
  isSaveTemplateModalOpenAC,
  isSearchBicModalOpenAC,
  newRubOperationTemplateAC,
  rubOperationQueryAC,
} from '@store/reducers/payments';
import { senderAccountsModalOpenAC } from '@store/reducers/accounts';

const { TextArea } = Input;

const RubOperationsForm = ({ form, senderAccountCurrency }: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const senderAccountBalance = useAppSelector(state => state.accountsReducer.senderAccountBalance);
  const countries = useAppSelector(state => state.globalReducer.countries);
  const rubOperationQuery = useAppSelector(state => state.paymentsReducer.rubOperationQuery);
  const newRubOperationTemplate = useAppSelector(state => state.paymentsReducer.newRubOperationTemplate);

  const [file, setFile] = useState<any>();
  const [showEditionalInfo, setShowEditionalInfo] = useState(false);

  const submit = (values: any) => {
    if (+rubOperationQuery.sending_amount > +senderAccountBalance) {
      form.setFields([
        {
          name: 'sending_ac',
          errors: [t('Insufficient funds in the account')],
        },
      ]);
    } else {
      const formData = new FormData();

      for (const key in values) {
        formData.append(key, values[key]);
      }

      if (file) {
        formData.append('file', file?.originFileObj);
      }

      formData.append('narrative', values?.narrative1 + ' ' + values?.narrative2);

      dispatch(rubOperationQueryAC({ ...values, narrative: values?.narrative1 + ' ' + values?.narrative2 }));

      dispatch(isConfirmPaymentModalOpenAC(true));
      dispatch(deadlineAC(Date.now() + 1000 * 30));
    }
  };

  const openSaveTemplateModal = () => {
    dispatch(
      newRubOperationTemplateAC({
        ...newRubOperationTemplate,
        sending_amount: form.getFieldValue('sending_amount'),
        sending_ac: form.getFieldValue('sending_ac'),
        receiving_ac: form.getFieldValue('receiving_ac'),
        receiver_name: form.getFieldValue('receiver_name'),
        sending_ccy: senderAccountCurrency,
        narrative: form.getFieldValue('narrative'),
        doc_no: form.getFieldValue('doc_no'),
        sender_address: form.getFieldValue('sender_address'),
        receiver_country: form.getFieldValue('receiver_country'),
        receiver_bank_name: form.getFieldValue('receiver_bank_name'),
        receiver_bank_country: form.getFieldValue('receiver_bank_country'),
        receiver_address: form.getFieldValue('receiver_address'),
        tax_id: form.getFieldValue('tax_id'),
        receiver_kpp: form.getFieldValue('receiver_kpp'),
        bank_code: form.getFieldValue('bank_code'),
        receiver_bank_address: form.getFieldValue('receiver_bank_address'),
        bank_corr_acc: form.getFieldValue('bank_corr_acc'),
      })
    );

    dispatch(isSaveTemplateModalOpenAC(true));
  };

  const selectNarrative = () => {
    setShowEditionalInfo(true);
  };

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  return (
    <Form form={form} name='Swift_rub' onFinish={submit}>
      <div>
        <p className='text-base color-active font-semibold mb-4'>{t('Sender information')}</p>
        <p className='text-bs color-title md:hidden'>{t('From account')}:</p>
        <div className='md:grid grid-cols-7'>
          <p className='col-span-2 text-bs color-title max-md:hidden'>{t('From account')}:</p>
          <div className='col-span-5'>
            <Form.Item className='m-0' name='sending_ac' rules={[{ required: true, message: `${t('Required')}` }]}>
              <Space.Compact size='large' className='w-full'>
                <Input disabled style={{ width: 60 }} size='large' value={senderAccountCurrency} />
                <Input
                  value={rubOperationQuery.sending_ac}
                  placeholder={`${t('Select account')}`}
                  onClick={() => dispatch(senderAccountsModalOpenAC(true))}
                  size='large'
                  style={{ width: '100%' }}
                  type='text'
                />
              </Space.Compact>
            </Form.Item>
            {senderAccountBalance ? (
              <p className='text-bs color-title font-medium text-end mt-2'>
                {t('Balance')}: {digitNumber(senderAccountBalance)} {senderAccountCurrency}
              </p>
            ) : null}
          </div>
        </div>
      </div>

      <Divider className='my-6' />

      <div>
        <p className='text-base color-active font-semibold mb-4'>{t('Bank information')}</p>
        <p className='text-bs color-title mt-5 md:hidden'>{t('BIC')}:</p>
        <div className='md:grid grid-cols-7 md:mt-7'>
          <p className='col-span-2 text-bs color-title max-md:hidden'>{t('BIC')}:</p>
          <div className='col-span-5 flex'>
            <Form.Item
              className='m-0 w-full'
              name='bank_code'
              rules={[{ required: true, message: `${t('Required')}` }]}
            >
              <Input
                disabled
                placeholder={`${t('Enter bic code')}`}
                size='large'
                style={{ width: '100%', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              />
            </Form.Item>
            <Button
              onClick={() => dispatch(isSearchBicModalOpenAC(true))}
              icon={<SearchOutlined />}
              size='large'
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftWidth: 0 }}
            ></Button>
          </div>
        </div>

        <p className='text-bs color-title mt-5 md:hidden'>{t('Bank name')}:</p>
        <div className='md:grid grid-cols-7 md:mt-7'>
          <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Bank name')}:</p>
          <div className='col-span-5'>
            <Form.Item className='m-0' name='receiver_bank_name'>
              <Input disabled placeholder={`${t('Bank name')}`} size='large' style={{ width: '100%' }} />
            </Form.Item>
          </div>
        </div>

        <p className='text-bs color-title mt-5 md:hidden'>{t('City')}:</p>
        <div className='md:grid grid-cols-7 md:mt-7'>
          <p className='col-span-2 text-bs color-title max-md:hidden'>{t('City')}:</p>
          <div className='col-span-5'>
            <Form.Item className='m-0' name='receiver_bank_address'>
              <Input disabled placeholder={`${t('City')}`} size='large' style={{ width: '100%' }} />
            </Form.Item>
          </div>
        </div>

        <p className='text-bs color-title mt-5 md:hidden'>{t('Correspondent account')}:</p>
        <div className='md:grid grid-cols-7 md:mt-7'>
          <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Correspondent account')}:</p>
          <div className='col-span-5'>
            <Form.Item className='m-0' name='bank_corr_acc'>
              <Input disabled placeholder={`${t('Correspondent account')}`} size='large' style={{ width: '100%' }} />
            </Form.Item>
          </div>
        </div>
      </div>

      <Divider className='my-6' />

      <div>
        <p className='text-base color-active font-semibold mb-4'>{t('Recipient information')}</p>
        <p className='text-bs color-title md:hidden'>{t('Recipient')}:</p>
        <div className='md:grid grid-cols-7 md:mt-7'>
          <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Recipient')}:</p>
          <div className='col-span-5'>
            <Form.Item
              className='m-0'
              name='receiver_name'
              rules={[
                { required: true, message: `${t('Required')}` },
                // {
                //   pattern: /^[a-zA-Zа-яА-Я0-9\s]+$/,
                //   message: `${t('Enter number or letter only')}`,
                // },
              ]}
            >
              <Input maxLength={70} placeholder={`${t('Enter Recipient')}`} size='large' style={{ width: '100%' }} />
            </Form.Item>
          </div>
        </div>

        <p className='text-bs color-title mt-5 md:hidden'>{t('Account number')}:</p>
        <div className='md:grid grid-cols-7 md:mt-7'>
          <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Account number')}:</p>
          <div className='col-span-5'>
            <Form.Item
              className='m-0'
              name='receiving_ac'
              rules={[
                { required: true, message: `${t('Required')}` },
                { min: 8, message: `${t('Account must contain at least 8 digits')}` },
                { max: 35, message: `${t('Account must contain no more than 35 digits')}` },
                {
                  pattern: /^[a-zA-Z0-9]+$/,
                  message: `${t('Enter number or letter only')}`,
                },
              ]}
              hasFeedback
            >
              <Input maxLength={35} placeholder={`${t('Enter Account')}`} size='large' style={{ width: '100%' }} />
            </Form.Item>
          </div>
        </div>

        <p className='text-bs color-title mt-5 md:hidden'>{t('Confirm account number')}:</p>
        <div className='md:grid grid-cols-7 md:mt-7'>
          <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Confirm account number')}:</p>
          <div className='col-span-5'>
            <Form.Item
              name='receiving_ac2'
              dependencies={['receiving_ac']}
              hasFeedback
              rules={[
                { required: true, message: `${t('Required')}` },
                { min: 8, message: `${t('Account must contain at least 8 digits')}` },
                { max: 35, message: `${t('Account must contain no more than 35 digits')}` },
                {
                  pattern: /^[a-zA-Z0-9]+$/,
                  message: `${t('Enter number or letter only')}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('receiving_ac') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(`${t('Account does not match')}`));
                  },
                }),
              ]}
            >
              <Input maxLength={35} placeholder={`${t('Enter Account')}`} size='large' style={{ width: '100%' }} />
            </Form.Item>
          </div>
        </div>

        <p className='text-bs color-title mt-5 md:hidden'>{t('TIN')}:</p>
        <div className='md:grid grid-cols-7 md:mt-7'>
          <p className='col-span-2 text-bs color-title max-md:hidden'>{t('TIN')}:</p>
          <div className='col-span-5'>
            <Form.Item
              className='m-0'
              name='tax_id'
              rules={[
                { required: true, message: `${t('Required')}` },
                {
                  pattern: /^[0-9]+$/,
                  message: 'Только цифры',
                },
                {
                  min: 9,
                  message: 'Минимум 9 цифр',
                },
              ]}
            >
              <Input
                maxLength={15}
                minLength={9}
                size='large'
                placeholder={`${t('Enter TIN')}`}
                type='text'
                style={{ width: '100%' }}
                // onChange={e => setQuery({ ...query, tax_id: e.target.value })}
              />
            </Form.Item>
          </div>
        </div>

        <p className='text-bs color-title mt-5 md:hidden'>{t('KPP')}:</p>
        <div className='md:grid grid-cols-7 md:mt-7'>
          <p className='col-span-2 text-bs color-title max-md:hidden'>{t('KPP')}:</p>
          <div className='col-span-5'>
            <Form.Item
              className='m-0'
              name='receiver_kpp'
              rules={[
                {
                  pattern: /^[0-9]+$/,
                  message: 'Только цифры',
                },
                {
                  min: 9,
                  message: 'Минимум 9 цифр',
                },
              ]}
            >
              <Input
                maxLength={15}
                minLength={9}
                size='large'
                placeholder={`${t('Enter KPP')}`}
                type='text'
                // onChange={e => setQuery({ ...query, tax_id: e.target.value })}
              />
            </Form.Item>
          </div>
        </div>

        <p className='text-bs color-title mt-5 md:hidden'>{t('Country')}:</p>
        <div className='md:grid grid-cols-7 md:mt-7'>
          <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Country')}:</p>
          <div className='col-span-5'>
            <Form.Item
              className='m-0'
              name='receiver_country'
              rules={[
                { required: true, message: `${t('Required')}` },
                {
                  pattern: /^[a-zA-Z ]+$/,
                  message: `${t('Latin Only')}`,
                },
              ]}
            >
              <Select
                size='large'
                style={{ width: '100%' }}
                allowClear
                showSearch
                optionFilterProp='children'
                filterOption={filterOption}
                options={countries}
                placeholder={`${t('Enter Country')}`}
              />
            </Form.Item>
          </div>
        </div>

        <p className='text-bs color-title mt-5 md:hidden'>{t('Address')}:</p>
        <div className='md:grid grid-cols-7 md:mt-7'>
          <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Address')}:</p>
          <div className='col-span-5'>
            <Form.Item
              className='m-0'
              name='receiver_address'
              rules={[{ required: true, message: `${t('Required')}` }]}
            >
              <Input maxLength={35} placeholder={`${t('Enter Address')}`} size='large' style={{ width: '100%' }} />
            </Form.Item>
          </div>
        </div>
      </div>

      <Divider className='my-6' />

      <div>
        <p className='text-base color-active font-semibold mb-4'>{t('Payment details')}</p>
        <p className='text-bs color-title mt-5 md:hidden'>{t('Transfer amount')}:</p>
        <div className='md:grid grid-cols-7 md:mt-7'>
          <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Transfer amount')}:</p>
          <div className='col-span-5'>
            <Form.Item
              className='m-0'
              name='sending_amount'
              rules={[
                { required: true, message: `${t('Required')}` },
                {
                  pattern: /^[0-9]\d*(?:[.]\d{1,2})?$/,
                  message: `${t('Wrong amount')}`,
                },
                {
                  pattern: /^[1-9]\d*/,
                  message: `${t('Amount must be more 1')}`,
                },
              ]}
            >
              <Space.Compact size='large' className='w-full'>
                <Input disabled style={{ width: 60 }} size='large' value={senderAccountCurrency} />
                <Input
                  maxLength={12}
                  placeholder={`${t('Enter amount')}`}
                  size='large'
                  style={{ width: '100%' }}
                  type='text'
                />
              </Space.Compact>
            </Form.Item>
          </div>
        </div>

        <p className='text-bs color-title mt-5 md:hidden'>{t('Narrative')}:</p>
        <div className='md:grid grid-cols-7 md:mt-7'>
          <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Narrative')}:</p>
          <div className='col-span-5'>
            <Form.Item className='m-0' name='narrative1' rules={[{ required: true, message: `${t('Required')}` }]}>
              <Select size='large' placeholder={t('Select narrative')} onChange={selectNarrative}>
                <Select.Option value='VO10100'>{t('PrepaymentForProduct')}</Select.Option>
                <Select.Option value='VO10200'>{t('PaymentForProduct')}</Select.Option>
                <Select.Option value='VO20100'>{t('PrepaymentForService')}</Select.Option>
                <Select.Option value='VO20200'>{t('PaymentForService')}</Select.Option>
                <Select.Option value='VO99090'>{t('Others')}</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        {showEditionalInfo ? (
          <div className='mt-5'>
            <p className='text-bs color-title'>{t('EditionalInfo')}</p>
            <Form.Item
              className='m-0'
              name='narrative2'
              rules={[
                { required: true, message: `${t('Required')}` },
                {
                  pattern: /^(?=.*\S).*/,
                  message: `${t('Enter symbol only')}`,
                },
              ]}
            >
              <TextArea maxLength={130} rows={4} />
            </Form.Item>
          </div>
        ) : null}
      </div>

      <Divider className='my-6' />

      <p className='text-base color-active font-semibold mb-4'>{t('Other details')}</p>
      <p className='text-bs color-title mt-5 md:hidden'>{t('Document number')}:</p>
      <div className='md:grid grid-cols-7 md:mt-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Document number')}:</p>
        <div className='col-span-5'>
          <Form.Item
            className='m-0'
            name='doc_no'
            rules={[
              { pattern: /^[0-9№ ]+$/, message: `${t('Enter number only')}` },
              { required: true, message: `${t('Required')}` },
            ]}
          >
            <Input size='large' placeholder={`${t('Enter doc number')}`} type='text' style={{ width: '100%' }} />
          </Form.Item>
        </div>
      </div>
      <p className='text-bs color-title mt-5 md:hidden'>{t('Document')}:</p>
      <div className='md:grid grid-cols-7 md:mt-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Document')}:</p>
        <div className='col-span-5'>
          <Form.Item className='m-0'>
            <Upload
              onChange={e => {
                setFile(e.file);
              }}
              customRequest={dummyRequest}
              listType='picture'
              accept='.pdf'
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>{t('Upload')}</Button>
            </Upload>
          </Form.Item>
        </div>
      </div>
      <div className='flex justify-end mt-5'>
        <Button className='mr-3' type='default' onClick={openSaveTemplateModal}>
          {t('Save template')}
        </Button>
        <Button type='primary' htmlType='submit'>
          {t('Confirm')}
        </Button>
      </div>
    </Form>
  );
};

export default RubOperationsForm;
