import { Modal, Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { transactionApproveApi } from '@http/transactions';
import { bulkTransactionActionModalOpenAC } from '@store/reducers/bulkTransaction';

const BulkTransactionActionModal = (props: any) => {
  const { fileId } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isBulkTransactionActionModalOpen = useAppSelector(
    state => state.bulkTransactionReducer.isBulkTransactionActionModalOpen
  );
  const actionModalType = useAppSelector(state => state.bulkTransactionReducer.actionModalType);

  return (
    <div>
      <Modal
        open={isBulkTransactionActionModalOpen}
        onOk={() => dispatch(bulkTransactionActionModalOpenAC(false))}
        onCancel={() => dispatch(bulkTransactionActionModalOpenAC(false))}
        footer={null}
        width={400}
      >
        <Result
          status='warning'
          title={actionModalType === 'DECLINE' ? t('Sure to decline?') : t('Sure to confirm?')}
          extra={[
            <div>
              {actionModalType === 'DECLINE' ? (
                <Button
                  danger
                  size='middle'
                  onClick={() =>
                    dispatch(
                      transactionApproveApi(
                        {
                          params: {
                            trans_id: fileId,
                            action: 'DECLINE',
                            trn_type: 'BULK',
                          },
                          fileId: fileId,
                        },
                        () => {
                          dispatch(bulkTransactionActionModalOpenAC(false));
                        }
                      )
                    )
                  }
                >
                  {t('Reject')}
                </Button>
              ) : (
                <Button
                  type='primary'
                  size='middle'
                  onClick={() =>
                    dispatch(
                      transactionApproveApi(
                        {
                          params: {
                            trans_id: fileId,
                            action: 'APPROVE',
                            trn_type: 'BULK',
                          },
                          fileId: fileId,
                        },
                        () => {
                          dispatch(bulkTransactionActionModalOpenAC(false));
                        }
                      )
                    )
                  }
                >
                  {t('Confirm')}
                </Button>
              )}
            </div>,
          ]}
        />
      </Modal>
    </div>
  );
};

export default BulkTransactionActionModal;
