import { $host } from './index';
import { AppDispatch } from '@store';
import {
  getAccountsAC,
  senderAccountBalanceAC,
  selectAccountsAC,
  accountInfoAC,
  selectedAccountAC,
  cardAccountsAC,
  toAccountBalanceAC,
  senderAccountsAC,
  receiveAccountsAC,
  searchedAccountAC,
  isAccountValidAC,
  statementAC,
  namedAccountsAC,
  accountBalanceAC,
} from '@store/reducers/accounts';
import { isSpinAC, notificationModalAC } from '@store/reducers/app';

const END_POINT = '/client';

const getAccountInfoApi = (params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/account/details`, params);
    dispatch(accountInfoAC(data[0]));
    dispatch(selectedAccountAC(data[0].cust_ac_no));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getAccountsApi = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/accounts`);
    dispatch(getAccountsAC(data));
    dispatch(selectAccountsAC(data));
    dispatch(namedAccountsAC(data));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getCardAccountsApi = (query: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/accounts`, query);
    dispatch(cardAccountsAC(data));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getAccountBalanceApi = (type: any, params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/account/balance`, params);
    if (type === 'sender') {
      dispatch(senderAccountBalanceAC(data?.acy_avl_bal));
    }
    if (type === 'to') {
      dispatch(toAccountBalanceAC(data?.acy_avl_bal));
    }
    if (type === 'account') {
      dispatch(accountBalanceAC(data));
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getAccountDetailsApi = (params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    await $host.post(`${END_POINT}/account/details`, params);
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const accountCheckApi = (params: object, callback: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/check/account`, params);
    callback(data);
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getSenderAccountsApi = (params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/send/options`, params);
    dispatch(senderAccountsAC(data));
    dispatch(namedAccountsAC(data));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getReceiveAccountsApi = (params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/receive/options`, params);
    dispatch(receiveAccountsAC(data));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const checkIsAccountOwnApi = (params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/check/account`, params);
    dispatch(searchedAccountAC(data));
    dispatch(isAccountValidAC(true));
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getAccountStatementApi = (params: object, page: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/statement?page=${page}`, params);
    dispatch(statementAC(data));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const editAccountNameApi = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    await $host.post(`${END_POINT}/account/name`, params);
    dispatch(
      getAccountInfoApi({
        cust_ac_no: params.cust_ac_no,
      })
    );
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: 'Название счета успешно отредактировано',
        type: 'success',
      })
    );
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const dowloadAccountRequisitesApi = (params: object) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/account/requisites`, params, {
      responseType: 'arraybuffer',
    });
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'requisites.pdf');
    link.click();
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

export {
  getAccountsApi,
  getAccountBalanceApi,
  getAccountDetailsApi,
  accountCheckApi,
  getAccountInfoApi,
  getCardAccountsApi,
  getSenderAccountsApi,
  getReceiveAccountsApi,
  checkIsAccountOwnApi,
  getAccountStatementApi,
  editAccountNameApi,
  dowloadAccountRequisitesApi,
};
