import { useState } from 'react';
import { Input, Form, Button, Statistic } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { changePasswordConfirmApi, changePasswordValidateApi } from '@http/auth';
import { useTranslation } from 'react-i18next';

const { Countdown } = Statistic;

export const ChangePassword = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const changePasswordOtpState = useAppSelector(state => state.appReducer.changePasswordOtpState);
  const dispatch = useAppDispatch();

  const [time, setTime] = useState(false);
  const [deadline, setDeadline] = useState(0);
  const [query, setQuery] = useState({
    old_password: '',
    new_password: '',
  });

  const changePassword = () => {
    dispatch(changePasswordValidateApi(query)).then(data => {
      if (data) {
        setDeadline(Date.now() + 1000 * 60);
        form.resetFields();
      }
    });
  };

  const confirmOtp = (values: any) => {
    dispatch(changePasswordConfirmApi({ ...query, code: values.code }));
  };

  const onFinishCountDown = () => {
    setTime(true);
  };

  return (
    <div>
      {!changePasswordOtpState ? (
        <Form form={form} className='mt-5' name='optCode' onFinish={changePassword} autoComplete='off'>
          <Form.Item
            name='old_password'
            rules={[
              { min: 8, message: `${t('Field must contain at least 8 digits')}` },
              { required: true, message: `${t('Required')}` },
            ]}
          >
            <Input.Password
              type='password'
              onChange={e => setQuery({ ...query, old_password: e.target.value })}
              size='large'
              placeholder={`${t('Enter old password')}`}
            />
          </Form.Item>
          <Form.Item
            name='new_password'
            rules={[
              { min: 8, message: `${t('Field must contain at least 8 digits')}` },
              { required: true, message: `${t('Required')}` },
            ]}
            hasFeedback
          >
            <Input.Password
              onChange={e => setQuery({ ...query, new_password: e.target.value })}
              size='large'
              placeholder={`${t('Enter a new password')}`}
            />
          </Form.Item>

          <Form.Item
            name='newPassword1'
            dependencies={['new_password']}
            hasFeedback
            rules={[
              { min: 8, message: `${t('Field must contain at least 8 digits')}` },
              { required: true, message: `${t('Required')}` },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(`${t('Password does not match')}`));
                },
              }),
            ]}
          >
            <Input.Password size='large' placeholder={`${t('Confirm new password')}`} />
          </Form.Item>

          <Form.Item>
            <div className='flex justify-end mt-5'>
              <Button className='' type='primary' htmlType='submit'>
                {t('Change')}
              </Button>
            </div>
          </Form.Item>
        </Form>
      ) : (
        <Form
          className='mt-5'
          name='otpCode'
          initialValues={{ remember: true }}
          onFinish={confirmOtp}
          autoComplete='off'
        >
          <div className='flex'>
            <Form.Item className='m-0 mb-5' name='code' rules={[{ required: true, message: `${t('Required')}` }]}>
              <Input size='large' style={{ width: 400 }} placeholder={`${t('Enter SMS code')}`} />
            </Form.Item>
            <Form.Item className='m-0 ml-3'>
              <Button size='large' className='' type='primary' htmlType='submit'>
                {t('Confirm')}
              </Button>
            </Form.Item>
          </div>

          {!time ? (
            <div className='flex items-center text-slate-400 mb-2 max-sm:text-xs text-sm'>
              <span className='mr-2 text-right'>{t('ResendTime')}</span>
              <Countdown
                className='!text-xs'
                style={{ fontSize: 12 }}
                value={deadline}
                onFinish={onFinishCountDown}
                format='mm:ss'
              />
              <span className='ml-2'> {t('Second')}!</span>
            </div>
          ) : (
            <Button
              className='mb-3'
              size='middle'
              onClick={() => {
                setDeadline(Date.now() + 1000 * 60);
                setTime(false);
                dispatch(changePasswordValidateApi);
              }}
            >
              {t('ResendCode')}
            </Button>
          )}
        </Form>
      )}
    </div>
  );
};

export default ChangePassword;
