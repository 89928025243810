import { Button, Modal, Result } from 'antd';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '@hooks/redux';

import { notificationModalAC } from '@store/reducers/app';

const NotificationModalStyled = styled.div`
  > div {
    padding: 24px;

    > .ant-result-subtitle {
      font-size: 20px;
    }
  }
`;

const NotificationModal = () => {
  const dispatch = useAppDispatch();
  const notificationModal = useAppSelector(state => state.appReducer.notificationModal);

  const handleOk = () => {};

  const handleCancel = () => {};

  const handlerClick = () => {
    dispatch(
      notificationModalAC({
        type: 'success',
        message: '',
        isOpen: false,
        width: '400px',
      })
    );
  };

  return (
    <Modal
      open={notificationModal.isOpen}
      footer={null}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      width={notificationModal.width}
      zIndex={99999}
    >
      <NotificationModalStyled>
        <Result
          status={notificationModal.type}
          title={notificationModal.type === 'success' ? 'Успешно' : 'Ошибка'}
          subTitle={
            notificationModal.message
              ? notificationModal.message
              : notificationModal.type !== 'success'
              ? 'Сервис временно недоступен, повторите позже'
              : 'Выполненно успешно!'
          }
          extra={[
            <Button onClick={handlerClick} size={'large'} type='primary'>
              Закрыть
            </Button>,
          ]}
        />
      </NotificationModalStyled>
    </Modal>
  );
};

export default NotificationModal;
