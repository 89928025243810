import DepositsBanner from '@components/Deposits/DepositBanner';
import mainBanner from '@assets/images/deposits/main.png';
import termdeposit from '@assets/images/deposits/termdeposit.png';
import jamshavanda from '@assets/images/deposits/jamshavanda.png';
import jamshavandaplus from '@assets/images/deposits/jamshavandaplus.png';
import daromadnok from '@assets/images/deposits/daromadnok.png';

const deposits = [
  {
    title: 'Срочный',
    subTitle:
      'Депозит "Срочный" - это классический депозит с удобными сроками размещения денег и высокими фиксированными ставками вознаграждения. Ставка вознаграждения зависит от срока размещения вклада.',
    conditions: [
      {
        title: 'Сумма вклада:',
        text: 'минимальная сумма первоначального взноса составляет: в национальной валюте - 500 сомони, в иностранной валюте - 100 долларов США, 100 Евро или 5 000 российских рублей',
      },
      {
        title: 'Взнос',
        text: 'внесение дополнительных взносов не допускается',
      },
    ],
    imgPath: termdeposit,
  },
  {
    title: 'Джамшаванда',
    subTitle: 'Срочный депозит "Джамшаванда" - это вклад с возможностью увеличения первоначальной суммы.',
    conditions: [
      {
        title: 'Сумма вклада:',
        text: 'минимальная сумма первоначального взноса составляет: в национальной валюте - 500 сомони, в иностранной валюте - 100 долларов США, 100 Евро или 5 000 российских рублей',
      },
      {
        title: 'Взнос',
        text: 'допускается внесение дополнительных взносов',
      },
    ],
    imgPath: jamshavanda,
  },
  {
    title: 'Джамшаванда Плюс',
    subTitle:
      'Срочный депозит "Джамшаванда Плюс" - этот вид депозита позволяет вносить дополнительные взносы к первоначальной сумме депозита.',
    conditions: [
      {
        title: 'Сумма вклада:',
        text: 'минимальная сумма первоначального взноса составляет: в национальной валюте - 500 сомони, в иностранной валюте - 100 долларов США, 100 Евро или 5 000 российских рублей',
      },
      {
        title: 'Взнос',
        text: 'допускается внесение дополнительных взносов',
      },
    ],
    imgPath: jamshavandaplus,
  },
  {
    title: 'Даромаднок',
    subTitle:
      'Срочный депозит «Даромаднок» позволяет вам зарабатывать более высокие проценты, чем другие виды депозитов за тот же период времени.',
    conditions: [
      {
        title: 'Сумма вклада:',
        text: 'минимальная сумма первоначального взноса составляет: в национальной валюте - 1000 сомони, в иностранной валюте - 100 долларов США, 100 Евро или 6 000 российских рублей',
      },
      {
        title: 'Взнос',
        text: 'внесение дополнительных взносов не допускается',
      },
    ],
    imgPath: daromadnok,
  },
  {
    title: 'Наврасон',
    subTitle:
      'Срочный депозит "Наврасон" данный вид депозита позволит накопить крупную сумму для ребенка, тем самым обеспечив ему достойное будущее.',
    conditions: [
      {
        title: 'Сумма вклада:',
        text: 'минимальная сумма первоначального взноса составляет: в национальной валюте - 300 сомони, в иностранной валюте - 50 долларов США, 50 Евро или 3 000 российских рублей для детей сирот - в национальной валюте 150 сомони, 30 долларов США, 30 Евро или 2000 российских рублей',
      },
      {
        title: 'Взнос',
        text: 'допускается внесение дополнительных взносов',
      },
    ],
    imgPath: daromadnok,
  },
];

function Deposits() {
  return (
    <div className='flex justify-center bg-stone-100'>
      <div className='lg:w-[1130px] w-full'>
        <div className='overflow-hidden bg-white min-h-[400px] rounded-[30px] grid grid-cols-2 max-[968px]:block my-14'>
          <div className='p-[50px_40px_50px_80px] flex items-center max-[968px]:p-[50px] max-[768px]:p-10 max-[568px]:p-[32px]'>
            <div>
              <h1 className='text-4xl color-active'>Вклады</h1>
              <p className='text-lg mt-5 text-stone-500 leading-6'>
                ОАО "Ориёнбонк" принимает денежные средства физических лиц, а также индивидуальных предпринимателей в
                виде депозитов. Депозитные операции – это операции банков, связанные с привлечением денежных средств
                частных лиц на определенный срок или бессрочно на платной основе
              </p>
            </div>
          </div>
          <div className='flex justify-end'>
            <img src={mainBanner} alt='' className='items-end' />
          </div>
        </div>
        {deposits.map((deposit: any) => (
          <DepositsBanner deposit={deposit} />
        ))}
      </div>
    </div>
  );
}

export default Deposits;
