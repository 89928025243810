import { Button, Divider, Form, Input, Modal, Statistic } from 'antd';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { isConfirmPaymentModalOpenAC } from '@store/reducers/payments';
import { useTranslation } from 'react-i18next';
import digitNumber from '@utils/digitNumber';
import { transactionVerifyApi, transactionVerifyResendCodeApi } from '@http/transactions';
import { useNavigate } from 'react-router';

const { Countdown } = Statistic;

const ConfirmPaymentModal = ({
  paymentInfo,
  currency,
  paymentTransactionHandler,
  clearFields,
  route,
  title,
  type,
  recepientCurrency,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [confirmForm] = Form.useForm();
  const navigate = useNavigate();
  const isConfirmPaymentModalOpen = useAppSelector(state => state.paymentsReducer.isConfirmPaymentModalOpen);
  const otpConfirm = useAppSelector(state => state.transactionsReducer.otpConfirm);
  const deadline = useAppSelector(state => state.transactionsReducer.deadline);

  const verifyTransId = useAppSelector(state => state.transactionsReducer.verifyTransId);

  const [time, setTime] = useState(false);

  const onFinishCountDown = () => {
    setTime(true);
  };

  const confirmTransaction = (values: any) => {
    dispatch(
      transactionVerifyApi({ code: values.code, trans_id: verifyTransId }, () => {
        navigate(`/${route}/${verifyTransId}`);
        clearFields();
      })
    );
    confirmForm.resetFields();
  };

  return (
    <div>
      <Modal
        open={isConfirmPaymentModalOpen}
        onOk={() => dispatch(isConfirmPaymentModalOpenAC(false))}
        onCancel={() => dispatch(isConfirmPaymentModalOpenAC(false))}
        footer={null}
      >
        <div>
          <h1 className='text-lg'>{t('Confirm payment')}</h1>
          <div className='leading-9 mt-5'>
            <p className='lg:text-base mt-2'>
              {t('Payment type')}: <span className='font-semibold ml-2'>{t(title)}</span>
            </p>
            {type === 'EXCHANGE' ? (
              <p className='lg:text-base'>
                {t('Amount')}:
                <span className='font-semibold ml-2'>
                  {currency === 'TJS'
                    ? digitNumber(paymentInfo?.receiving_amount) + ' ' + recepientCurrency
                    : digitNumber(paymentInfo?.sending_amount) + ' ' + currency}
                </span>
              </p>
            ) : (
              <p className='lg:text-base'>
                {t('Amount')}:
                <span className='font-semibold ml-2'>
                  {digitNumber(paymentInfo?.sending_amount)} {currency}
                </span>
              </p>
            )}
          </div>

          <Divider />

          <div>
            <div className='mb-3'>
              <p className='font-light'>{t('From account')}:</p>
              <p className='font-semibold'>
                {paymentInfo?.sending_ac} ({currency})
              </p>
            </div>
            <div className='mb-3'>
              <p className='font-light'>{t('To account')}:</p>
              <p className='font-semibold'>{paymentInfo?.receiving_ac}</p>
            </div>

            {type === 'INTERBANK' ? (
              <div>
                <div className='mb-3'>
                  <p className='font-light'>{t('Recipient')}:</p>
                  <p className='font-semibold'>{paymentInfo?.receiver_name}</p>
                </div>
                <div className='mb-3'>
                  <p className='font-light'>{t('Recipient TIN')}:</p>
                  <p className='font-semibold'>{paymentInfo?.tax_id}</p>
                </div>
                <div className='mb-3'>
                  <p className='font-light'>{t('Commission')}:</p>
                  <p className='font-semibold'>{t('2 somoni')}</p>
                </div>
              </div>
            ) : null}

            {type === 'SWIFT' ? (
              <div>
                <div className='mb-3'>
                  <p className='font-light'>{t('Recipient')}:</p>
                  <p className='font-semibold'>{paymentInfo?.receiver_name}</p>
                </div>
                <div className='mb-3'>
                  <p className='font-light'>{t('Swift')}:</p>
                  <p className='font-semibold'>
                    {paymentInfo?.receiver_bank_swift ? paymentInfo?.receiver_bank_swift : paymentInfo?.bank_code}
                  </p>
                </div>
                <div className='mb-3'>
                  <p className='font-light'>{t('Bank name')}:</p>
                  <p className='font-semibold'>{paymentInfo?.receiver_bank_name}</p>
                </div>
                <div className='mb-3'>
                  <p className='font-light'>{t('Country')}:</p>
                  <p className='font-semibold'>{paymentInfo?.receiver_country}</p>
                </div>
              </div>
            ) : null}

            {type === 'SWIFT_RUB' ? (
              <div>
                <div className='mb-3'>
                  <p className='font-light'>{t('Recipient')}:</p>
                  <p className='font-semibold'>{paymentInfo?.receiver_name}</p>
                </div>
                <div className='mb-3'>
                  <p className='font-light'>{t('BIC')}:</p>
                  <p className='font-semibold'>{paymentInfo?.bank_code}</p>
                </div>
                <div className='mb-3'>
                  <p className='font-light'>{t('Correspondent account')}:</p>
                  <p className='font-semibold'>{paymentInfo?.bank_corr_acc}</p>
                </div>
                <div className='mb-3'>
                  <p className='font-light'>{t('Bank name')}:</p>
                  <p className='font-semibold'>{paymentInfo?.receiver_bank_name}</p>
                </div>
                <div className='mb-3'>
                  <p className='font-light'>{t('Country')}:</p>
                  <p className='font-semibold'>{paymentInfo?.receiver_bank_address}</p>
                </div>
              </div>
            ) : null}

            {type === 'INTERNAL' ? (
              <div className='mb-3'>
                <p className='font-light'>{t('Commission')}:</p>
                <p className='font-semibold'>0%</p>
              </div>
            ) : null}

            {type === 'EXCHANGE' ? (
              <div>
                <div className='mb-3'>
                  <p className='font-light'>{t('Credit amount')}:</p>
                  <p className='font-semibold'>
                    {currency === 'TJS'
                      ? digitNumber(paymentInfo?.sending_amount) + ' ' + currency
                      : digitNumber(paymentInfo?.sending_amount) + ' ' + currency}
                  </p>
                </div>
                <div className='mb-3'>
                  <p className='font-light'>{t('Debit amount')}:</p>
                  <p className='font-semibold'>
                    {currency === 'TJS'
                      ? digitNumber(paymentInfo?.receiving_amount) + ' ' + recepientCurrency
                      : digitNumber(paymentInfo?.receiving_amount) + ' ' + recepientCurrency}
                  </p>
                </div>
                <div className='mb-3'>
                  <p className='font-light'>{t('Rate')}:</p>
                  <p className='font-semibold'>{paymentInfo?.xrate}</p>
                </div>
              </div>
            ) : null}

            <div className='mb-3'>
              <p className='font-light'>{t('Narrative')}:</p>
              <p className='font-semibold'>
                {paymentInfo?.narrative ? paymentInfo?.narrative : paymentInfo?.narrative2}
              </p>
            </div>
          </div>

          {!otpConfirm ? (
            <div className='flex justify-end mt-5'>
              <Button type='primary' htmlType='submit' onClick={paymentTransactionHandler}>
                {t('Confirm')}
              </Button>
            </div>
          ) : (
            <div>
              <Divider />
              <div>
                <p className='text-base'>{t('Confirm payment')}</p>
                <span className='text-xs text-slate-400'>{t('ConfirmSmsFromPhone')}</span>

                <Form form={confirmForm} name='internalOperations' onFinish={confirmTransaction}>
                  <Form.Item className='m-0' name='code' rules={[{ required: true, message: `${t('Required')}` }]}>
                    <Input
                      placeholder={`${t('Enter SMS code')}`}
                      size='large'
                      style={{ width: '100%' }}
                      type='text'
                      maxLength={6}
                    />
                  </Form.Item>
                  {!time ? (
                    <div className='mt-3 flex items-center text-sm text-slate-400'>
                      <span className='mr-2'>{t('ResendTime')}</span>
                      <Countdown
                        className='!text-xs'
                        style={{ fontSize: 12 }}
                        value={deadline}
                        onFinish={onFinishCountDown}
                        format='mm:ss'
                      />
                      <span className='ml-2'> {t('Second')}!</span>
                    </div>
                  ) : null}
                  <div className='flex justify-end mt-5'>
                    {time ? (
                      <Button
                        className='mr-3'
                        onClick={() => {
                          setTime(false);
                          dispatch(transactionVerifyResendCodeApi({ trans_id: verifyTransId, trn_type: type }));
                        }}
                      >
                        {t('ResendCode')}
                      </Button>
                    ) : null}
                    <Button type='primary' htmlType='submit'>
                      {t('Confirm')}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmPaymentModal;
