import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface accountsState {
  accounts: Array<any>;
  senderAccountBalance: number;
  toAccountBalance: number;
  accountBalance: any;
  selectAccounts: Array<any>;
  accountInfo: any;
  filteredAccounts: Array<any>;
  selectedAccount: string;
  cardAccounts: Array<any>;
  selectedSenderAccount: any;
  selectedToAccount: any;
  senderAccounts: Array<any>;
  receiveAccounts: Array<any>;
  searchedAccount: any;
  isAccountValid: any;
  statement: any;
  namedAccounts: any;
  senderAccountsModalOpen: boolean;
  recipientAccountsModalOpen: boolean;
  selectedRecipientAccount: any;
}

const initialState: accountsState = {
  accounts: [],
  senderAccountBalance: 0,
  toAccountBalance: 0,
  accountBalance: {},
  selectAccounts: [],
  namedAccounts: [],
  accountInfo: [],
  filteredAccounts: [],
  selectedAccount: '',
  cardAccounts: [],
  selectedToAccount: {},
  senderAccounts: [],
  receiveAccounts: [],
  searchedAccount: {},
  isAccountValid: true,
  statement: {},
  senderAccountsModalOpen: false,
  recipientAccountsModalOpen: false,
  selectedSenderAccount: {},
  selectedRecipientAccount: {},
};

export const accounts = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    recipientAccountsModalOpenAC(state, action: PayloadAction<any>) {
      state.recipientAccountsModalOpen = action.payload;
    },
    senderAccountsModalOpenAC(state, action: PayloadAction<any>) {
      state.senderAccountsModalOpen = action.payload;
    },
    selectedRecipientAccountAC(state, action: PayloadAction<any>) {
      state.selectedRecipientAccount = action.payload;
    },
    selectedSenderAccountAC(state, action: PayloadAction<any>) {
      state.selectedSenderAccount = action.payload;
    },
    selectedToAccountAC(state, action: PayloadAction<any>) {
      state.selectedToAccount = action.payload;
    },
    selectAccountsAC(state, action: PayloadAction<Array<any>>) {
      state.selectAccounts = [];
      action.payload.forEach((item: any) => {
        state.selectAccounts.push({
          label: item.cust_ac_no + ' (' + item.ccy + ')',
          value: item.cust_ac_no,
          ccy: item.ccy,
        });
      });
    },
    namedAccountsAC(state, action: PayloadAction<Array<any>>) {
      state.namedAccounts = [];
      action.payload.forEach((item: any) => {
        state.namedAccounts.push({
          label: item.cust_ac_no + ' (' + item.ccy + ') ' + item.name,
          value: item.cust_ac_no,
          ccy: item.ccy,
          balance: item.acy_avl_bal,
        });
      });
    },
    getAccountsAC(state, action: PayloadAction<Array<any>>) {
      state.accounts = action.payload;
      state.filteredAccounts = action.payload;
    },
    senderAccountBalanceAC(state, action: PayloadAction<number>) {
      state.senderAccountBalance = action.payload;
    },
    toAccountBalanceAC(state, action: PayloadAction<number>) {
      state.toAccountBalance = action.payload;
    },
    accountBalanceAC(state, action: PayloadAction<number>) {
      state.accountBalance = action.payload;
    },
    accountInfoAC(state, action: PayloadAction<Array<any>>) {
      state.accountInfo = action.payload;
    },
    filterAccountsByCurrencyAC(state, action: PayloadAction<string>) {
      if (action.payload === 'all') {
        state.filteredAccounts = state.accounts;
      } else {
        state.filteredAccounts = state.accounts.filter(item => item.ccy === action.payload.toUpperCase());
      }
    },
    selectedAccountAC(state, action: PayloadAction<string>) {
      state.selectedAccount = action.payload;
    },
    cardAccountsAC(state, action: PayloadAction<Array<any>>) {
      state.cardAccounts = action.payload;
    },
    senderAccountsAC(state, action: PayloadAction<Array<any>>) {
      state.senderAccounts = [];
      action.payload.forEach((item: any) => {
        state.senderAccounts.push({
          label: item.cust_ac_no + ' (' + item.ccy + ')',
          value: item.cust_ac_no,
          ccy: item.ccy,
          acy_avl_bal: item.acy_avl_bal,
          record_stat: item.record_stat,
          name: item.name,
        });
      });
    },
    receiveAccountsAC(state, action: PayloadAction<Array<any>>) {
      state.receiveAccounts = [];
      action.payload.forEach((item: any) => {
        state.receiveAccounts.push({
          label: item.cust_ac_no + ' (' + item.ccy + ')',
          value: item.cust_ac_no,
          ccy: item.ccy,
          acy_avl_bal: item.acy_avl_bal,
          record_stat: item.record_stat,
          name: item.name,
        });
      });
    },
    searchedAccountAC(state, action: PayloadAction<any>) {
      state.searchedAccount = action.payload;
    },
    isAccountValidAC(state, action: PayloadAction<boolean>) {
      state.isAccountValid = action.payload;
    },
    statementAC(state, action: PayloadAction<any>) {
      state.statement = action.payload;
    },
  },
});

export const {
  accountBalanceAC,
  getAccountsAC,
  senderAccountBalanceAC,
  toAccountBalanceAC,
  selectAccountsAC,
  accountInfoAC,
  filterAccountsByCurrencyAC,
  selectedAccountAC,
  cardAccountsAC,
  selectedSenderAccountAC,
  selectedToAccountAC,
  senderAccountsAC,
  receiveAccountsAC,
  searchedAccountAC,
  isAccountValidAC,
  statementAC,
  namedAccountsAC,
  senderAccountsModalOpenAC,
  recipientAccountsModalOpenAC,
  selectedRecipientAccountAC,
} = accounts.actions;

export default accounts.reducer;
