import { Modal, Result, Button } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { transactionApproveApi } from '@http/transactions';
import { transactionActionModalOpenAC } from '@store/reducers/transactions';

const TransactionActionModal = (props: any) => {
  const { transactionId, transactionStatus } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isConfirmTransactionModalOpen = useAppSelector(state => state.transactionsReducer.isTransactionActionModalOpen);
  const actionModalType = useAppSelector(state => state.transactionsReducer.actionModalType);

  return (
    <div>
      <Modal
        open={isConfirmTransactionModalOpen}
        onOk={() => dispatch(transactionActionModalOpenAC(false))}
        onCancel={() => dispatch(transactionActionModalOpenAC(false))}
        footer={null}
        width={400}
      >
        <Result
          status='warning'
          title={actionModalType === 'DECLINE' ? t('Sure to decline?') : t('Sure to confirm?')}
          extra={[
            <div>
              {actionModalType === 'DECLINE' ? (
                <Button
                  danger
                  onClick={() => {
                    dispatch(
                      transactionApproveApi(
                        {
                          params: { trans_id: transactionId, action: 'DECLINE' },
                          page: 1,
                          status: transactionId,
                        },
                        () => dispatch(transactionActionModalOpenAC(false))
                      )
                    );
                  }}
                >
                  {t('Reject')}
                </Button>
              ) : (
                <Button
                  className='ml-4'
                  type='primary'
                  onClick={() => {
                    dispatch(
                      transactionApproveApi(
                        {
                          params: { trans_id: transactionId, action: 'APPROVE' },
                          page: 1,
                          status: transactionStatus,
                        },
                        () => dispatch(transactionActionModalOpenAC(false))
                      )
                    );
                  }}
                >
                  {t('Confirm')}
                </Button>
              )}
            </div>,
          ]}
        />
      </Modal>
    </div>
  );
};

export default TransactionActionModal;
