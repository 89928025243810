import { SyncOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { TFunction } from 'i18next';

const getStatus = (status: string, size: number = 12, t: TFunction) => {
  switch (status) {
    case 'RECEIVED':
      return (
        <Tag style={{ fontSize: size }} color='processing' className='m-0'>
          {t('RECEIVED')}
        </Tag>
      );
    case 'IN_PROGRESS':
      return (
        <Tag style={{ fontSize: size }} icon={<SyncOutlined spin />} color='processing' className='m-0'>
          {t('IN_PROGRESS')}
        </Tag>
      );
    case 'IN_VERIFICATION':
      return (
        <Tag style={{ fontSize: size }} icon={<ClockCircleOutlined />} color='processing' className='m-0'>
          {t('IN_VERIFICATION')}
        </Tag>
      );
    case 'IN_CONFIRMATION':
      return (
        <Tag style={{ fontSize: size }} icon={<ClockCircleOutlined />} color='orange' className='m-0'>
          {t('IN_CONFIRMATION')}
        </Tag>
      );
    case 'READY':
      return (
        <Tag style={{ fontSize: size }} color='success' key={status} className='m-0'>
          {t('READY')}
        </Tag>
      );
    case 'SUCCESS':
      return (
        <Tag style={{ fontSize: size }} color='success' key={status} className='m-0'>
          {t('SUCCESS')}
        </Tag>
      );
    case 'FAILED':
      return (
        <Tag style={{ fontSize: size }} color='error' key={status} className='m-0'>
          {t('FAILED')}
        </Tag>
      );
    case 'DECLINED':
      return (
        <Tag style={{ fontSize: size }} color='error' key={status} className='m-0'>
          {t('DECLINED')}
        </Tag>
      );
    case 'INVALID_FILE':
      return (
        <Tag style={{ fontSize: size }} color='error' key={status} className='m-0'>
          {t('INVALID_FILE')}
        </Tag>
      );
    case 'INVALID':
      return (
        <Tag style={{ fontSize: size }} color='error' key={status} className='m-0'>
          {t('INVALID')}
        </Tag>
      );
    case 'IN_APPROVAL':
      return (
        <Tag style={{ fontSize: size }} color='processing' key={status} className='m-0'>
          {t('IN_APPROVAL')}
        </Tag>
      );
    case 'TO_APPROVE':
      return (
        <Tag style={{ fontSize: size }} color='processing' key={status} className='m-0'>
          {t('TO_APPROVE')}
        </Tag>
      );
    case 'APPROVED':
      return (
        <Tag style={{ fontSize: size }} color='processing' key={status} className='m-0'>
          {t('APPROVED')}
        </Tag>
      );
    case 'PARTIAL_SUCCESS':
      return (
        <Tag
          style={{ fontSize: size }}
          icon={<ExclamationCircleOutlined />}
          color='volcano'
          key={status}
          className='m-0'
        >
          {t('PARTIAL_SUCCESS')}
        </Tag>
      );
    case 'POSTPONED':
      return (
        <Tag style={{ fontSize: size }} color='orange' key={status} className='m-0'>
          {t('POSTPONED')}
        </Tag>
      );
    case 'IN_BANK_REVIEW':
      return (
        <Tag style={{ fontSize: size }} color='processing' key={status} className='m-0'>
          {t('IN_BANK_REVIEW')}
        </Tag>
      );
    case 'IN_REVIEW':
      return (
        <Tag style={{ fontSize: size }} color='processing' key={status} className='m-0'>
          {t('IN_REVIEW')}
        </Tag>
      );
    case 'BANK_APPROVED':
      return (
        <Tag style={{ fontSize: size }} color='success' key={status} className='m-0'>
          {t('BANK_APPROVED')}
        </Tag>
      );
    case 'CANCELED':
      return (
        <Tag style={{ fontSize: size }} color='error' key={status} className='m-0'>
          {t('CANCELED')}
        </Tag>
      );
    case 'BANK_DECLINED':
      return (
        <Tag style={{ fontSize: size }} color='error' key={status} className='m-0'>
          {t('BANK_DECLINED')}
        </Tag>
      );
    case 'REVERSED':
      return (
        <Tag style={{ fontSize: size }} color='processing' key={status} className='m-0'>
          {t('REVERSED')}
        </Tag>
      );
    case 'IN_VALIDATION':
      return (
        <Tag style={{ fontSize: size }} color='processing' key={status} className='m-0'>
          {t('IN_VALIDATION')}
        </Tag>
      );
    case 'READY_TO_PROCEED':
      return (
        <Tag style={{ fontSize: size }} color='success' key={status} className='m-0'>
          {t('READY_TO_PROCEED')}
        </Tag>
      );
    default:
      break;
  }
};

export default getStatus;
