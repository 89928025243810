import AccountInfo from '@components/Accounts/AccountInfo';
import CardAccounts from './CardAccounts';
import { useTranslation } from 'react-i18next';

function Visa() {
  const { t } = useTranslation();

  return (
    <div className='flex justify-center'>
      <div style={{ width: '1130px' }}>
        <div className='flex'>
          <div className='py-5 px-8 w-full h-full bg-white mb-12'>
            <div className='grid grid-cols-6 gap-10 mt-5'>
              <div className='col-span-4'>
                <h1 className='text-2xl mb-2 font-semibold'>{t('MyAccounts')}</h1>
                <div className='flex border-b'>
                  <div className='header__items py-5 header__items-active mr-5 text-base'>{t('Card accounts')}</div>
                </div>
                <CardAccounts />
              </div>
              <div className='col-span-2'>
                <AccountInfo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Visa;
