import { useEffect, useRef, useState } from 'react';
import { Pagination, Modal, Button, Upload } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useNavigate } from 'react-router-dom';
import { FileAddOutlined, FileDoneOutlined, FileExcelOutlined } from '@ant-design/icons';
import { getSalaryListApi, getSalaryTemplateApi, refreshSalaryListApi, uploadSalaryFileApi } from '@http/salary';

import getStatus from '@utils/getStatus';

import SalaryListSearchForm from './SalaryListSearchForm';

import { useTranslation } from 'react-i18next';

import { otpConfirmAC } from '@store/reducers/transactions';
import openNotification from '@utils/openNotification';

export const SalaryList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const salaryList = useAppSelector(state => state.salaryReducer.salaryList);
  const permissions = useAppSelector(state => state.userReducer.permissions);
  const salaryListSearchQuery = useAppSelector(state => state.salaryReducer.salaryListSearchQuery);

  const pageRef = useRef(1);
  const salaryListRef = useRef(salaryList);

  const [isImportFileModalOpen, setIsImportFileModalOpen] = useState(false);

  const changePage = (page: number) => {
    pageRef.current = page;
    dispatch(getSalaryListApi({ ...salaryListSearchQuery }, page));
  };

  const fetchData = () => {
    dispatch(getSalaryListApi({ ...salaryListSearchQuery }, 1));
  };

  const refreshData = () => {
    const hasInProgress = salaryListRef.current?.results?.some(
      (item: any) => item.status === 'IN_PROGRESS' || item.status === 'IN_VALIDATION'
    );

    if (hasInProgress) {
      dispatch(refreshSalaryListApi({ ...salaryListSearchQuery }, pageRef.current));
    }
  };

  useEffect(() => {
    salaryListRef.current = salaryList;
  }, [salaryList]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(refreshData, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div className='flex items-center gap-x-3 mb-2 justify-between'>
        <h1 className='text-2xl font-semibold color-active'>{t('Salary project')}</h1>
        {permissions?.salary[0]?.allow_init ? (
          <div className='flex items-center gap-x-4'>
            <Button
              size='large'
              type='primary'
              icon={<FileExcelOutlined />}
              onClick={() => setIsImportFileModalOpen(true)}
            >
              {t('Import')}
            </Button>
          </div>
        ) : null}
      </div>
      <div className='mb-5 mt-7'>
        <SalaryListSearchForm />
      </div>
      <div
        className='grid grid-cols-12 max-lg:text-xs text-sm max-sm:py-3 sm:p-5 gap-5 font-semibold color-active'
        style={{ borderBottom: '1px solid #eee' }}
      >
        <p className='col-span-1 max-md:col-span-2'>{t('Date')}</p>
        <p className='col-span-5 max-sm:col-span-4'>{t('Name')}</p>
        <p className='col-span-2 max-md:hidden'>{t('Initiator')}</p>
        <p className='col-span-2 max-sm:col-span-3 text-right'>{t('Amount')}</p>
        <p className='col-span-2 max-sm:col-span-3 text-right'>{t('Status')}</p>
      </div>
      {salaryList?.results?.length > 0 ? (
        <div>
          {salaryList?.results?.map((item: any) => (
            <div
              style={{ borderBottom: '1px solid #eee' }}
              className='grid grid-cols-12 items-center sm:p-5 max-sm:py-3 max-sm:text-ex border-b cursor-pointer gap-5 listItem max-lg:text-xs text-sm'
              key={item.id}
              onDoubleClick={() => {
                navigate(`/salary/info/${item.file_id}`);
              }}
            >
              <p className='col-span-1 max-md:col-span-2 font-medium text-center'>{item.created_at}</p>
              <p className='col-span-5 max-sm:col-span-4'>{item.title}</p>
              <p className='col-span-2 max-md:hidden'>{item.initiator}</p>
              <p className='col-span-2 text-right max-sm:col-span-3'>
                {item.total_amount} {item.trn_ccy}
              </p>
              <p className='col-span-2 max-sm:col-span-3 text-right max-lg:hidden'>{getStatus(item.status, 12, t)}</p>
              <p className='col-span-2 max-sm:col-span-3 text-right lg:hidden'>{getStatus(item.status, 10, t)}</p>
            </div>
          ))}

          <div className='flex justify-end mt-5'>
            <Pagination
              onChange={changePage}
              current={salaryList.current_page}
              defaultPageSize={10}
              total={salaryList.total_pages * 10}
              showSizeChanger={false}
            />
          </div>
        </div>
      ) : (
        <div className='h-24 lg:h-72 flex items-center justify-center'>
          <p>{t('No Transactions')}</p>
        </div>
      )}

      <Modal
        open={isImportFileModalOpen}
        onOk={() => setIsImportFileModalOpen(false)}
        onCancel={() => setIsImportFileModalOpen(false)}
        footer={null}
      >
        <p className='text-center text-lg mb-7 font-bold color-active'>{t('Importing payments from a file')}</p>

        <div
          className='p-5 flex items-center mb-5 cursor-pointer rounded-lg'
          style={{ border: '1px solid #eee' }}
          onClick={() => dispatch(getSalaryTemplateApi(() => setIsImportFileModalOpen(false)))}
        >
          <FileDoneOutlined style={{ fontSize: '38px' }} className='color-active' />
          <p className='font-medium color-active text-base ml-3'>{t('Download salary file template')}</p>
        </div>

        <Upload
          className='w-full'
          maxCount={1}
          showUploadList={false}
          name='logo'
          onChange={e => {
            dispatch(
              uploadSalaryFileApi(e.file, () => {
                dispatch(getSalaryListApi({ ...salaryListSearchQuery }, 1));
                setIsImportFileModalOpen(false);
                openNotification('success', { code: '', message: '', description: 'Файл отправлен на проверку' });
              })
            );
          }}
          beforeUpload={() => false}
          listType='picture'
          accept='.xlsx,.xls,.xlsb,.xlsm'
        >
          <div className='p-5 flex items-center rounded-lg w-full cursor-pointer' style={{ border: '1px solid #eee' }}>
            <div className='flex items-center'>
              <FileAddOutlined style={{ fontSize: '38px' }} className='color-active' />
              <div className='ml-3'>
                <p className='font-medium color-active text-base'>{t('Upload document')}</p>
                <p className='text-slate-500'>{t('The file format must be xlsx, xls')}</p>
                <p className='text-slate-500'>{t('The file size should not exceed 2MB')}</p>
              </div>
            </div>
          </div>
        </Upload>
      </Modal>
    </div>
  );
};

export default SalaryList;
