import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface depositsState {
  depositList: any;
  depositInfo: any;
  selectedDeposit: any;
}

const initialState: depositsState = {
  depositList: [],
  depositInfo: {},
  selectedDeposit: {},
};

const deposits = createSlice({
  name: 'loans',
  initialState,
  reducers: {
    depositListAC(state, action) {
      state.depositList = action.payload;
    },
    depositInfoAC(state, action) {
      state.depositInfo = action.payload;
    },
    selectedDepositAC(state, action) {
      state.selectedDeposit = action.payload;
    },
  },
});

export const { depositListAC, depositInfoAC, selectedDepositAC } = deposits.actions;
export default deposits.reducer;
