import { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/redux';

import { getAccountBalanceApi, getReceiveAccountsApi, getSenderAccountsApi } from '@http/accounts';
import { ownAccountTransactionApi } from '@http/payments';

import Currencies from '@components/Currency/OrienbankRates';
import SenderAccountsModal from '@components/Payments/modals/SenderAccountsModal';
import RecipientAccountsModal from '@components/Payments/modals/RecipientAccountsModal';
import ConfirmPaymentModal from '@components/Payments/modals/ConfirmPaymentModal';

import { isConfirmPaymentModalOpenAC, ownAccountsQueryAC } from '@store/reducers/payments';
import {
  senderAccountBalanceAC,
  senderAccountsModalOpenAC,
  receiveAccountsAC,
  selectedSenderAccountAC,
  toAccountBalanceAC,
  recipientAccountsModalOpenAC,
  selectedRecipientAccountAC,
} from '@store/reducers/accounts';
import { otpConfirmAC, repeatOwnTransactionAC, verifyTransIdAC } from '@store/reducers/transactions';
import OwnAccountsForm from '@components/Payments/forms/OwnAccountsForm';

function OwnAccounts() {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { senderAccounts, receiveAccounts } = useAppSelector(state => state.accountsReducer);
  const repeatOwnTransaction = useAppSelector(state => state.transactionsReducer.repeatOwnTransaction);
  const ownAccountsQuery = useAppSelector(state => state.paymentsReducer.ownAccountsQuery);

  const [senderAccountCurrency, setSenderAccountCurrency] = useState('');
  const [toAccountCurrency, setToAccountCurrency] = useState('');

  useEffect(() => {
    initializeData();

    return clearFields;
  }, []);

  useEffect(() => {
    repeatOwnTransactionHandler();
  }, [repeatOwnTransaction]);

  useEffect(() => {
    dispatch(otpConfirmAC(false));
  }, [ownAccountsQuery]);

  const senderAccountSelect = (account: any) => {
    setToAccountCurrency('');
    setSenderAccountCurrency(account.ccy);
    dispatch(ownAccountsQueryAC({ ...ownAccountsQuery, sending_ac: account.value, receiving_ac: '' }));

    form.setFieldValue('sending_ac', account.value);

    dispatch(senderAccountsModalOpenAC(false));
    dispatch(selectedSenderAccountAC(account.value));
    dispatch(getAccountBalanceApi('sender', { cust_ac_no: account.value }));
    dispatch(receiveAccountsAC([]));
    dispatch(toAccountBalanceAC(0));
    dispatch(
      getReceiveAccountsApi({
        transaction_type: 'OWN',
        cust_ac_no: account.value,
      })
    );
  };

  const toAccountSelect = (account: any) => {
    setToAccountCurrency(account.ccy);
    dispatch(ownAccountsQueryAC({ ...ownAccountsQuery, receiving_ac: account.value }));

    form.setFieldValue('receiving_ac', account.value);

    dispatch(recipientAccountsModalOpenAC(false));
    dispatch(getAccountBalanceApi('to', { cust_ac_no: account.value }));
    dispatch(selectedRecipientAccountAC(account.value));
  };

  const ownAccountTransaction = () => {
    dispatch(ownAccountTransactionApi(ownAccountsQuery, navigate, () => clearFields()));
  };

  const initializeData = () => {
    dispatch(selectedSenderAccountAC({}));
    dispatch(receiveAccountsAC([]));
    dispatch(senderAccountBalanceAC(0));
    dispatch(toAccountBalanceAC(0));
    dispatch(
      getSenderAccountsApi({
        transaction_type: 'OWN',
      })
    );
  };

  const repeatOwnTransactionHandler = () => {
    if (Object.keys(repeatOwnTransaction).length > 0) {
      setSenderAccountCurrency(repeatOwnTransaction.sending_ccy);
      setToAccountCurrency(repeatOwnTransaction.sending_ccy);
      dispatch(
        ownAccountsQueryAC({
          sending_ac: repeatOwnTransaction.sending_ac,
          receiving_ac: repeatOwnTransaction.receiving_ac,
          sending_amount: '',
          narrative: repeatOwnTransaction.narrative,
        })
      );

      form.setFieldValue('sending_ac', repeatOwnTransaction.sending_ac);
      form.setFieldValue('receiving_ac', repeatOwnTransaction.receiving_ac);
      form.setFieldValue('narrative', repeatOwnTransaction.narrative);

      dispatch(selectedSenderAccountAC(repeatOwnTransaction.sending_ac));
      dispatch(selectedRecipientAccountAC(repeatOwnTransaction.receiving_ac));
      dispatch(getAccountBalanceApi('sender', { cust_ac_no: repeatOwnTransaction.sending_ac }));
      dispatch(getAccountBalanceApi('to', { cust_ac_no: repeatOwnTransaction.receiving_ac }));
      dispatch(
        getReceiveAccountsApi({
          transaction_type: 'OWN',
          cust_ac_no: repeatOwnTransaction.sending_ac,
        })
      );
    }
  };

  const clearFields = () => {
    dispatch(
      ownAccountsQueryAC({
        sending_ac: '',
        receiving_ac: '',
        sending_amount: '',
        narrative: '',
      })
    );

    form.resetFields();

    setToAccountCurrency('');
    setSenderAccountCurrency('');
    dispatch(isConfirmPaymentModalOpenAC(false));
    dispatch(selectedSenderAccountAC({}));
    dispatch(receiveAccountsAC([]));
    dispatch(senderAccountBalanceAC(0));
    dispatch(toAccountBalanceAC(0));
    dispatch(otpConfirmAC(false));
    dispatch(verifyTransIdAC(''));
    dispatch(repeatOwnTransactionAC({}));
    dispatch(selectedRecipientAccountAC({}));
  };

  return (
    <div className='md:grid grid-cols-6 gap-10 mt-5'>
      <div className='col-span-4'>
        <OwnAccountsForm
          form={form}
          senderAccountCurrency={senderAccountCurrency}
          toAccountCurrency={toAccountCurrency}
        />
        <SenderAccountsModal accounts={senderAccounts} selectHandler={senderAccountSelect} />
        <RecipientAccountsModal accounts={receiveAccounts} selectHandler={toAccountSelect} />
        <ConfirmPaymentModal
          title='Transfer between own accounts'
          paymentInfo={ownAccountsQuery}
          currency={senderAccountCurrency}
          paymentTransactionHandler={ownAccountTransaction}
          clearFields={clearFields}
          route={'ownAccountsPaymentStatus'}
          type='OWN'
          recepientCurrency={toAccountCurrency}
        />
      </div>
      <div className='col-span-2 max-md:hidden'>
        <Currencies size='max-lg:text-xs text-sm' />
      </div>
    </div>
  );
}

export default OwnAccounts;
