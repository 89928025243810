import { $host } from './index';
import { paymentDetailsAC, bankBicsAC, searchedSwiftAC, exchangeXrateAC } from '@store/reducers/payments';
import { isSpinAC, notificationModalAC } from '@store/reducers/app';
import { AppDispatch } from '@store';
import { deadlineAC, otpConfirmAC, verifyTransIdAC } from '@store/reducers/transactions';

const END_POINT = '/client';

const checkBicApi = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    await $host.post(`${END_POINT}/transaction/check-bic`, params);
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getBankBicsApi = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/search-bic`);
    dispatch(bankBicsAC(data));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

// swift

const searchSwiftApi = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/search-swift`, params);
    dispatch(searchedSwiftAC(data));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const checkSwiftApi = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    await $host.post(`${END_POINT}/transaction/check-swift`, params);
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

// swift

const swiftTransactionApi = (params: any, navigate: any, callback: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/swift`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (data) {
      if (data?.otp_required) {
        dispatch(otpConfirmAC(true));
        dispatch(verifyTransIdAC(data?.trans_id));
        dispatch(deadlineAC(Date.now() + 1000 * data.code_lifetime));
      } else {
        callback();
        navigate(`/swiftPaymentStatus/${data.trans_id}`);
      }
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

// swift_rub

const swiftRubTransactionApi = (params: any, navigate: any, callback: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/swift/rub`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (data) {
      if (data?.otp_required) {
        dispatch(otpConfirmAC(true));
        dispatch(verifyTransIdAC(data?.trans_id));
        dispatch(deadlineAC(Date.now() + 1000 * data.code_lifetime));
      } else {
        callback();
        navigate(`/swiftRubPaymentStatus/${data.trans_id}`);
      }
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

// own

const ownAccountTransactionApi = (params: any, navigate: any, callback: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/own-account`, params);
    if (data) {
      if (data?.otp_required) {
        dispatch(otpConfirmAC(true));
        dispatch(verifyTransIdAC(data?.trans_id));
        dispatch(deadlineAC(Date.now() + 1000 * data.code_lifetime));
      } else {
        callback();
        navigate(`/ownAccountsPaymentStatus/${data.trans_id}`);
      }
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

// internal

const internalTransactionApi = (params: any, navigate: any, callback: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/internal`, params);
    if (data) {
      if (data?.otp_required) {
        dispatch(otpConfirmAC(true));
        dispatch(verifyTransIdAC(data?.trans_id));
        dispatch(deadlineAC(Date.now() + 1000 * data.code_lifetime));
      } else {
        callback();
        navigate(`/internalPaymentStatus/${data.trans_id}`);
      }
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

// interbank

const interbankTransactionApi = (params: any, navigate: any, callback: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/interbank`, params);
    if (data) {
      if (data?.otp_required) {
        dispatch(otpConfirmAC(true));
        dispatch(verifyTransIdAC(data?.trans_id));
        dispatch(deadlineAC(Date.now() + 1000 * data.code_lifetime));
      } else {
        callback();
        navigate(`/interbankPaymentStatus/${data.trans_id}`);
      }
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

// exchange

const exchangeTransactionApi = (params: any, navigate: any, callback: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/fx/init`, params);
    if (data) {
      if (data?.otp_required) {
        dispatch(otpConfirmAC(true));
        dispatch(verifyTransIdAC(data?.trans_id));
        dispatch(deadlineAC(Date.now() + 1000 * data.code_lifetime));
      } else {
        callback();
        navigate(`/exchangePaymentStatus/${data.trans_id}`);
      }
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

// get exchange rate

const getExchangeRateApi = (params: any, callback: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/fx/rate`, params);
    if (data) {
      dispatch(exchangeXrateAC(data.xrate));
      callback(data);
    }
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error.response?.data?.detail,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

// get payment details

const getPaymentDetailsApi = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/details`, params);
    dispatch(paymentDetailsAC(data));
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error.response?.data?.detail,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

// get payment details order

const getPaymentDetailsOrderApi = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const response = await $host.post(`${END_POINT}/transaction/payment/order`, params, {
      responseType: 'arraybuffer',
    });
    const dispositionHeader = response.headers['content-disposition'];
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(dispositionHeader);
    let filename = '';
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    link.click();
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getPaymentDetailsOrderToPrintApi = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/payment/order`, params, {
      responseType: 'arraybuffer',
    });
    const blob = new Blob([data], { type: 'application/pdf' });

    // Создаем URL объекта Blob
    const url = URL.createObjectURL(blob);

    // Открываем содержимое на печать
    const printWindow = window.open(url, '_blank');

    // Печатаем документ после полной загрузки
    printWindow?.addEventListener('load', () => {
      printWindow?.print();
    });
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

// get payment details order

const getPaymentOrderApi = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const response = await $host.post(`${END_POINT}/transaction/bill`, params, {
      responseType: 'arraybuffer',
    });
    const dispositionHeader = response.headers['content-disposition'];
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(dispositionHeader);
    let filename = '';
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    link.click();
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

const getPaymentOrderToPrintApi = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isSpinAC(true));
    const { data } = await $host.post(`${END_POINT}/transaction/bill`, params, {
      responseType: 'arraybuffer',
    });
    const blob = new Blob([data], { type: 'application/pdf' });

    // Создаем URL объекта Blob
    const url = URL.createObjectURL(blob);

    // Открываем содержимое на печать
    const printWindow = window.open(url, '_blank');

    // Печатаем документ после полной загрузки
    printWindow?.addEventListener('load', () => {
      printWindow?.print();
    });
  } catch (error: any) {
    dispatch(
      notificationModalAC({
        isOpen: true,
        message: error?.response?.data?.detail || error?.response?.data?.message,
        type: 'error',
      })
    );
  } finally {
    dispatch(isSpinAC(false));
  }
};

export {
  interbankTransactionApi,
  checkBicApi,
  getBankBicsApi,
  searchSwiftApi,
  checkSwiftApi,
  swiftTransactionApi,
  internalTransactionApi,
  ownAccountTransactionApi,
  getPaymentDetailsApi,
  getPaymentDetailsOrderApi,
  getPaymentOrderApi,
  exchangeTransactionApi,
  getExchangeRateApi,
  getPaymentOrderToPrintApi,
  getPaymentDetailsOrderToPrintApi,
  swiftRubTransactionApi,
};
