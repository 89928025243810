import logo from '@assets/images/logoSmall.svg';

function LoaderApp() {
  return (
    <div className='h-screen flex items-center justify-center'>
      <img className='animate-bounce h-12' src={logo} alt='' />
    </div>
  );
}

export default LoaderApp;
