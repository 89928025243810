import visaIcon from '@assets/images/Visa_Inc._logo.svg.png';

function CardAccounts() {
  return (
    <div>
      <div className='flex bg-yellow-50 justify-between items-center p-5 border-b  cursor-pointer'>
        <div>
          <p className='text-base font-medium flex items-center'>
            <img className='w-16 h-4' src={visaIcon} alt='' />
            <p className='text-lg font-bold ml-2' style={{ color: '#00579f' }}>
              Business
            </p>
          </p>
          <div className='flex items-center'>
            <div className='bg-green-500 h-3 w-3 mr-3 rounded-full' />
            <p>Активный</p>
          </div>
        </div>
        <p className='text-base font-medium'>1000.20 TJS</p>
      </div>
      <div className='flex justify-between items-center p-5 border-b cursor-pointer'>
        <div>
          <p className='text-base font-medium flex items-center'>
            <img className='w-16 h-4' src={visaIcon} alt='' />
            <p className='text-lg font-bold ml-2' style={{ color: '#00579f' }}>
              Business
            </p>
          </p>
          <div className='flex items-center'>
            <div className='bg-green-500 h-3 w-3 mr-3 rounded-full' />
            <p>Активный</p>
          </div>
        </div>
        <p className='text-base font-medium'>100.23 USD</p>
      </div>
    </div>
  );
}

export default CardAccounts;
