import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import logoSmall from '@assets/images/logoSmall.svg';
import { Link } from 'react-router-dom';
import { Divider } from 'antd';

const FooterNew = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className='max-w-[1270px] mx-auto px-14 max-[768px]:px-10 max-[568px]:px-5'>
      <div className='bg-white'>
        <div>
          <div
            className={
              'pt-[50px] pb-[30px] grid gap-[50px] grid-cols-[4fr_3fr_3fr] max-[768px]:py-[40px] max-[968px]:grid-cols-2 max-[568px]:grid-cols-1 max-[568px]:py-[30px] max-[568px]:gap-[30px]'
            }
          >
            <div>
              <Link to='/' className='flex items-center'>
                <img src={logoSmall} alt='' className='w-10 h-10' />
                <div className='text-white font-bold ml-3'>
                  <p className='md:text-2xl text-xl leading-4 mt-1 color-active'>{t('Oriyon')}</p>
                  <p
                    className={`${i18n.language === 'en' ? 'mt-0.5' : ''} text-xs md:text-base text-right color-active`}
                  >
                    {t('Business')}
                  </p>
                </div>
              </Link>
              <p className='text-sm lg:text-base text-stone-500 leading-6 mt-5'>{t('Footer.address')}</p>
              <p className={'text-sm lg:text-base text-stone-500 mt-5'}>
                {t('Footer.emails')}:{' '}
                <a
                  className={'hover:text-primary text-sm lg:text-base transition-all underline text-black'}
                  href='mailto:oriyon-business@oriyonbonk.tj'
                >
                  oriyon-business@oriyonbonk.tj
                </a>
              </p>
            </div>
            <div>
              <div>
                <a className={'text-xl font-bold max-[568px]:text-lg text-black'} href='tel:+992446007035'>
                  +992 (44) 600 70 35
                </a>
                <p className={'text-sm lg:text-base text-stone-500 mt-1'}>{t('Footer.supportNumber')}</p>
              </div>

              <div className={'mt-5'}>
                <a className={'text-xl font-bold block max-[568px]:text-lg text-black'} href='tel:+992446001520'>
                  +992 44 600 15 20
                </a>
                <p className={'text-sm lg:text-base text-stone-500 mt-1'}>{t('Footer.nbt')}</p>
              </div>
            </div>
            <div>
              <h1 className={'text-xl font-semibold block max-[568px]:text-lg'}>{t('Footer.workTime')}</h1>
              <div>
                <p className={'text-sm lg:text-base text-stone-500 mt-3'}>{t('Footer.workDays')}</p>
                <p className={'text-sm lg:text-base text-stone-500 mt-3'}>{t('Footer.dayOf')}</p>
                {/* <p className={'text-sm lg:text-base text-stone-500 mt-3'}>{t('Footer.sunday')}</p> */}
              </div>
            </div>
          </div>
          <Divider />
          <div className={'flex items-center justify-between pb-[30px] pt-[10px] bg-white max-[768px]:block'}>
            <p className={'text-sm lg:text-base text-stone-500'}>
              ©️ 1991 - {moment().year()}, {t('Footer.orb')}
            </p>
            <p className={'text-sm lg:text-base text-stone-500 max-[768px]:mt-2'}>{t('Footer.license')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterNew;
