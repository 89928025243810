import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getAccountsApi, getAccountInfoApi } from '@http/accounts';
import { selectedAccountAC } from '@store/reducers/accounts';
import { getTransactionListApi } from '@http/transactions';
import digitNumber from '@utils/digitNumber';
import { useTranslation } from 'react-i18next';

function BankAccounts(props: any) {
  const { t } = useTranslation();
  const { itemsCount, defaultInfo } = props;
  const dispatch = useAppDispatch();
  const bankAccounts = useAppSelector(state => state.accountsReducer.filteredAccounts);
  const selectedAccount = useAppSelector(state => state.accountsReducer.selectedAccount);

  const getAccountInfo = (account: any) => {
    dispatch(selectedAccountAC(account.cust_ac_no));
    dispatch(getTransactionListApi(1, { sending_ac: account.cust_ac_no }));
    dispatch(
      getAccountInfoApi({
        cust_ac_no: account.cust_ac_no,
      })
    );
  };

  useEffect(() => {
    dispatch(getAccountsApi());
  }, []);

  useEffect(() => {
    if (bankAccounts.length > 0 && defaultInfo) {
      dispatch(
        getAccountInfoApi({
          cust_ac_no: bankAccounts[0].cust_ac_no,
        })
      );
    }
  }, [bankAccounts]);

  return (
    <div>
      {bankAccounts.length > 0 ? (
        <div>
          {bankAccounts.slice(0, itemsCount).map((item: any) => (
            <div key={item.cust_ac_no} onClick={() => getAccountInfo(item)}>
              <div
                style={{ borderBottom: '1px solid #eee' }}
                className={`flex justify-between items-center p-5 border-b cursor-pointer listItem ${
                  selectedAccount === item.cust_ac_no ? 'bg-yellow-50' : null
                }`}
              >
                <div>
                  <p className='text-base font-medium'>{item.cust_ac_no}</p>
                  <div>
                    {item.record_stat === 'O' ? (
                      <div className='flex items-center'>
                        <div className='bg-green-500 h-3 w-3 mr-3 rounded-full' />
                        <p className='text-sm'>{t('Active')}</p>
                      </div>
                    ) : (
                      <div className='flex items-center'>
                        <div className='bg-red-400 h-3 w-3 mr-3 rounded-full' />
                        <p className='text-sm'>{t('Not active')}</p>
                      </div>
                    )}
                  </div>
                </div>
                <p className='text-base font-medium'>
                  {digitNumber(item.acy_avl_bal)} <span className='font-bold'>{item.ccy}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className='h-24 flex items-center justify-center'>
          <p>{t('No accounts')}</p>
        </div>
      )}
    </div>
  );
}

export default BankAccounts;
