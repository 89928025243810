import { useState, useEffect } from 'react';
import { Form, Divider } from 'antd';
import { useNavigate } from 'react-router';

import { useAppDispatch, useAppSelector } from '@hooks/redux';

import { checkIsAccountOwnApi, getAccountBalanceApi, getSenderAccountsApi } from '@http/accounts';
import { getTemplatesApi } from '@http/user';
import { internalTransactionApi } from '@http/payments';

import Templates from '@components/Templates/Templates';
import SenderAccountsModal from '@components/Payments/modals/SenderAccountsModal';
import ConfirmPaymentModal from '@components/Payments/modals/ConfirmPaymentModal';
import SaveTemplateModal from '@components/Payments/modals/SaveTemplateModal';
import Currencies from '@components/Currency/OrienbankRates';

import { pickedInternalTemplateAC } from '@store/reducers/user';
import { internalOperationsQueryAC, isConfirmPaymentModalOpenAC } from '@store/reducers/payments';
import {
  senderAccountBalanceAC,
  searchedAccountAC,
  selectedSenderAccountAC,
  senderAccountsModalOpenAC,
} from '@store/reducers/accounts';
import { otpConfirmAC, repeatInternalTransactionAC, verifyTransIdAC } from '@store/reducers/transactions';
import InternalOperationsForm from '@components/Payments/forms/InternalOperationsForm';

function Internal() {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const senderAccounts = useAppSelector(state => state.accountsReducer.senderAccounts);

  const repeatInternalTransaction = useAppSelector(state => state.transactionsReducer.repeatInternalTransaction);
  const pickedInternalTemplate = useAppSelector(state => state.userReducer.pickedInternalTemplate);
  const internalOperationsQuery = useAppSelector(state => state.paymentsReducer.internalOperationsQuery);
  const newInternalTemplate = useAppSelector(state => state.paymentsReducer.newInternalTemplate);

  const [senderAccountCurrency, setSenderAccountCurrency] = useState('');

  useEffect(() => {
    initializeData();

    return clearFields;
  }, []);

  useEffect(() => {
    pickedInternalTemplateHandler();
  }, [pickedInternalTemplate]);

  useEffect(() => {
    repeatInternalTransactionHandler();
  }, [repeatInternalTransaction]);

  useEffect(() => {
    dispatch(otpConfirmAC(false));
  }, [internalOperationsQuery]);

  const senderAccountSelect = (account: any) => {
    setSenderAccountCurrency(account.ccy);
    dispatch(internalOperationsQueryAC({ ...internalOperationsQuery, sending_ac: account.value }));

    form.setFieldValue('sending_ac', account.value);

    dispatch(senderAccountsModalOpenAC(false));
    dispatch(selectedSenderAccountAC(account.value));
    dispatch(getAccountBalanceApi('sender', { cust_ac_no: account.value }));
  };

  const internalTransaction = () => {
    dispatch(internalTransactionApi(internalOperationsQuery, navigate, () => clearFields()));
  };

  const initializeData = () => {
    dispatch(selectedSenderAccountAC({}));
    dispatch(searchedAccountAC({}));
    dispatch(senderAccountBalanceAC(0));
    dispatch(getTemplatesApi({ transaction_type: 'INTERNAL' }));
    dispatch(
      getSenderAccountsApi({
        transaction_type: 'INTERNAL',
      })
    );
  };

  const pickedInternalTemplateHandler = () => {
    setSenderAccountCurrency(pickedInternalTemplate.sending_ccy);

    fillForms(pickedInternalTemplate, 'template');
    fillQuery(pickedInternalTemplate, 'template');

    if (+pickedInternalTemplate.sending_amount > 0) {
      dispatch(getAccountBalanceApi('sender', { cust_ac_no: pickedInternalTemplate.sending_ac }));
      dispatch(checkIsAccountOwnApi({ cust_ac_no: pickedInternalTemplate.receiving_ac, transaction_type: 'INTERNAL' }));
    }
  };

  const repeatInternalTransactionHandler = () => {
    setSenderAccountCurrency(repeatInternalTransaction.sending_ccy);

    fillForms(repeatInternalTransaction, 'repeat');
    fillQuery(repeatInternalTransaction, 'repeat');

    if (+repeatInternalTransaction.sending_amount > 0) {
      dispatch(getAccountBalanceApi('sender', { cust_ac_no: repeatInternalTransaction.sending_ac }));
      dispatch(
        checkIsAccountOwnApi({ cust_ac_no: repeatInternalTransaction.receiving_ac, transaction_type: 'INTERNAL' })
      );
    }
  };

  const fillForms = (data: any, type: any) => {
    form.setFieldValue('sending_ac', data.sending_ac);
    form.setFieldValue('receiving_ac', data.receiving_ac);
    form.setFieldValue('narrative', data.narrative);
    form.setFieldValue('sending_amount', data.sending_amount);

    dispatch(selectedSenderAccountAC(data?.sending_ac));
  };

  const fillQuery = (data: any, type: any) => {
    dispatch(
      internalOperationsQueryAC({
        sending_ac: data.sending_ac,
        receiving_ac: data.receiving_ac,
        sending_amount: data.sending_amount,
        narrative: data.narrative,
      })
    );
  };

  const clearFields = () => {
    setSenderAccountCurrency('');
    dispatch(
      internalOperationsQueryAC({
        sending_ac: '',
        receiving_ac: '',
        sending_amount: '',
        narrative: '',
      })
    );

    form.resetFields();

    dispatch(isConfirmPaymentModalOpenAC(false));
    dispatch(selectedSenderAccountAC({}));
    dispatch(searchedAccountAC({}));
    dispatch(otpConfirmAC(false));
    dispatch(verifyTransIdAC(''));
    dispatch(repeatInternalTransactionAC({}));
    dispatch(pickedInternalTemplateAC({}));
  };

  return (
    <div className='md:grid grid-cols-6 gap-10 mt-5'>
      <div className='col-span-4'>
        <div className=' mt-7'>
          <Templates type='INTERNAL' />
        </div>
        <Divider className='my-6' />
        <InternalOperationsForm form={form} senderAccountCurrency={senderAccountCurrency} />
        <SenderAccountsModal accounts={senderAccounts} selectHandler={senderAccountSelect} />
        <ConfirmPaymentModal
          title='Intrabank payment'
          paymentInfo={internalOperationsQuery}
          currency={senderAccountCurrency}
          paymentTransactionHandler={internalTransaction}
          clearFields={clearFields}
          route={'internalPaymentStatus'}
          type='INTERNAL'
          recepientCurrency={senderAccountCurrency}
        />
        <SaveTemplateModal template={newInternalTemplate} />
      </div>
      <div className='col-span-2 max-md:hidden'>
        <Currencies size='max-lg:text-xs text-sm' />
      </div>
    </div>
  );
}

export default Internal;
