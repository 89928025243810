import { useAppDispatch, useAppSelector } from '@hooks/redux';

import digitNumber from '@utils/digitNumber';
import { useTranslation } from 'react-i18next';
import LoanInfo from '@components/Loans/LoanInfo';
import LoansList from '@components/Loans/LoansList';
import { useEffect, useState } from 'react';
import { getLoanStatementApi } from '@http/loans';
import { Pagination } from 'antd';
import { formatDate } from '@utils/formatDate';

function LoansIndex() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loanStatement = useAppSelector(state => state.loansReducer.loanStatement);
  const loansList = useAppSelector(state => state.loansReducer.loansList);

  const loanInfo = useAppSelector(state => state.loansReducer.loanInfo);

  const [selectedLoanType, setSelectedLoanType] = useState('loans');
  const [filteredLoanList, setFilteredLoanList] = useState([]);

  const selectLoanTypeHandler = (type: any) => {
    setSelectedLoanType(type);

    if (loansList?.results) {
      if (type === 'accredits') {
        setFilteredLoanList(loansList?.results?.filter((loan: any) => loan.account_number.includes('AT')));
      } else if (type === 'overdraft') {
        setFilteredLoanList(loansList?.results?.filter((loan: any) => loan.account_number.includes('ODLC')));
      } else {
        setFilteredLoanList(
          loansList?.results?.filter(
            (loan: any) => !loan.account_number.includes('ODLC') && !loan.account_number.includes('AT')
          )
        );
      }
    } else {
      setFilteredLoanList([]);
    }
  };

  useEffect(() => {
    if (loansList?.results?.length > 0) {
      selectLoanTypeHandler('loans');
    }
  }, [loansList]);

  useEffect(() => {
    if (loansList?.results?.length > 0) {
      dispatch(
        getLoanStatementApi(
          {
            contract_no: loanInfo.account_number,
            acc_no: loanInfo.cr_prod_ac,
          },
          1
        )
      );
    }
  }, [loanInfo]);

  const changePage = (page: number) => {
    dispatch(
      getLoanStatementApi(
        {
          contract_no: loanInfo.account_number,
          acc_no: loanInfo.cr_prod_ac,
        },
        page
      )
    );
  };

  const items = [
    {
      key: 'loans',
      label: t('Loans'),
    },
    {
      key: 'accredits',
      label: t('Accredits'),
    },
    {
      key: 'overdraft',
      label: t('Overdraft'),
    },
  ];

  return (
    <div className='flex justify-center'>
      <div className='lg:w-[1130px] w-full'>
        <div className='flex'>
          <div className='p-4 lg:p-8 w-full h-full bg-white mb-12'>
            <div className='flex justify-between'>
              <h1 className='text-xl lg:text-2xl font-semibold color-active'>{t('Loans')}</h1>
            </div>
            <div className='sm:grid lg:grid-cols-6 sm:grid-cols-8 gap-10'>
              <div className='lg:col-span-4 sm:col-span-5'>
                <div className='flex' style={{ borderBottom: '1px solid #eee' }}>
                  {items.map((item: any) => (
                    <div
                      onClick={() => selectLoanTypeHandler(item.key)}
                      key={item.key}
                      className={`${
                        selectedLoanType === item.key ? 'header__items-active' : ''
                      } header__items py-5 mr-5 text-base`}
                    >
                      {item.label}
                    </div>
                  ))}
                </div>
                <LoansList data={filteredLoanList} itemsCount='20' defaultInfo={true} />
                <div>
                  {filteredLoanList?.length > 0 ? (
                    <div className='grid md:grid-cols-4 lg:mt-20 mt-10'>
                      <div className='col-span-4'>
                        <h1 className='max-sm:text-lg text-2xl mb-5 font-semibold color-active'>
                          {t('Last operations')}
                        </h1>
                        <div
                          className='gap-5 grid grid-cols-12 py-5 px-5 max-md:text-sm font-semibold color-active text-base'
                          style={{ borderBottom: '1px solid #eee' }}
                        >
                          <p className='col-span-2 max-sm:col-span-3 max-md:hidden'>{t('Date')}</p>
                          <p className='col-span-4 max-md:col-span-7'>{t('Operation')}</p>
                          <p className='col-span-3 max-md:hidden text-right'>{t('Credit')}</p>
                          <p className='col-span-3 max-md:col-span-5 text-right'>{t('Debit')}</p>
                        </div>
                        <div>
                          {loanStatement?.results?.length > 0 ? (
                            <div>
                              <div>
                                {loanStatement?.results?.slice(0, 10).map((item: any) => (
                                  <div
                                    key={item?.trans_id}
                                    style={{ borderBottom: '1px solid #eee' }}
                                    className='p-5 gap-5 grid grid-cols-12 listItem md:text-base max-md:text-sm'
                                  >
                                    <p className='col-span-2 max-sm:col-span-3 max-md:hidden font-medium'>
                                      {formatDate(item?.dt)}
                                    </p>
                                    <div className='col-span-4 max-md:col-span-7'>
                                      <p
                                        className={`${
                                          item?.nar.includes('MAIN_INT') ? 'text-slate-500' : null
                                        } font-medium`}
                                      >
                                        {item?.oper}
                                      </p>
                                      <p className='md:hidden max-md:text-sm mt-2'>{item?.created_at?.slice(0, 10)}</p>
                                    </div>
                                    <p className='col-span-3 max-md:hidden text-right'>
                                      {digitNumber(item.ac_ccy === 'TJS' ? item?.cred : item?.fcred)}{' '}
                                    </p>
                                    <p className='col-span-3 max-md:col-span-5 text-right font-medium'>
                                      <span className=''>
                                        {digitNumber(item.ac_ccy === 'TJS' ? item?.deb : item?.fdeb)}{' '}
                                      </span>
                                      <span className='font-semibold'>{item?.ac_ccy}</span>
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : (
                            <div className='h-24 flex items-center justify-center'>
                              <p>{t('No transaction')}</p>
                            </div>
                          )}
                        </div>
                        <div className='flex justify-end mt-7'>
                          <Pagination
                            onChange={changePage}
                            current={loanStatement?.current_page}
                            defaultPageSize={10}
                            total={loanStatement?.total_pages * 10}
                            showSizeChanger={false}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='lg:col-span-2 sm:col-span-3'>
                {filteredLoanList?.length > 0 ? (
                  <div className='max-sm:mt-5'>
                    <LoanInfo />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoansIndex;
