import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Statistic } from 'antd';
import { resendCodeApi, verifyApi } from '@http/auth';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useTranslation } from 'react-i18next';
import Header from './components/header';
import FooterNew from '@components/Container/Footer-new';

const { Countdown } = Statistic;

function OtpCode() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [time, setTime] = useState(false);
  const otpLifeTime = useAppSelector(state => state.appReducer.otpLifeTime);
  const otpType = useAppSelector(state => state.appReducer.otpType);

  const onFinish = async (values: any) => {
    const query = {
      code: values.code,
    };

    dispatch(verifyApi(query, navigate));
  };

  const onFinishCountDown = () => {
    setTime(true);
  };

  return (
    <div
      className='bg-white'
      style={{ height: '100vh', display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: 'auto 1fr auto' }}
    >
      <Header />
      <main className='flex items-center justify-center py-12'>
        <div className='w-[340px] lg:w-[480px]'>
          <div className='text-center mb-7'>
            <p className='text-2xl'>{t('OtpCode')}</p>
          </div>
          <Form name='otpCode' initialValues={{ remember: true }} onFinish={onFinish} autoComplete='off'>
            <Form.Item className='mb-5' name='code' rules={[{ required: true, message: `${t('Required')}` }]}>
              <Input className='auth__input-primary text-lg' style={{ width: '100%' }} placeholder={`${t('Code')}`} />
            </Form.Item>

            {!time ? (
              <div className='text-xs lg:text-sm flex items-center text-slate-400 justify-center mb-2'>
                <span className='mr-2 text-right'>{t('ResendTime')}</span>
                <Countdown
                  className='!text-xs'
                  style={{ fontSize: 12 }}
                  value={otpLifeTime}
                  onFinish={onFinishCountDown}
                  format='mm:ss'
                />
                <span className='ml-2'>{t('Second')}!</span>
              </div>
            ) : (
              <Button
                className='mb-3'
                size='middle'
                onClick={() => {
                  setTime(false);
                  dispatch(resendCodeApi({ otp_delivery: otpType }));
                }}
              >
                {t('ResendCode')}
              </Button>
            )}

            <p className='text-center'>
              {t(`${otpType === 'sms' ? 'Sent code to phone for sequirity' : 'Sent code to email for sequirity'}`)}
            </p>

            <Form.Item>
              <div className='flex justify-end mt-5'>
                <Button className='auth__btn-primary text-lg w-full' type='primary' htmlType='submit'>
                  {t('Sign in')}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </main>
      <div className='max-lg:hidden mt-10' style={{ borderTop: '1px solid #eee' }}>
        <FooterNew />
      </div>
    </div>
  );
}

export default OtpCode;
