import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  users: any;
  isLoading: boolean;
  error: string;
  templates: Array<any>;
  pickedInterbankTemplate: any;
  interbankTemplate: any;
  permissions: any;
  paymentsPermissions: any;
  pickedInternalTemplate: any;
  pickedSwiftTemplate: any;
  pickedSwiftRubTemplate: any;
}
const initialState: UserState = {
  users: [],
  isLoading: false,
  error: '',
  templates: [],
  pickedInterbankTemplate: {},
  interbankTemplate: {},
  permissions: [],
  paymentsPermissions: [],
  pickedInternalTemplate: {},
  pickedSwiftTemplate: {},
  pickedSwiftRubTemplate: {},
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    pickedSwiftRubTemplateAC(state, action: PayloadAction<any>) {
      state.pickedSwiftRubTemplate = action.payload;
    },
    pickedSwiftTemplateAC(state, action: PayloadAction<any>) {
      state.pickedSwiftTemplate = action.payload;
    },
    paymentsPermissionsAC(state, action: PayloadAction<any>) {
      state.paymentsPermissions = action.payload;
    },
    permissionsAC(state, action: PayloadAction<any>) {
      state.permissions = action.payload;
    },
    interbankTemplateAC(state, action) {
      state.interbankTemplate = action.payload;
    },
    pickedInterbankTemplateAC(state, action) {
      state.pickedInterbankTemplate = action.payload;
    },
    pickedInternalTemplateAC(state, action) {
      state.pickedInternalTemplate = action.payload;
    },
    templatesAC(state, action) {
      state.templates = [];
      action.payload.forEach((item: any) => {
        state.templates.push({
          ...item,
          label: item.title,
          value: item.id,
        });
      });
    },
  },
});

export const {
  templatesAC,
  pickedInterbankTemplateAC,
  interbankTemplateAC,
  permissionsAC,
  paymentsPermissionsAC,
  pickedInternalTemplateAC,
  pickedSwiftTemplateAC,
  pickedSwiftRubTemplateAC,
} = user.actions;
export default user.reducer;
