import digitNumber from '@utils/digitNumber';
import { Button, Form, Input, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { recipientAccountsModalOpenAC, senderAccountsModalOpenAC } from '@store/reducers/accounts';
import { isConfirmPaymentModalOpenAC, ownAccountsQueryAC } from '@store/reducers/payments';
import { deadlineAC } from '@store/reducers/transactions';

const { TextArea } = Input;

const OwnAccountsForm = ({ form, senderAccountCurrency, toAccountCurrency }: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { senderAccountBalance, toAccountBalance } = useAppSelector(state => state.accountsReducer);
  const ownAccountsQuery = useAppSelector(state => state.paymentsReducer.ownAccountsQuery);

  const submit = () => {
    if (+ownAccountsQuery.sending_amount > +senderAccountBalance) {
      form.setFields([
        {
          name: 'sending_ac',
          errors: [t('Insufficient funds in the account')],
        },
      ]);
    } else {
      dispatch(isConfirmPaymentModalOpenAC(true));
      dispatch(deadlineAC(Date.now() + 1000 * 30));
    }
  };

  const handleNarrativeChange = (e: any) => {
    dispatch(ownAccountsQueryAC({ ...ownAccountsQuery, narrative: e.target.value }));
  };

  const handleAmountChange = (e: any) => {
    dispatch(ownAccountsQueryAC({ ...ownAccountsQuery, sending_amount: e.target.value }));
  };

  return (
    <Form form={form} name='OwnAccounts' onFinish={submit}>
      <p className='text-base color-active font-semibold mb-4'>{t('Payment information')}</p>
      <p className='text-bs color-title md:hidden'>{t('From account')}:</p>
      <div className='md:grid grid-cols-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('From account')}:</p>
        <div className='col-span-5'>
          <Form.Item className='m-0' name='sending_ac' rules={[{ required: true, message: `${t('Required')}` }]}>
            <Space.Compact size='large' className='w-full'>
              <Input disabled style={{ width: 60 }} size='large' value={senderAccountCurrency} />
              <Input
                value={ownAccountsQuery.sending_ac}
                placeholder={`${t('Select account')}`}
                onClick={() => dispatch(senderAccountsModalOpenAC(true))}
                size='large'
                style={{ width: '100%' }}
                type='text'
                maxLength={20}
              />
            </Space.Compact>
          </Form.Item>
          {senderAccountBalance ? (
            <p className='text-bs color-title font-medium text-end mt-2'>
              {t('Balance')}: {digitNumber(senderAccountBalance)} {senderAccountCurrency}
            </p>
          ) : null}
        </div>
      </div>
      <p className='text-bs color-title mt-5 md:hidden'>{t('To account')}:</p>
      <div className='md:grid grid-cols-7 md:mt-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('To account')}:</p>
        <div className='col-span-5'>
          <Form.Item className='m-0' name='receiving_ac' rules={[{ required: true, message: `${t('Required')}` }]}>
            <Space.Compact size='large' className='w-full'>
              <Input disabled style={{ width: 60 }} size='large' value={toAccountCurrency} />
              <Input
                value={ownAccountsQuery.receiving_ac}
                placeholder={`${t('Select account')}`}
                onClick={() => dispatch(recipientAccountsModalOpenAC(true))}
                size='large'
                style={{ width: '100%' }}
                type='text'
                maxLength={20}
              />
            </Space.Compact>
          </Form.Item>
          {toAccountBalance ? (
            <p className='text-bs color-title font-medium text-end mt-2'>
              {t('Balance')}: {digitNumber(toAccountBalance)} {toAccountCurrency}
            </p>
          ) : null}
        </div>
      </div>
      <p className='text-bs color-title mt-5 md:hidden'>{t('Transfer amount')}:</p>
      <div className='md:grid grid-cols-7 md:mt-7'>
        <p className='col-span-2 text-bs color-title max-md:hidden'>{t('Transfer amount')}:</p>
        <div className='col-span-5'>
          <Form.Item
            className='m-0'
            name='sending_amount'
            rules={[
              { required: true, message: `${t('Required')}` },
              {
                pattern: /^[0-9]\d*(?:[.]\d{1,2})?$/,
                message: `${t('Wrong amount')}`,
              },
              {
                pattern: /^[1-9]\d*/,
                message: `${t('Amount must be more 1')}`,
              },
            ]}
          >
            <Space.Compact size='large' className='w-full'>
              <Input disabled style={{ width: 60 }} size='large' value={senderAccountCurrency} />
              <Input
                min={0}
                value={ownAccountsQuery.sending_amount}
                maxLength={12}
                placeholder={`${t('Enter amount')}`}
                size='large'
                style={{ width: '100%' }}
                type='text'
                onChange={e => {
                  handleAmountChange(e);
                }}
              />
            </Space.Compact>
          </Form.Item>
        </div>
      </div>
      <div className='mt-7'>
        <p className='text-base color-title'>{t('Narrative')}:</p>
        <Form.Item
          className='m-0'
          name='narrative'
          rules={[
            { required: true, message: `${t('Required')}` },
            {
              pattern: /^(?=.*\S).*/,
              message: `${t('Enter symbol only')}`,
            },
          ]}
        >
          <TextArea
            value={ownAccountsQuery.narrative}
            maxLength={250}
            onChange={e => {
              handleNarrativeChange(e);
            }}
            rows={4}
          />
        </Form.Item>
      </div>
      <div className='flex justify-end mt-5'>
        <Button type='primary' htmlType='submit'>
          {t('Confirm')}
        </Button>
      </div>
    </Form>
  );
};

export default OwnAccountsForm;
