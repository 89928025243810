import { Button, Modal, Input, Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { editAccountNameApi, dowloadAccountRequisitesApi } from '@http/accounts';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import digitNumber from '@utils/digitNumber';

function AccountInfo() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const accountInfo = useAppSelector(state => state.accountsReducer.accountInfo);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dowloadAccountRequisites = () => {
    dispatch(dowloadAccountRequisitesApi({ cust_ac_no: accountInfo.cust_ac_no }));
  };

  const submit = (values: any) => {
    dispatch(editAccountNameApi({ cust_ac_no: accountInfo.cust_ac_no, name: values.account_name }));
    setIsModalOpen(false);
  };

  useEffect(() => {
    form.setFieldValue('account_name', accountInfo.name);
  }, [accountInfo.name]);

  return (
    <div className='bg-orange-50 p-5 rounded text-sm'>
      <h1 className='text-lg font-semibold'>{t('Account Information')}</h1>
      <div className='mt-5'>
        <div>
          <div className='flex'>
            <p className='text-slate-500'>{t('Account name')}:</p>
            <EditOutlined className='ml-2 cursor-pointer' style={{ fontSize: 18 }} onClick={() => showModal()} />
          </div>
          <p className='font-medium'>{accountInfo?.name}</p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Account')}:</p>
          <p className='font-medium'>{accountInfo?.cust_ac_no}</p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Balance')}:</p>
          <p className='font-medium'>{digitNumber(accountInfo?.acy_avl_bal)}</p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Currency')}:</p>
          <p className='font-medium'>{accountInfo?.ccy}</p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Account type')}:</p>
          <p className='font-medium'>{t('Urgent')}</p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Branch')}:</p>
          <p className='font-medium'>{accountInfo?.branch_code}</p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Address')}:</p>
          <p className='font-medium'>{accountInfo?.branch_addr}</p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('TIN')}:</p>
          <p className='font-medium'>{accountInfo?.taxid_no}</p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Description')}:</p>
          <p className='font-medium'>{accountInfo?.description}</p>
        </div>
        <div className='mt-3'>
          <p className='text-slate-500'>{t('Date of opening')}:</p>
          <p className='font-medium'>{accountInfo?.maker_dt_stamp?.slice(0, 10)}</p>
        </div>
        <div className='mt-3'>
          {accountInfo?.record_stat === 'O' ? (
            <div className='flex items-center'>
              <div className='bg-green-500 h-3 w-3 mr-2 rounded-full' />
              <p>{t('Active')}</p>
            </div>
          ) : (
            <div className='flex items-center'>
              <div className='bg-red-400 h-3 w-3 mr-2 rounded-full' />
              <p>{t('Not active')}</p>
            </div>
          )}
        </div>
        <div className='mt-3'>
          <p className='text-slate-500 mb-2'>{t('Requisites')}:</p>
          <Button type='dashed' size='small' onClick={dowloadAccountRequisites}>
            {t('Download')}
          </Button>
        </div>
      </div>
      <Modal
        title={`${t('Edit account name')}`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className='mt-5'>
          <Form form={form} name='OwnAccounts' onFinish={submit}>
            <Form.Item
              className='m-0'
              name='account_name'
              rules={[
                { required: true, message: `${t('Required')}` },
                { max: 70, message: `${t('Maximum number of characters: 70')}` },
              ]}
            >
              <Input placeholder={`${t('Account name')}`} />
            </Form.Item>
            <div className='flex justify-end mt-5'>
              <Button type='primary' htmlType='submit'>
                {t('Save')}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default AccountInfo;
