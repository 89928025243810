import React, { useEffect, useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Button, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import type { MenuProps } from 'antd';
import globus from '@assets/images/icons/globus.svg';
import { useNavigate } from 'react-router-dom';
import { requestInfoModalAC } from '@store/reducers/app';
import { useAppDispatch } from '@hooks/redux';
import logoSmall from '@assets/images/logoSmall.svg';

const Header = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const languages: MenuProps['items'] = [
    {
      key: '1',
      label: 'Русский',
      onClick: () => {
        i18n.changeLanguage('ru');
        localStorage.setItem('lang', 'ru');
      },
    },
    {
      key: '2',
      label: 'Таджикский',
      onClick: () => {
        i18n.changeLanguage('tg');
        localStorage.setItem('lang', 'tg');
      },
    },
    {
      key: '3',
      label: 'Английский',
      onClick: () => {
        i18n.changeLanguage('en');
        localStorage.setItem('lang', 'en');
      },
    },
  ];

  const [activeMenuItem, setActiveMenuItem] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const menuItems = document.querySelectorAll('.menu-item');

      menuItems.forEach(menuItem => {
        const sectionId: any = menuItem.getAttribute('data-section-id');
        const section = document.getElementById(sectionId);

        if (section && isElementInViewport(section)) {
          setActiveMenuItem(sectionId);
        }
      });
    };

    const isElementInViewport = (e: any) => {
      const rect = e.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const windowWidth = window.innerWidth || document.documentElement.clientWidth;

      // Для элемента с id "Main" используем сравнение с серединой окна
      if (e.title === t('header.main')) {
        const scrollPosition = window.scrollY + windowHeight / 1.2;
        return rect.top <= scrollPosition;
      } else {
        // Для остальных элементов используем обычное сравнение
        const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
        const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;
        return vertInView && horInView;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className='bg-white sticky top-0 z-50'>
      <div className=' max-w-[1127px] mx-auto'>
        <div className='flex justify-between py-6 px-4'>
          <ScrollLink className='color-active' to='main' smooth={true} offset={-160} duration={500}>
            <div className='flex items-center'>
              <img src={logoSmall} alt='' className='w-10 h-10' />
              <div className='color-active font-semibold ml-3'>
                <p className='md:text-2xl text-xl leading-4 mt-1'>{t('Oriyon')}</p>
                <p
                  className={`${i18n.language === 'en' ? 'mt-1' : ''} text-xs text-right`}
                  style={{ fontSize: '14px' }}
                >
                  {t('Business')}
                </p>
              </div>
            </div>
          </ScrollLink>

          <ul className='flex gap-x-10 :gap-x-5 cursor-pointer m-auto py-2'>
            <li
              className={`py-1 max-lg:hidden lg:block border-b-[4px] border-white transition-transform hover:border-[#FFCD4B] hover:text-[#1D2023] text-[16px] font-medium leading-6 menu-item ${
                activeMenuItem === 'main' ? 'border-[#FFCD4B] border-b-[4px]' : ''
              }`}
              data-section-id={'main'}
            >
              <ScrollLink
                className='color-active hover:text-[#FFCD4B]'
                to={'main'}
                smooth={true}
                offset={'main' === t('header.main') ? -155 : -160}
                duration={500}
              >
                {t('header.main')}
              </ScrollLink>
            </li>
            <li
              className={`py-1 max-lg:hidden lg:block border-b-[4px] border-white transition-transform hover:border-[#FFCD4B] hover:text-[#1D2023] text-[16px] font-medium leading-6 menu-item ${
                activeMenuItem === 'vozm' ? 'border-[#FFCD4B] border-b-[4px]' : ''
              }`}
              data-section-id={'vozm'}
            >
              <ScrollLink
                className='color-active hover:text-[#FFCD4B]'
                to={'vozm'}
                smooth={true}
                offset={'vozm' === t('header.vozm') ? -155 : -130}
                duration={500}
              >
                {t('header.vozm')}
              </ScrollLink>
            </li>
            <li
              className={`py-1 max-lg:hidden lg:block border-b-[4px] border-white transition-transform hover:border-[#FFCD4B] hover:text-[#1D2023] text-[16px] font-medium leading-6 menu-item ${
                activeMenuItem === 'products' ? 'border-[#FFCD4B] border-b-[4px]' : ''
              }`}
              data-section-id={'products'}
            >
              <ScrollLink
                className='color-active hover:text-[#FFCD4B]'
                to={'products'}
                smooth={true}
                offset={'products' === t('header.products') ? -155 : -130}
                duration={500}
              >
                {t('header.products')}
              </ScrollLink>
            </li>
            <li
              className={`py-1 max-lg:hidden lg:block border-b-[4px] border-white transition-transform hover:border-[#FFCD4B] hover:text-[#1D2023] text-[16px] font-medium leading-6 menu-item ${
                activeMenuItem === 'documents' ? 'border-[#FFCD4B] border-b-[4px]' : ''
              }`}
              data-section-id={'documents'}
            >
              <ScrollLink
                className='color-active hover:text-[#FFCD4B]'
                to={'documents'}
                smooth={true}
                offset={'documents' === t('header.docs') ? -155 : -130}
                duration={500}
              >
                {t('header.docs')}
              </ScrollLink>
            </li>
            <li
              className={`py-1 max-lg:hidden lg:block border-b-[4px] border-white transition-transform hover:border-[#FFCD4B] hover:text-[#1D2023] text-[16px] font-medium leading-6 menu-item ${
                activeMenuItem === 'request' ? 'border-[#FFCD4B] border-b-[4px]' : ''
              }`}
              data-section-id={'request'}
            >
              <p
                className='color-active hover:text-[#FFCD4B] text-sm'
                onClick={() => dispatch(requestInfoModalAC(true))}
              >
                {t('header.request')}
              </p>
            </li>
          </ul>
          <div className='flex gap-2 items-center'>
            <div className='flex cursor-pointer'>
              <Dropdown menu={{ items: languages }}>
                <div className='flex items-center'>
                  <img src={globus} alt='' />
                  <span className='mx-2'>{t('Language')}</span>
                </div>
              </Dropdown>
            </div>
            <Button type='primary' onClick={() => navigate('login')}>
              {t('Enter')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
