import axios from 'axios';
import openNotification from '@utils/openNotification';
import i18n from 'i18next';

const $host = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // headers: {
  //   'Content-Type': 'application/json',
  //   Accept: 'application/json',
  // },
});

const $authHost = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

$host.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('at')}`;
  config.headers['Accept-Language'] = i18n.language;
  return config;
});

$authHost.interceptors.request.use(config => {
  config.headers['Accept-Language'] = i18n.language;
  return config;
});

let isRefreshing: any = false; // Флаг для отслеживания статуса обновления токена
let refreshPromise: any = null; // Promise для обновления токена

const refreshToken = async () => {
  try {
    isRefreshing = true;
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/refresh-token`, {
      refresh: localStorage.getItem('rt'),
    });
    if (response.status === 200) {
      localStorage.setItem('at', response.data.access);
      localStorage.setItem('rt', response.data.refresh);
      isRefreshing = false;
      return response;
    }
  } catch (error: any) {
    localStorage.removeItem('at');
    localStorage.removeItem('rt');
    isRefreshing = false;
  }
};

// Добавление интерсептора для обработки 401 Unauthorized
$host.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (error?.response?.status === 401 && !error?.config?._retry) {
      error.config._retry = true;

      if (!isRefreshing) {
        // Если не выполняется обновление токена, выполняем его и ожидаем завершения
        refreshPromise = refreshToken();
        await refreshPromise;
        return $host(error.config);
      } else if (refreshPromise) {
        // Если выполняется обновление токена, ожидаем его завершения и затем повторно отправляем запрос
        await refreshPromise;
        return $host(error.config);
      }
    }

    if (error.code === 'ERR_NETWORK') {
      openNotification('error', {
        code: error?.code,
        message: 'Ошибка',
        detail: 'Произошла ошибка, попробуйте позже',
      });
    }

    if (error.code === 404) {
      openNotification('error', {
        code: error?.code,
        message: 'Ошибка',
        detail: error?.detail,
      });
    }

    if (error.code === 413) {
      openNotification('error', {
        code: error?.code,
        message: 'Ошибка',
        detail: 'Размер файла слишком велик',
      });
    }

    return Promise.reject(error);
  }
);

$authHost.interceptors.response.use(
  config => config,
  async error => {
    if (error.code === 'ERR_NETWORK') {
      openNotification('error', {
        code: error.code,
        message: 'Ошибка',
        detail: 'Произошла ошибка, попробуйте позже',
      });
    }

    throw error;
  }
);

export { $host, $authHost };
