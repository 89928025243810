import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';

import { useTranslation } from 'react-i18next';
import { getDepositListApi } from '@http/deposits';
import { depositInfoAC, selectedDepositAC } from '@store/reducers/deposits';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { getAccountBalanceApi } from '@http/accounts';

function DepositList(props: any) {
  const { t } = useTranslation();
  const { itemsCount, defaultInfo } = props;
  const dispatch = useAppDispatch();
  const depositList = useAppSelector(state => state.depositsReducer.depositList);
  const selectedDeposit = useAppSelector(state => state.depositsReducer.selectedDeposit);
  const accountBalance = useAppSelector(state => state.accountsReducer.accountBalance);

  const [pickedDepositBalance, setPickedDepositBalance] = useState('');

  const showDepositBalance = (item: any) => {
    if (pickedDepositBalance === item.acc) {
      setPickedDepositBalance('');
    } else {
      setPickedDepositBalance(item.acc);
      dispatch(getAccountBalanceApi('account', { cust_ac_no: item.acc }));
    }
  };

  useEffect(() => {
    dispatch(getDepositListApi());
  }, []);

  useEffect(() => {
    if (depositList?.results?.length > 0 && defaultInfo) {
      dispatch(selectedDepositAC(depositList.results[0]));
      dispatch(depositInfoAC(depositList.results[0]));
    }
  }, [depositList]);

  return (
    <div>
      {depositList?.results?.length > 0 ? (
        <div>
          {depositList?.results?.slice(0, itemsCount).map((item: any) => (
            <div
              key={item.cust_ac_no}
              onClick={() => {
                dispatch(depositInfoAC(item));
                dispatch(selectedDepositAC(item));
              }}
            >
              <div
                style={{ borderBottom: '1px solid #eee' }}
                className={`flex justify-between max-lg:h-[80px] cursor-pointer lg:p-5 px-5 pt-5 border-b listItem ${
                  selectedDeposit.acc === item.acc ? 'bg-yellow-50' : null
                }`}
              >
                <div>
                  <p className='text-base font-medium'>{item.acc}</p>
                  <div>
                    {item.acc ? (
                      <div className='flex items-center'>
                        <div className='bg-green-500 h-3 w-3 mr-3 rounded-full' />
                        <p className='text-sm'>{t('Active')}</p>
                      </div>
                    ) : (
                      <div className='flex items-center'>
                        <div className='bg-red-400 h-3 w-3 mr-3 rounded-full' />
                        <p className='text-sm'>{t('Closed')}</p>
                      </div>
                    )}
                    {pickedDepositBalance === item.acc ? (
                      <span className='max-md:text-sm text-base mt-1 lg:hidden'>
                        {accountBalance?.acy_avl_bal} <span className='font-bold'>{accountBalance.ccy}</span>
                      </span>
                    ) : null}
                  </div>
                </div>

                <p className='text-base font-medium max-lg:hidden'>
                  {pickedDepositBalance === item.acc ? (
                    <div>
                      <span className='max-md:text-sm text-base'>
                        {accountBalance?.acy_avl_bal} <span className='font-bold'>{accountBalance.ccy}</span>
                      </span>
                      <Popover title={t('Hide balance')} overlayStyle={{ width: '175px' }}>
                        <EyeInvisibleOutlined
                          style={{ fontSize: 20 }}
                          className='mx-3 cursor-pointer'
                          onClick={() => showDepositBalance(item)}
                        />
                      </Popover>
                    </div>
                  ) : (
                    <Popover title={t('Show balance')} overlayStyle={{ width: '170px' }}>
                      <EyeOutlined
                        style={{ fontSize: 20 }}
                        className='mx-3 cursor-pointer'
                        onClick={() => showDepositBalance(item)}
                      />
                    </Popover>
                  )}
                </p>

                <p className='text-base font-medium lg:hidden'>
                  {pickedDepositBalance === item.acc ? (
                    <div>
                      <Popover title={t('Hide balance')} overlayStyle={{ width: '175px' }}>
                        <EyeInvisibleOutlined
                          style={{ fontSize: 20 }}
                          className='mx-3 cursor-pointer'
                          onClick={() => showDepositBalance(item)}
                        />
                      </Popover>
                    </div>
                  ) : (
                    <Popover title={t('Show balance')} overlayStyle={{ width: '170px' }}>
                      <EyeOutlined
                        style={{ fontSize: 20 }}
                        className='mx-3 cursor-pointer'
                        onClick={() => showDepositBalance(item)}
                      />
                    </Popover>
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className='h-24 flex items-center justify-center'>
          <p className='text-base'>{t('No deposits')}</p>
        </div>
      )}
    </div>
  );
}

export default DepositList;
