import { Button, Divider, Tag } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getPaymentDetailsOrderApi, getPaymentDetailsOrderToPrintApi } from '@http/payments';
import { useNavigate } from 'react-router-dom';
import digitNumber from '@utils/digitNumber';
import {
  actionModalTypeAC,
  transactionActionModalOpenAC,
  repeatInterbankTransactionAC,
  repeatInternalTransactionAC,
  repeatOwnTransactionAC,
  repeatSwiftTransactionAC,
  repeatSwiftRubTransactionAC,
  repeatExchangeTransactionAC,
} from '@store/reducers/transactions';
import { useTranslation } from 'react-i18next';
import TransactionActionModal from './TransactionActionModal';
import { cancelExchangeApi, proceedExchangeApi } from '@http/transactions';
import getBankName from '@utils/bankName';
import { CloudDownloadOutlined, PrinterOutlined, ReloadOutlined, SyncOutlined } from '@ant-design/icons';

function TransactionInfo(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const transactionStatus = useAppSelector(state => state.transactionsReducer.transactionStatus);
  const user = useAppSelector(state => state.appReducer.user);
  const { item } = props;

  const getPaymentDetailsOrder = () => {
    dispatch(getPaymentDetailsOrderApi({ trans_id: item.trans_id }));
  };

  const getPaymentDetailsOrderToPrint = () => {
    dispatch(getPaymentDetailsOrderToPrintApi({ trans_id: item.trans_id }));
  };

  const repeatTransactionHandler = (item: any) => {
    switch (item.type) {
      case 'OWN':
        dispatch(repeatOwnTransactionAC(item));
        break;
      case 'INTERNAL':
        dispatch(repeatInternalTransactionAC(item));
        break;
      case 'INTERBANK':
        dispatch(repeatInterbankTransactionAC(item));
        break;
      case 'SWIFT':
        dispatch(repeatSwiftTransactionAC(item));
        break;
      case 'SWIFT_RUB':
        dispatch(repeatSwiftRubTransactionAC(item));
        break;
      case 'EXCHANGE':
        dispatch(repeatExchangeTransactionAC(item));
        break;

      default:
        break;
    }
  };

  return (
    <div className='bg-slate-50'>
      <div className='grid grid-cols-7 items-center py-5 max-sm:px-8 px-2 border-b lg:gap-5 max-sm:text-xs'>
        <div />
        <div className='max-sm:col-span-7 max-sm:leading-6 col-span-5 leading-8'>
          <div className='grid grid-cols-6'>
            <p className='col-span-2'>{t('Transaction type')}: </p>
            <p className='ml-3 font-bold col-span-4'>{item.type_name}</p>
          </div>
          <div className='grid grid-cols-6'>
            <p className='col-span-2'>{t('TxID')}: </p>
            <p className='ml-3 font-bold col-span-4'>{item.trans_id}</p>
          </div>
          <div className='grid grid-cols-6'>
            <p className='col-span-2'>{t('Sender account')}: </p>
            <p className='ml-3 font-bold col-span-4'>
              {item.sending_ac} ({item.sending_ccy}) {item.sending_ac_name !== '' ? '- ' + item.sending_ac_name : null}
            </p>
          </div>
          <div className='grid grid-cols-6'>
            <p className='col-span-2'>{t('Recipient account')}: </p>
            <p className='ml-3 font-bold col-span-4'>
              {item.receiving_ac} ({item.receiving_ccy})
              {item.receiver_ac_name !== '' ? '- ' + item.receiver_ac_name : null}
            </p>
          </div>
          {item.receiver_name ? (
            <div className='grid grid-cols-6'>
              <p className='col-span-2'>{t('Recipient')}: </p>
              <p className='ml-3 font-bold col-span-4'>{item.receiver_name}</p>
            </div>
          ) : null}
          {item.tax_id ? (
            <div className='grid grid-cols-6'>
              <p className='col-span-2'>{t('Recipient TIN')}: </p>
              <p className='ml-3 font-bold col-span-4'>{item.tax_id}</p>
            </div>
          ) : null}
          {item.doc_type_title ? (
            <div className='grid grid-cols-6'>
              <p className='col-span-2'>{t('Document type')}: </p>
              <p className='ml-3 font-bold col-span-4'>
                ({item.doc_type}) {item.doc_type_title}
              </p>
            </div>
          ) : null}
          {item.doc_no ? (
            <div className='grid grid-cols-6'>
              <p className='col-span-2'>{t('Document number')}: </p>
              <p className='ml-3 font-bold col-span-4'>{item.doc_no}</p>
            </div>
          ) : null}
          {item.type === 'INTERBANK' ? (
            <div className='grid grid-cols-6'>
              <p className='col-span-2'>{t('Beneficiary Bank')}: </p>
              <p className='ml-3 font-bold col-span-4'>{getBankName(item.bank_code)}</p>
            </div>
          ) : null}
          {item.type === 'SWIFT' || item.type === 'SWIFT_RUB' ? (
            <div>
              <div className='grid grid-cols-6'>
                <p className='col-span-2'>{t('Recipient country')}: </p>
                <p className='ml-3 font-bold col-span-4'>{item.receiver_country}</p>
              </div>
              <div className='grid grid-cols-6'>
                <p className='col-span-2'>{t('Beneficiary Bank')}: </p>
                <p className='ml-3 font-bold col-span-4'>{item.receiver_bank_name}</p>
              </div>
              <div className='grid grid-cols-6'>
                <p className='col-span-2'>{t('Recipient bank address')}: </p>
                <p className='ml-3 font-bold col-span-4'>{item.receiver_bank_address}</p>
              </div>
              <div className='grid grid-cols-6'>
                <p className='col-span-2'>{t('Recipient address')}: </p>
                <p className='ml-3 font-bold col-span-4'>{item.receiver_address}</p>
              </div>
            </div>
          ) : null}
          {item.type !== 'EXCHANGE' ? (
            <div className='grid grid-cols-6'>
              <p className='col-span-2'>{t('Amount')}: </p>
              <p className='ml-3 font-bold col-span-4'>
                {digitNumber(item.sending_amount)} {item.sending_ccy}
              </p>
            </div>
          ) : null}
          {item.type === 'EXCHANGE' ? (
            <div>
              <div className='grid grid-cols-6'>
                <p className='col-span-2'>{t('Credit amount')}: </p>
                <p className='ml-3 font-bold col-span-4'>
                  {digitNumber(item.sending_amount)} {item.sending_ccy}
                </p>
              </div>
              <div className='grid grid-cols-6'>
                <p className='col-span-2'>{t('Rate')}: </p>
                <p className='ml-3 font-bold col-span-4'>{item.xrate}</p>
              </div>
              <div className='grid grid-cols-6'>
                <p className='col-span-2'>{t('Debit amount')}: </p>
                <p className='ml-3 font-bold col-span-4'>
                  {digitNumber(item.receiving_amount)} {item.receiving_ccy}
                </p>
              </div>
            </div>
          ) : null}
          {item.fee_amount ? (
            <div className='grid grid-cols-6'>
              <p className='col-span-2'>{t('Commission')}: </p>
              <p className='ml-3 font-bold col-span-4'>
                {digitNumber(item.fee_amount)} {item.sending_ccy}
              </p>
            </div>
          ) : null}
          <div className='grid grid-cols-6'>
            <p className='col-span-2'>{t('Narrative')}: </p>
            <p className='ml-3 font-bold col-span-4'>{item.narrative}</p>
          </div>
          <div className='grid grid-cols-6'>
            <p className='col-span-2'>{t('Initiator')}: </p>
            <p className='ml-3 font-bold col-span-4'>{item.initiator_name}</p>
          </div>
          <div className='grid grid-cols-6'>
            <p className='col-span-2'>{t('Date of creation')}: </p>
            <p className='ml-3 font-bold col-span-4'>{item.created_at.slice(0, 19)}</p>
          </div>
          {item.authenticator.length > 0 ? (
            <div className='grid grid-cols-6'>
              <p className='col-span-2'>{t('Authorizers')}: </p>
              <div className='ml-3 col-span-4'>
                {item.authenticator.map((item: any) => (
                  <div className='flex items-center '>
                    <p className='mr-3 font-bold'>{item.authenticator_name}</p>
                    {item.authed === true ? <Tag color='success'>{t('Approved')}</Tag> : null}
                    {item.authed === false ? <Tag color='error'>{t('Rejected')}</Tag> : null}
                    {item.authed === null ? <Tag color='processing'>{t('Signing in')}</Tag> : null}
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          <div>
            {item.status === 'SUCCESS' || item.status === 'IN_PROGRESS' ? (
              <div>
                <Divider />
                <div className='flex'>
                  <div className='md:hidden flex'>
                    <Button
                      icon={<SyncOutlined />}
                      size='small'
                      type='primary'
                      onClick={() => {
                        navigate(`/payments/${item.type.toLowerCase()}`);
                        repeatTransactionHandler(item);
                      }}
                    >
                      {t('Retry payment')}
                    </Button>
                    <Button
                      icon={<CloudDownloadOutlined />}
                      size='small'
                      type='primary'
                      onClick={getPaymentDetailsOrder}
                      className='ml-4'
                    >
                      {t('Download PDF')}
                    </Button>
                  </div>
                  <div className='flex max-md:hidden'>
                    <Button
                      icon={<SyncOutlined />}
                      size='middle'
                      type='primary'
                      onClick={() => {
                        navigate(`/payments/${item.type.toLowerCase()}`);
                        repeatTransactionHandler(item);
                      }}
                    >
                      {t('Retry payment')}
                    </Button>
                    <Button
                      icon={<CloudDownloadOutlined />}
                      size='middle'
                      type='primary'
                      onClick={getPaymentDetailsOrder}
                      className='ml-4'
                    >
                      {t('Download PDF')}
                    </Button>
                  </div>
                  <Button
                    icon={<PrinterOutlined />}
                    size='middle'
                    type='primary'
                    onClick={getPaymentDetailsOrderToPrint}
                    className='ml-4 max-md:hidden'
                  >
                    {t('Print')}
                  </Button>
                </div>
              </div>
            ) : null}
            {item.is_authenticator && item.status === 'TO_APPROVE' ? (
              <div>
                <Divider />
                <Button
                  danger
                  onClick={() => {
                    dispatch(transactionActionModalOpenAC(true));
                    dispatch(actionModalTypeAC('DECLINE'));
                  }}
                >
                  {t('Reject')}
                </Button>
                <Button
                  className='ml-4'
                  type='primary'
                  onClick={() => {
                    dispatch(transactionActionModalOpenAC(true));
                    dispatch(actionModalTypeAC('APPROVE'));
                  }}
                >
                  {t('Confirm')}
                </Button>
              </div>
            ) : null}
          </div>
          {item.type === 'EXCHANGE' && (item.status === 'IN_BANK_REVIEW' || item.status === 'BANK_APPROVED') ? (
            <div>
              {item.initiator_id === user.id ? (
                <div>
                  <Divider />
                  <Button
                    disabled={item.status !== 'BANK_APPROVED'}
                    className='mr-4'
                    type='primary'
                    onClick={() => {
                      dispatch(proceedExchangeApi({ trans_id: item.trans_id }));
                      dispatch(transactionActionModalOpenAC(false));
                    }}
                  >
                    {t('Confirm')}
                  </Button>
                  <Button
                    danger
                    onClick={() => {
                      dispatch(cancelExchangeApi({ trans_id: item.trans_id }));
                      dispatch(transactionActionModalOpenAC(false));
                    }}
                  >
                    {t('Cancel')}
                  </Button>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <TransactionActionModal transactionId={item.trans_id} transactionStatus={transactionStatus} />
      </div>
    </div>
  );
}

export default TransactionInfo;
