const banks = [
  {
    'bank_code': '350101858',
    'bank_name': 'ҶСК Коммерсбонки Тоҷикистон',
    'bank_addr1': 'Ҷамъияти саҳомии кушода',
  },
  {
    'bank_code': '350101860',
    'bank_name': 'ҶДММ ТАҚХ Тиҷорат-Қарз',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350101870',
    'bank_name': 'ҶДММ ТАҚХ Тамвил дар ш.Душанбе',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350101871',
    'bank_name': 'ҶДММ ТАҚХ Пахта Сармоя дар ',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350101872',
    'bank_name': 'ҶДММ ТАҚХ Сомон-Тиҷорат',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350101875',
    'bank_name': 'ҶДММ ТАҚХ Финка ',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350101878',
    'bank_name': 'ҶДММ ТАҚХ Фазо С',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350101890',
    'bank_name': 'ҶДММ ТАҚХ Сарват',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350101891',
    'bank_name': 'ҶДММ ТАҚХ Тезинфоз',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350101892',
    'bank_name': 'ҶСП ТАҚХ Ҳумо дар ш.Душанбе',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350101900',
    'bank_name': 'ҶСК Алиф Бонк',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350501841',
    'bank_name': 'ҶДММ ТАҚХ Авфар',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350501848',
    'bank_name': 'ҶСП Бонки Арванд дар ш.Хӯҷанд',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350501849',
    'bank_name': 'ҶДММ ТАҚХ Матин дар ш.Хӯҷанд',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350501859',
    'bank_name': 'ҶДММ ТАҚХ Азизӣ-Молия дар ш.Хӯҷанд',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350403820',
    'bank_name': 'Филиали ҶСП Аввалин бонки молиявии хурд',
    'bank_addr1': 'Муросилотҳои байнибонки',
  },
  {
    'bank_code': '350501876',
    'bank_name': 'ҶСП ТАҚХ Имон Интернешнл  дар ш. Хуҷанд',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350202816',
    'bank_name': 'Филиали ҶСП Аввалин бонки молиявии хурд',
    'bank_addr1': 'Муросилотҳои байнибонки',
  },
  {
    'bank_code': '350501819',
    'bank_name': 'Филиали ҶСП Аввалин бонки молиявии хурд',
    'bank_addr1': 'Муросилотҳои байнибонки тариқи',
  },
  {
    'bank_code': '359004815',
    'bank_name': 'Филиали ҶСП Аввалин бонки молиявии хурд',
    'bank_addr1': 'Муросилотҳои байнибонки',
  },
  {
    'bank_code': '350101101',
    'bank_name': 'Бонки миллии Тоҷикистон ',
    'bank_addr1': 'Ташкилотҳои қарзӣ',
  },
  {
    'bank_code': '350101800',
    'bank_name': 'Сарраёсати хазинадории марказии Вазорати молияи ҶТ',
    'bank_addr1': ' ',
  },
  {
    'bank_code': '350101821',
    'bank_name': 'Филиали ҶСП Аввалин бонки молиявии хурд',
    'bank_addr1': 'ш.Душанбе',
  },
  {
    'bank_code': '350101822',
    'bank_name': 'Филиали ҶСП Аввалин бонки молиявии хурд',
    'bank_addr1': 'Муросилотҳои байнибонки',
  },
  {
    'bank_code': '350101626',
    'bank_name': 'КВД БА ҶТ Амонатбонк',
    'bank_addr1': ' ',
  },
  {
    'bank_code': '350101706',
    'bank_name': 'Филиали БТ Тиҷорат-ҶИ Эрон',
    'bank_addr1': 'Ташкилотҳои қарзӣ',
  },
  {
    'bank_code': '350101720',
    'bank_name': 'ҶСК Тавҳидбанк дар ш.Душанбе',
    'bank_addr1': 'Ташкилотҳои қарзӣ',
  },
  {
    'bank_code': '350101736',
    'bank_name': 'ҶСП Бонки Рушди Тоҷикистон',
    'bank_addr1': 'Ташкилотҳои қарзӣ',
  },
  {
    'bank_code': '350101777',
    'bank_name': 'Бонки байнидавлатӣ дар ш.Москва',
    'bank_addr1': 'Ташкилотҳои қарзӣ',
  },
  {
    'bank_code': '350101779',
    'bank_name': 'ҶСП Актив Бонк дар ш.Душанбе',
    'bank_addr1': 'Ташкилотҳои қарзӣ',
  },
  {
    'bank_code': '350101781',
    'bank_name': 'ҶСП Бонки фаръии БМП Покистон',
    'bank_addr1': 'Ташкилотҳои қарзӣ',
  },
  {
    'bank_code': '350101803',
    'bank_name': 'ҶСП Бонки байналмиллалии Тоҷикистон',
    'bank_addr1': 'Ташкилотҳои қарзӣ',
  },
  {
    'bank_code': '350101805',
    'bank_name': 'ҶСП Аввалин бонки молиявии хурд',
    'bank_addr1': ' ',
  },
  {
    'bank_code': '350101808',
    'bank_name': 'ҶСП Спитамен Бонк',
    'bank_addr1': 'Ташкилотҳои қарзӣ',
  },
  {
    'bank_code': '350501707',
    'bank_name': 'ҶСК Бонки Эсхата',
    'bank_addr1': 'Ташкилотҳои қарзӣ',
  },
  {
    'bank_code': '350101817',
    'bank_name': 'ҶСП ТАҚХ Ҳамров',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350101823',
    'bank_name': 'ҶСП ТАҚХ Зудамал',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350101842',
    'bank_name': 'ҶДММ ТАҚХ Молия ва сармоя',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350101857',
    'bank_name': 'ҶДММ ТАҚХ Арғун дар ш.Душанбе',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350101841',
    'bank_name': 'ҶСП Душанбе Сити Бонк дар ш.Душанбе',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350101880',
    'bank_name': 'ҶСП ТАҚХ Сандуқ дар ш.Душанбе',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350403910',
    'bank_name': 'ҶДММ ТАҚХ Фурӯз',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350101970',
    'bank_name': 'ҶСП ТАҚХ Шукр Молия',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
  {
    'bank_code': '350101899',
    'bank_name': 'ҶДММ ТАҚХ Пайванд Гуруҳ',
    'bank_addr1': 'ш. Душанбе',
  },
  {
    'bank_code': '350101814',
    'bank_name': 'Филиали ҶСП БАМХ дар н.Рашт ',
    'bank_addr1': 'DUSHANBE',
  },
  {
    'bank_code': '350101895',
    'bank_name': 'ҶСП ТАҚХ Ардо-Капитал',
    'bank_addr1': 'ш. Душанбе, н. И.Сомонӣ,',
  },
  {
    'bank_code': '350101655',
    'bank_name': 'КВД БССТ Саноатсодиротбонк',
    'bank_addr1': 'КВД БССТ Саноатсодиротбонк',
  },
  {
    'bank_code': '350101972',
    'bank_name': 'ҶДММ ТАҚХ Баракат Молия',
    'bank_addr1': 'ҶДММ ТАҚХ',
  },
  {
    'bank_code': '350101971',
    'bank_name': 'ҶДММ ТАҚХ Эмин Сармоя',
    'bank_addr1': 'ҶДММ ТАҚХ',
  },
  {
    'bank_code': '350101820',
    'bank_name': 'ҶДММ ТАҚХ Васл дар ш.Душанбе',
    'bank_addr1': 'Ташкилотҳои молиявии ғайрибонкӣ',
  },
];

export default banks;
