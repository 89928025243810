import { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useTranslation } from 'react-i18next';

import { getAccountBalanceApi, getReceiveAccountsApi, getSenderAccountsApi } from '@http/accounts';
import { exchangeTransactionApi, getExchangeRateApi } from '@http/payments';

import Currencies from '@components/Currency/OrienbankRates';
import SenderAccountsModal from '@components/Payments/modals/SenderAccountsModal';
import RecipientAccountsModal from '@components/Payments/modals/RecipientAccountsModal';
import ConfirmPaymentModal from '@components/Payments/modals/ConfirmPaymentModal';

import { exchangeQueryAC, exchangeXrateAC, isConfirmPaymentModalOpenAC } from '@store/reducers/payments';

import {
  senderAccountBalanceAC,
  senderAccountsModalOpenAC,
  receiveAccountsAC,
  selectedSenderAccountAC,
  selectedToAccountAC,
  toAccountBalanceAC,
  recipientAccountsModalOpenAC,
  selectedRecipientAccountAC,
} from '@store/reducers/accounts';

import { otpConfirmAC, repeatExchangeTransactionAC, verifyTransIdAC } from '@store/reducers/transactions';
import ExchangeForm from '@components/Payments/forms/ExchangeForm';

function Exchange() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const senderAccounts = useAppSelector(state => state.accountsReducer.senderAccounts);
  const receiveAccounts = useAppSelector(state => state.accountsReducer.receiveAccounts);
  const repeatExchangeTransaction = useAppSelector(state => state.transactionsReducer.repeatExchangeTransaction);
  const exchangeQuery = useAppSelector(state => state.paymentsReducer.exchangeQuery);

  const [senderAccountCurrency, setSenderAccountCurrency] = useState('');
  const [toAccountCurrency, setToAccountCurrency] = useState('');

  useEffect(() => {
    initializeData();

    return clearFields;
  }, []);

  useEffect(() => {
    repeatExchangeTransactionHandler();
  }, [repeatExchangeTransaction]);

  useEffect(() => {
    dispatch(otpConfirmAC(false));
  }, [exchangeQuery]);

  const senderAccountSelect = (account: any) => {
    setToAccountCurrency('');
    setSenderAccountCurrency(account.ccy);
    dispatch(
      exchangeQueryAC({
        ...exchangeQuery,
        sending_ac: account.value,
        receiving_ac: '',
        sending_amount: '',
        receiving_amount: '',
        xrate: '',
      })
    );

    form.setFieldValue('sending_ac', account.value);
    form.setFieldValue('xrate', '');

    dispatch(senderAccountsModalOpenAC(false));
    dispatch(selectedSenderAccountAC(account.value));
    dispatch(getAccountBalanceApi('sender', { cust_ac_no: account.value }));
    dispatch(receiveAccountsAC([]));
    dispatch(selectedToAccountAC({}));
    dispatch(toAccountBalanceAC(0));
    dispatch(exchangeXrateAC(''));
    dispatch(
      getReceiveAccountsApi({
        transaction_type: 'EXCHANGE',
        cust_ac_no: account.value,
      })
    );
  };

  const toAccountSelect = (account: any) => {
    setToAccountCurrency(account.ccy);
    dispatch(
      exchangeQueryAC({
        ...exchangeQuery,
        receiving_ac: account.value,
      })
    );

    form.setFieldValue('receiving_ac', account.value);

    dispatch(recipientAccountsModalOpenAC(false));
    dispatch(selectedToAccountAC(account));
    dispatch(selectedRecipientAccountAC(account.value));
    dispatch(getAccountBalanceApi('to', { cust_ac_no: account.value }));
    dispatch(
      getExchangeRateApi(
        {
          sending_ccy: senderAccountCurrency,
          receiving_ccy: account.ccy,
        },
        (data: any) => {
          form.setFieldValue('xrate', data.xrate);
          dispatch(
            exchangeQueryAC({
              ...exchangeQuery,
              receiving_ac: account.value,
              xrate: data.xrate,
              sending_amount: '',
              receiving_amount: '',
            })
          );
        }
      )
    );
  };

  const exchangeTransaction = () => {
    dispatch(exchangeTransactionApi(exchangeQuery, navigate, () => clearFields()));
  };

  const initializeData = () => {
    dispatch(exchangeXrateAC(''));
    dispatch(selectedSenderAccountAC({}));
    dispatch(selectedToAccountAC({}));
    dispatch(receiveAccountsAC([]));
    dispatch(senderAccountBalanceAC(0));
    dispatch(toAccountBalanceAC(0));
    dispatch(
      getSenderAccountsApi({
        transaction_type: 'EXCHANGE',
      })
    );
  };

  const repeatExchangeTransactionHandler = () => {
    if (Object.keys(repeatExchangeTransaction).length > 0) {
      setSenderAccountCurrency(repeatExchangeTransaction.sending_ccy);
      setToAccountCurrency(repeatExchangeTransaction.receiving_ccy);
      dispatch(
        exchangeQueryAC({
          ...exchangeQuery,
          sending_ac: repeatExchangeTransaction.sending_ac,
          receiving_ac: repeatExchangeTransaction.receiving_ac,
          xrate: repeatExchangeTransaction.xrate,
          narrative: repeatExchangeTransaction.narrative,
        })
      );

      form.setFieldValue('sending_ac', repeatExchangeTransaction.sending_ac);
      form.setFieldValue('receiving_ac', repeatExchangeTransaction.receiving_ac);
      form.setFieldValue('xrate', repeatExchangeTransaction.xrate);
      form.setFieldValue('narrative', repeatExchangeTransaction.narrative);

      dispatch(getAccountBalanceApi('sender', { cust_ac_no: repeatExchangeTransaction.sending_ac }));
      dispatch(getAccountBalanceApi('to', { cust_ac_no: repeatExchangeTransaction.receiving_ac }));
      dispatch(exchangeXrateAC(repeatExchangeTransaction.xrate));
    }
  };

  const clearFields = () => {
    dispatch(exchangeQueryAC({}));

    form.resetFields();

    setToAccountCurrency('');
    setSenderAccountCurrency('');
    dispatch(isConfirmPaymentModalOpenAC(false));
    dispatch(selectedSenderAccountAC({}));
    dispatch(selectedToAccountAC({}));
    dispatch(receiveAccountsAC([]));
    dispatch(senderAccountBalanceAC(0));
    dispatch(toAccountBalanceAC(0));
    dispatch(otpConfirmAC(false));
    dispatch(verifyTransIdAC(''));
    dispatch(repeatExchangeTransactionAC({}));
    dispatch(selectedRecipientAccountAC({}));
  };

  return (
    <div className='md:grid grid-cols-6 gap-10 mt-5'>
      <div className='col-span-4'>
        <ExchangeForm form={form} toAccountCurrency={toAccountCurrency} senderAccountCurrency={senderAccountCurrency} />
        <SenderAccountsModal accounts={senderAccounts} selectHandler={senderAccountSelect} />
        <RecipientAccountsModal accounts={receiveAccounts} selectHandler={toAccountSelect} />
        <ConfirmPaymentModal
          title={`${t('Exchange')}`}
          paymentInfo={exchangeQuery}
          currency={senderAccountCurrency}
          recepientCurrency={toAccountCurrency}
          paymentTransactionHandler={exchangeTransaction}
          clearFields={clearFields}
          route={'exchangePaymentStatus'}
          type='EXCHANGE'
        />
      </div>
      <div className='col-span-2 max-md:hidden'>
        <Currencies size='max-lg:text-xs text-sm' />
      </div>
    </div>
  );
}

export default Exchange;
