import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getCountriesApi } from '@http/global';
import { Button, Divider, Input, Select } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ForeigsForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const countries = useAppSelector(state => state.globalReducer.countries);

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    dispatch(getCountriesApi());
  }, []);

  return (
    <div className='mt-3'>
      <p className='text-base color-active font-semibold mb-4'>{t('Основная информация')}</p>
      <div className='md:grid grid-cols-5'>
        <p className='col-span-1 text-bs color-title'>{t('Name')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <div className='md:grid grid-cols-5 mt-7'>
        <p className='col-span-1 text-bs color-title'>{t('TIN')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <Divider />
      <p className='text-base color-active font-semibold mb-4'>{t('Информация о контрагенте')}</p>
      <div className='md:grid grid-cols-5'>
        <p className='col-span-1 text-bs color-title'>{t('Account number')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <div className='md:grid grid-cols-5 mt-7'>
        <p className='col-span-1 text-bs color-title'>{t('KPP')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <div className='md:grid grid-cols-5 mt-7'>
        <p className='col-span-1 text-bs color-title'>{t('Country')}:</p>
        <div className='col-span-2'>
          <Select
            size='large'
            style={{ width: '100%' }}
            allowClear
            showSearch
            optionFilterProp='children'
            filterOption={filterOption}
            options={countries}
            placeholder={`${t('Enter Country')}`}
          />
        </div>
      </div>
      <div className='md:grid grid-cols-5 mt-7'>
        <p className='col-span-1 text-bs color-title'>{t('City')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <div className='md:grid grid-cols-5 mt-7'>
        <p className='col-span-1 text-bs color-title'>{t('Address')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <Divider />
      <p className='text-base color-active font-semibold mb-4'>{t('Информация о банке')}</p>
      <div className='md:grid grid-cols-5'>
        <p className='col-span-1 text-bs color-title'>{t('Bank name')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <div className='md:grid grid-cols-5 mt-7'>
        <p className='col-span-1 text-bs color-title'>{t('Swift')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <div className='md:grid grid-cols-5 mt-7'>
        <p className='col-span-1 text-bs color-title'>{t('BIC')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <div className='md:grid grid-cols-5 mt-7'>
        <p className='col-span-1 text-bs color-title'>{t('Country')}:</p>
        <div className='col-span-2'>
          <Select
            size='large'
            style={{ width: '100%' }}
            allowClear
            showSearch
            optionFilterProp='children'
            filterOption={filterOption}
            options={countries}
            placeholder={`${t('Enter Country')}`}
          />
        </div>
      </div>
      <Divider />
      <p className='text-base color-active font-semibold mb-4'>{t('Contacts')}</p>
      <div className='md:grid grid-cols-5'>
        <p className='col-span-1 text-bs color-title'>{t('Phone number')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <div className='md:grid grid-cols-5 mt-7'>
        <p className='col-span-1 text-bs color-title'>{t('Email')}:</p>
        <div className='col-span-2'>
          <Input style={{ width: '100%' }} size='large' />
        </div>
      </div>
      <div className='md:grid grid-cols-5 mt-7'>
        <div className='col-span-3 flex justify-end'>
          <Button type='primary' size='middle'>
            {t('Confirm')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ForeigsForm;
