import { Route, Routes } from 'react-router-dom';
import BulkTransactionInfo from '@components/BulkTransaction/BulkTransactionInfo';
import BulkTransactionList from '@components/BulkTransaction/BulkTransactionList';

const BulkTransactionIndex = () => {
  return (
    <div className='flex justify-center'>
      <div className='lg:w-[1130px] w-full'>
        <div className='flex'>
          <div className='w-full h-full bg-white mb-12'>
            <div className='mt-5'>
              <Routes>
                <Route path='list' element={<BulkTransactionList />} key='list' />
                <Route path='info/*' element={<BulkTransactionInfo />} key='info' />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkTransactionIndex;
